import Service from '@ember/service';
import { DAILY, WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';

const hourDisplay = (hours) => {
  const suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours === '00' ? 12 : hours;
  return `${hours}:00 ${suffix}`;
};

const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) return i + 'st';
  if (j === 2 && k !== 12) return i + 'nd';
  if (j === 3 && k !== 13) return i + 'rd';
  return i + 'th';
};

const hoursInDay = () =>
  [...Array(24).keys()].map((h) => ({
    id: h,
    text: 'at ' + hourDisplay(h),
  }));

const daysInMonth = () =>
  [...Array(31).keys()]
    .map((d) => ++d)
    .map((d) => ({
      id: d,
      text: 'on ' + ordinalSuffix(d),
    }));

export default class ReportDataService extends Service {
  scheduleIntervals = [
    { id: DAILY, text: 'Daily' },
    { id: WEEKLY, text: 'Weekly' },
    { id: MONTHLY, text: 'Monthly' },
  ];

  daysInWeek = [
    { id: 0, text: 'On Sunday' },
    { id: 1, text: 'On Monday' },
    { id: 2, text: 'On Tuesday' },
    { id: 3, text: 'On Wednesday' },
    { id: 4, text: 'On Thursday' },
    { id: 5, text: 'On Friday' },
    { id: 6, text: 'On Saturday' },
  ];

  hoursInDay = hoursInDay();
  daysInMonth = daysInMonth();
}
