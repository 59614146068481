import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TagsListComponent extends Component {
  @tracked isAdding = false;

  get canAddANewTag() {
    return this.args.editableTags && !this.isAdding;
  }

  @action
  addNewTag() {
    this.isAdding = true;
  }

  @action
  dismissNewTag() {
    this.isAdding = false;
  }

  @action
  submitNewTag(newTag) {
    this.args.addTag(newTag);
    if (this.isAdding) this.isAdding = false;
  }
}
