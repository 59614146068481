import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const GROUPS_ORDER_KEY = 'nw:sidebar:groups';

export default class UrlSelectorComponent extends Component {
  /*
    Important that this is a real property rather than getter/setter
    Because it is mutated by the `<SortableObjects /> component
   */
  @tracked sortedUrlGroups = [];

  @action
  setSortedUrlGroups() {
    const comparator = (persistedOrder) => (a, b) => {
      if (!a.id || !b.id) return 0;
      const aIdx = persistedOrder.indexOf(a.id);
      const bIdx = persistedOrder.indexOf(b.id);
      return aIdx > bIdx ? 1 : aIdx < bIdx ? -1 : 0;
    };
    const order = this.groupsOrder;

    this.sortedUrlGroups = this.args.urlGroups
      .toArray()
      .sort(comparator(order));
  }

  get groupsOrder() {
    try {
      return JSON.parse(localStorage.getItem(GROUPS_ORDER_KEY)) || [];
    } catch {
      return [];
    }
  }

  set groupsOrder(value) {
    value !== null
      ? localStorage.setItem(GROUPS_ORDER_KEY, JSON.stringify(value))
      : localStorage.removeItem(GROUPS_ORDER_KEY);
  }

  @action
  saveGroupsOrder() {
    this.groupsOrder = this.sortedUrlGroups.mapBy('id');
  }
}
