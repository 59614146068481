import { modifier } from 'ember-modifier';

/**
 @param {HTMLElement} scrollToElement - Scroll offset will be calculated to this element
 @param {Array} _args - Unused args that are watched by the modifier
 @param {Boolean} enabled - Aborted unless this is true
 */
const scrollParentHere = (scrollToElement, _args, { enabled }) => {
  if (!scrollToElement || !enabled) return;

  // Scrolls the native `offsetParent` of `scrollToElement`
  scrollToElement.offsetParent.scrollTo({
    top: scrollToElement.offsetTop,
    behavior: 'smooth',
  });
};

export default modifier(scrollParentHere, { eager: false });
