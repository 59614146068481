import { inject as service } from '@ember/service';
import Model, { belongsTo, attr } from '@ember-data/model';
import { DAILY, WEEKLY } from 'nightwatch-web/constants/time-periods';
import { task } from 'ember-concurrency';

export default class Report extends Model {
  @service('report-mailer') reportMailerService;
  @service siteData;

  @attr('number') local_report_day;
  @attr('number') local_report_hour;
  @attr('number') day_in_month;
  @attr('string') report_format;
  @attr('string') name;
  @attr() recipients;
  @attr() keyword_ids;
  @attr() graph_ids;
  @attr('boolean') scheduled;
  @attr('number') schedule_interval;
  @attr('number') report_interval;
  @attr('boolean') customized_email_enabled;
  @attr('string') custom_subject;
  @attr('string') custom_body;
  @attr('date') start_time;
  @attr('date') end_time;
  @attr('string') date_range_type;
  @attr('date') last_sent_at;
  @attr('number') keyword_count;
  @attr('number') url_count;
  @attr() columns;
  @attr('number') whitelabel_logo_id;

  // New report attrs
  @attr('number') report_version; // 1 for old, 2 for new
  @attr() report_config; // For blocks
  @attr('string') template_name; // basic_report, ...

  @belongsTo('url', { async: true }) url;
  @belongsTo('dynamic-view', { async: true }) dynamicView;

  get isNewVersion() {
    return this.report_version === 2;
  }

  get isDaily() {
    return this.schedule_interval === DAILY;
  }

  get isWeekly() {
    return this.schedule_interval === WEEKLY;
  }

  get description() {
    if (this.get('dynamicView.id')) {
      return `View report: ${this.name}`;
    } else {
      if (this.name) {
        return `URL report: ${this.name}`;
      } else {
        return 'URL report';
      }
    }
  }

  setEmailParams() {
    const { siteTitle } = this.siteData;

    if (!this.custom_subject) {
      this.custom_subject = `Your ${siteTitle} Report`;
    }
    if (!this.custom_body) {
      this.custom_body = `Hey, \n\nYour ${siteTitle} report is attached. \n\nHave a great day,\n- ${siteTitle} Team`;
    }
  }

  @task({ drop: true })
  *sendReport() {
    return yield this.reportMailerService.sendReport(this);
  }
}
