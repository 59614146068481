const queryParamFragment = (queryParams) => {
  let fragment = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join('&');
  return fragment ? `?${fragment}` : '';
};

export default queryParamFragment;
