import Model, { belongsTo, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class Note extends Model {
  @attr('number') accountId;
  @attr('day-date') date;
  @attr('string') text;

  @belongsTo('url', { async: false }) url;

  get isGlobal() {
    return isPresent(this.accountId);
  }
}
