import { helper } from '@ember/component/helper';

export const upDownStagnant = ([value, invertValue]) => {
  if (invertValue) value = -value;
  if (value > 0) return 'up';
  if (value < 0) return 'down';
  return 'stagnant';
};

export default helper(upDownStagnant);
