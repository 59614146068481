import Model, { belongsTo, attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';

export default class Backlink extends Model {
  @attr('string') urlFrom;
  @attr('string') domain;
  @attr('string') rootDomain;
  @attr('string') urlTo;
  @attr('number') pageAuthority;
  @attr('number') domainAuthority;
  @attr('number') urlRating;
  @attr('number') domainRating;
  @attr('string') ipFrom;
  @attr('string') title;
  @attr('date') firstSeen;
  @attr('date') lastVisited;
  @attr('date') prevVisited;
  @attr('string') linkType;
  @attr('number') redirect;
  @attr('boolean') nofollow;
  @attr('string') alt;
  @attr('string') rel;
  @attr('string') anchor;
  @attr('boolean') active;
  @attr('string') source;
  @attr('string') status;
  @attr() tags;

  @belongsTo('url', { async: false }) url;

  get isProcessing() {
    return isBlank(this.lastVisited);
  }

  get sourceDisplay() {
    if (this.source === 'ahrefs') {
      return 'Auto-Discovery';
    } else {
      return 'Manual';
    }
  }
}
