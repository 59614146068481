import { sub, startOfDay, startOfMonth, endOfMonth } from 'date-fns';

export const relativeDateRanges = () => {
  const today = startOfDay(new Date());
  const yesterday = sub(startOfDay(new Date()), { days: 1 });
  return [
    {
      type: 'today',
      name: 'Today',
      start: sub(today, { days: 1 }),
      end: today,
    },
    {
      type: 'day',
      name: 'Yesterday',
      start: sub(yesterday, { days: 1 }),
      end: yesterday,
    },
    {
      type: '2_days',
      name: 'Last 2 days',
      start: sub(yesterday, { days: 2 }),
      end: yesterday,
    },
    {
      type: 'week',
      name: 'Last 7 days',
      start: sub(yesterday, { days: 7 }),
      end: yesterday,
    },
    {
      type: '2_weeks',
      name: 'Last 14 days',
      start: sub(yesterday, { days: 14 }),
      end: yesterday,
    },
    {
      type: '30_days',
      name: 'Last 30 days',
      start: sub(yesterday, { days: 30 }),
      end: yesterday,
    },
    {
      type: 'last_month',
      name: 'Last month',
      start: startOfMonth(sub(yesterday, { months: 1 })),
      end: endOfMonth(sub(yesterday, { months: 1 })),
    },
    {
      type: 'this_month',
      name: 'This month',
      start: startOfMonth(yesterday),
      end: yesterday,
    },
    {
      type: '3_months',
      name: 'Last 3 months',
      start: startOfMonth(sub(yesterday, { months: 3 })),
      end: yesterday,
    },
    {
      type: '6_months',
      name: 'Last 6 months',
      start: startOfMonth(sub(yesterday, { months: 6 })),
      end: yesterday,
    },
    {
      type: '12_months',
      name: 'Last year',
      start: startOfMonth(sub(yesterday, { months: 12 })),
      end: yesterday,
    },
  ];
};
