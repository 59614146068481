import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NwAddKeywordsInlineComponent extends Component {
  @tracked isSaved = false;
  @action
  triggerSaveKeywords(provider) {
    provider.saveKeywords.saveKeywordsTask.perform().then((res) => {
      if (provider.saveKeywords.saveKeywordsTask.lastComplete) {
        this.args.onSaveKeywordsComplete(res.success);
        this.isSaved = true;
        this.temporalyShowSaved();
      }
    });
  }

  temporalyShowSaved() {
    setTimeout(() => {
      this.isSaved = false;
    }, 2000);
  }
}
