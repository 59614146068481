import KEYWORD_DISTRIBUTION_PROPS from '../../../constants/chart/keyword-distribution-props';
import { groupMethods } from '../grouping';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';
import dimensionSources from './dimension-sources';
import { inject as service } from '@ember/service';

class UrlDimension extends Dimension {
  requestWith = ['url'];

  get findData() {
    return (
      this.providedData
        ?.find((d) => d.url_id === parseInt(this.url.id, 10) && d.url_series)
        ?.url_series?.findBy('name', this.name)?.series ?? []
    );
  }
}

class TrafficDimension extends Dimension {
  endpoint = 'trafficStats';

  get findData() {
    return this.providedData?.[`${this.name}_series`] ?? [];
  }

  get lastValue() {
    return this.providedData?.[`${this.name}_sum`];
  }

  get change() {
    return this.providedData?.[`${this.name}_change`];
  }
}

export class UrlDimensions extends DimensionGroup {
  @service session;
  label = 'Website';

  constructor() {
    super(...arguments);
    const props = { ...this.dimensionProps, subTitle: this.url.friendlyUrl };
    this.dimensions = [
      new UrlDimension({
        ...props,
        name: 'average_position',
        scaleId: 'nwPosition',
        title: 'Average Position',
        label: 'Average Position',
      }),
      new UrlDimension({
        ...props,
        name: 'search_visibility_index',
        title: 'Search Visibility',
        label: 'Search Visibility',
      }),
      new UrlDimension({
        ...props,
        name: 'click_potential',
        title: 'Click Potential',
        label: 'Click Potential',
      }),
      new UrlDimension({
        ...props,
        name: 'total_keywords',
        title: 'Total Keywords',
        label: 'Total Keywords',
      }),
      new UrlDimension({
        ...props,
        ...KEYWORD_DISTRIBUTION_PROPS,
        label: `Keyword Distribution | ${this.url.name}`,
      }),
      new TrafficDimension({
        ...props,
        name: 'clicks',
        title: 'Clicks',
        label: 'Clicks',
        groupMethod: groupMethods.sum,
        source: dimensionSources.searchConsole,
      }),
      new TrafficDimension({
        ...props,
        name: 'impressions',
        title: 'Impressions',
        label: 'Impressions',
        groupMethod: groupMethods.sum,
        source: dimensionSources.searchConsole,
      }),
      new TrafficDimension({
        ...props,
        name: 'sessions',
        title: 'Sessions',
        label: 'Sessions',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'pageviews',
        title: 'Pageviews',
        label: 'Pageviews',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'users',
        title: 'Users',
        label: 'Users',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'organic',
        title: 'Organic Traffic',
        label: 'Organic Traffic',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'referral',
        title: 'Referral Traffic',
        label: 'Referral Traffic',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'ads',
        title: 'Paid Traffic',
        label: 'Paid Traffic',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new TrafficDimension({
        ...props,
        name: 'social',
        title: 'Social Media Traffic',
        label: 'Social Media Traffic',
        groupMethod: groupMethods.sum,
        source: dimensionSources.googleAnalytics,
      }),
      new UrlDimension({
        ...props,
        name: 'search_console_position',
        title: 'Search Console Position',
        label: 'Search Console Position',
        scaleId: 'nwPosition',
        source: dimensionSources.searchConsole,
      }),
      new UrlDimension({
        ...props,
        name: 'ctr',
        title: 'CTR',
        label: 'CTR',
        source: dimensionSources.searchConsole,
      }),
      new UrlDimension({
        ...props,
        name: 'indexed_pages',
        title: 'Indexed Pages',
        label: 'Indexed Pages',
      }),
    ];
    // Backlinks specific dimensions. Only show in the selector widget if backlinks are enabled.
    if (false) {
      this.dimensions.push(
        new UrlDimension({
          ...props,
          name: 'moz_domain_authority',
          title: 'Domain Authority',
          label: 'Domain Authority',
        }),
        new UrlDimension({
          ...props,
          name: 'moz_page_authority',
          title: 'Page Authority',
          label: 'Page Authority',
        }),
        new UrlDimension({
          ...props,
          name: 'ahrefs_domain_rating',
          title: 'Domain Rating',
          label: 'Domain Rating',
        }),
        new UrlDimension({
          ...props,
          name: 'ahrefs_rank',
          title: 'URL Rating',
          label: 'URL Rating',
        }),
        new UrlDimension({
          ...props,
          name: 'majestic_trust_flow',
          title: 'Trust Flow',
          label: 'Trust Flow',
        }),
        new UrlDimension({
          ...props,
          name: 'majestic_citation_flow',
          title: 'Citation Flow',
          label: 'Citation Flow',
        })
      );
    }
  }
}
