import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordsAddDiscoverRoute extends Route {
  @service metrics;
  @service store;

  queryParams = {
    sort: {
      refreshModel: true,
    },
    direction: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    with_tracked: {
      refreshModel: true,
    },
    status: {
      refreshModel: true,
    },
  };

  model(params) {
    const url = this.modelFor('dashboard.url');
    return this.store.query('keyword-suggestion', {
      url_id: url.id,
      sort: params.sort,
      direction: params.direction,
      limit: 50,
      page: params.page || 1,
      with_tracked: params.with_tracked,
      with_ignored: true,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('url', this.modelFor('dashboard.url'));
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Add Keyword Suggestions' });
    return true;
  }
}
