import { helper } from '@ember/component/helper';
import { DAILY, WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';

export const INTERVALS_HUMANIZE_NAMES = {
  [DAILY]: 'daily',
  [WEEKLY]: 'weekly',
  [MONTHLY]: 'monthly',
};

export default helper(([interval]) => INTERVALS_HUMANIZE_NAMES[interval]);
