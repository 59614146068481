import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import loadExternalScripts from '../../utils/load-external-scripts';
import ENV from 'nightwatch-web/config/environment';
import { format, sub } from 'date-fns';
import { v4 } from 'ember-uuid';
import DOCS from '../../constants/docs';
import { htmlSafe } from '@ember/template';
import isSafari from '../../utils/isSafari';
/**
 * This component wraps a keyword table for importing GSC keywords from a GSC account.
 *
 * @param url {Url} current URL
 * @param keywordPreciseLocation {String} keywordPreciseLocation from keyword instance.
 * @param provider Provider instance for the parent page.
 * Resources for auth flow: https://developers.google.com/identity/sign-in/web/reference#googleauthsignin
 * Resources for auth GSC flow: https://developers.google.com/webmaster-tools/v1/searchanalytics/
 */

const GAPI_SCRIPT_URL = 'https://apis.google.com/js/api.js';

export default class GscKeywordImportProviderComponent extends Component {
  @service store;
  @service notifications;
  @service discovery;
  @tracked showGSCImport = false;
  @tracked selectedKeywords = [];
  @tracked keywordResults = [];
  @tracked selectedSCPropertyID = null;
  @tracked loading = false;
  @tracked showAuthenticateButton = false;
  @tracked searchConsoleProperties = [];
  @tracked sort = 'key';
  @tracked direction = 'asc';

  columns = {
    default: {
      name: 'keyword-query',
      displayName: 'Keyword',
      sortProperty: 'key',
    },
    selected: [
      {
        name: 'keyword-position',
        displayName: 'Position',
        sortProperty: 'position',
      },
      {
        name: 'keyword-clicks',
        displayName: 'Clicks',
        sortProperty: 'clicks',
      },
      {
        name: 'keyword-impressions',
        displayName: 'Impressions',
        sortProperty: 'impressions',
      },
      {
        name: 'keyword-ctr',
        displayName: 'CTR',
        sortProperty: 'ctr',
      },
    ],
    drop: () => null,
  };

  get keywordProperties() {
    return {
      url: this.args.url,
      google_gl: this.args.url?.country_code?.toLowerCase(),
      google_hl: this.args.url?.language_code?.toLowerCase(),
      engine: 'google',
      tags: [],
      precise_location: this.args.keywordPreciseLocation,
      mobile: false,
    };
  }

  get keywords() {
    if (this.sort) return this.sortedItems;
    return this.keywordResults || [];
  }

  get searchConsoleProperty() {
    return (
      this.selectedSCPropertyID || this.args.url.search_console_url || null
    );
  }

  get isGoogleAuthenticated() {
    return window.gapi?.auth2?.getAuthInstance?.()?.isSignedIn.get();
  }

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  get sortedItems() {
    let items = (this.keywordResults || []).sortBy(this.sort);
    if (this.direction === 'desc') {
      items = items.reverse();
    }
    return items;
  }

  @action
  onSort(sort, direction) {
    this.sort = sort;
    this.direction = direction;
  }

  @action
  async onInsert() {
    this.loading = true;
    if (!window.gapi) {
      await loadExternalScripts([GAPI_SCRIPT_URL]);
    }
    if (!window.gapi) {
      this.notifications.error(
        'There was an error loading Google Authentication please disable any installed ad blockers',
        { autoClear: true }
      );
    }
    // if (isSafari) return;
    window.gapi.load('client:auth2', async () => {
      window.gapi.auth2.init({ client_id: ENV.googleClientId });
      await this.loadClient();
      await this.loadSearchConsoleProperties();
      if (this.searchConsoleProperties.length === 0) {
        this.loading = false;
        return;
      }
      await this.getGSCKeywords();
      this.loading = false;
    });
  }

  async loadClient() {
    try {
      await window.gapi.client.load(
        'https://content.googleapis.com/discovery/v1/apis/searchconsole/v1/rest'
      );
      console.info('GAPI client loaded for API');
    } catch (err) {
      console.error('Error loading GAPI client for API', err);
    }
  }

  async getGSCKeywords() {
    if (!this.searchConsoleProperty) return;
    const now = new Date();
    try {
      const request = await window.gapi.client.webmasters.searchanalytics.query(
        {
          siteUrl: this.searchConsoleProperty,
          resource: {
            startDate: format(sub(now, { years: 1 }), 'yyyy-MM-dd'),
            endDate: format(now, 'yyyy-MM-dd'),
            dimensions: ['QUERY'],
          },
        }
      );
      const response = JSON.parse(request.body);
      if (!response.rows) {
        this.notifications.warning(
          'There were no keywords found for the selected Search Console property.',
          { autoClear: true }
        );
        return;
      }
      this.keywordResults = response?.rows.map((row) => {
        return {
          id: v4(),
          ...row,
          keys: row?.keys?.[0],
        };
      });
    } catch (err) {
      if (err.status === 401) {
        await this.authenticate();
      } else if (err.status === 403) {
        this.args.url.search_console_url = null;
      }
      console.error('Execute error', err);
    }
  }

  async loadSearchConsoleProperties() {
    try {
      const request = await window.gapi.client.webmasters.sites.list({});
      const response = JSON.parse(request.body);
      const formattedList = response?.siteEntry?.map((site) => site.siteUrl);
      if (!formattedList) {
        this.notifications.info(
          'There were no search console properties found on this account.',
          { autoClear: true }
        );
        return;
      }
      this.searchConsoleProperties = [...formattedList];
      if (!this.searchConsoleProperty) {
        this.notifications.info(
          'Please select a Search Console Property from the list.',
          { autoClear: true }
        );
      }
    } catch (err) {
      if (err.status === 401) {
        await this.authenticate();
      }
      console.error('Execute error', err);
    }
  }

  async authenticate() {
    try {
      await window.gapi.auth2.getAuthInstance().signIn({
        scope:
          'https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly',
        redirect_uri: 'postmessage',
      });
      await this.getGSCKeywords();
      await this.loadSearchConsoleProperties();
    } catch (err) {
      if (err.error === 'popup_blocked_by_browser') {
        this.showAuthenticateButton = true;
        this.notifications.info(
          'Please click log into your Google account with the Authenticate button.',
          { autoClear: true }
        );
        return;
      }
      if (err.error === 'popup_closed_by_user') {
        this.notifications.error(
          'You must authenticate with your Google account to import from Search Console.',
          { autoClear: true }
        );
        this.args.closeGSCKeywordImport();
        return;
      }
      this.showAuthError();
      console.error('Error signing in', err);
    }
  }

  @action
  async reauthenticate() {
    try {
      await window.gapi.auth2.getAuthInstance().disconnect();
      await window.gapi.auth2.getAuthInstance().signOut();
      await this.authenticate();
    } catch (e) {
      console.error(e);
    }
  }

  showAuthError() {
    const helpDocLink = DOCS.gaPropertiesListingPermissionsError;
    const errorMessage = htmlSafe(
      `There was an error getting your analytics profiles,<br> if the issue persists, please refer to this <a href="${helpDocLink}" target="_blank" style="color: #0078f0; text-decoration: underline;">help document</a> to fix this error.`
    );
    this.notifications.error(errorMessage, {
      autoClear: false,
      onClick: () => {
        window.open(helpDocLink, '_blank');
      },
    });
  }

  @action
  addSelectedKeywords() {
    const keywordsToAdd = this.selectedKeywords.map((kw) => {
      const newKeyword = this.store.createRecord('keyword');
      for (let k in this.keywordProperties) {
        newKeyword[k] = Array.isArray(this.keywordProperties[k])
          ? this.keywordProperties[k].slice()
          : this.keywordProperties[k];
        newKeyword.query = kw.keys;
      }
      return newKeyword;
    });
    this.discovery.keywords = [...this.discovery.keywords, ...keywordsToAdd];
  }

  @action
  importAllKeywords() {
    this.selectedKeywords = [...this.keywordResults];
    this.addSelectedKeywords();
  }

  @action
  async onPropertyChange(property) {
    this.loading = true;
    this.selectedSCPropertyID = property;
    this.args.url.search_console_url = property;
    await this.getGSCKeywords();
    this.loading = false;
  }

  @action
  toggleKeywordSelected(keyword) {
    if (this.selectedKeywords.includes(keyword)) {
      this.selectedKeywords.removeObject(keyword);
    } else {
      this.selectedKeywords.pushObject(keyword);
    }
  }

  @action
  onSelectAllToggleChange(checked) {
    if (checked) {
      this.selectedKeywords = [...this.keywordResults];
    } else {
      this.selectedKeywords = [];
    }
  }
}
