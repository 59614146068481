import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';

export default class WhitelabelLogoListComponent extends Component {
  @service session;
  @service metrics;
  @service notifications;

  @task({ restartable: true, maxConcurrency: 3 })
  *uploadFile(file) {
    try {
      yield file.upload(`${ENV.apiBaseURL}whitelabel_logos`, {
        fileKey: 'whitelabel_logo[logo]',
        data: {
          access_token: this.session.token,
        },
      });
      this.metrics.trackEvent({ event: 'Added Logo' });
      this.args.onFileUploaded();
    } catch (error) {
      this.notifications.error(`File upload failed: ${error.message}`, {
        autoClear: true,
        clearDuration: 10000,
      });
    }
  }

  @action
  switchDefaultLogo(whitelabelLogo) {
    this.args.whitelabelLogos.setEach('default', false);
    whitelabelLogo.default = true;
    this.args.onSwitchDefaultLogo(whitelabelLogo);
  }

  @action
  deleteWhitelabelLogo(whitelabelLogo) {
    if (this.args.whitelabelLogos.length === 0) {
      this.notifications.error(
        'You need to have at least one logo for your white-label.',
        { autoClear: true, clearDuration: 10000 }
      );
      return;
    }
    whitelabelLogo.destroyRecord();
  }
}
