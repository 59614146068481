import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordsAddManualController extends Controller {
  @service router;
  @service session;
  @service notifications;

  @controller('dashboard.url.keywords.add') addKeywordsController;

  get addedKeywordSuggestions() {
    return this.addKeywordsController.addedKeywordSuggestions;
  }

  @action
  onKeywordsAdd(keywords) {
    this.addKeywordsController.removeKeywordSuggestions(keywords);
    this.url.loadKeywordSuggestionCount();
    this.session.user.reload();
    this.url.reload(); // Refresh keyword_count
  }

  @action
  goToUrl() {
    this.router.transitionTo('dashboard.url.keywords', this.url);
    this.notifications.success('Keywords added successfully.', {
      autoClear: true,
      clearDuration: 3500,
    });
  }
}
