import { helper } from '@ember/component/helper';

export function faviconUrl(params) {
  try {
    const hostName = new URL(params[0]).host;
    return `https://unavatar.io/${hostName}`;
  } catch {
    return '/assets/icons/empty-favicon.svg';
  }
}

export default helper(faviconUrl);
