import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NwOnboardingFlowTargetComponent extends Component {
  @service onboardingLocalStorage;

  @tracked googleGl = 'US';
  @tracked googleHl = 'en';
  googlePlace = null;

  get deviceOptions() {
    return [
      { id: 'mobile', text: 'Mobile' },
      { id: 'desktop', text: 'Desktop' },
    ];
  }

  constructor(owner, args) {
    super(owner, args);

    this.args.url.engine = this.args.url.engine || 'google';
    this.args.url.device = this.args.url.device || 'desktop';
  }

  @action
  onNewLocationSelect(locationId) {
    this.args.url.country_code = locationId;
    this.onboardingLocalStorage.setTargetCountryCode(locationId);
    this.onNewLocationSearch(null);
  }

  @action
  onNewLocationSearch(newKeywordPreciseLocation) {
    this.args.updateKeywordPreciseLocation(newKeywordPreciseLocation);
    this.onboardingLocalStorage.setTargetKeywordPreciseLocation(
      newKeywordPreciseLocation
    );
  }

  @action
  onNewEngineSelect(newEngine) {
    this.args.url.engine = newEngine;
    this.onboardingLocalStorage.setTargetEngine(newEngine);
  }

  @action
  onNewDeviceSelect({ id }) {
    this.args.url.device = id;
    this.onboardingLocalStorage.setTargetDevice(id);
  }

  @action
  onNewLanguageCodeSelect({ id }) {
    this.args.url.language_code = id;
    this.onboardingLocalStorage.setTargetLanguageCode(id);
  }
}
