import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { format } from 'date-fns';

export default class SnapshotSelectorContainer extends Component {
  @tracked crawlingSessionsForDay = null;

  @tracked month = this.args.currentCrawlingSession?.startedAt || new Date();

  get crawlingSessions() {
    return this.args.crawlingSessions || [];
  }

  get uniqueCrawlingSessions() {
    return this.args.crawlingSessions.uniqBy('startedAtDateString') || [];
  }

  getSessionsForCalendarDay(day) {
    return this.crawlingSessions.filterBy(
      'startedAtDateString',
      format(new Date(day.date), 'MM-dd-yyyy')
    );
  }

  @action
  dayClass(day) {
    let classes = 'calendar-day';
    if (this.getSessionsForCalendarDay(day).length) {
      classes = classes + ' calendar-day--has-items';
    }
    return classes;
  }

  @action
  selectCrawlingSession(crawlingSession) {
    this.args.closeSnapshotSelector();
    this.args.onSelectCrawlingSession(crawlingSession);
  }

  @action
  onSelectDay(day) {
    this.crawlingSessionsForDay = null;

    const crawlingSessionsForDay = this.getSessionsForCalendarDay({
      date: day,
    });
    switch (crawlingSessionsForDay.length) {
      case 0:
        break;
      case 1:
        this.selectCrawlingSession(crawlingSessionsForDay.firstObject);
        break;
      default:
        this.crawlingSessionsForDay = crawlingSessionsForDay;
    }
  }
}
