import Model, { attr, hasMany } from '@ember-data/model';
import ENV from '../config/environment';

export default class WhitelabelLogo extends Model {
  @attr('string') logo;
  @attr('boolean') default;

  @hasMany('whitelabel-domain', { async: false }) whitelabelDomain;

  get logoImgUrl() {
    return `${ENV.serverURL}${this.logo}`;
  }
}
