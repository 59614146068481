import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ENV from 'nightwatch-web/config/environment';
import loadExternalScripts from '../utils/load-external-scripts';

const BLACK_COLOR = '00000';
const HTML2CANVAS_LIB_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js';

export default class ScreenGrabberService extends Service {
  @service store;
  @service fetch;
  @service notifications;

  /**
   * This task makes and uploads a screenshot
   *
   * @param el {Node} HTML element to sharing
   * @param dateFrom {Date} start date
   * @param dateTo {Date} finish date
   */
  @task({ restartable: true })
  *grabAndUpload(el, dateFrom, dateTo, title) {
    const canvas = yield this.grab.perform(el);
    return yield this.upload.perform(canvas, dateFrom, dateTo, title);
  }

  /**
   * This task makes a screenshot
   *
   * @param el {Node} HTML element to sharing
   */
  @task({ restartable: true })
  *grab(el) {
    yield this._ensureLibrary.perform();
    try {
      return yield window.html2canvas(el, {
        backgroundColor: BLACK_COLOR,
        proxy: ENV.corsProxyURL,
        scale: 2,
        ignoreElements: (element) =>
          element.classList.contains('ember-attacher'),
      });
    } catch (error) {
      this.notifications.error(
        'Cannot make a screenshot. Try again or contact support.'
      );
      throw error;
    }
  }

  /**
   * This task create sharingPage model and commits it to the server
   *
   * @param canvas {Canvas} Canvas with screenshot
   * @param dateFrom {Date} start date
   * @param dateTo {Date} fifnish date
   */
  @task({ restartable: true })
  *upload(canvas, dateFrom, dateTo, title) {
    try {
      return yield this.store
        .createRecord('sharing-page', {
          image: canvas.toDataURL('image/png'),
          dateFrom: dateFrom,
          dateTo: dateTo,
          title: title,
          description: 'SEO Progress Report',
        })
        .save();
    } catch (error) {
      this.notifications.error(
        'There has been an error while preparing the sharing link. Please try again or contact support.'
      );
      throw error;
    }
  }

  @task({ restartable: true })
  *_ensureLibrary() {
    if (window.html2canvas) {
      return;
    }
    try {
      yield loadExternalScripts([HTML2CANVAS_LIB_URL]);
    } catch (error) {
      this.notifications.error(
        'There has been an error while preparing the sharing link. Please try again or contact support.'
      );
      throw error;
    }
  }
}
