import Component from '@glimmer/component';
import { action } from '@ember/object';
import { didCancel } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CompetitorDiscoveryProviderComponent extends Component {
  @service store;
  @service fetch;
  @service session;
  @service metrics;
  @service discovery;
  @service notifications;

  @tracked noteDialog = false;
  @tracked currentNote = null; // new or editable note

  columns = {
    default: {
      name: 'note-date',
      displayName: 'Date',
    },
    selected: [
      {
        name: 'note-text',
        displayName: 'Note',
      },
      {
        name: 'note-actions',
        displayName: '',
      },
    ],
    drop: () => null,
  };

  get title() {
    return this.args.title ?? 'Notes';
  }

  get notes() {
    const all = this.store.peekAll('note').rejectBy('isNew');
    const global = all.filterBy('isGlobal');

    if (this.args.url) {
      const urlNotes = all.filter((note) => {
        return note.belongsTo('url')?.id() === this.args.url.id;
      });
      return urlNotes.concat(global);
    }

    return global;
  }

  @action
  showNoteDialog(note) {
    this.currentNote =
      note ??
      this.store.createRecord('note', {
        url: this.args.url,
        date: new Date(),
      });
    this.noteDialog = true;
  }

  @action
  closeNoteDialog() {
    this.noteDialog = false;
    this.currentNote.rollbackAttributes();
    if (this.currentNote.isNew) {
      this.currentNote.unloadRecord();
    }
    this.currentNote = null;
  }

  @action
  saveNote() {
    const promise = this.currentNote.save();
    promise.then(() => {
      this.notifications.success('Note saved.', { autoClear: true });
      this.noteDialog = false;
      this.args.refreshNotes?.();

      if (this.args.url) {
        this.args.url.loadNotes
          .perform({ reload: true })
          .then(() => {
            this.args.reloadNotesInGraph?.();
          })
          .catch((e) => {
            if (!didCancel(e)) {
              throw e;
            }
          });
      }
    });
    promise.catch(() => {
      this.notifications.error('Something went wrong while saving the note.', {
        autoClear: true,
      });
    });
  }

  @action
  deleteNote(note) {
    if (!confirm('Are you sure you want to delete this note?')) {
      return;
    }

    const promise = note.destroyRecord();
    promise.then(() => {
      this.notifications.success('Note deleted.', { autoClear: true });
      if (this.args.refreshNotes) {
        this.args.refreshNotes();
      }
    });
    promise.catch(() => {
      this.notifications.error(
        'Something went wrong while deleting the note.',
        { autoClear: true }
      );
    });
  }
}
