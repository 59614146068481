import Service from '@ember/service';
import { relativeDateRanges } from 'nightwatch-web/constants/date-ranges';

export default class DateRangeService extends Service {
  RANGE_KEY = null;
  DEFAULT_TYPE = '2_weeks';

  rangeTypes = relativeDateRanges().mapBy('type');

  parseDateRange() {
    let rangeType = localStorage.getItem(this.RANGE_KEY);

    if (!this.rangeTypes.includes(rangeType)) {
      rangeType = this.DEFAULT_TYPE;
      this.persistDateRange(rangeType);
    }

    const { type, start, end } = relativeDateRanges().findBy('type', rangeType);
    return { type, start, end };
  }

  persistDateRange(type = this.DEFAULT_TYPE) {
    localStorage.setItem(this.RANGE_KEY, type);
  }
}
