import Component from '@glimmer/component';
import { sub, parseISO, isToday } from 'date-fns';
import { tracked } from '@glimmer/tracking';

export default class LiveComponent extends Component {
  @tracked showTextChange = true;

  get compareDateMax() {
    const date = this.args.serpPreview?.date
      ? parseISO(this.args.serpPreview.date)
      : new Date();
    return isToday(new Date(date)) ? date : sub(new Date(date), { days: 1 });
  }
}
