import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsNotesRoute extends Route {
  @service store;

  model() {
    return this.store.query('note', {
      global: true,
    });
  }
}
