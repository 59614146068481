import Component from '@glimmer/component';
import { action } from '@ember/object';
import { allSearchEngines } from 'nightwatch-web/constants/keyword-data';
import { allEngines } from '../../utils/filters/keyword-page-filter-state';

export default class NwSelectorsEngineSelectComponent extends Component {
  get options() {
    return this.args.allowAllEngines
      ? [allEngines].concat(allSearchEngines)
      : allSearchEngines;
  }

  get selected() {
    return this.options.findBy('id', this.args.selectedId);
  }

  @action onChange(engine) {
    this.args.onSelected(engine.id);
  }
}
