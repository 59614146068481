const base = {
  display: 'auto', // Only display if one or more dataset is visible
  type: 'linear',
  title: {
    display: false,
    text: '',
  },
};

const scales = Object.freeze({
  nwValue: {
    ...base,
    title: {
      display: true,
      text: 'Analytics Data',
    },
    description: 'Scale for analytics series (anything other than positions)',
  },
  nwStackedValue: {
    ...base,
    display: false,
    stacked: true,
  },
  nwPosition: {
    ...base,
    reverse: true,
    title: {
      display: true,
      text: 'Position',
    },
    description: 'Scale for ranks (avg. position, position)',
  },
  nwNotes: {
    ...base,
    display: false,
  },
});

export default scales;

const displayScales = Object.entries(scales)
  .map(([name, { display }]) => (display ? name : null))
  .compact();

export { displayScales };
