import Model, { attr } from '@ember-data/model';

export default class SharingPage extends Model {
  @attr('string') image;
  @attr('string') title;
  @attr('string') token;
  @attr('date') dateFrom;
  @attr('date') dateTo;
  @attr('string') description;
}
