import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import extractTld from 'nightwatch-web/utils/extract-tld';
import { inject as service } from '@ember/service';
import { startUrlRegex } from '../../utils/regexes';

export default class StartUrlComponent extends Component {
  @service fetch;
  @service siteData;
  @tracked multiple = false;
  @tracked urls = [];

  @tracked errorsForMultiple;
  @tracked urlsAdded;

  @action
  validateUrl(event) {
    const isValid = event.target.value.match(startUrlRegex) !== null;
    if (!isValid && event.target.value.length > 0) {
      event.target.setAttribute('error', 'true');
    } else {
      event.target.setAttribute('error', 'false');
    }
  }

  @action
  fillLocalizationFromDomain() {
    const url = this.args.url.url;
    const tld = extractTld(url);
    if (!tld) {
      return;
    }
    if (['com', 'net', 'org', 'io'].includes(tld.toLowerCase())) {
      this.args.url.country_code = 'US';
      this.args.url.language_code = 'en';
      return;
    }
    if (['gb'].includes(tld.toLowerCase())) {
      this.args.url.country_code = 'UK';
      this.args.url.language_code = 'en';
      return;
    }

    const countryCode = this.siteData.countries.find(
      (countryData) => countryData[0] === tld.toLowerCase()
    );

    if (countryCode) {
      const countryLanguage = this.siteData.countryLanguages.find(
        (countryLanguageData) => {
          const code = countryLanguageData[0].toLowerCase();
          return code === countryCode[0];
        }
      );
      const lang = countryLanguage?.[1];
      this.args.url.country_code = countryCode[0] ?? '';
      this.args.url.language_code = lang ?? 'en';
    }
  }
}
