import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class DashboardUrlChartSettingsController extends Controller {
  @service notifications;
  @service router;

  model() {
    return this.modelFor('dashboard.url');
  }

  @task({ drop: true })
  *deleteGraph() {
    if (!confirm('Are you sure you want to delete this graph?')) return;
    const graph = this.model;
    const url = graph.url;
    yield graph.destroyRecord();
    url.loadGraphs.perform();
    this.router.transitionTo('dashboard.url', url);
    this.notifications.success('Graph deleted.', {
      autoClear: true,
      clearDuration: 2500,
    });
  }

  @task({ drop: true })
  *saveGraph() {
    const graph = this.model;
    yield graph.save();
    graph.url.loadGraphs.perform();
    this.notifications.success('Graph saved.', {
      autoClear: true,
      clearDuration: 3000,
    });
    this.router.transitionTo('dashboard.url.chart', graph);
  }
}
