import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export const SOURCE = {
  MOZ: 'moz',
  AHREFS: 'ahrefs',
  MAJESTIC: 'majestic',
};
const FIRST_GRAPH_TITLE = {
  [SOURCE.MOZ]: 'Page Authority',
  [SOURCE.AHREFS]: 'URL Rating',
  [SOURCE.MAJESTIC]: 'Citation Flow',
};
const SECOND_GRAPH_TITLE = {
  [SOURCE.MOZ]: 'Domain Authority',
  [SOURCE.AHREFS]: 'Domain Rating',
  [SOURCE.MAJESTIC]: 'Trust Flow',
};
const FIRST_GRAPH_FIELD = {
  [SOURCE.MOZ]: 'moz_page_authority',
  [SOURCE.AHREFS]: 'ahrefs_rank',
  [SOURCE.MAJESTIC]: 'majestic_citation_flow',
};
const SECOND_GRAPH_FIELD = {
  [SOURCE.MOZ]: 'moz_domain_authority',
  [SOURCE.AHREFS]: 'ahrefs_domain_rating',
  [SOURCE.MAJESTIC]: 'majestic_trust_flow',
};

const EXPLANATIONS = {
  moz_page_authority:
    'Page Authority is a score that predicts how well a specific page will rank on search engine result pages (SERP). Data source: Moz',
  moz_domain_authority:
    'Domain Authority measures the strength of entire domains or subdomains. Data source: Moz',
  ahrefs_rank:
    'URL Rating measures the strength of a target URL’s backlink profile and the likelihood that the URL will rank high in Google.',
  ahrefs_domain_rating:
    'Domain Rating shows the strength of a given website’s overall backlink profile.',
  majestic_citation_flow:
    "Citation flow measures the link equity or 'power' the website or link carries. Data source: Majestic",
  majestic_trust_flow:
    'Trust flow measures the quality of the link. Data source: Majestic',
};

export default class OverviewSeoMetricBoxComponent extends Component {
  @service graphColorManager;

  get firstGraphSeries() {
    const fieldName = FIRST_GRAPH_FIELD[this.args.source];
    const changeField = `${fieldName}_change`;
    const title = FIRST_GRAPH_TITLE[this.args.source];
    const available = !!FIRST_GRAPH_FIELD[this.args.source];
    const value = this.args.stats?.[fieldName];
    return {
      title,
      available,
      value,
      changeValue: this.args.stats?.[changeField],
      tooltip: EXPLANATIONS[fieldName],
      graphSeries: [
        {
          data: value,
          backgroundColor: '#4CD071',
        },
        {
          data: 100 - value,
          backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        },
      ],
    };
  }

  get secondGraphSeries() {
    const fieldName = SECOND_GRAPH_FIELD[this.args.source];
    const changeField = `${fieldName}_change`;
    const title = SECOND_GRAPH_TITLE[this.args.source];
    const available = !!SECOND_GRAPH_FIELD[this.args.source];
    const value = this.args.stats?.[fieldName];
    return {
      title,
      available,
      value,
      changeValue: this.args.stats?.[changeField],
      tooltip: EXPLANATIONS[fieldName],
      noRankColor: this.graphColorManager.colorForSeries('no_rank'),
      graphSeries: [
        {
          data: value,
          backgroundColor: '#DA8333',
        },
        {
          data: 100 - value,
          backgroundColor: this.graphColorManager.colorForSeries('no_rank'),
        },
      ],
    };
  }
}
