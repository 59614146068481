import Checkbox from '@ember/component/checkbox';

export const initialize = () =>
  Checkbox.reopen({
    attributeBindings: ['data-id'],
  });

export default {
  name: 'input-attributes',
  initialize,
};
