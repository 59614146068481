import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { fadeOut } from '../utils/dom/fade-element';
import { task } from 'ember-concurrency';
import { DEFAULT_GROUPING_TYPE } from '../constants/chart/grouping';
import { debounce } from '@ember/runloop';
import PinnedItemsService from "../services/pinned-items";

/**
  @class PinnedItemsAreaComponent
  @argument pinnedItems
  @argument ensureSelectedGroup
  @argument selectedDateRange
  @argument setDateRange
*/
export default class PinnedItemsAreaComponent extends Component {
  @service session;
  @service router;
  @service store;
  @service metrics;
  @service candidates;
  @service notifications;
  @service pinnedItems;

  @tracked modalShown = false;
  /*
    Important that this is a real property rather than getter/setter
    Because it is mutated by the `<SortableObjects /> component
   */
  @tracked sortedPinnedItems;

  @tracked grouping = DEFAULT_GROUPING_TYPE;

  @tracked isResizing = false;

  constructor() {
    super(...arguments);
  }

  @action
  setSortedPinnedItems() {
    let persistedOrder = (this.session?.user.pinnedItemsOrder || []).map(
      String
    );
    this.sortedPinnedItems = this.args.pinnedItems
      .toArray()
      .sort(function (a, b) {
        if (!a.id || !b.id) {
          return 0;
        }
        const aIdx = persistedOrder.indexOf(String(a.id));
        const bIdx = persistedOrder.indexOf(String(b.id));
        if (aIdx == -1) {
          return 1; // Not in defined order - move to the last place
        } else if (aIdx > bIdx) {
          return 1;
        } else if (aIdx < bIdx) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  savePinnedItemsOrder() {
    const { user } = this.session;
    user.pinnedItemsOrder = this.sortedPinnedItems.mapBy('id');
    user.save();
  }

  @action
  dragStartAction(_class, event) {
    if (!event.dataTransfer.setDragImage) return;
    const isChrome = /Chrome/.test(navigator.userAgent);
    if (!isChrome) return true;

    // Chrome produces terribly weird artifacts when dragging items around
    // As a workaround, we set a blank image to the drag image.
    const img = new Image();
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    return event.dataTransfer.setDragImage(img, 0, 0);
  }

  @action
  toggleModalShown() {
    this.modalShown = !this.modalShown;
  }

  @action
  openCandidates() {
    this.candidates.loadPinnedItems.perform();
    this.resetSearch();
    this.modalShown = !this.modalShown;
    this.metrics.trackEvent({ event: 'Open Pin Items' });
  }

  @task
  *pinItem(candidate) {
    this.modalShown = !this.modalShown;
    let urlGroup, url, dynamicView;

    const graph = this.store.createRecord('graph', {
      grouping: this.grouping,
      relativeDateRange: 'last_30_days',
    });

    if (candidate.type === 'url') {
      url = yield this.store.findRecord('url', candidate.id);
      this.store.createRecord('graph/series', {
        graph,
        url,
        color: '#0ea1ec',
        active: true,
        name: 'average_position',
      }),
        (graph.name = url.friendlyUrl);
    } else if (candidate.type == 'dynamic_view') {
      dynamicView = yield this.store.findRecord('dynamic-view', candidate.id);
      this.store.createRecord('graph/series', {
        graph,
        dynamicView,
        color: '#0ea1ec',
        active: true,
        name: 'average_position',
      }),
        (graph.name = dynamicView.name);
    } else if (candidate.type == 'group') {
      urlGroup = yield this.store.findRecord('url-group', candidate.id);
      this.store.createRecord('graph/series', {
        graph,
        urlGroup,
        color: '#0ea1ec',
        active: true,
        name: 'average_position',
      }),
        (graph.name = urlGroup.name);
    } else {
      // Bail just in case an unexpected candidate is pinned
      return;
    }

    const pinnedItem = this.store.createRecord('pinned-item', {
      graph,
      url,
      urlGroup,
      dynamicView,
    });

    try {
      yield pinnedItem.save();
      this.metrics.trackEvent({
        event: 'Pinned Item',
        pinned_item: pinnedItem.toJSON(),
      });
      this.setSortedPinnedItems();
      this.savePinnedItemsOrder();
      this.notifications.success(`Item pinned successfuly.`, {
        autoClear: true,
      });
    } catch (error) {
      this.notifications.error(
        `There was an error while pinning the item: ${error}.`,
        { autoClear: true, clearDuration: 6000 }
      );
      pinnedItem.isPinned = false;
      this.store.unloadRecord(pinnedItem);
      throw error;
    }
  }

  @task
  *unpinItem(pinnedItem, element) {
    yield fadeOut(element);

    // Only continue if the passed model object is a PinnedItem model
    // There was a bug where UrlGroups could be dragged in and then deleted
    if (pinnedItem.constructor.modelName !== 'pinned-item') return;

    this.metrics.trackEvent({
      event: 'Unpinned Item',
      pinned_item: pinnedItem.toJSON(),
    });
    yield pinnedItem.destroyRecord();
    this.savePinnedItemsOrder();
  }

  @action
  onSort() {
    this.savePinnedItemsOrder();
  }

  @action
  resetSearch() {
    this.candidates.pinnedItemSearch = null;
  }

  @action
  onSearchChange(searchValue) {
    this.candidates.pinnedItemSearch = searchValue;
  }

  @action
  goToPinnedItem(pinnedItem) {
    if (pinnedItem.get('isView')) {
      if (pinnedItem.get('url.id')) {
        this.router.transitionTo(
          'dashboard.url.dynamic-view',
          pinnedItem.get('url'),
          pinnedItem.get('dynamicView')
        );
      } else {
        this.router.transitionTo(
          'dashboard.dynamic-view',
          pinnedItem.get('dynamicView')
        );
      }
    } else if (pinnedItem.get('isGroup')) {
      this.args.ensureSelectedGroup(pinnedItem.get('urlGroup.id'));
    } else {
      this.router.transitionTo(
        'dashboard.url.keywords.index',
        pinnedItem.get('url')
      );
    }
  }
}
