import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import backlinkViewControllerMixin from 'nightwatch-web/mixins/backlink-view-controller';
import throttle from 'lodash-es/throttle';
import refreshRoute from 'nightwatch-web/utils/refresh-route';
import isTesting from 'nightwatch-web/utils/is-testing';

export default class DashboardUrlBacklinksIndexController extends Controller.extend(
  backlinkViewControllerMixin
) {
  @service session;

  isLoading = false;

  get showProgress() {
    return !this.initialFetchingDone;
  }

  get initialFetchingDone() {
    return isPresent(
      this.get('url.backlinksStatus.ahrefs_initial_fetching_done_at')
    );
  }

  get progressPercent() {
    const count = this.get('url.backlinksCount');
    const total = this.get('url.backlinksStatus.backlinks_total');
    if (count === 0) {
      return 100;
    }
    const percent = 100 * (count / total);
    if (percent == null || percent < 3) {
      return 3;
    } else {
      return percent;
    }
  }

  get limitReached() {
    return this.get('session.user.backlinksRemaining') <= 0;
  }

  handleProgress() {
    if (this.isPeriodicallyRefreshing) {
      return;
    }
    const _refresh = () => {
      refreshRoute(this, 'dashboard.url.backlinks.index');
      this.fetchStats();

      const { user } = this.session;
      user.reload().then(() => {
        this.backlinksNotifications.notifyLimitReached(user);
      });
    };

    const throttledRefresh = throttle(_refresh, 10000);

    const _check = (url) => {
      if (isTesting) return;

      if (!this.progressCheckingEnabled) {
        return;
      }

      if (this.initialFetchingDone || this.limitReached) {
        this.set('isPeriodicallyRefreshing', false);
        return;
      }

      url.reload().then((url) => {
        const newBacklinksCount = url.get('backlinksCount');
        if (newBacklinksCount > this.currentBacklinksTmp) {
          throttledRefresh();
        }

        this.set('currentBacklinksTmp', newBacklinksCount);
        later(() => {
          _check(url);
        }, 2000);
      });
    };

    const url = this.url;
    this.set('currentBacklinksTmp', url.get('backlinksCount'));
    this.set('isPeriodicallyRefreshing', true);
    _check(url);
  }
}
