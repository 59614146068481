import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NwOnboardingFlowLanguageSelectComponent extends Component {
  @service siteData;

  get languages() {
    return this.siteData.languages?.map(([id, text]) => ({
      id,
      text,
    }));
  }
}
