import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 @argument url
 @argument selectedTags
 @argument selectTag
 @argument removeTag
 */
export default class TagSelectComponent extends Component {
  @service fetchTags;
  @tracked searchTerm = '';

  get allTags() {
    const selectedTags = new Set(this.args.selectedTags);
    return this.fetchTags.keywordTagCache
      ?.mapBy('name')
      ?.filter((tagName) =>
        this.searchTerm
          ? tagName.toLowerCase().match(this.searchTerm.toLowerCase()) &&
            !selectedTags.has(tagName)
          : !selectedTags.has(tagName)
      );
  }
}
