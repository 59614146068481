import { modifier } from 'ember-modifier';

export default modifier(function formAutofocus(
  element,
  [selector = 'input:not([disabled])']
) {
  let focusTimeout;
  const setFocus = () => {
    const childElement = element.querySelector(selector);
    if (childElement) {
      childElement.focus();
    } else {
      element.focus();
    }
  };

  if (focusTimeout) {
    clearTimeout(focusTimeout);
  }
  focusTimeout = setTimeout(setFocus, 50);
});
