import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import localStorage from 'ember-local-storage-decorator';

/*
  Temporary state bucket while we refactor away from injecting controllers.

  Eventually most of the dashboard/urlGroup/selectedUrl state will be in a service like this.
 */
export default class DashboardStateService extends Service {
  @tracked currentUrlGroup;
  @localStorage('nw:group:most-recently-selected')
  mostRecentlySelectedUrlGroupId;
}
