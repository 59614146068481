import Service, { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import isTesting from '../utils/is-testing';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { capitalizeString } from '../helpers/capitalize-string';

export default class SaveKeywordsService extends Service {
  @service fetch;
  @service discovery;
  @service session;
  @service store;
  @service notifications;
  @service router;
  @service filterGroupHandling;
  @tracked keywordTasks;
  @tracked upsertKeywords = false;

  get hasKeywordErrors() {
    return this.discovery.keywords.some((keyword) => keyword.errors.length > 0);
  }

  @action
  toggleUpsertKeywords() {
    this.upsertKeywords = !this.upsertKeywords;
  }

  @task({ maxConcurrency: 3, enqueue: true })
  *saveKeywordTask(keyword) {
    try {
      yield keyword.save({ adapterOptions: { upsert: this.upsertKeywords } });
    } catch (err) {
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *updateKeywordTranslationTask(keyword, translation) {
    try {
      yield this.fetch.put(
        `/urls/${keyword.url.id}/keywords/${keyword.id}/update_translation`,
        {
          data: {
            translation: translation,
          },
        }
      );
      this.notifications.success('Translation saved successfully.', {
        autoClear: true,
      });
    } catch (err) {
      this.notifications.error(
        'Failed to save translation. Please try again.',
        {
          autoClear: true,
        }
      );
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *saveKeywordsTask(callback) {
    const clearDuration = isTesting ? 0 : 6000;
    if (this.session.user.keywordLimitReached) {
      this.notifications.error(
        htmlSafe(
          `You have reached your keyword limit! <a href="#">Click here</a> to upgrade your plan.`
        ),
        {
          onClick: () => {
            this.router.transitionTo('/plans');
            this.notifications.clearAll();
          },
        }
      );
      return;
    }

    this.discovery.keywords = [
      ...this.discovery.keywords?.filter((keyword) => keyword.query),
    ];

    if (this.discovery.keywords.length === 0) yield null;

    const tempKwMap = new Map();

    this.keywordTasks = this.discovery.keywords
      ?.map((keyword) => {
        const key = this.generateKeywordKey(keyword);

        if (tempKwMap.has(key)) {
          keyword.errors.add('query', 'has already been taken');
          return null;
        }

        tempKwMap.set(key, true);
        return this.saveKeywordTask.perform(keyword);
      })
      .filter(Boolean);

    yield allSettled(this.keywordTasks);

    if (!this.hasKeywordErrors) {
      if (typeof callback === 'function') callback();
      if (this.discovery.keywords.length > 0) {
        //store all unique tags
        const uniqueTags = new Set(
          this.discovery.keywords.map((keyword) => keyword.tags).flat()
        );
        for (const tag of uniqueTags) {
          yield this.saveTagDynamicView.perform(tag);
        }

        this.notifications.success(
          `Successfully saved ${this.discovery.keywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        return { success: true };
      }
    } else {
      const successfullySavedKeywords = this.discovery.keywords.filter(
        (kw) => kw.id && !kw.isNew
      );
      if (successfullySavedKeywords.length) {
        this.discovery.keywords = this.discovery.keywords.filter(
          (kw) => !kw.isValid || kw.errorStrings.length
        );

        this.notifications.success(
          `Successfully saved ${successfullySavedKeywords.length} keywords.`,
          { autoClear: true, clearDuration }
        );
        this.notifications.warning(
          `But ${this.discovery.keywords.length} keywords were not saved, please check them for errors.`,
          { autoClear: true, clearDuration }
        );
        return { success: false };
      } else {
        this.notifications.error(
          `Failed to save keywords, please check them for errors.`,
          { autoClear: true }
        );
        return { success: false };
      }
    }
    this.keywordTasks = null;
    this.session.user.reload();
    // Reloads the URL so the keyword count in the sidebar updates.
    this.discovery.keywords[0]?.url?.reload();
  }

  @task
  *saveTagDynamicView(tag) {
    if (tag && tag.trim() !== '') {
      const dynamicView = this.store.createRecord('dynamic-view', {
        url: this.discovery.keywords[0].url,
        name: capitalizeString(tag),
      });

      yield dynamicView.save();

      const filterGroup = this.store.createRecord('filter-group', {
        dynamicView,
      });

      this.store.createRecord('filter', {
        filterGroup,
        field: 'keyword_tag',
        condition: 'equals',
        value: tag,
      });
      yield this.filterGroupHandling.saveViewFilters.perform(dynamicView);
    }
  }

  @action
  generateKeywordKey(keyword) {
    return [
      keyword.query,
      keyword.mobile,
      keyword.location,
      keyword.google_gl,
      keyword.google_hl,
      keyword.engine, // Make unique key for each keyword for an engine
      JSON.stringify(keyword.precise_location),
    ].join('|');
  }
}
