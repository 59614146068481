import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class Competitor extends Model {
  @attr('string') competitor_url;
  @attr() places_info;
  @attr('string') places_match;
  @attr('string') places_image_title;
  @attr('boolean') matchSubdomains;
  @attr('string') customName;
  @attr('string') displayName;

  @tracked discovered = false;

  @belongsTo('url', { async: false }) url;

  get friendlyUrl() {
    if (!this.competitor_url) return '';
    return this.competitor_url
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .replace(/\/$/, '');
  }

  get errorStrings() {
    return this.errors?.toArray()?.map((error) => error.message);
  }
}
