import Component from '@glimmer/component';
import { task, rawTimeout } from 'ember-concurrency';

export default class NwDimensionToggle extends Component {
  @task({ restartable: true })
  *changeColor(color) {
    yield rawTimeout(170);
    this.args.dimension.color = color;
  }
}
