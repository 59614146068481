import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class NwToBottomButtonComponent extends Component {
  @tracked isAtBottom = false;

  @action
  onInsert() {
    document.addEventListener('scroll', () => {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      // When the user is [modifier]px from the bottom, fire the event.
      let modifier = 100;
      this.isAtBottom = currentScroll + modifier > documentHeight;
    });
  }

  @action
  goToTopOrBottom() {
    if (this.isAtBottom) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
}
