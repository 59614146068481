import Model, { attr } from '@ember-data/model';

export default class SerpResultOrganic extends Model {
  @attr('string') url;
  @attr('string') title;
  @attr('string') description;
  @attr('string') cid;
  @attr('string') channelId;
  @attr('string') videoId;
  @attr('string') playlistId;
}
