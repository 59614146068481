import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Papa from 'papaparse';

export default class PagesChartComponent extends Component {
  @tracked previewOpen = false;
  @tracked previewDate;
  @service siteData;
  @service notifications;
  @tracked _previewKeyword;
  get previewKeyword() {
    return this._previewKeyword || this.args.serpPreviewKeyword;
  }

  // Opens SERP preview automatically (based on queryParam)
  @action
  keywordSeriesLoaded(dimension, data) {
    if (dimension.keyword !== this.args.serpPreviewKeyword) return;
    // If the serpPreviewKeyword data has been loaded

    // Opens the SERP preview with the last crawled date for that keyword
    this.previewDate = data?.lastObject?.x;
    this.previewOpen = Boolean(this.previewDate);
  }

  // Opens SERP preview when a keyword position point is clicked in the chart
  @action
  showPreview(previewKeyword, previewDate) {
    this._previewKeyword = previewKeyword;
    this.previewDate = previewDate;
    this.previewOpen = true;
  }

  /**
   * Downloads the CSV file for the given series data
   * @param seriesData
   */
  @action
  downloadCsv(seriesData) {
    const csvString = this.generateCsv(seriesData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');

    // Check if the browser supports the download (HTML5) attribute
    if (typeof link.download === 'undefined') {
      this.handleUnsupportedDownload(blob);
    } else {
      this.handleSupportedDownload(blob, seriesData);
    }
  }

  /**
   * Handles the download of the CSV file for unsupported browsers (opens the generated report in a separate tab)
   * @param blob
   */
  handleUnsupportedDownload(blob) {
    const url = URL.createObjectURL(blob);
    this.notifications.warning(
      "Oops! Your browser can't download directly. We're opening the CSV in a new tab. For a direct download, please try another browser. Thanks!",
      { autoClear: true, clearDuration: 3000 }
    );

    setTimeout(() => {
      window.open(url, '_blank');
      URL.revokeObjectURL(url);
    }, 3000);
  }

  /**
   * Handles the download of the CSV file for supported browsers (triggers the download of the generated report)
   * @param blob
   */
  handleSupportedDownload(blob) {
    const url = URL.createObjectURL(blob);
    const fileName = `graph-export-for-${this.args.url.name.replace(
      /\./g,
      '-'
    )}.csv`;

    this.triggerDownload(url, fileName);
    URL.revokeObjectURL(url);
  }

  triggerDownload(url, fileName) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Generates the CSV string for the given series data
   * @param seriesData
   * @returns {*}
   */
  generateCsv(seriesData) {
    const mutatedSeriesData = this.createCsv(seriesData.slice(1));
    return Papa.unparse(mutatedSeriesData);
  }

  /**
   * Generates a CSV-friendly array of data points aggregated by date from a series of data.
   * Each series can optionally include a custom label. If the series label does not include '|',
   * it appends the current URL name or a default value to the label.
   *
   * @param {Array} series - Array of series data, each with label and data properties.
   * @returns {Array} An array of objects representing aggregated data points for CSV conversion.
   */
  createCsv(series) {
    const aggregatedData = {};

    series.forEach((seria) => {
      //CHeck whether the series is a part of the current url or not, and sets the label accordingly
      const customLabel = seria.label.includes('|')
        ? seria.label
        : `${seria.label} | ${this.args.url.name ?? 'Current'}`;

      seria.data.forEach((dataPoint) => {
        const { x: dateKey, y } = dataPoint;
        const value =
          y === undefined || y === null || Number.isNaN(y) ? '/' : y;

        if (!aggregatedData[dateKey]) {
          aggregatedData[dateKey] = { date: dateKey };
        }

        aggregatedData[dateKey][customLabel] = value;
      });
    });

    // Convert the aggregated object to an array of its values
    const lines = Object.values(aggregatedData);
    return lines;
  }
}
