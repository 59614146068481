export default {
  timezones: {
    'Pacific/Pago_Pago': 'American Samoa (GMT-11:00)',
    'Pacific/Midway': 'Midway Island (GMT-11:00)',
    'Pacific/Honolulu': 'Hawaii (GMT-10:00)',
    'America/Juneau': 'Alaska (GMT-09:00)',
    'America/Los_Angeles': 'Pacific Time (US & Canada) (GMT-08:00)',
    'America/Tijuana': 'Tijuana (GMT-08:00)',
    'America/Phoenix': 'Arizona (GMT-07:00)',
    'America/Chihuahua': 'Chihuahua (GMT-07:00)',
    'America/Mazatlan': 'Mazatlan (GMT-07:00)',
    'America/Denver': 'Mountain Time (US & Canada) (GMT-07:00)',
    'America/Guatemala': 'Central America (GMT-06:00)',
    'America/Chicago': 'Central Time (US & Canada) (GMT-06:00)',
    'America/Mexico_City': 'Mexico City (GMT-06:00)',
    'America/Monterrey': 'Monterrey (GMT-06:00)',
    'America/Regina': 'Saskatchewan (GMT-06:00)',
    'America/Bogota': 'Bogota (GMT-05:00)',
    'America/New_York': 'Eastern Time (US & Canada) (GMT-05:00)',
    'America/Indiana/Indianapolis': 'Indiana (East) (GMT-05:00)',
    'America/Lima': 'Quito (GMT-05:00)',
    'America/Halifax': 'Atlantic Time (Canada) (GMT-04:00)',
    'America/Caracas': 'Caracas (GMT-04:00)',
    'America/Guyana': 'Georgetown (GMT-04:00)',
    'America/La_Paz': 'La Paz (GMT-04:00)',
    'America/Santiago': 'Santiago (GMT-04:00)',
    'America/St_Johns': 'Newfoundland (GMT-03:30)',
    'America/Sao_Paulo': 'Brasilia (GMT-03:00)',
    'America/Argentina/Buenos_Aires': 'Buenos Aires (GMT-03:00)',
    'America/Godthab': 'Greenland (GMT-03:00)',
    'America/Montevideo': 'Montevideo (GMT-03:00)',
    'Atlantic/South_Georgia': 'Mid-Atlantic (GMT-02:00)',
    'Atlantic/Azores': 'Azores (GMT-01:00)',
    'Atlantic/Cape_Verde': 'Cape Verde Is. (GMT-01:00)',
    'Africa/Casablanca': 'Casablanca (GMT+00:00)',
    'Europe/Dublin': 'Dublin (GMT+00:00)',
    'Europe/London': 'London (GMT+00:00)',
    'Europe/Lisbon': 'Lisbon (GMT+00:00)',
    'Africa/Monrovia': 'Monrovia (GMT+00:00)',
    'Etc/UTC': 'UTC (GMT+00:00)',
    'Europe/Amsterdam': 'Europe/Amsterdam (GMT+01:00)',
    'Europe/Belgrade': 'Belgrade (GMT+01:00)',
    'Europe/Berlin': 'Bern (GMT+01:00)',
    'Europe/Bratislava': 'Bratislava (GMT+01:00)',
    'Europe/Brussels': 'Brussels (GMT+01:00)',
    'Europe/Budapest': 'Budapest (GMT+01:00)',
    'Europe/Copenhagen': 'Copenhagen (GMT+01:00)',
    'Europe/Ljubljana': 'Ljubljana (GMT+01:00)',
    'Europe/Madrid': 'Madrid (GMT+01:00)',
    'Europe/Paris': 'Paris (GMT+01:00)',
    'Europe/Prague': 'Prague (GMT+01:00)',
    'Europe/Rome': 'Rome (GMT+01:00)',
    'Europe/Sarajevo': 'Sarajevo (GMT+01:00)',
    'Europe/Skopje': 'Skopje (GMT+01:00)',
    'Europe/Stockholm': 'Stockholm (GMT+01:00)',
    'Europe/Vienna': 'Vienna (GMT+01:00)',
    'Europe/Warsaw': 'Warsaw (GMT+01:00)',
    'Africa/Algiers': 'West Central Africa (GMT+01:00)',
    'Europe/Zagreb': 'Zagreb (GMT+01:00)',
    'Europe/Athens': 'Athens (GMT+02:00)',
    'Europe/Bucharest': 'Bucharest (GMT+02:00)',
    'Africa/Cairo': 'Cairo (GMT+02:00)',
    'Africa/Harare': 'Harare (GMT+02:00)',
    'Europe/Helsinki': 'Helsinki (GMT+02:00)',
    'Asia/Jerusalem': 'Jerusalem (GMT+02:00)',
    'Europe/Kaliningrad': 'Kaliningrad (GMT+02:00)',
    'Europe/Kiev': 'Kyiv (GMT+02:00)',
    'Africa/Johannesburg': 'Pretoria (GMT+02:00)',
    'Europe/Riga': 'Riga (GMT+02:00)',
    'Europe/Sofia': 'Sofia (GMT+02:00)',
    'Europe/Tallinn': 'Tallinn (GMT+02:00)',
    'Europe/Vilnius': 'Vilnius (GMT+02:00)',
    'Asia/Baghdad': 'Baghdad (GMT+03:00)',
    'Europe/Istanbul': 'Istanbul (GMT+03:00)',
    'Asia/Kuwait': 'Kuwait (GMT+03:00)',
    'Europe/Minsk': 'Minsk (GMT+03:00)',
    'Europe/Moscow': 'St. Petersburg (GMT+03:00)',
    'Africa/Nairobi': 'Nairobi (GMT+03:00)',
    'Asia/Riyadh': 'Riyadh (GMT+03:00)',
    'Europe/Volgograd': 'Volgograd (GMT+03:00)',
    'Asia/Tehran': 'Tehran (GMT+03:30)',
    'Asia/Muscat': 'Muscat (GMT+04:00)',
    'Asia/Baku': 'Baku (GMT+04:00)',
    'Europe/Samara': 'Samara (GMT+04:00)',
    'Asia/Tbilisi': 'Tbilisi (GMT+04:00)',
    'Asia/Yerevan': 'Yerevan (GMT+04:00)',
    'Asia/Kabul': 'Kabul (GMT+04:30)',
    'Asia/Yekaterinburg': 'Ekaterinburg (GMT+05:00)',
    'Asia/Karachi': 'Karachi (GMT+05:00)',
    'Asia/Tashkent': 'Tashkent (GMT+05:00)',
    'Asia/Kolkata': 'New Delhi (GMT+05:30)',
    'Asia/Colombo': 'Sri Jayawardenepura (GMT+05:30)',
    'Asia/Kathmandu': 'Kathmandu (GMT+05:45)',
    'Asia/Almaty': 'Almaty (GMT+06:00)',
    'Asia/Dhaka': 'Dhaka (GMT+06:00)',
    'Asia/Urumqi': 'Urumqi (GMT+06:00)',
    'Asia/Rangoon': 'Rangoon (GMT+06:30)',
    'Asia/Bangkok': 'Hanoi (GMT+07:00)',
    'Asia/Jakarta': 'Jakarta (GMT+07:00)',
    'Asia/Krasnoyarsk': 'Krasnoyarsk (GMT+07:00)',
    'Asia/Novosibirsk': 'Novosibirsk (GMT+07:00)',
    'Asia/Shanghai': 'Beijing (GMT+08:00)',
    'Asia/Chongqing': 'Chongqing (GMT+08:00)',
    'Asia/Hong_Kong': 'Hong Kong (GMT+08:00)',
    'Asia/Irkutsk': 'Irkutsk (GMT+08:00)',
    'Asia/Kuala_Lumpur': 'Kuala Lumpur (GMT+08:00)',
    'Australia/Perth': 'Perth (GMT+08:00)',
    'Asia/Singapore': 'Singapore (GMT+08:00)',
    'Asia/Taipei': 'Taipei (GMT+08:00)',
    'Asia/Ulaanbaatar': 'Ulaanbaatar (GMT+08:00)',
    'Asia/Tokyo': 'Tokyo (GMT+09:00)',
    'Asia/Seoul': 'Seoul (GMT+09:00)',
    'Asia/Yakutsk': 'Yakutsk (GMT+09:00)',
    'Australia/Adelaide': 'Adelaide (GMT+09:30)',
    'Australia/Darwin': 'Darwin (GMT+09:30)',
    'Australia/Brisbane': 'Brisbane (GMT+10:00)',
    'Australia/Melbourne': 'Melbourne (GMT+10:00)',
    'Pacific/Guam': 'Guam (GMT+10:00)',
    'Australia/Hobart': 'Hobart (GMT+10:00)',
    'Pacific/Port_Moresby': 'Port Moresby (GMT+10:00)',
    'Australia/Sydney': 'Sydney (GMT+10:00)',
    'Asia/Vladivostok': 'Vladivostok (GMT+10:00)',
    'Asia/Magadan': 'Magadan (GMT+11:00)',
    'Pacific/Noumea': 'New Caledonia (GMT+11:00)',
    'Pacific/Guadalcanal': 'Solomon Is. (GMT+11:00)',
    'Asia/Srednekolymsk': 'Srednekolymsk (GMT+11:00)',
    'Pacific/Auckland': 'Wellington (GMT+12:00)',
    'Pacific/Fiji': 'Fiji (GMT+12:00)',
    'Asia/Kamchatka': 'Kamchatka (GMT+12:00)',
    'Pacific/Majuro': 'Marshall Is. (GMT+12:00)',
    'Pacific/Chatham': 'Chatham Is. (GMT+12:45)',
    'Pacific/Tongatapu': "Nuku'alofa (GMT+13:00)",
    'Pacific/Apia': 'Samoa (GMT+13:00)',
    'Pacific/Fakaofo': 'Tokelau Is. (GMT+13:00)',
  },
  countries: [
    ['af', 'Afghanistan'],
    ['al', 'Albania'],
    ['dz', 'Algeria'],
    ['as', 'American Samoa'],
    ['ad', 'Andorra'],
    ['ao', 'Angola'],
    ['ai', 'Anguilla'],
    ['aq', 'Antarctica'],
    ['ag', 'Antigua and Barbuda'],
    ['ar', 'Argentina'],
    ['am', 'Armenia'],
    ['aw', 'Aruba'],
    ['au', 'Australia'],
    ['at', 'Austria'],
    ['az', 'Azerbaijan'],
    ['bs', 'Bahamas'],
    ['bh', 'Bahrain'],
    ['bd', 'Bangladesh'],
    ['bb', 'Barbados'],
    ['by', 'Belarus'],
    ['be', 'Belgium'],
    ['bz', 'Belize'],
    ['bj', 'Benin'],
    ['bm', 'Bermuda'],
    ['bt', 'Bhutan'],
    ['bo', 'Bolivia'],
    ['ba', 'Bosnia and Herzegovina'],
    ['bw', 'Botswana'],
    ['bv', 'Bouvet Island'],
    ['br', 'Brazil'],
    ['io', 'British Indian Ocean Territory'],
    ['bn', 'Brunei Darussalam'],
    ['bg', 'Bulgaria'],
    ['bf', 'Burkina Faso'],
    ['bi', 'Burundi'],
    ['kh', 'Cambodia'],
    ['cm', 'Cameroon'],
    ['ca', 'Canada'],
    ['cv', 'Cape Verde'],
    ['ky', 'Cayman Islands'],
    ['cf', 'Central African Republic'],
    ['td', 'Chad'],
    ['cl', 'Chile'],
    ['cn', 'China'],
    ['cx', 'Christmas Island'],
    ['cc', 'Cocos (Keeling) Islands'],
    ['co', 'Colombia'],
    ['km', 'Comoros'],
    ['cg', 'Congo'],
    ['cd', 'Congo, the Democratic Republic of the'],
    ['ck', 'Cook Islands'],
    ['cr', 'Costa Rica'],
    ['ci', "Cote D'ivoire"],
    ['hr', 'Croatia'],
    ['cu', 'Cuba'],
    ['cw', 'Curaçao'],
    ['cy', 'Cyprus'],
    ['cz', 'Czech Republic'],
    ['dk', 'Denmark'],
    ['dj', 'Djibouti'],
    ['dm', 'Dominica'],
    ['do', 'Dominican Republic'],
    ['ec', 'Ecuador'],
    ['eg', 'Egypt'],
    ['sv', 'El Salvador'],
    ['gq', 'Equatorial Guinea'],
    ['er', 'Eritrea'],
    ['ee', 'Estonia'],
    ['et', 'Ethiopia'],
    ['fk', 'Falkland Islands (Malvinas)'],
    ['fo', 'Faroe Islands'],
    ['fj', 'Fiji'],
    ['fi', 'Finland'],
    ['fr', 'France'],
    ['gf', 'French Guiana'],
    ['pf', 'French Polynesia'],
    ['tf', 'French Southern Territories'],
    ['ga', 'Gabon'],
    ['gm', 'Gambia'],
    ['ge', 'Georgia'],
    ['de', 'Germany'],
    ['gh', 'Ghana'],
    ['gi', 'Gibraltar'],
    ['gr', 'Greece'],
    ['gl', 'Greenland'],
    ['gd', 'Grenada'],
    ['gp', 'Guadeloupe'],
    ['gu', 'Guam'],
    ['gt', 'Guatemala'],
    ['gg', 'Guernsey'],
    ['gn', 'Guinea'],
    ['gw', 'Guinea-Bissau'],
    ['gy', 'Guyana'],
    ['ht', 'Haiti'],
    ['hm', 'Heard Island and Mcdonald Islands'],
    ['va', 'Holy See (Vatican City State)'],
    ['hn', 'Honduras'],
    ['hk', 'Hong Kong'],
    ['hu', 'Hungary'],
    ['is', 'Iceland'],
    ['in', 'India'],
    ['id', 'Indonesia'],
    ['ir', 'Iran, Islamic Republic of'],
    ['iq', 'Iraq'],
    ['ie', 'Ireland'],
    ['il', 'Israel'],
    ['it', 'Italy'],
    ['jm', 'Jamaica'],
    ['jp', 'Japan'],
    ['je', 'Jersey'],
    ['jo', 'Jordan'],
    ['kz', 'Kazakhstan'],
    ['ke', 'Kenya'],
    ['ki', 'Kiribati'],
    ['kp', "Korea, Democratic People's Republic of"],
    ['kr', 'Korea, Republic of'],
    ['kw', 'Kuwait'],
    ['kg', 'Kyrgyzstan'],
    ['la', "Lao People's Democratic Republic"],
    ['lv', 'Latvia'],
    ['lb', 'Lebanon'],
    ['ls', 'Lesotho'],
    ['lr', 'Liberia'],
    ['ly', 'Libyan Arab Jamahiriya'],
    ['li', 'Liechtenstein'],
    ['lt', 'Lithuania'],
    ['lu', 'Luxembourg'],
    ['me', 'Montenegro'],
    ['mo', 'Macao'],
    ['mk', 'Macedonia, the Former Yugosalv Republic of'],
    ['mg', 'Madagascar'],
    ['mw', 'Malawi'],
    ['my', 'Malaysia'],
    ['mv', 'Maldives'],
    ['ml', 'Mali'],
    ['mt', 'Malta'],
    ['mh', 'Marshall Islands'],
    ['mq', 'Martinique'],
    ['mr', 'Mauritania'],
    ['mu', 'Mauritius'],
    ['yt', 'Mayotte'],
    ['mx', 'Mexico'],
    ['fm', 'Micronesia, Federated States of'],
    ['md', 'Moldova, Republic of'],
    ['mc', 'Monaco'],
    ['mn', 'Mongolia'],
    ['ms', 'Montserrat'],
    ['ma', 'Morocco'],
    ['mz', 'Mozambique'],
    ['mm', 'Myanmar'],
    ['na', 'Namibia'],
    ['nr', 'Nauru'],
    ['np', 'Nepal'],
    ['nl', 'Netherlands'],
    ['an', 'Netherlands Antilles'],
    ['nc', 'New Caledonia'],
    ['nz', 'New Zealand'],
    ['ni', 'Nicaragua'],
    ['ne', 'Niger'],
    ['ng', 'Nigeria'],
    ['nu', 'Niue'],
    ['nf', 'Norfolk Island'],
    ['mp', 'Northern Mariana Islands'],
    ['no', 'Norway'],
    ['om', 'Oman'],
    ['pk', 'Pakistan'],
    ['pw', 'Palau'],
    ['ps', 'Palestinian Territory, Occupied'],
    ['pa', 'Panama'],
    ['pg', 'Papua New Guinea'],
    ['py', 'Paraguay'],
    ['pe', 'Peru'],
    ['ph', 'Philippines'],
    ['pn', 'Pitcairn'],
    ['pl', 'Poland'],
    ['pt', 'Portugal'],
    ['pr', 'Puerto Rico'],
    ['qa', 'Qatar'],
    ['re', 'Reunion'],
    ['ro', 'Romania'],
    ['ru', 'Russian Federation'],
    ['rw', 'Rwanda'],
    ['sh', 'Saint Helena'],
    ['kn', 'Saint Kitts and Nevis'],
    ['lc', 'Saint Lucia'],
    ['pm', 'Saint Pierre and Miquelon'],
    ['vc', 'Saint Vincent and the Grenadines'],
    ['ws', 'Samoa'],
    ['sm', 'San Marino'],
    ['st', 'Sao Tome and Principe'],
    ['sa', 'Saudi Arabia'],
    ['sn', 'Senegal'],
    ['rs', 'Serbia'],
    ['sc', 'Seychelles'],
    ['sl', 'Sierra Leone'],
    ['sg', 'Singapore'],
    ['sk', 'Slovakia'],
    ['si', 'Slovenia'],
    ['sb', 'Solomon Islands'],
    ['so', 'Somalia'],
    ['za', 'South Africa'],
    ['gs', 'South Georgia and the South Sandwich Islands'],
    ['es', 'Spain'],
    ['lk', 'Sri Lanka'],
    ['sd', 'Sudan'],
    ['sr', 'Suriname'],
    ['sj', 'Svalbard and Jan Mayen'],
    ['sz', 'Swaziland'],
    ['se', 'Sweden'],
    ['ch', 'Switzerland'],
    ['sy', 'Syrian Arab Republic'],
    ['tw', 'Taiwan'],
    ['tj', 'Tajikistan'],
    ['tz', 'Tanzania, United Republic of'],
    ['th', 'Thailand'],
    ['tl', 'Timor-Leste'],
    ['tg', 'Togo'],
    ['tk', 'Tokelau'],
    ['to', 'Tonga'],
    ['tt', 'Trinidad and Tobago'],
    ['tn', 'Tunisia'],
    ['tr', 'Turkey'],
    ['tm', 'Turkmenistan'],
    ['tc', 'Turks and Caicos Islands'],
    ['tv', 'Tuvalu'],
    ['ug', 'Uganda'],
    ['ua', 'Ukraine'],
    ['ae', 'United Arab Emirates'],
    // ['uk', 'United Kingdom'],
    ['uk', 'United Kingdom of Great Britain and Northern Ireland'],
    ['us', 'United States'],
    ['um', 'United States Minor Outlying Islands'],
    ['uy', 'Uruguay'],
    ['uz', 'Uzbekistan'],
    ['vu', 'Vanuatu'],
    ['ve', 'Venezuela'],
    ['vn', 'Viet Nam'],
    ['vg', 'Virgin Islands, British'],
    ['vi', 'Virgin Islands, U.S.'],
    ['wf', 'Wallis and Futuna'],
    ['eh', 'Western Sahara'],
    ['ye', 'Yemen'],
    ['zm', 'Zambia'],
    ['zw', 'Zimbabwe'],
  ],
  languages: [
    ['af', 'Afrikaans'],
    ['sq', 'Albanian'],
    ['sm', 'Amharic'],
    ['ar', 'Arabic'],
    ['az', 'Azerbaijani'],
    ['eu', 'Basque'],
    ['be', 'Belarusian'],
    ['bn', 'Bengali'],
    ['bh', 'Bihari'],
    ['bs', 'Bosnian'],
    ['bg', 'Bulgarian'],
    ['ca', 'Catalan'],
    ['zh-CN', 'Chinese (Simplified)'],
    ['zh-TW', 'Chinese (Traditional)'],
    ['hr', 'Croatian'],
    ['cs', 'Czech'],
    ['da', 'Danish'],
    ['nl', 'Dutch'],
    ['en', 'English'],
    ['eo', 'Esperanto'],
    ['et', 'Estonian'],
    ['fo', 'Faroese'],
    ['fi', 'Finnish'],
    ['fr', 'French'],
    ['fy', 'Frisian'],
    ['gl', 'Galician'],
    ['ka', 'Georgian'],
    ['de', 'German'],
    ['el', 'Greek'],
    ['gu', 'Gujarati'],
    ['iw', 'Hebrew'],
    ['hi', 'Hindi'],
    ['hu', 'Hungarian'],
    ['is', 'Icelandic'],
    ['id', 'Indonesian'],
    ['ia', 'Interlingua'],
    ['ga', 'Irish'],
    ['it', 'Italian'],
    ['ja', 'Japanese'],
    ['jw', 'Javanese'],
    ['kn', 'Kannada'],
    ['ko', 'Korean'],
    ['la', 'Latin'],
    ['lv', 'Latvian'],
    ['lt', 'Lithuanian'],
    ['mk', 'Macedonian'],
    ['ms', 'Malay'],
    ['ml', 'Malayam'],
    ['mt', 'Maltese'],
    ['mr', 'Marathi'],
    ['ne', 'Nepali'],
    ['no', 'Norwegian'],
    ['nn', 'Norwegian (Nynorsk)'],
    ['oc', 'Occitan'],
    ['fa', 'Persian'],
    ['pl', 'Polish'],
    ['pt-BR', 'Portuguese (Brazil)'],
    ['pt-PT', 'Portuguese (Portugal)'],
    ['pa', 'Punjabi'],
    ['ro', 'Romanian'],
    ['ru', 'Russian'],
    ['gd', 'Scots Gaelic'],
    ['sr', 'Serbian'],
    ['si', 'Sinhalese'],
    ['sk', 'Slovak'],
    ['sl', 'Slovenian'],
    ['es', 'Spanish'],
    ['su', 'Sudanese'],
    ['sw', 'Swahili'],
    ['sv', 'Swedish'],
    ['tl', 'Tagalog'],
    ['ta', 'Tamil'],
    ['te', 'Telugu'],
    ['th', 'Thai'],
    ['ti', 'Tigrinya'],
    ['tr', 'Turkish'],
    ['uk', 'Ukrainian'],
    ['ur', 'Urdu'],
    ['uz', 'Uzbek'],
    ['vi', 'Vietnamese'],
    ['cy', 'Welsh'],
    ['xh', 'Xhosa'],
    ['zu', 'Zulu'],
  ],
  countryLanguages: [
    ['ZA', 'af'],
    ['AE', 'ar'],
    ['BH', 'ar'],
    ['DZ', 'ar'],
    ['EG', 'ar'],
    ['IQ', 'ar'],
    ['JO', 'ar'],
    ['KW', 'ar'],
    ['LB', 'ar'],
    ['LY', 'ar'],
    ['MA', 'ar'],
    ['OM', 'ar'],
    ['QA', 'ar'],
    ['SA', 'ar'],
    ['SY', 'ar'],
    ['TN', 'ar'],
    ['YE', 'ar'],
    ['AZ', 'az'],
    ['AZ', 'az'],
    ['BY', 'be'],
    ['BG', 'bg'],
    ['BA', 'bs'],
    ['ES', 'ca'],
    ['CZ', 'cs'],
    ['GB', 'cy'],
    ['DK', 'da'],
    ['AT', 'de'],
    ['CH', 'de'],
    ['DE', 'de'],
    ['LI', 'de'],
    ['LU', 'de'],
    ['MV', 'dv'],
    ['GR', 'el'],
    ['AU', 'en'],
    ['BZ', 'en'],
    ['CA', 'en'],
    ['CB', 'en'],
    ['GB', 'en'],
    ['UK', 'en'],
    ['IE', 'en'],
    ['JM', 'en'],
    ['NZ', 'en'],
    ['PH', 'en'],
    ['TT', 'en'],
    ['US', 'en'],
    ['ZA', 'en'],
    ['ZW', 'en'],
    ['AR', 'es'],
    ['BO', 'es'],
    ['CL', 'es'],
    ['CO', 'es'],
    ['CR', 'es'],
    ['DO', 'es'],
    ['EC', 'es'],
    ['ES', 'es'],
    ['ES', 'es'],
    ['GT', 'es'],
    ['HN', 'es'],
    ['MX', 'es'],
    ['NI', 'es'],
    ['PA', 'es'],
    ['PE', 'es'],
    ['PR', 'es'],
    ['PY', 'es'],
    ['SV', 'es'],
    ['UY', 'es'],
    ['VE', 'es'],
    ['EE', 'et'],
    ['ES', 'eu'],
    ['IR', 'fa'],
    ['FI', 'fi'],
    ['FO', 'fo'],
    ['BE', 'fr'],
    ['CA', 'fr'],
    ['CH', 'fr'],
    ['FR', 'fr'],
    ['LU', 'fr'],
    ['MC', 'fr'],
    ['ES', 'gl'],
    ['IN', 'gu'],
    ['IL', 'he'],
    ['IN', 'hi'],
    ['BA', 'hr'],
    ['HR', 'hr'],
    ['HU', 'hu'],
    ['AM', 'hy'],
    ['ID', 'id'],
    ['IS', 'is'],
    ['CH', 'it'],
    ['IT', 'it'],
    ['JP', 'ja'],
    ['GE', 'ka'],
    ['KZ', 'kk'],
    ['IN', 'kn'],
    ['KR', 'ko'],
    ['IN', 'kok'],
    ['KG', 'ky'],
    ['LT', 'lt'],
    ['LV', 'lv'],
    ['NZ', 'mi'],
    ['MK', 'mk'],
    ['MN', 'mn'],
    ['IN', 'mr'],
    ['BN', 'ms'],
    ['MY', 'ms'],
    ['MT', 'mt'],
    ['NO', 'nb'],
    ['BE', 'nl'],
    ['NL', 'nl'],
    ['NO', 'nn'],
    ['ZA', 'ns'],
    ['IN', 'pa'],
    ['PL', 'pl'],
    ['AR', 'ps'],
    ['BR', 'pt'],
    ['PT', 'pt'],
    ['BO', 'qu'],
    ['EC', 'qu'],
    ['PE', 'qu'],
    ['RO', 'ro'],
    ['RU', 'ru'],
    ['IN', 'sa'],
    ['FI', 'se'],
    ['FI', 'se'],
    ['FI', 'sv'],
    ['NO', 'se'],
    ['NO', 'se'],
    ['NO', 'se'],
    ['SE', 'sv'],
    ['SK', 'sk'],
    ['SI', 'sl'],
    ['AL', 'sq'],
    ['BA', 'sr'],
    ['BA', 'sr'],
    ['SP', 'sr'],
    ['SP', 'sr'],
    ['FI', 'sv'],
    ['SE', 'sv'],
    ['KE', 'sw'],
    ['SY', 'syr'],
    ['IN', 'ta'],
    ['IN', 'te'],
    ['TH', 'th'],
    ['PH', 'tl'],
    ['ZA', 'tn'],
    ['TR', 'tr'],
    ['RU', 'tt'],
    ['UA', 'uk'],
    ['PK', 'ur'],
    ['UZ', 'uz'],
    ['UZ', 'uz'],
    ['VN', 'vi'],
    ['ZA', 'xh'],
    ['CN', 'zh'],
    ['HK', 'zh'],
    ['MO', 'zh'],
    ['zh', 'zh'],
    ['ZA', 'zu'],
  ],
  countriesExtended: [
    {
      id: 'US',
      label: 'United States',
      alpha3: 'USA',
    },
    {
      id: 'AF',
      label: 'Afghanistan',
      alpha3: 'AFG',
    },
    {
      id: 'AL',
      label: 'Albania',
      alpha3: 'ALB',
    },
    {
      id: 'DZ',
      label: 'Algeria',
      alpha3: 'DZA',
    },
    {
      id: 'AS',
      label: 'American Samoa',
      alpha3: 'ASM',
    },
    {
      id: 'AD',
      label: 'Andorra',
      alpha3: 'AND',
    },
    {
      id: 'AO',
      label: 'Angola',
      alpha3: 'AGO',
    },
    {
      id: 'AI',
      label: 'Anguilla',
      alpha3: 'AIA',
    },
    {
      id: 'AQ',
      label: 'Antarctica',
      alpha3: 'ATA',
    },
    {
      id: 'AG',
      label: 'Antigua and Barbuda',
      alpha3: 'ATG',
    },
    {
      id: 'AR',
      label: 'Argentina',
      alpha3: 'ARG',
    },
    {
      id: 'AM',
      label: 'Armenia',
      alpha3: 'ARM',
    },
    {
      id: 'AW',
      label: 'Aruba',
      alpha3: 'ABW',
    },
    {
      id: 'AU',
      label: 'Australia',
      alpha3: 'AUS',
    },
    {
      id: 'AT',
      label: 'Austria',
      alpha3: 'AUT',
    },
    {
      id: 'AZ',
      label: 'Azerbaijan',
      alpha3: 'AZE',
    },
    {
      id: 'BS',
      label: 'Bahamas',
      alpha3: 'BHS',
    },
    {
      id: 'BH',
      label: 'Bahrain',
      alpha3: 'BHR',
    },
    {
      id: 'BD',
      label: 'Bangladesh',
      alpha3: 'BGD',
    },
    {
      id: 'BB',
      label: 'Barbados',
      alpha3: 'BRB',
    },
    {
      id: 'BY',
      label: 'Belarus',
      alpha3: 'BLR',
    },
    {
      id: 'BE',
      label: 'Belgium',
      alpha3: 'BEL',
    },
    {
      id: 'BZ',
      label: 'Belize',
      alpha3: 'BLZ',
    },
    {
      id: 'BJ',
      label: 'Benin',
      alpha3: 'BEN',
    },
    {
      id: 'BM',
      label: 'Bermuda',
      alpha3: 'BMU',
    },
    {
      id: 'BT',
      label: 'Bhutan',
      alpha3: 'BTN',
    },
    {
      id: 'BO',
      label: 'Bolivia',
      alpha3: 'BOL',
    },
    {
      id: 'BQ',
      label: 'Bonaire, Sint Eustatius and Saba',
      alpha3: 'BES',
    },
    {
      id: 'BA',
      label: 'Bosnia and Herzegovina',
      alpha3: 'BIH',
    },
    {
      id: 'BW',
      label: 'Botswana',
      alpha3: 'BWA',
    },
    {
      id: 'BV',
      label: 'Bouvet Island',
      alpha3: 'BVT',
    },
    {
      id: 'BR',
      label: 'Brazil',
      alpha3: 'BRA',
    },
    {
      id: 'IO',
      label: 'British Indian Ocean Territory',
      alpha3: 'IOT',
    },
    {
      id: 'BN',
      label: 'Brunei Darussalam',
      alpha3: 'BRN',
    },
    {
      id: 'BG',
      label: 'Bulgaria',
      alpha3: 'BGR',
    },
    {
      id: 'BF',
      label: 'Burkina Faso',
      alpha3: 'BFA',
    },
    {
      id: 'BI',
      label: 'Burundi',
      alpha3: 'BDI',
    },
    {
      id: 'KH',
      label: 'Cambodia',
      alpha3: 'KHM',
    },
    {
      id: 'CM',
      label: 'Cameroon',
      alpha3: 'CMR',
    },
    {
      id: 'CA',
      label: 'Canada',
      alpha3: 'CAN',
    },
    {
      id: 'CV',
      label: 'Cape Verde',
      alpha3: 'CPV',
    },
    {
      id: 'KY',
      label: 'Cayman Islands',
      alpha3: 'CYM',
    },
    {
      id: 'CF',
      label: 'Central African Republic',
      alpha3: 'CAF',
    },
    {
      id: 'TD',
      label: 'Chad',
      alpha3: 'TCD',
    },
    {
      id: 'CL',
      label: 'Chile',
      alpha3: 'CHL',
    },
    {
      id: 'CN',
      label: 'China',
      alpha3: 'CHN',
    },
    {
      id: 'CX',
      label: 'Christmas Island',
      alpha3: 'CXR',
    },
    {
      id: 'CC',
      label: 'Cocos (Keeling) Islands',
      alpha3: 'CCK',
    },
    {
      id: 'CO',
      label: 'Colombia',
      alpha3: 'COL',
    },
    {
      id: 'KM',
      label: 'Comoros',
      alpha3: 'COM',
    },
    {
      id: 'CG',
      label: 'Congo',
      alpha3: 'COG',
    },
    {
      id: 'CD',
      label: 'Congo, The Democratic Republic Of The',
      alpha3: 'COD',
    },
    {
      id: 'CK',
      label: 'Cook Islands',
      alpha3: 'COK',
    },
    {
      id: 'CR',
      label: 'Costa Rica',
      alpha3: 'CRI',
    },
    {
      id: 'HR',
      label: 'Croatia',
      alpha3: 'HRV',
    },
    {
      id: 'CU',
      label: 'Cuba',
      alpha3: 'CUB',
    },
    {
      id: 'CW',
      label: 'Curaçao',
      alpha3: 'CUW',
    },
    {
      id: 'CY',
      label: 'Cyprus',
      alpha3: 'CYP',
    },
    {
      id: 'CZ',
      label: 'Czech Republic',
      alpha3: 'CZE',
    },
    {
      id: 'CI',
      label: "Côte D'Ivoire",
      alpha3: 'CIV',
    },
    {
      id: 'DK',
      label: 'Denmark',
      alpha3: 'DNK',
    },
    {
      id: 'DJ',
      label: 'Djibouti',
      alpha3: 'DJI',
    },
    {
      id: 'DM',
      label: 'Dominica',
      alpha3: 'DMA',
    },
    {
      id: 'DO',
      label: 'Dominican Republic',
      alpha3: 'DOM',
    },
    {
      id: 'EC',
      label: 'Ecuador',
      alpha3: 'ECU',
    },
    {
      id: 'EG',
      label: 'Egypt',
      alpha3: 'EGY',
    },
    {
      id: 'SV',
      label: 'El Salvador',
      alpha3: 'SLV',
    },
    {
      id: 'GQ',
      label: 'Equatorial Guinea',
      alpha3: 'GNQ',
    },
    {
      id: 'ER',
      label: 'Eritrea',
      alpha3: 'ERI',
    },
    {
      id: 'EE',
      label: 'Estonia',
      alpha3: 'EST',
    },
    {
      id: 'ET',
      label: 'Ethiopia',
      alpha3: 'ETH',
    },
    {
      id: 'FK',
      label: 'Falkland Islands (Malvinas)',
      alpha3: 'FLK',
    },
    {
      id: 'FO',
      label: 'Faroe Islands',
      alpha3: 'FRO',
    },
    {
      id: 'FJ',
      label: 'Fiji',
      alpha3: 'FJI',
    },
    {
      id: 'FI',
      label: 'Finland',
      alpha3: 'FIN',
    },
    {
      id: 'FR',
      label: 'France',
      alpha3: 'FRA',
    },
    {
      id: 'GF',
      label: 'French Guiana',
      alpha3: 'GUF',
    },
    {
      id: 'PF',
      label: 'French Polynesia',
      alpha3: 'PYF',
    },
    {
      id: 'TF',
      label: 'French Southern Territories',
      alpha3: 'ATF',
    },
    {
      id: 'GA',
      label: 'Gabon',
      alpha3: 'GAB',
    },
    {
      id: 'GM',
      label: 'Gambia',
      alpha3: 'GMB',
    },
    {
      id: 'GE',
      label: 'Georgia',
      alpha3: 'GEO',
    },
    {
      id: 'DE',
      label: 'Germany',
      alpha3: 'DEU',
    },
    {
      id: 'GH',
      label: 'Ghana',
      alpha3: 'GHA',
    },
    {
      id: 'GI',
      label: 'Gibraltar',
      alpha3: 'GIB',
    },
    {
      id: 'GR',
      label: 'Greece',
      alpha3: 'GRC',
    },
    {
      id: 'GL',
      label: 'Greenland',
      alpha3: 'GRL',
    },
    {
      id: 'GD',
      label: 'Grenada',
      alpha3: 'GRD',
    },
    {
      id: 'GP',
      label: 'Guadeloupe',
      alpha3: 'GLP',
    },
    {
      id: 'GU',
      label: 'Guam',
      alpha3: 'GUM',
    },
    {
      id: 'GT',
      label: 'Guatemala',
      alpha3: 'GTM',
    },
    {
      id: 'GG',
      label: 'Guernsey',
      alpha3: 'GGY',
    },
    {
      id: 'GN',
      label: 'Guinea',
      alpha3: 'GIN',
    },
    {
      id: 'GW',
      label: 'Guinea-Bissau',
      alpha3: 'GNB',
    },
    {
      id: 'GY',
      label: 'Guyana',
      alpha3: 'GUY',
    },
    {
      id: 'HT',
      label: 'Haiti',
      alpha3: 'HTI',
    },
    {
      id: 'HM',
      label: 'Heard and McDonald Islands',
      alpha3: 'HMD',
    },
    {
      id: 'VA',
      label: 'Holy See (Vatican City State)',
      alpha3: 'VAT',
    },
    {
      id: 'HN',
      label: 'Honduras',
      alpha3: 'HND',
    },
    {
      id: 'HK',
      label: 'Hong Kong',
      alpha3: 'HKG',
    },
    {
      id: 'HU',
      label: 'Hungary',
      alpha3: 'HUN',
    },
    {
      id: 'IS',
      label: 'Iceland',
      alpha3: 'ISL',
    },
    {
      id: 'IN',
      label: 'India',
      alpha3: 'IND',
    },
    {
      id: 'ID',
      label: 'Indonesia',
      alpha3: 'IDN',
    },
    {
      id: 'IR',
      label: 'Iran, Islamic Republic Of',
      alpha3: 'IRN',
    },
    {
      id: 'IQ',
      label: 'Iraq',
      alpha3: 'IRQ',
    },
    {
      id: 'IE',
      label: 'Ireland',
      alpha3: 'IRL',
    },
    {
      id: 'IM',
      label: 'Isle of Man',
      alpha3: 'IMN',
    },
    {
      id: 'IL',
      label: 'Israel',
      alpha3: 'ISR',
    },
    {
      id: 'IT',
      label: 'Italy',
      alpha3: 'ITA',
    },
    {
      id: 'JM',
      label: 'Jamaica',
      alpha3: 'JAM',
    },
    {
      id: 'JP',
      label: 'Japan',
      alpha3: 'JPN',
    },
    {
      id: 'JE',
      label: 'Jersey',
      alpha3: 'JEY',
    },
    {
      id: 'JO',
      label: 'Jordan',
      alpha3: 'JOR',
    },
    {
      id: 'KZ',
      label: 'Kazakhstan',
      alpha3: 'KAZ',
    },
    {
      id: 'KE',
      label: 'Kenya',
      alpha3: 'KEN',
    },
    {
      id: 'KI',
      label: 'Kiribati',
      alpha3: 'KIR',
    },
    {
      id: 'KP',
      label: "Korea, Democratic People's Republic Of",
      alpha3: 'PRK',
    },
    {
      id: 'KR',
      label: 'Korea, Republic of',
      alpha3: 'KOR',
    },
    {
      id: 'KW',
      label: 'Kuwait',
      alpha3: 'KWT',
    },
    {
      id: 'KG',
      label: 'Kyrgyzstan',
      alpha3: 'KGZ',
    },
    {
      id: 'LA',
      label: "Lao People's Democratic Republic",
      alpha3: 'LAO',
    },
    {
      id: 'LV',
      label: 'Latvia',
      alpha3: 'LVA',
    },
    {
      id: 'LB',
      label: 'Lebanon',
      alpha3: 'LBN',
    },
    {
      id: 'LS',
      label: 'Lesotho',
      alpha3: 'LSO',
    },
    {
      id: 'LR',
      label: 'Liberia',
      alpha3: 'LBR',
    },
    {
      id: 'LY',
      label: 'Libya',
      alpha3: 'LBY',
    },
    {
      id: 'LI',
      label: 'Liechtenstein',
      alpha3: 'LIE',
    },
    {
      id: 'LT',
      label: 'Lithuania',
      alpha3: 'LTU',
    },
    {
      id: 'LU',
      label: 'Luxembourg',
      alpha3: 'LUX',
    },
    {
      id: 'MO',
      label: 'Macao',
      alpha3: 'MAC',
    },
    {
      id: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic Of',
      alpha3: 'MKD',
    },
    {
      id: 'MG',
      label: 'Madagascar',
      alpha3: 'MDG',
    },
    {
      id: 'MW',
      label: 'Malawi',
      alpha3: 'MWI',
    },
    {
      id: 'MY',
      label: 'Malaysia',
      alpha3: 'MYS',
    },
    {
      id: 'MV',
      label: 'Maldives',
      alpha3: 'MDV',
    },
    {
      id: 'ML',
      label: 'Mali',
      alpha3: 'MLI',
    },
    {
      id: 'MT',
      label: 'Malta',
      alpha3: 'MLT',
    },
    {
      id: 'MH',
      label: 'Marshall Islands',
      alpha3: 'MHL',
    },
    {
      id: 'MQ',
      label: 'Martinique',
      alpha3: 'MTQ',
    },
    {
      id: 'MR',
      label: 'Mauritania',
      alpha3: 'MRT',
    },
    {
      id: 'MU',
      label: 'Mauritius',
      alpha3: 'MUS',
    },
    {
      id: 'YT',
      label: 'Mayotte',
      alpha3: 'MYT',
    },
    {
      id: 'MX',
      label: 'Mexico',
      alpha3: 'MEX',
    },
    {
      id: 'FM',
      label: 'Micronesia, Federated States Of',
      alpha3: 'FSM',
    },
    {
      id: 'MD',
      label: 'Moldova, Republic of',
      alpha3: 'MDA',
    },
    {
      id: 'MC',
      label: 'Monaco',
      alpha3: 'MCO',
    },
    {
      id: 'MN',
      label: 'Mongolia',
      alpha3: 'MNG',
    },
    {
      id: 'ME',
      label: 'Montenegro',
      alpha3: 'MNE',
    },
    {
      id: 'MS',
      label: 'Montserrat',
      alpha3: 'MSR',
    },
    {
      id: 'MA',
      label: 'Morocco',
      alpha3: 'MAR',
    },
    {
      id: 'MZ',
      label: 'Mozambique',
      alpha3: 'MOZ',
    },
    {
      id: 'MM',
      label: 'Myanmar',
      alpha3: 'MMR',
    },
    {
      id: 'NA',
      label: 'Namibia',
      alpha3: 'NAM',
    },
    {
      id: 'NR',
      label: 'Nauru',
      alpha3: 'NRU',
    },
    {
      id: 'NP',
      label: 'Nepal',
      alpha3: 'NPL',
    },
    {
      id: 'NL',
      label: 'Netherlands',
      alpha3: 'NLD',
    },
    {
      id: 'NC',
      label: 'New Caledonia',
      alpha3: 'NCL',
    },
    {
      id: 'NZ',
      label: 'New Zealand',
      alpha3: 'NZL',
    },
    {
      id: 'NI',
      label: 'Nicaragua',
      alpha3: 'NIC',
    },
    {
      id: 'NE',
      label: 'Niger',
      alpha3: 'NER',
    },
    {
      id: 'NG',
      label: 'Nigeria',
      alpha3: 'NGA',
    },
    {
      id: 'NU',
      label: 'Niue',
      alpha3: 'NIU',
    },
    {
      id: 'NF',
      label: 'Norfolk Island',
      alpha3: 'NFK',
    },
    {
      id: 'MP',
      label: 'Northern Mariana Islands',
      alpha3: 'MNP',
    },
    {
      id: 'NO',
      label: 'Norway',
      alpha3: 'NOR',
    },
    {
      id: 'OM',
      label: 'Oman',
      alpha3: 'OMN',
    },
    {
      id: 'PK',
      label: 'Pakistan',
      alpha3: 'PAK',
    },
    {
      id: 'PW',
      label: 'Palau',
      alpha3: 'PLW',
    },
    {
      id: 'PS',
      label: 'Palestine, State of',
      alpha3: 'PSE',
    },
    {
      id: 'PA',
      label: 'Panama',
      alpha3: 'PAN',
    },
    {
      id: 'PG',
      label: 'Papua New Guinea',
      alpha3: 'PNG',
    },
    {
      id: 'PY',
      label: 'Paraguay',
      alpha3: 'PRY',
    },
    {
      id: 'PE',
      label: 'Peru',
      alpha3: 'PER',
    },
    {
      id: 'PH',
      label: 'Philippines',
      alpha3: 'PHL',
    },
    {
      id: 'PN',
      label: 'Pitcairn',
      alpha3: 'PCN',
    },
    {
      id: 'PL',
      label: 'Poland',
      alpha3: 'POL',
    },
    {
      id: 'PT',
      label: 'Portugal',
      alpha3: 'PRT',
    },
    {
      id: 'PR',
      label: 'Puerto Rico',
      alpha3: 'PRI',
    },
    {
      id: 'QA',
      label: 'Qatar',
      alpha3: 'QAT',
    },
    {
      id: 'RO',
      label: 'Romania',
      alpha3: 'ROU',
    },
    {
      id: 'RU',
      label: 'Russian Federation',
      alpha3: 'RUS',
    },
    {
      id: 'RW',
      label: 'Rwanda',
      alpha3: 'RWA',
    },
    {
      id: 'RE',
      label: 'Réunion',
      alpha3: 'REU',
    },
    {
      id: 'BL',
      label: 'Saint Barthélemy',
      alpha3: 'BLM',
    },
    {
      id: 'SH',
      label: 'Saint Helena',
      alpha3: 'SHN',
    },
    {
      id: 'KN',
      label: 'Saint Kitts And Nevis',
      alpha3: 'KNA',
    },
    {
      id: 'LC',
      label: 'Saint Lucia',
      alpha3: 'LCA',
    },
    {
      id: 'MF',
      label: 'Saint Martin',
      alpha3: 'MAF',
    },
    {
      id: 'PM',
      label: 'Saint Pierre And Miquelon',
      alpha3: 'SPM',
    },
    {
      id: 'VC',
      label: 'Saint Vincent And The Grenedines',
      alpha3: 'VCT',
    },
    {
      id: 'WS',
      label: 'Samoa',
      alpha3: 'WSM',
    },
    {
      id: 'SM',
      label: 'San Marino',
      alpha3: 'SMR',
    },
    {
      id: 'ST',
      label: 'Sao Tome and Principe',
      alpha3: 'STP',
    },
    {
      id: 'SA',
      label: 'Saudi Arabia',
      alpha3: 'SAU',
    },
    {
      id: 'SN',
      label: 'Senegal',
      alpha3: 'SEN',
    },
    {
      id: 'RS',
      label: 'Serbia',
      alpha3: 'SRB',
    },
    {
      id: 'SC',
      label: 'Seychelles',
      alpha3: 'SYC',
    },
    {
      id: 'SL',
      label: 'Sierra Leone',
      alpha3: 'SLE',
    },
    {
      id: 'SG',
      label: 'Singapore',
      alpha3: 'SGP',
    },
    {
      id: 'SX',
      label: 'Sint Maarten',
      alpha3: 'SXM',
    },
    {
      id: 'SK',
      label: 'Slovakia',
      alpha3: 'SVK',
    },
    {
      id: 'SI',
      label: 'Slovenia',
      alpha3: 'SVN',
    },
    {
      id: 'SB',
      label: 'Solomon Islands',
      alpha3: 'SLB',
    },
    {
      id: 'SO',
      label: 'Somalia',
      alpha3: 'SOM',
    },
    {
      id: 'ZA',
      label: 'South Africa',
      alpha3: 'ZAF',
    },
    {
      id: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      alpha3: 'SGS',
    },
    {
      id: 'SS',
      label: 'South Sudan',
      alpha3: 'SSD',
    },
    {
      id: 'ES',
      label: 'Spain',
      alpha3: 'ESP',
    },
    {
      id: 'LK',
      label: 'Sri Lanka',
      alpha3: 'LKA',
    },
    {
      id: 'SD',
      label: 'Sudan',
      alpha3: 'SDN',
    },
    {
      id: 'SR',
      label: 'Suriname',
      alpha3: 'SUR',
    },
    {
      id: 'SJ',
      label: 'Svalbard And Jan Mayen',
      alpha3: 'SJM',
    },
    {
      id: 'SZ',
      label: 'Swaziland',
      alpha3: 'SWZ',
    },
    {
      id: 'SE',
      label: 'Sweden',
      alpha3: 'SWE',
    },
    {
      id: 'CH',
      label: 'Switzerland',
      alpha3: 'CHE',
    },
    {
      id: 'SY',
      label: 'Syrian Arab Republic',
      alpha3: 'SYR',
    },
    {
      id: 'TW',
      label: 'Taiwan',
      alpha3: 'TWN',
    },
    {
      id: 'TJ',
      label: 'Tajikistan',
      alpha3: 'TJK',
    },
    {
      id: 'TZ',
      label: 'Tanzania, United Republic of',
      alpha3: 'TZA',
    },
    {
      id: 'TH',
      label: 'Thailand',
      alpha3: 'THA',
    },
    {
      id: 'TL',
      label: 'Timor-Leste',
      alpha3: 'TLS',
    },
    {
      id: 'TG',
      label: 'Togo',
      alpha3: 'TGO',
    },
    {
      id: 'TK',
      label: 'Tokelau',
      alpha3: 'TKL',
    },
    {
      id: 'TO',
      label: 'Tonga',
      alpha3: 'TON',
    },
    {
      id: 'TT',
      label: 'Trinidad and Tobago',
      alpha3: 'TTO',
    },
    {
      id: 'TN',
      label: 'Tunisia',
      alpha3: 'TUN',
    },
    {
      id: 'TR',
      label: 'Turkey',
      alpha3: 'TUR',
    },
    {
      id: 'TM',
      label: 'Turkmenistan',
      alpha3: 'TKM',
    },
    {
      id: 'TC',
      label: 'Turks and Caicos Islands',
      alpha3: 'TCA',
    },
    {
      id: 'TV',
      label: 'Tuvalu',
      alpha3: 'TUV',
    },
    {
      id: 'UG',
      label: 'Uganda',
      alpha3: 'UGA',
    },
    {
      id: 'UA',
      label: 'Ukraine',
      alpha3: 'UKR',
    },
    {
      id: 'AE',
      label: 'United Arab Emirates',
      alpha3: 'ARE',
    },
    {
      id: 'UK',
      label: 'United Kingdom',
      alpha3: 'GBR',
    },
    {
      id: 'UM',
      label: 'United States Minor Outlying Islands',
      alpha3: 'UMI',
    },
    {
      id: 'UY',
      label: 'Uruguay',
      alpha3: 'URY',
    },
    {
      id: 'UZ',
      label: 'Uzbekistan',
      alpha3: 'UZB',
    },
    {
      id: 'VU',
      label: 'Vanuatu',
      alpha3: 'VUT',
    },
    {
      id: 'VE',
      label: 'Venezuela, Bolivarian Republic of',
      alpha3: 'VEN',
    },
    {
      id: 'VN',
      label: 'Vietnam',
      alpha3: 'VNM',
    },
    {
      id: 'VG',
      label: 'Virgin Islands, British',
      alpha3: 'VGB',
    },
    {
      id: 'VI',
      label: 'Virgin Islands, U.S.',
      alpha3: 'VIR',
    },
    {
      id: 'WF',
      label: 'Wallis and Futuna',
      alpha3: 'WLF',
    },
    {
      id: 'EH',
      label: 'Western Sahara',
      alpha3: 'ESH',
    },
    {
      id: 'YE',
      label: 'Yemen',
      alpha3: 'YEM',
    },
    {
      id: 'ZM',
      label: 'Zambia',
      alpha3: 'ZMB',
    },
    {
      id: 'ZW',
      label: 'Zimbabwe',
      alpha3: 'ZWE',
    },
    {
      id: 'AX',
      label: 'Åland Islands',
      alpha3: 'ALA',
    },
  ],
};
