import Component from '@glimmer/component';
import { action } from '@ember/object';

const options = [
  { id: 'desktop', text: 'Desktop', icon: '/assets/icons/blue/desktop.svg' },
  { id: 'mobile', text: 'Mobile', icon: '/assets/icons/blue/mobile.svg' },
];

export default class NwOnboardingFlowDeviceSelectComponent extends Component {
  get options() {
    return options;
  }

  get selected() {
    return this.options.findBy('id', this.args.selectedId);
  }

  @action onChange(device) {
    this.args.onSelected(device.id === 'mobile');
  }
}
