import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SubuserModalForm extends Component {
  @service session;
  @service notifications;
  @service metrics;

  @tracked urlLimitError = null;
  @tracked keywordLimitError = null;

  @action
  toggleAccessGroup(groupId) {
    const groupIds = this.args.subuser.group_ids;
    if (groupIds.includes(groupId)) {
      groupIds.removeObject(groupId);
    } else {
      groupIds.addObject(groupId);
    }
  }

  @action
  validate() {
    this.urlLimitError = null;
    this.keywordLimitError = null;

    const subuser = this.args.subuser;
    const isLimited = subuser.access_type === 'limited';

    let valid = true;

    if (isLimited && subuser.url_limit_enabled) {
      if (!(Number.parseInt(subuser.url_limit, 10) > 1)) {
        valid = false;
        this.urlLimitError = 'Set a URL limit.';
      }
      if (
        Number.parseInt(subuser.url_limit, 10) >
        Number.parseInt(this.session.user.urls_remaining, 10)
      ) {
        valid = false;
        this.urlLimitError = 'Limit must be lower than available URLs.';
      }
    }
    if (isLimited && subuser.keyword_limit_enabled) {
      if (!(Number.parseInt(subuser.keyword_limit, 10) > 1)) {
        valid = false;
        this.keywordLimitError = 'Set a keyword limit.';
      }
      if (
        Number.parseInt(subuser.keyword_limit, 10) >
        Number.parseInt(this.session.user.keywords_remaining, 10)
      ) {
        valid = false;
        this.keywordLimitError = 'Limit must be lower than available URLs.';
      }
    }
    return valid;
  }

  @action
  save() {
    if (!this.validate()) return;
    const subuser = this.args.subuser;
    if (!subuser.keyword_limit) {
      subuser.keyword_limit = 0;
    }
    if (!subuser.url_limit) {
      subuser.url_limit = 0;
    }
    const isNew = subuser.isNew;
    return subuser.save().then(() => {
      const text = isNew ? 'Invitation sent' : 'Successfully saved';
      this.notifications.success(text, {
        autoClear: true,
      });
      this.args.onClose();
      if (isNew) this.metrics.trackEvent({ event: 'Invited Teammate' });
    });
  }
}
