import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import ENV from 'nightwatch-web/config/environment';

export default class PlanController extends Controller {
  @service router;
  @service metrics;

  // paymentAmount = 1 is the default used by FB when no value submitted
  trackConversion(paymentAmount = 1) {
    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Conversions',
        eventAction: 'subscribe',
        eventLabel: '',
        eventValue: paymentAmount,
      });
    }

    if (window.gtag) {
      const conversionId = 'inJgCNDogI8BEP3p7Z0D';
      window.gtag('event', 'conversion', {
        send_to: `${ENV.gtagId}/${conversionId}`,
        value: paymentAmount,
        currency: 'USD',
        transaction_id: '',
      });
    }

    if (window.qp) {
      window.qp('track', 'Purchase');
    }

    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: paymentAmount,
        currency: 'USD',
      });
    }
  }

  trackSubscription(paymentAmount) {
    const plan = this.get('model.plan');
    this.metrics.trackEvent({
      event: 'Subscribed',
      paymentAmount,
      planId: plan.id,
      planName: plan.name,
      planPrice: plan.price,
      currency: 'USD',
    });
  }

  @action
  afterPaymentSuccess(isConversion = false, paymentAmount) {
    window.scroll(0, 0);
    this.trackSubscription(paymentAmount);
    if (isConversion) this.trackConversion(paymentAmount);
    this.router.transitionTo('plan-success', this.get('model.plan'));
  }
}
