import { helper } from '@ember/component/helper';
import { pluralize } from 'ember-inflector';

const COUNT_CONVERT_DIC = ['no', 'a'];

const humanizeCount = (count) => COUNT_CONVERT_DIC[count] || count;
const areOrIs = (count) => (count == 1 ? 'is' : 'are');
const pluralizeIfNeed = (count, type) => (count == 1 ? type : pluralize(type));
const auditCheckTooltip = ([count, type]) => {
  const safeCount = count || 0;
  return `There ${areOrIs(safeCount)} ${humanizeCount(
    safeCount
  )} ${pluralizeIfNeed(safeCount, type)} present on this page`;
};

export default helper(auditCheckTooltip);
