import intercomAdapter from 'ember-metrics/metrics-adapters/intercom';
import googleAnalyticsAdapter from 'ember-metrics/metrics-adapters/google-analytics';
import googleTagManagerAdapter from 'ember-metrics/metrics-adapters/google-analytics';

export function initialize(application) {
  application.register('metrics-adapter:intercom', intercomAdapter);
  application.register(
    'metrics-adapter:google-analytics',
    googleAnalyticsAdapter
  );
  application.register(
    'metrics-adapter:google-tag-manager',
    googleTagManagerAdapter
  );
}

export default {
  initialize,
};
