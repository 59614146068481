import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isBlank, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import underscorifyKeys from 'nightwatch-web/utils/underscorify-keys';

export default class DynamicView extends Model {
  @service store;
  @service filterData;

  @attr('string') name;
  @attr('number', { readOnly: true }) keyword_count;
  @attr('number', { readOnly: true }) report_count;

  @belongsTo('url-group', { async: false }) urlGroup;
  @belongsTo('url', { async: false }) url;
  @hasMany('filter-group', { async: false }) filterGroups;
  @hasMany('reports', { async: false }) reports;

  @tracked filtersSaving = false;
  @tracked filtersSaved = false;

  get visibleFilterGroups() {
    return this.filterGroups.rejectBy('markedAsDeleted');
  }

  get isGlobal() {
    return isBlank(this.url) && isPresent(this.urlGroup);
  }

  serializeFilters() {
    const filterGroupsPayload = this.filterGroups.map((fg) => fg.serialize());
    return this.filterData.removeEmptyFiltersFromGroups(filterGroupsPayload);
  }

  loadFilterGroups() {
    return this.store.query(
      'filter-group',
      underscorifyKeys({ dynamicViewId: this.id })
    );
  }
}
