import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';

class GlobalViewDimension extends Dimension {
  requestWith = ['dynamicView'];

  get findData() {
    return (
      this.providedData
        ?.find((d) => d.dynamic_view_id === parseInt(this.dynamicView.id, 10))
        ?.dynamic_view_series?.findBy('name', this.name)?.series ?? []
    );
  }

  get series() {
    return this.graph.series.find(
      (s) =>
        s.name === this.name &&
        s.dynamicView === this.dynamicView &&
        s.url === null
    );
  }
}

export class GlobalViewDimensions extends DimensionGroup {
  label = 'Views';

  constructor() {
    super(...arguments);
    const { dynamicView } = this;
    const props = {
      ...this.dimensionProps,
      dynamicView,
      subTitle: dynamicView.name,
    };
    this.dimensions = [
      new GlobalViewDimension({
        ...props,
        name: 'average_position',
        scaleId: 'nwPosition',
        title: 'Average Position',
        label: 'Average Position',
      }),
      new GlobalViewDimension({
        ...props,
        name: 'search_visibility_index',
        title: 'Search Visibility',
        label: 'Search Visibility',
      }),
      new GlobalViewDimension({
        ...props,
        name: 'click_potential',
        title: 'Click Potential',
        label: 'Click Potential',
      }),
      new GlobalViewDimension({
        ...props,
        name: 'total_keywords',
        title: 'Total Keywords',
        label: 'Total Keywords',
      }),
      new GlobalViewDimension({
        ...props,
        type: 'nwBar',
        scaleId: 'nwStackedValue',
        icon: '/assets/icons/distribution-icon.png',
        name: 'keyword_distribution',
        title: 'Keyword Distribution',
        label: 'Keyword Distribution',
        // Required so that object series will be detected and rendered
        fillGapsValue: {
          no_rank: null,
          top_3: null,
          top_10: null,
          top_100: null,
        },
      }),
    ];
  }
}
