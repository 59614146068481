import { modifier } from 'ember-modifier';

/**
 @param {HTMLElement} resizable - Element that will be resized
 @param {String} resizerSelector - Query selector reference to resizer child element
 @param {Integer} initialWidth - Initial resizable element width (Optional)
 @param {Integer} minWidth - Minimum resized width
 @param {Integer} maxWidth - Maximum resize width
 @param {function(Integer)} onResize - Called with the new width each time resizable is resized (Optional)
 */
const draggableResizeHorizontal = (
  resizable,
  [resizerSelector],
  { initialWidth, minWidth, maxWidth, onResize }
) => {
  const resizer = resizable.querySelector(resizerSelector);
  if (!resizer || !minWidth || !maxWidth) return;

  const getWidth = () => resizable.getBoundingClientRect().width;
  const setWidth = (value) => (resizable.style.minWidth = `${value}px`);

  let startWidth, startX;

  const doDrag = (e) => {
    let newWidth = startWidth + e.clientX - startX;
    if (newWidth < minWidth) newWidth = minWidth;
    if (newWidth > maxWidth) newWidth = maxWidth;
    setWidth(newWidth);
  };

  const stopDrag = () => {
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
    onResize?.(getWidth());
  };

  const initDrag = (e) => {
    startWidth = getWidth();
    startX = e.clientX;
    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
  };

  const onDoubleClick = () => {
    setWidth(minWidth + 1);
    onResize?.(getWidth());
  };

  if (initialWidth) setWidth(initialWidth);

  resizer.addEventListener('mousedown', initDrag, false);
  resizer.addEventListener('dblclick', onDoubleClick, false);

  return () => {
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
    resizer.removeEventListener('mousedown', initDrag, false);
    resizer.removeEventListener('dblclick', onDoubleClick, false);
  };
};

export default modifier(draggableResizeHorizontal, { eager: false });
