import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class PlanSuccessController extends Controller {
  @service router;

  get plan() {
    return this.model;
  }

  @action
  afterSave() {
    this.router.transitionTo('dashboard.overview');
  }
}
