import ApplicationAdapter from './application';

export default class CompetitorAdapter extends ApplicationAdapter {
  buildURL(_modelName, id, snapshot, requestType, query) {
    const defaultUrl = super.buildURL(...arguments);
    const urlId =
      query && query.url_id
        ? query.url_id
        : snapshot && snapshot.belongsTo
        ? snapshot.belongsTo('url')?.id
        : undefined;
    switch (requestType) {
      case 'deleteRecord':
      case 'updateRecord':
        return defaultUrl.replace(
          /\/competitors\/?\d*/,
          `/urls/${urlId}/competitors/${id}`
        );
      default:
        return defaultUrl.replace(
          /\/competitors/,
          `/urls/${urlId}/competitors`
        );
    }
  }
}
