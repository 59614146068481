import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BacklinksActionsComponent extends Component {
  @service('fetch-tags') fetchTagsService;

  @tracked backlinkTags;

  // Don't allow the actions dropdown to open unless some backlinks have been selected
  get disableActionsDropdown() {
    return !this.args.someBacklinksSelected;
  }

  @action
  onActionsDropdownOpen() {
    if (this.disableActionsDropdown) return false;
  }

  @task({ restartable: true })
  *fetchTags() {
    this.backlinkTags = yield this.fetchTagsService.fetchBacklinkTags(this.url);
  }
}
