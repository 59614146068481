import Model, { belongsTo, attr } from '@ember-data/model';

export default class Series extends Model {
  @attr('string') name;
  @attr('boolean') active;
  @attr('string') color;

  @belongsTo('graph', { async: false }) graph;
  @belongsTo('url', { async: false }) url;

  @belongsTo('competitor', { async: false }) competitor;
  @belongsTo('keyword', { async: false }) keyword;
  @belongsTo('dynamic-view', { async: false }) dynamicView;
  @belongsTo('url-group', { async: false }) urlGroup;
  @belongsTo('backlink-view', { async: false }) backlinkView;

  get dimensionGroupName() {
    if (this.checkId('url')) {
      if (
        this.checkId('keyword') ||
        (this.checkId('keyword') && this.checkId('competitor'))
      ) {
        return 'keyword';
      }

      if (
        this.checkId('dynamicView') ||
        (this.checkId('dynamicView') && this.checkId('competitor'))
      ) {
        return 'dynamicView';
      }

      if (this.checkId('competitor')) {
        return 'competitor';
      }

      if (this.checkId('backlinkView')) {
        return 'backlinkView';
      }

      return 'url';
    }

    if (this.checkId('dynamicView')) return 'dynamicView';
    if (this.checkId('urlGroup')) return 'urlGroup';

    return null;
  }

  checkId(relationship) {
    return this.belongsTo(relationship).id();
  }
}
