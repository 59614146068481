const POSITION_VALUES = Object.freeze({
  // Source data values in series endpoint responses
  SOURCE: {
    NOT_IN_RESULTS: null,
    // NOT_CRAWLED – represented in source data by a missing item
  },
  // Values used internally eg. in charts
  NOT_IN_RESULTS: null,
  NOT_CRAWLED: undefined,
});

export default POSITION_VALUES;
