import { parseISO, isWithinInterval } from 'date-fns';

// Ensure there are start and end dates matching the selected date range
const clampData = (data, interval) => {
  return data.filter(([dateString]) =>
    isWithinInterval(parseISO(dateString), interval)
  );
};

export default clampData;
