/**
 * Helper function to construct an HTML tree
 * @param  {String}       tag         HTML element tag
 * @param  {Object}       [attrs={}]  Element attributes
 * @param  {Node|String}  children    Child node(s); HTML element(s) or text
 * @return {[Node]}                   Node
 */
const html = (tag, attrs = {}, ...children) => {
  const el = document.createElement(tag);
  Object.keys(attrs).forEach((attr) => {
    el.setAttribute(attr, attrs[attr]);
  });
  children.forEach((c) => {
    if (!c.toString()) return; // Skip empty strings
    if (typeof c !== 'object') {
      c = document.createTextNode(c.toString());
    }
    el.appendChild(c);
  });
  return el;
};

// Tests runner
const supports = (tests) => {
  for (let i = 0; i < tests.length; i++) {
    if (!tests[i]()) return false;
  }
  return true;
};

/*
 * Tests
 * - return true if pass, false if fail
 */
// Test if querySelectorAll exists and returns a forEach-able NodeList
const forEachOnNodeList = () => {
  const el = html('div');
  if (!el.querySelectorAll) return false;
  if (!el.querySelectorAll('[test]').forEach) return false;
  return true;
};

const stringIncludes = () => {
  if (!String.prototype.includes) return false;
  return true;
};

const arrayFrom = () => {
  if (!Array.from) return false;
  return true;
};

export const initialize = (application) => {
  if (!supports([forEachOnNodeList, stringIncludes, arrayFrom])) {
    application.deferReadiness(); // Stops booting
    const warning = html(
      'div',
      { class: 'unsupported-browser' },
      html(
        'h1',
        { class: 'unsupported-browser__heading' },
        'Please update or change your browser'
      ),
      html(
        'p',
        { class: 'unsupported-browser__description' },
        "You're using a browser that is missing essential programming features. We're not particularly strict about blocking outdated browsers but when missing essential functionality, we don't have a choice."
      ),
      html(
        'p',
        { class: 'unsupported-browser__description' },
        'This application is being extensively tested on recent versions of Firefox, Safari and Chromium based browsers. Other browsers might work.'
      ),
      html(
        'p',
        { class: 'unsupported-browser__description' },
        html(
          'a',
          { href: 'mailto:info@nightwatch.io' },
          'Need help? Get in touch.'
        )
      )
    );
    document.body.className = 'dusk';
    document.body.appendChild(warning);
  }
};

export default {
  name: 'browser-support',
  before: [
    'inject-notifications',
    'input-attributes',
    'site-data',
    'user-settings',
  ],
  initialize,
};
