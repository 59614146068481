import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditAllChecksRoute extends Route {
  @service router;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }
}
