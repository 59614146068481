import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';

export default class NwDeepResearchProgressbarComponent extends Component {
  @tracked infinite = this.args.infinite ?? false;
  @tracked showLabel = this.args.showLabel ?? false;
  @tracked size = this.args.size ?? 'medium';
  @tracked steps = this.args.steps ?? 1;
  @tracked successValue = this.args.successValue ?? 100;
  @tracked minValue = this.args.minValue ?? 0;
  @tracked maxValue = this.args.maxValue ?? 100;

  @computed('args.value')
  get value() {
    return this.args.value ?? 0;
  }

  @computed('value', 'minValue', 'maxValue')
  get progressLabel() {
    const { value, maxValue, minValue } = this;
    return `${Math.round(
      ((value - minValue) / (maxValue - minValue)) * 100
    )}% Complete`;
  }

  @computed('value', 'minValue', 'maxValue', 'steps')
  get stepsLabel() {
    const { value, maxValue, minValue, steps } = this;
    return `Step ${Math.ceil(
      ((value - minValue) / (maxValue - minValue)) * steps
    )} of ${steps}`;
  }

  @computed('infinite', 'steps', 'progressLabel', 'stepsLabel')
  get ariaLabel() {
    if (this.args.ariaLabel) return this.args.ariaLabel;
    return this.infinite
      ? 'Loading'
      : this.steps > 1
      ? this.stepsLabel
      : this.progressLabel;
  }

  @computed('steps')
  get stepsArray() {
    return Array.from({ length: this.steps });
  }

  @action
  getStepState(index) {
    const { value, maxValue, minValue, steps } = this;
    const stepValue = (maxValue - minValue) / steps;
    const currentValue = value - minValue;

    if (currentValue >= (index + 1) * stepValue) {
      return 'completed';
    } else if (
      currentValue >= index * stepValue &&
      currentValue < (index + 1) * stepValue
    ) {
      return 'in-progress';
    } else {
      return 'awaits';
    }
  }

  @action
  getProgressStyle(index) {
    const stepState = this.getStepState(index);
    console.log('stepState', stepState);
    if (stepState === 'completed') {
      return 'width: 100%; transform: translateX(0); animation: none;';
    } else if (stepState === 'in-progress') {
      return 'width: 100%; animation: inProgressAnimation 2.1s infinite linear;';
    } else {
      return 'width: 0%; transform: translateX(-102%);';
    }
  }
}
