import Component from '@glimmer/component';

export default class BooleanIconComponent extends Component {
  get isValuePositive() {
    return this.args.reverse ? !this.args.value : this.args.value;
  }

  get icon() {
    return this.isValuePositive ? 'checkmark' : 'cross';
  }

  get statusIcon() {
    return this.isValuePositive ? 'ok' : 'bad';
  }

  get text() {
    if (this.args.reverse) {
      if (this.isValuePositive) {
        return this.args.falseText;
      } else {
        return this.args.trueText;
      }
    } else {
      if (this.isValuePositive) {
        return this.args.trueText;
      } else {
        return this.args.falseText;
      }
    }
  }
}
