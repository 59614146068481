import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InvoicesListRowComponent extends Component {
  @service invoiceActions;
  @tracked isRegenerating = false;
  @tracked isRegenerated = false;
  daysLimit = 10;

  get canRegenerate() {
    const limitDaysAgo =
      new Date().getTime() - this.daysLimit * 24 * 60 * 60 * 1000;
    const invoiceDateTimestamp = new Date(
      this.args.invoice?.invoiceDate
    ).getTime();
    return invoiceDateTimestamp > limitDaysAgo;
  }

  get regenerateButtonTooltip() {
    const canRegenerate = this.canRegenerate;
    if (canRegenerate) {
      return 'Override invoice with your current billing information.';
    }
    return 'Can not regenerate invoices older than 10 days.';
  }

  @action
  regenerate(invoice) {
    if (!this.canRegenerate) return;
    this.isRegenerating = true;
    const regeneratePromise = this.invoiceActions.regenerate(invoice);
    regeneratePromise.then(() => {
      this.isRegenerated = true;
    });
    regeneratePromise.finally(() => {
      this.isRegenerating = false;
    });
  }
}
