import Service, { inject as service } from '@ember/service';
import { rawTimeout, task } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';
import ENV from 'nightwatch-web/config/environment';
import fetch from 'fetch';

const CHECK_INTERVAL_MINUTES = 2;

export default class UpdateVersionCheckerService extends Service {
  @service notifications;

  fingerprint = null;

  @task
  *checkNewVersion() {
    yield rawTimeout(CHECK_INTERVAL_MINUTES * 60 * 1000);

    try {
      const request = yield fetch('/index.html', {
        contentType: 'text/html',
      });
      const response = yield request.text();

      if (!this.fingerprint) {
        this.fingerprint = this.parseFingerprint(response);
        return;
      }

      this.fingerprint === this.parseFingerprint(response)
        ? this.checkNewVersion.perform()
        : this.showNewVersionNotification();
    } catch (error) {
      this.checkNewVersion.perform();
      throw error;
    }
  }

  parseFingerprint(response) {
    const regEx = new RegExp(`${ENV.modulePrefix}-(.*)\\.js`, 'mg');

    const matches = regEx.exec(response);
    return matches?.length > 1 ? matches[1] : '';
  }

  showNewVersionNotification() {
    const msg = htmlSafe(
      `You're using an outdated version of the app. <b>Click here</b> to refresh the page.`
    );

    this.notifications.info(msg, {
      onClick: () => {
        window.location.reload();
      },
    });
  }
}
