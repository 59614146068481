import Component from '@glimmer/component';
/**
 * Returns css class for an icon
 * * returns "up" if value > 0
 * * returns "down" if value < 0
 * * returns "stagnant" if value == 0 / undefined / null
 * @type {('up'|'down'|'stagnant')}
 */

export default class StatChangeComponent extends Component {
  get valueOrZero() {
    return !this.args.value ? 0 : this.args.value;
  }

  get directionClass() {
    return this.valueOrZero > 0
      ? 'up'
      : this.valueOrZero < 0
      ? 'down'
      : 'stagnant';
  }
  get absValue() {
    return Math.abs(this.valueOrZero);
  }

  get needInvertedClass() {
    return this.args.inverted && this.valueOrZero !== 0;
  }
}
