import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

export default class PinnedItemsService extends Service {
  @tracked isResizing = false;

  constructor() {
    super(...arguments);
    window.addEventListener('resize', () => {
      this.isResizing = true;
      debounce(this, 'handleResize', 400);
    });
  }

  handleResize() {
    // To refresh the grid. So it will follow the resizing.
    this.isResizing = false;
  }

  callTheResizeHandler() {
    this.isResizing = true;
    debounce(this, 'handleResize', 400);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('resize', this.handleResize.bind(this));
  }
}
