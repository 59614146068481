import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import shakeElement from '../utils/dom/shake-element';

export default class dynamicViewSettingsForm extends Component {
  @tracked saved = false;
  @tracked isSaving = false;
  @tracked newUrlGroup = null;

  oldUrlGroup = null;

  get currentGroupId() {
    return this.newUrlGroup?.id ?? this.args.dynamicView?.urlGroup?.id;
  }

  get showGroupDropdown() {
    return (
      !this.args.dynamicView?.isNew &&
      !this.isUrlView &&
      this.args.urlGroups &&
      this.args.urlGroups?.length > 0
    );
  }

  handleGroupChange() {
    if (!this.newUrlGroup) return;
    this.oldUrlGroup = this.args.dynamicView.urlGroup;
    this.args.dynamicView.urlGroup = this.newUrlGroup;
  }

  @action
  save(ev) {
    this.isSaving = true;
    const savingStarted = new Date();
    const isNew = this.args.dynamicView.isNew;
    this.handleGroupChange();
    return this.args.dynamicView
      .save()
      .then((dynamicView) => {
        const savingEnded = new Date();
        const savingTime = savingEnded - savingStarted;
        // ensure saving animation for at least 1s
        const t = 1000;
        const animateFor = savingTime >= t ? 0 : t - savingTime;
        later(
          this,
          () => {
            this.isSaving = false;
            this.saved = true;
          },
          animateFor
        );
        if (!isNew) {
          if (this.args.onDynamicViewUpdate) {
            this.args.onDynamicViewUpdate(dynamicView, this.oldUrlGroup);
          }
        }
      })
      .catch(() => {
        this.isSaving = false;
        // Must defer shakeElement here – setting isSaving updates the DOM at the same time
        later(() => shakeElement(ev.submitter), 100);
      });
  }

  @action
  async delete() {
    if (!confirm('Are you sure you want to delete this view?')) return;

    const url = this.args.dynamicView.url;
    const urlGroup = this.args.dynamicView.urlGroup;
    await this.args.dynamicView.destroyRecord();
    urlGroup?.reload();
    this.args.afterDelete(url);
  }
}
