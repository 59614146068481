import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DashboardOverviewController extends Controller {
  @service('persistence/pinned-items') pinnedItemsPersistence;

  @controller dashboard;
  @tracked selectedDateRange;

  get pinnedItems() {
    return this.model;
  }

  init() {
    super.init(...arguments);
    const { type, start, end } = this.pinnedItemsPersistence.parseDateRange();
    this.selectedDateRange = { type, start, end };
  }

  @action
  setDateRange({ start, end, type }) {
    this.selectedDateRange = { start, end, type };
    this.pinnedItemsPersistence.persistDateRange(type);
  }
}
