import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ENV from 'nightwatch-web/config/environment';
import { action } from '@ember/object';
import { task, rawTimeout } from 'ember-concurrency';

export default class SocialSharingPopupComponent extends Component {
  @tracked hasCopied = false;

  get link() {
    if (!this.args.page) return '';
    return `${ENV.sharingPagesUrl}/${this.args.page.token}`;
  }

  get shareText() {
    if (!this.args.page) return '';
    return `SEO Progress for ${this.args.page.title}`;
  }

  @task({ drop: true })
  *copySuccess() {
    this.hasCopied = true;
    yield rawTimeout(900);
    this.hasCopied = false;
  }

  @action
  copyError() {
    this.notifications.error(
      'Something went wrong while copying the link. Please contact support',
      { autoClear: true }
    );
  }

  @action
  selectInput(event) {
    event.target.select();
  }
}
