import Service, { inject as service } from '@ember/service';

export default class SiteAuditSettingsService extends Service {
  @service userSettings;

  getSettings({ searchKeywordUrlId, siteAuditViewId }) {
    let settings = {
      sort: null,
      direction: null,
      limit: null,
      columns: null,
    };

    try {
      const setting = this.userSettings.getSetting({
        searchKeywordUrlId,
        siteAuditViewId,
      });

      if (setting) {
        const columns = setting.get('settingsData.siteAudit.columns');
        const sort = setting.get('settingsData.siteAudit.sortField');
        const direction = setting.get('settingsData.siteAudit.sortDirection');
        const limit = setting.get('settingsData.siteAudit.limit');

        if (columns) settings.columns = columns;
        if (sort) settings.sort = sort;
        if (direction) settings.direction = direction;
        if (limit) settings.limit = limit;
      }
      return settings;
    } catch (e) {
      return settings;
    }
  }
}
