import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ReportCandidatesComponent extends Component {
  @service candidates;

  @tracked sort = 'name';
  @tracked direction = 'asc';

  constructor(owner, args) {
    super(owner, args);
    this.candidates.loadReports.perform();
  }

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  get sortedItems() {
    let items = (this.candidates.reports || []).sortBy(this.sort);
    if (this.direction === 'desc') {
      items = items.reverse();
    }
    return items;
  }

  @action
  sortItems(sort, direction) {
    this.sort = sort;
    this.direction = direction;
  }
}
