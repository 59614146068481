import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

let ITEMS_PER_PAGE = 20;

export default class NwDeepResearchKeywordsListComponent extends Component {
  @service deepResearch;
  @service store;
  @tracked searchString = '';
  @tracked currentPage = 1;
  @tracked currentSort = 'ASC';

  columns = {
    default: {
      name: 'keyword',
      displayName: 'Keyword',
      sortProperty: 'keyword',
    },
    selected: [
      {
        name: 'avgMonthlySearches',
        displayName: 'Average Monthly Searches',
        sortProperty: 'avgMonthlySearches',
      },
      {
        name: 'lowCpc',
        displayName: 'Low CPC',
        sortProperty: 'lowCpc',
      },
      {
        name: 'highCpc',
        displayName: 'High CPC',
        sortProperty: 'highCpc',
      },
      {
        name: 'competition',
        displayName: 'Competition',
        sortProperty: 'competition',
      },
      {
        name: 'competitionIndex',
        displayName: 'Competition Index',
        sortProperty: 'competitionIndex',
      },
    ],
    drop: () => null,
  };

  get mimicProvider() {
    return {
      columns: this.columns,
      sort: (...args) => null,
      onSort: this.sortKeywords.bind(this),
      onSelectAllToggleChange: this.selectAllKeywords.bind(this),
    };
  }

  // Now using deepResearch.keywords directly instead of sortedKeywords
  get sortedKeywords() {
    return this.deepResearch.keywords;
  }

  get paginatedKeywords() {
    const start = (this.currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return this.filteredKeywordIndices.slice(start, end);
  }

  // This computes the filtered list based on search string
  @computed('searchString', 'sortedKeywords')
  get filteredKeywordIndices() {
    if (!this.searchString) {
      return this.sortedKeywords;
    }

    const searchStringLower = this.searchString.toLowerCase();
    return this.sortedKeywords.filter((keywordObj) =>
      keywordObj.keyword.toLowerCase().includes(searchStringLower)
    );
  }

  get totalPages() {
    return Math.ceil(this.filteredKeywordIndices.length / ITEMS_PER_PAGE);
  }

  get isFirstPage() {
    return this.currentPage === 1;
  }

  get isLastPage() {
    return this.currentPage === this.totalPages;
  }

  @action
  updateSearch(event) {
    this.searchString = event.target.value;
    this.currentPage = 1; // Reset to first page on search
  }

  @action
  nextPage() {
    if (!this.isLastPage) {
      this.currentPage++;
    }
  }

  @action
  previousPage() {
    if (!this.isFirstPage) {
      this.currentPage--;
    }
  }

  get selectedKeywordsQueries() {
    return this.selectedKeywords.map((keyword) => keyword.id);
  }

  @action
  sortKeywords(sortProperty) {
    const competitionRank = {
      HIGH: 3,
      MEDIUM: 2,
      LOW: 1,
      '/': 0, // assuming '/' is the lowest priority
    };

    const sortFunction = (a, b) => {
      // Handle sorting for competition field
      if (sortProperty === 'competition') {
        return (
          competitionRank[b[sortProperty]] - competitionRank[a[sortProperty]]
        );
      }

      // General sorting for strings and numbers
      if (
        typeof a[sortProperty] === 'string' &&
        typeof b[sortProperty] === 'string'
      ) {
        return parseInt(a[sortProperty]) - parseInt(b[sortProperty]);
      } else {
        return a[sortProperty] - b[sortProperty];
      }
    };

    if (this.currentSort === 'ASC') {
      this.deepResearch.keywords =
        this.deepResearch.keywords.sort(sortFunction);
      this.currentSort = 'DESC';
    } else {
      this.deepResearch.keywords = this.deepResearch.keywords.sort((a, b) =>
        sortFunction(b, a)
      ); // Reverse sort
      this.currentSort = 'ASC';
    }

    this.currentPage = 1; // Reset to first page after sorting
  }

  @action
  toggleKeywordSelection(keyword) {
    this.deepResearch.keywords = this.deepResearch.keywords.map((k) => {
      if (k.id === keyword.id) {
        return {
          ...k,
          selected: !k.selected,
        };
      }
      return k;
    });
  }

  get getKeywordsForExport() {
    if (this.selectedKeywords.length > 0) {
      return this.selectedKeywords;
    } else {
      if (this.searchString) {
        return this.filteredKeywordIndices;
      } else {
        return this.deepResearch.keywords;
      }
    }
  }

  @action
  selectAllKeywords(newVal) {
    const filteredKeywords = this.filteredKeywordIndices.map(
      (kw) => kw.keyword
    );

    if (this.searchString) {
      // Toggle selection for filtered keywords only
      this.deepResearch.keywords = this.deepResearch.keywords.map((keyword) => {
        if (filteredKeywords.includes(keyword.keyword)) {
          return {
            ...keyword,
            selected: newVal, // Toggle only for the filtered ones
          };
        }
        return keyword;
      });
    } else {
      this.deepResearch.keywords = this.deepResearch.keywords.map(
        (keyword) => ({
          ...keyword,
          selected: newVal, // Toggle all keywords
        })
      );
    }
  }

  get selectedKeywords() {
    return this.deepResearch.keywords.filter((keyword) => keyword.selected);
  }

  @action
  async prepareDownloadItem(item, format) {
    const columns = [
      'Keywords',
      'Average Monthly Searches',
      'lowCpc',
      'highCpc',
      'competition',
      'competitionIndex',
    ];
    const rowsGenerator = (entry) => [
      entry.keyword,
      entry.avgMonthlySearches,
      entry.lowCpc,
      entry.highCpc,
      entry.competition,
      entry.competitionIndex,
    ];
    const title =
      'Research History Report - Keywords - ' + this.args.currentUrl;
    const subtitle = 'Research was done on ' + this.args.currentReportDate;

    const fileNameBase =
      this.args.currentUrl +
      '-keywords-research-' +
      this.args.currentReportDate;
    await this.deepResearch.exportData(
      this.getKeywordsForExport,
      format,
      columns,
      rowsGenerator,
      title,
      subtitle,
      fileNameBase
    );
  }

  get creditsEstimatedCost() {
    if (!this.args.classificationEnabled) {
      return 0;
    }
    const costPerRequest = 1;
    const maxKeywordsPerRequest = 100;

    const numberOfRequests = Math.ceil(
      this.selectedKeywords.length / maxKeywordsPerRequest
    );

    return numberOfRequests * costPerRequest;
  }
}
