import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const MAX_VISIBLE_TAGS = 5;

export default class NwKeywordTableRowTagsComponent extends Component {
  @service notifications;
  @service fetchTags;
  @tracked showAll = false;
  @tracked showInput = false;
  @tracked inputValue = '';

  @action
  removeTagAndSave(tag) {
    const { keyword } = this.args;
    this.fetchTags.removeTagFromSelectedModels.perform(
      tag,
      [keyword],
      this.args.saveOnEnter
    );
  }

  @action
  async addTagAndSave() {
    const { keyword } = this.args;
    const tag = this.inputValue;
    this.fetchTags.addTagToSelectedModels.perform(
      tag,
      [keyword],
      this.args.saveOnEnter
    );
  }

  @action
  toggleInput() {
    this.showInput = !this.showInput;
  }

  get displayTags() {
    const { tags } = this;
    if (tags.length > MAX_VISIBLE_TAGS && !this.showAll) {
      return tags.slice().splice(0, MAX_VISIBLE_TAGS);
    }
    return tags;
  }

  get showTagOverflow() {
    return this.hiddenTagsTooltip !== '';
  }

  get hiddenTagsTooltip() {
    if (this.showAll) return '';

    return this.overflowTags.join(' | ');
  }

  get hasTags() {
    return Boolean(this.tags.length);
  }

  get overflowTagsLength() {
    return this.overflowTags.length;
  }

  get overflowTags() {
    return this.tags.slice().splice(MAX_VISIBLE_TAGS, this.tags.length);
  }

  get tags() {
    return this.args.keyword.tags;
  }
}
