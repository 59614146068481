import Service, { inject as service } from '@ember/service';
import { allSettled, task } from 'ember-concurrency';

export default class SaveCompetitorsService extends Service {
  @service fetch;
  @service discovery;
  @service session;
  @service notifications;
  @service metrics;

  get hasCompetitorErrors() {
    return this.discovery.competitors.some(
      (competitor) => competitor.errors.length > 0
    );
  }

  checkCompetitorLimit(url) {
    const limit = this.session.user.competitors_per_url;
    if (url?.competitors?.length > limit) {
      this.notifications.error(
        `Maximum number of competitors for your plan: ${limit}`,
        { autoClear: true, clearDuration: 10000 }
      );
      this.saveCompetitorsTask.cancelAll();
    }
  }

  @task
  *saveCompetitorTask(competitor) {
    try {
      yield competitor.save();
    } catch (err) {
      throw new Error(err);
    }
  }

  @task({ drop: true })
  *saveCompetitorsTask(callback, isSettingsMode, url) {
    this.checkCompetitorLimit(url);
    if (this.discovery.competitors.length) {
      const validCompetitors = this.discovery.competitors?.filter(
        (competitor) => competitor.competitor_url
      );
      const tasks = validCompetitors?.map((competitor) =>
        this.saveCompetitorTask.perform(competitor)
      );
      yield allSettled(tasks);
    }
    if (!this.hasCompetitorErrors) {
      this.notifications.success(`Saved Competitors successfully!`, {
        autoClear: true,
      });
      if (typeof callback === 'function') callback();
    } else {
      this.notifications.error(
        `Failed to save competitors, please check them for errors.`,
        { autoClear: true }
      );
    }
    const trackingSource = isSettingsMode ? 'settings' : 'onboarding';
    this.metrics.trackEvent({
      event: 'Set Competitors',
      url: url.url,
      competitors: this.discovery.competitors.mapBy('friendlyUrl'),
      trackingSource,
    });
    // Reload competitors to refresh the relationship
    if (isSettingsMode) {
      this.fetch.post('/graphs/update_competitors_graph', {
        contentType: 'application/json',
        data: { url_id: url.id },
      });
      url.loadGraphs.perform();
    }
  }
}
