import { helper } from '@ember/component/helper';

export function round([column, competitors]) {
  const competitorId = column.split('-').reverse()[0];
  const competitor = competitors.findBy('id', competitorId);
  if (competitor) return competitor.get('friendlyUrl');
  else {
    return 'Competitor deleted. Please switch column.';
  }
}

export default helper(round);
