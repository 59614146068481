import ApplicationAdapter from './application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class BacklinkViewAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    if (Array.isArray(payload)) {
      const transformedPayload = payload.map((backlink) => ({
        ...backlink,
        url_id: backlink.search_keyword_url_id,
      }));
      payload = { 'backlink-views': transformedPayload };
    } else if (isObjectType(payload)) {
      payload = {
        'backlink-view': {
          ...payload,
          url_id: payload.search_keyword_url_id,
        },
      };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
