import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import Route from '@ember/routing/route';
import { isBlank, isPresent } from '@ember/utils';
import { A } from '@ember/array';
import RSVP from 'rsvp';

export default class DashboardUrlBacklinksIndexRoute extends Route {
  @service router;
  @service fetch;
  @service userSettings;
  @service('persistence/backlinks-settings') backlinksSettings;
  @service('notifications/backlinks-notifications') backlinksNotifications;
  @service metrics;
  @service session;
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
    direction: {
      refreshModel: true,
    },
    search: {
      refreshModel: true,
    },
    filters: {
      refreshModel: true,
    },
    domain: {
      refreshModel: true,
    },
    root_domain: {
      refreshModel: true,
    },
    one_link_per_domain: {
      refreshModel: true,
    },
    limit: {
      refreshModel: true,
    },
  };

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel(transition) {
    const url = this.modelFor('dashboard.url');
    const searchKeywordUrlId = url.get('id');

    const { sort, direction, limit } = this.backlinksSettings.getSettings({
      searchKeywordUrlId,
    });

    const queryParams = transition.to.queryParams;

    // When there are no params set, use the persisted params
    const shouldOverwriteQueryParams = isBlank(Object.keys(queryParams));

    if (shouldOverwriteQueryParams) {
      const newQueryParams = Object.assign({}, queryParams);

      if (isBlank(queryParams.sort) && isPresent(sort)) {
        newQueryParams.sort = sort;
        newQueryParams.direction = direction || 'desc';
      }
      if (isBlank(queryParams.limit) && isPresent(limit)) {
        newQueryParams.limit = limit;
      }
      this.router.transitionTo('dashboard.url.backlinks', url, {
        queryParams: newQueryParams,
      });
    }
  }

  model(params) {
    const url = this.modelFor('dashboard.url');
    const backlinks = this.store.query('backlink', {
      url_id: url.id,
      limit: params.limit || 50,
      sort: params.sort || 'url_rating',
      direction: params.direction || 'desc',
      page: params.page || 1,
      search: params.search,
      filters: params.filters,
      one_link_per_domain: params.one_link_per_domain,
    });

    return RSVP.hash({
      url: url,
      backlinks: backlinks,
    });
  }

  afterModel() {
    if (this.controller) {
      this.controller.set('selectedBacklinks', A());
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    // Fetch only when there is no stats present
    if (!controller.get('stats.all')) {
      controller.fetchStats();
    }

    const { user } = this.session;
    const filtersHidden =
      JSON.parse(localStorage.getItem('nw:backlinks:filters-shown')) === false;
    const filtersPresent = isPresent(controller.filters);
    controller.set('filtersShown', !filtersHidden || filtersPresent);
    this.backlinksNotifications.notifyLimitReached(user);
    this.backlinksNotifications.notifyFetchingDisabled(model.url);

    controller.set('progressCheckingEnabled', true);
    controller.handleProgress();

    // Prepare model for the view.
    if (!controller.backlinkView) {
      const url = model.url;
      const backlinkView = this.store.createRecord('backlink-view', {
        url: url,
      });
      controller.set('backlinkView', backlinkView);
    }
  }

  resetController(controller, isExiting) {
    controller.set('filters', null);

    if (isExiting) {
      controller.setProperties({
        stats: EmberObject.create({}),
        backlinkView: null,
        progressCheckingEnabled: false,
      });
    }
  }

  deactivate() {
    this.backlinksNotifications.clearNotifications();
  }

  @action
  async loading(transition) {
    const controller = this.controllerFor('dashboard.url.backlinks.index');
    controller.set('isLoading', true);
    try {
      await transition.promise;
    } finally {
      controller.set('isLoading', false);
    }
    return true;
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Open Backlinks' });
    return true;
  }
}
