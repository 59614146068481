import isTesting from 'nightwatch-web/utils/is-testing';
import Component from '@glimmer/component';
import { later } from '@ember/runloop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TimerComponent extends Component {
  @tracked startTime;
  @tracked currentTime;

  @action
  didInsert() {
    if (isTesting) return;
    this.startTime = this.args.crawlingSession?.createdAt;
    this.currentTime = new Date();
    this.startTimer();
  }

  startTimer() {
    const _tick = () => {
      this.currentTime = new Date();

      later(() => {
        _tick();
      }, 1000);
    };

    _tick();
  }

  stopTimer() {
    this.startTime = null;
  }

  willDestroy() {
    super.willDestroy();
    this.stopTimer();
  }
}
