import Model, { attr } from '@ember-data/model';

export default class Invoice extends Model {
  @attr('number') amount;
  @attr('date') createdAt;
  @attr('string') currency;
  @attr('string') description;
  @attr('day-date') invoiceDate;
  @attr('number') invoiceNumber;
  @attr('string') invoiceType;
  @attr('string') url;
}
