import Model, { attr, hasMany } from '@ember-data/model';
import { task, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class UrlGroup extends Model {
  @attr('string') name;
  @attr('number') urlCount;
  @attr('number') dynamicViewCount;
  @attr('boolean') defaultGroup;

  @hasMany('url', { async: false }) urls;
  @hasMany('dynamic-view', { async: false }) dynamicViews;

  @service store;

  get itemCount() {
    return this.urlCount + this.dynamicViewCount;
  }

  get loaded() {
    return Boolean(this.load.lastSuccessful);
  }

  get loading() {
    return this.load.isRunning;
  }

  @task({ restartable: true })
  *load() {
    if (this.loaded) return;

    yield all([this.loadUrls.perform(), this.loadDynamicViews.perform()]);
  }

  @task({ restartable: true })
  *loadUrls() {
    yield this.store.query('url', { group_id: this.id });
  }

  @task({ restartable: true })
  *loadDynamicViews() {
    yield this.store.query('dynamic-view', {
      group_id: this.id,
      without_counts: true,
    });
  }
}
