const dimensionSources = {
  googleAnalytics: {
    name: 'Google Analytics',
    iconSrc: '/assets/icons/ga-icon.svg',
  },
  searchConsole: {
    name: 'Search Console',
    iconSrc: '/assets/icons/sc-icon.svg',
  },
};

export default dimensionSources;
