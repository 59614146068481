import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { isBefore, isAfter } from 'date-fns';
import { tracked } from '@glimmer/tracking';

export default class DashboardUrlSiteAuditController extends Controller {
  @service fetch;
  @service router;
  @tracked crawling_session_id = null;
  @tracked comparison_crawling_session_id = null;
  @tracked c = false;

  get url() {
    return this.model.url;
  }

  get crawlingSessions() {
    return this.model.crawlingSessions;
  }

  get crawlingSession() {
    return this.model.crawlingSession;
  }

  get comparisonCrawlingSession() {
    return this.model.comparisonCrawlingSession;
  }

  get comparisonEnabled() {
    return this.c;
  }

  get mostRecentCrawlingSession() {
    return this.crawlingSessions.firstObject;
  }

  get secondMostRecentCrawlingSession() {
    return (this.crawlingSessions ?? []).toArray()[1];
  }

  get olderCrawlingSessions() {
    return (this.crawlingSessions ?? []).filter((s) => {
      return isBefore(
        new Date(s.createdAt),
        new Date(this.crawlingSession.createdAt)
      );
    });
  }

  get availableCrawlingSessionsForCurrentSnapshot() {
    return this.comparisonEnabled
      ? this.newerCrawlingSessions
      : this.crawlingSessions;
  }

  get availableCrawlingSessionsForComparisonSnapshot() {
    return this.olderCrawlingSessions;
  }

  get newerCrawlingSessions() {
    return (this.crawlingSessions ?? []).filter((s) => {
      return isAfter(
        new Date(s.createdAt),
        new Date(this.comparisonCrawlingSession.createdAt)
      );
    });
  }

  @action
  setCrawlingSession(crawlingSession) {
    // This set will refresh the model
    this.crawling_session_id = crawlingSession.id;
  }

  @action
  setComparisonCrawlingSession(crawlingSession) {
    // This set will refresh the model
    this.comparison_crawling_session_id = crawlingSession.id;
  }
}
