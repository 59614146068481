import { pluralize } from 'ember-inflector';
import { ActiveModelSerializer } from 'active-model-adapter';

export default class ApplicationSerializer extends ActiveModelSerializer {
  isNewSerializerAPI = true;

  normalizeSingleResponse(store, primaryType, payload, recordId) {
    const { modelName } = primaryType;
    let newPayload = {};
    if (payload[modelName] && typeof payload[modelName] === 'object') {
      newPayload = payload;
    } else {
      newPayload[modelName] = payload;
    }
    return super.normalizeSingleResponse(
      store,
      primaryType,
      newPayload,
      recordId
    );
  }

  normalizeArrayResponse(store, primaryType, payload) {
    const pluralTypeKey = pluralize(primaryType.modelName);
    let newPayload = {};
    if (payload[pluralTypeKey] && typeof payload[pluralTypeKey] === 'object') {
      newPayload = payload;
    } else {
      newPayload[pluralTypeKey] = Array.isArray(payload) ? payload : [payload];
    }
    return super.normalizeArrayResponse(store, primaryType, newPayload);
  }
}
