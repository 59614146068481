import ApplicationSerializer from './application';
import { dasherize } from '@ember/string';
import { DEFAULT_GROUPING_TYPE } from '../constants/chart/grouping';
import { inject as service } from '@ember/service';

export default class GraphSerializer extends ApplicationSerializer {
  @service store;

  normalize(_modelClass, payload) {
    const normalized = super.normalize(...arguments);
    normalized.included = [];
    const { data: resource, included } = normalized;
    const seriesModel = this.store.modelFor('graph/series');

    normalizeResource(payload, resource, included, seriesModel);

    return normalized;
  }

  normalizeArrayResponse(store, _primaryModelClass, payload) {
    const normalized = super.normalizeArrayResponse(...arguments);
    const { data, included } = normalized;
    const seriesModel = store.modelFor('graph/series');

    data.forEach((resource, index) =>
      normalizeResource(payload[index], resource, included, seriesModel)
    );

    return normalized;
  }

  normalizeSingleResponse(store, _primaryModelClass, payload) {
    const normalized = super.normalizeSingleResponse(...arguments);
    const { data: resource, included } = normalized;
    const seriesModel = store.modelFor('graph/series');

    normalizeResource(payload, resource, included, seriesModel);

    return normalized;
  }

  serializeIntoHash(data, _typeClass, snapshot) {
    super.serializeIntoHash(...arguments);
    // Required as a base-level param for create action
    data.url_id = snapshot.belongsTo('url').id;
  }

  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);

    delete json.url_id;
    if (snapshot.belongsTo('url')) {
      json.search_keyword_url_id = snapshot.belongsTo('url').id;
    }
    delete json.grouping;

    const seriesModel = snapshot._store.modelFor('graph/series');
    const seriesAttrNames = Array.from(seriesModel.attributes.keys());

    json.graph_data = {
      grouping: snapshot.attr('grouping'),
      series: snapshot.hasMany('series').map((seriesSnapshot) => {
        const seriesData = {};
        seriesAttrNames.forEach((name) => {
          seriesData[name] = seriesSnapshot.attr(name);
        });
        seriesModel.relationshipNames.belongsTo
          .without('graph')
          .forEach((name) => {
            seriesData[`${name}Id`] = seriesSnapshot.belongsTo(name)?.id;
          });
        return seriesData;
      }),
    };

    return json;
  }
}

const normalizeResource = (payload, resource, included, seriesModel) => {
  const { id, attributes } = resource;
  const seriesAttrNames = Array.from(seriesModel.attributes.keys());

  attributes.grouping = payload.graph_data?.grouping ?? DEFAULT_GROUPING_TYPE;

  payload.graph_data?.series?.forEach((seriesData) => {
    const seriesAttrs = seriesAttributes(seriesData, seriesAttrNames);
    seriesAttrs.active ??= true;
    included.push({
      type: 'graph/series',
      id: `${id}-${uniqueSeriesId(seriesData, seriesModel)}-${
        seriesAttrs.name
      }`,
      attributes: seriesAttrs,
      relationships: {
        graph: { data: { type: 'graph', id } },
        ...seriesRelationships(seriesData, seriesModel),
      },
    });
  });
};

// Generate relationships from `graph_data` series array
const seriesRelationships = (seriesData, seriesModel) => {
  return seriesModel.relationshipNames.belongsTo.reduce(
    (relationships, relationshipName) => {
      const id = seriesData[`${relationshipName}Id`];
      if (id) {
        relationships[relationshipName] = {
          data: { type: dasherize(relationshipName), id },
        };
      }
      return relationships;
    },
    {}
  );
};

const seriesAttributes = (seriesData, seriesAttrNames) => {
  return seriesAttrNames.reduce((attrs, attrName) => {
    attrs[attrName] = seriesData[attrName];
    return attrs;
  }, {});
};

// Series models are generated from the `graph_data` object
// They are not stored with any id in the backend
const uniqueSeriesId = (seriesData, seriesModel) => {
  return seriesModel.relationshipNames.belongsTo
    .reduce((ids, relationshipName) => {
      const id = seriesData[`${relationshipName}Id`];
      if (id) {
        ids.push(`${idPrefix(relationshipName)}${id}`);
      }
      return ids;
    }, [])
    .join('-');
};

// Unique id prefix = first char of each word eg.
// 'dynamicView' => 'dv'
const idPrefix = (relationshipName) =>
  dasherize(relationshipName)
    .split('-')
    .map((s) => s.charAt(0))
    .join('');
