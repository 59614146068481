import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlSettingsConfigurationController extends Controller {
  @service router;
  @service session;

  @controller dashboard;

  get urlGroups() {
    return this.get('dashboard.urlGroups');
  }

  @action
  onUrlDeleted() {
    this.notifications.success('URL deleted.', {
      autoClear: true,
      clearDuration: 3500,
    });
    this.session.user.reload();
    this.router.transitionTo('dashboard.overview');
  }

  @action
  async onUrlUpdate(url, oldGroup) {
    url.loadGraphs.perform();
    url.urlGroup.reload();

    if (!oldGroup) return;

    oldGroup.reload();
    this.dashboard.deactivateUrlGroup(oldGroup);
    this.dashboard.activateUrlGroup(url.urlGroup, true);
  }
}
