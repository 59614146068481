import EmberRouter from '@embroider/router';
import { inject as service } from '@ember/service';
import config from './config/environment';
import { scheduleOnce } from '@ember/runloop';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @service metrics;

  init() {
    super.init(...arguments);
    this.on('routeDidChange', () => {
      scheduleOnce('afterRender', this, this.afterRender);
    });
  }

  afterRender() {
    const page = this.url;
    const title = this.currentRouteName || 'unknown';

    this.metrics.trackPage('GoogleAnalytics', { page, title });
    this.metrics.trackPage('Intercom', { page, title });
  }
}

Router.map(function () {
  this.route('start');
  this.route('onboarding');
  this.route('maintenance');
  this.route('plans', function () {
    this.route('cancel');
  });
  this.route('update-payment');
  this.route('plan', { path: 'plans/:plan_id' });
  this.route('plan-success', { path: 'plans/:plan_id/success' });
  this.route('login');
  this.route('signup');

  this.route('confirm-email');

  this.route('signup-form', { path: 'signup/:token' });
  this.route('redeem-invitation', { path: 'redeem-invitation' });

  this.route('password-reset');
  this.route('invoices');
  this.route('settings', function () {
    this.route('profile');
    this.route('subusers', { path: 'users' }, function () {
      this.route('subuser', { path: '/:subuser_id' });
      this.route('new');
    });
    this.route('api-access');
    this.route('billing');
    this.route('whitelabel');
    this.route('integrations');
    this.route('privacy');
    this.route('notes');
  });
  this.route('research');
  this.route('dashboard', { path: '/' }, function () {
    this.route('overview');
    this.route('url', { path: 'urls/:url_id' }, function () {
      this.route('dashboard');
      this.route('keyword', { path: 'keywords/:keyword_id' });
      this.route('keywords', function () {
        this.route('new');
        this.route('add', function () {
          this.route('manual');
          this.route('discover');
        });
      });
      this.route(
        'dynamic-view',
        { path: 'views/:dynamic_view_id' },
        function () {
          this.route('settings');
        }
      );
      this.route('dynamic-views', { path: 'views/' }, function () {
        this.route('new');
      });
      this.route('settings', function () {
        this.route('configuration', function () {
          this.route('delete');
        });
        this.route('competitors');
        this.route('site-audit');
        this.route('backlinks');
      });
      this.route('report', { path: 'reports/:report_id' });
      this.route('reports', function () {
        this.route('new');
      });
      this.route('notes');

      this.route('chart.new', { path: 'graph/new' });
      this.route('chart', { path: 'graph/:graph_id' }, function () {
        this.route('settings');
      });

      this.route('backlink-view', { path: 'backlink-views/:backlink_view_id' });
      this.route('backlink-view-settings', {
        path: 'backlink-views/:backlink_view_id/settings',
      });
      this.route('backlink-view-new', { path: 'backlink-views/new' });
      this.route('backlinks', function () {
        this.route('enable');
        this.route('new');
      });
      this.route('site-audit-disabled');
      this.route('site-audit', function () {
        this.route('all', { path: '/' }, function () {
          this.route('checks', function () {
            this.route('show', { path: '/:audit_check_id' });
          });
        });
        this.route('view', { path: 'views/:view_id' }, function () {
          this.route('show', { path: '/' });
          this.route('edit');
          this.route('checks', function () {
            this.route('show', { path: '/:audit_check_id' });
          });
          this.route('settings');
        });
        this.route('views', function () {
          this.route('new');
        });
      });
    });
    this.route('dynamic-view', { path: 'views/:dynamic_view_id' }, function () {
      this.route('keywords');
      this.route('settings');
      this.route('report', { path: 'reports/:report_id' });
      this.route('reports', function () {
        this.route('new');
      });
    });
    this.route('dynamic-views', { path: 'views/' }, function () {
      this.route('new');
    });
    this.route('reports', function () {
      this.route('new');
    });
  });
  this.route('confirm-account');
  this.route('error');
  this.route('changelog');
  this.route('notifications');

  // KEEP THIS AT THE END !!!
  this.route('404', { path: '/*path' });
});
