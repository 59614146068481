import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NwSelectorsLocationSearchComponent extends Component {
  @service fetchPreciseLocation;
  @tracked searchLocations = [];
  @tracked placeholderText = '';
  @tracked location = null;

  initialGoogleGl = null;

  @action
  onGoogleGlChange() {
    if (!this.args.googleGl) {
      return;
    }

    if (!this.initialGoogleGl) {
      this.initialGoogleGl = this.args.googleGl;
      return;
    }

    if (this.initialGoogleGl !== this.args.googleGl) {
      this.initialGoogleGl = this.args.googleGl;
      this.refreshOptionsTask.perform();
    }
  }

  @task({ restartable: true })
  *refreshOptionsTask(search) {
    if (!search) {
      this.placeholderText = 'Enter a location';
      this.searchLocations = [];
      return;
    }

    yield timeout(250);

    const data = {
      gl: this.args.googleGl?.toLowerCase(),
      search,
    };
    try {
      const locations = yield this.fetchPreciseLocation.fetchLocation(data);

      if (locations.length === 0) {
        this.placeholderText = 'No results.';
      }

      this.searchLocations = locations;
    } catch (e) {
      this.placeholderText = 'Error fetching results';
    }
  }
}
