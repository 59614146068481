import Component from '@glimmer/component';
import { action } from '@ember/object';
import { all, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { UrlDimensions } from 'nightwatch-web/utils/chart/dimensions/url-dimensions';
import { CompetitorDimensions } from 'nightwatch-web/utils/chart/dimensions/competitor-dimensions';
import { KeywordDimensions } from 'nightwatch-web/utils/chart/dimensions/keyword-dimensions';
import { DynamicViewDimensions } from 'nightwatch-web/utils/chart/dimensions/dynamic-view-dimensions';
import { BacklinkViewDimensions } from 'nightwatch-web/utils/chart/dimensions/backlink-view-dimensions';
import { formatISO, min } from 'date-fns';
import { resetColors } from 'nightwatch-web/utils/chart/colors';
import scales, { displayScales } from 'nightwatch-web/utils/chart/scales';
import { GroupDimensions } from 'nightwatch-web/utils/chart/dimensions/group-dimensions';
import { GlobalViewDimensions } from 'nightwatch-web/utils/chart/dimensions/global-view-dimensions';
import DataRequester from 'nightwatch-web/utils/chart/data-requester';
import { groupBy } from 'lodash';
import { assert } from '@ember/debug';
import { relativeDateRanges } from 'nightwatch-web/constants/date-ranges';
import { DEFAULT_DATE_RANGE_TYPE } from 'nightwatch-web/models/graph';
import noteSeries from 'nightwatch-web/utils/chart/note-series';
import { DEFAULT_GROUPING_TYPE } from '../../constants/chart/grouping';
import { groupData, groupMethods } from '../../utils/chart/grouping';
import fillGaps from '../../utils/chart/fill-gaps';
import clampData from '../../utils/chart/clamp-data';

/**
  @argument graph {Graph}

  @argument url {Url} - relationship from user created graph or pinnedItem
  @argument urlGroup {UrlGroup} - relationship from pinnedItem
  @argument dynamicView {DynamicView} - relationship from pinnedItem, note it will be a *global* dynamic view

  @argument dimensionLimit {Number} - maximum number of dimensions allowed
  @argument onGraphUpdate {Function} - called whenever the graph is mutated

  @argument dateFrom {Date}
  @argument dateTo {Date}
 */
export default class ChartProviderComponent extends Component {
  @service session;
  @service fetch;
  @service store;
  @service notifications;

  @tracked dimensionGroups = {};

  seriesRequester = new DataRequester(getOwner(this), '/series');
  trafficRequester = new DataRequester(getOwner(this), '/traffic_stats');

  get dateFrom() {
    const { dateFrom, graph } = this.args;
    return dateFrom ?? graph.dateFrom ?? this.graphDateRange.start;
  }

  get dateTo() {
    const { dateTo, graph } = this.args;
    return dateTo ?? graph.dateTo ?? this.graphDateRange.end;
  }

  get graphDateRange() {
    const { graph } = this.args;
    return (
      relativeDateRanges().findBy('type', graph.relativeDateRange) ??
      relativeDateRanges().findBy('type', DEFAULT_DATE_RANGE_TYPE)
    );
  }

  get dateRangeType() {
    return this.args.graph?.relativeDateRange;
  }

  get graphLineWidth() {
    return this.selectedDimensions.length > 1 ? 0.85 : 1.5;
  }

  @action
  initialize() {
    const { graph } = this.args;
    if (!graph) return;

    this.seriesRequester.reset();
    this.trafficRequester.reset();

    resetColors();

    this.createDimensions();

    this.initialFetch.perform();
  }

  createDimensions() {
    const { graph, url, urlGroup, dynamicView, onKeywordSeriesLoaded } =
      this.args;

    let groupProps = {
      provider: this,
      graph,
      owner: getOwner(this),
      onGraphUpdate: this.args.onGraphUpdate,
      canAddDimension: this.canAddDimension,
      onSeriesAdded: this.fetchData.perform,
    };

    if (url) {
      url.loadNotes.perform();

      groupProps = { ...groupProps, url };
      this.dimensionGroups = {
        url: new UrlDimensions({ ...groupProps, active: true }),
        competitor: new CompetitorDimensions(groupProps),
        keyword: new KeywordDimensions({
          ...groupProps,
          searchEnabled: true,
          onSeriesLoaded: onKeywordSeriesLoaded,
        }),
        dynamicView: new DynamicViewDimensions(groupProps),
      };
      if (
        this.session.user.backlinksEnabled &&
        this.session.user.backlinksVisible &&
        url.backlinksEnabled &&
        !url.isYoutube
      ) {
        // this.dimensionGroups.backlinkView = new BacklinkViewDimensions(
        //   groupProps
        // );
      }
    } else if (dynamicView) {
      this.dimensionGroups = {
        dynamicView: new GlobalViewDimensions({
          ...groupProps,
          dynamicView,
          active: true,
        }),
      };
    } else if (urlGroup) {
      this.dimensionGroups = {
        urlGroup: new GroupDimensions({
          ...groupProps,
          urlGroup,
          active: true,
        }),
      };
    }
  }

  @task({ restartable: true })
  *initialFetch() {
    // Detect the selected dimensions and group them
    const groupedSeries = groupBy(
      this.args.graph.series.toArray(),
      'dimensionGroupName'
    );

    // Run partial load for all selected dimensions
    const tasks = Object.entries(groupedSeries).map(([name, seriesModels]) => {
      assert(`dimensionGroupName not present`, name);
      return this.dimensionGroups?.[name]?.partialLoadTask?.perform(
        seriesModels
      );
    });

    yield all(tasks);
    yield this.fetchData.perform();
  }

  get interval() {
    return {
      start: this.dateFrom,
      end: this.dateTo,
    };
  }

  get allDimensions() {
    return Object.values(this.dimensionGroups).flatMap((dg) => dg.dimensions);
  }

  get selectedDimensions() {
    return this.allDimensions.filterBy('selected');
  }

  get activeDimensions() {
    return this.selectedDimensions.filterBy('active', true);
  }

  get displayedScales() {
    return this.seriesData
      .mapBy('scaleId')
      .uniq()
      .filter((scaleId) => displayScales.includes(scaleId))
      .map((scaleId) => scales[scaleId]);
  }

  get grouping() {
    return this.args.graph?.grouping ?? DEFAULT_GROUPING_TYPE;
  }

  set grouping(value) {
    this.args.graph.grouping = value;
  }

  get isLoading() {
    return this.initialFetch.isRunning || this.fetchData.isRunning;
  }

  get isLoaded() {
    return Boolean(this.fetchData.lastSuccessful);
  }

  get seriesData() {
    if (
      !this.seriesRequester ||
      (!this.seriesRequester.data.length &&
        Array.isArray(this.trafficRequester.data))
    )
      return [];

    const data = this.activeDimensions.flatMap((d) => d.seriesData);

    // Only show nates when data is grouped by day
    if (this.grouping === DEFAULT_GROUPING_TYPE && this.noteSeries)
      data.unshift(this.noteSeries);

    if (this.args.grouping) {
      return data.map((d) => {
        const seriesData = d.data.map((sd) => [sd.x, sd.y]);

        d.data = groupData(
          fillGaps(clampData(seriesData, this.interval)),
          this.args.grouping,
          groupMethods.average
        ).map(([date, value]) => ({ x: date, y: value }));

        return d;
      });
    }

    return data;
  }

  get notes() {
    const globalNotes = this.store
      .peekAll('note')
      .rejectBy('isNew')
      .filterBy('isGlobal')
      .toArray();
    const urlNotes = this.args.url?.notes.toArray() ?? [];
    return [...globalNotes, ...urlNotes];
  }

  get noteSeries() {
    return noteSeries(this.notes, this.interval);
  }

  seriesRequestIds(relationship) {
    return this.selectedDimensions
      .filterBy('endpoint', 'series')
      .map((dimension) =>
        dimension.requestWith.includes(relationship)
          ? dimension[relationship]?.id
          : null
      )
      .compact()
      .uniq()
      .sort();
  }

  get seriesParams() {
    const { dateFrom, dateTo } = this;

    const params = {
      dateFrom: formatISO(dateFrom, { representation: 'date' }),
      dateTo: formatISO(dateTo, { representation: 'date' }),
      urlIds: this.seriesRequestIds('url'),
      competitorIds: this.seriesRequestIds('competitor'),
      keywordIds: this.seriesRequestIds('keyword'),
      dynamicViewIds: this.seriesRequestIds('dynamicView'),
      backlinkViewIds: this.seriesRequestIds('backlinkView'),
      urlGroupIds: this.seriesRequestIds('urlGroup'),
      withCompetitors: false, // Not completely sure this is needed
    };

    if (params.backlinkViewIds.includes('-1')) {
      // Required when loading "All Backlinks" - used this to determine which URL to load data for
      params.urlIdForAllBacklinks = this.args.url?.id;
    }

    return params;
  }

  get trafficParams() {
    const { url } = this.args;
    const selectedTrafficDimensions = this.selectedDimensions.any(
      (d) => d.endpoint === 'trafficStats'
    );
    if (!url || !selectedTrafficDimensions) return null;

    const { dateFrom, dateTo } = this;

    return {
      dateFrom: formatISO(dateFrom, { representation: 'date' }),
      dateTo: formatISO(dateTo, { representation: 'date' }),
      urlId: url.id,
    };
  }

  @task({ restartable: true })
  *fetchData() {
    yield all([
      this.seriesRequester.fetchData.perform(this.seriesParams),
      this.trafficRequester.fetchData.perform(this.trafficParams),
    ]);
  }

  @action
  canAddDimension() {
    const { dimensionLimit } = this.args;

    if (dimensionLimit && this.selectedDimensions.length >= dimensionLimit) {
      this.notifications.remove(this.dimensionLimitNotification);
      this.dimensionLimitNotification = this.notifications.error(
        `You can select up to ${dimensionLimit} dimensions.`,
        { autoClear: true }
      );
      return false;
    }

    return true;
  }

  @action
  dateRangeUpdated(_element, [start, end]) {
    this.setDateRange({ start, end });
  }

  @action
  setDateRange({ type, start, end }) {
    const { graph } = this.args;

    graph.relativeDateRange = type;
    graph.dateFrom = start;
    graph.dateTo = end;

    this.fetchData.perform();
  }

  // Used to determine whether to truncate the chart to begin only from the
  // first available data point.
  get firstDataPointDate() {
    if (!this.seriesData) return false;
    const firstAvailableDataDates = [];
    const validDataPointDatesAtIndexZero = [];
    const rawSeriesData = this.seriesData.map((series) => {
      return series.type !== 'nwNote' ? series.data : null; // exclude note series.
    });
    // Find whether first item in array contains a non-null y value
    rawSeriesData.forEach((dataPoint) => {
      if (dataPoint?.[0] && dataPoint?.[0]?.y)
        validDataPointDatesAtIndexZero.push(dataPoint?.[0]?.x);
    });
    // Returning false because the first date from the specified date range contains a
    // non-null value, implying that there is data from before the specified date range.
    if (validDataPointDatesAtIndexZero.length > 0) {
      return false;
    }
    // find the earliest date that contains a truthy y value,
    // forwards and backwards in the array.
    rawSeriesData.reduce((acc, seriesData) => {
      const earliest = seriesData?.find((dataPoint) => dataPoint.y);
      return earliest ? firstAvailableDataDates.push(earliest) : acc;
    }, null);

    return min(
      firstAvailableDataDates.uniq().map((dataPoint) => new Date(dataPoint?.x))
    );
  }
}
