import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditRoute extends Route {
  @service router;
  @service fetch;
  @service session;

  queryParams = {
    crawling_session_id: {
      refreshModel: true,
    },
    comparison_crawling_session_id: {
      refreshModel: true,
    },
  };

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    if (this.session.user.siteAuditEnabled) return;

    this.router.transitionTo('dashboard.url.site-audit-disabled');
  }

  model() {
    const url = this.modelFor('dashboard.url');
    return {
      url,
    };
  }
}
