import { helper } from '@ember/component/helper';
import { isSameDay } from 'date-fns';
import { relativeDate } from 'nightwatch-web/helpers/relative-date';

// If date is today, return "today", otherwise use relativeDate
export function relativeDateToday([date]) {
  return isSameDay(new Date(), date) ? 'today' : relativeDate([date]);
}

export default helper(relativeDateToday);
