import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsRoute extends Route {
  @service router;
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication();
    if (transition.targetName === 'settings.index') {
      this.router.transitionTo('settings.profile');
    }
  }

  @action
  willTransition() {
    this.session.user?.rollbackAttributes();
  }
}
