import ApplicationSerializer from 'nightwatch-web/serializers/application';

export default class SiteAuditAuditCheckSerializer extends ApplicationSerializer {
  serializeIntoHash(data, type, record, options) {
    super.serializeIntoHash(data, type, record, options);
    data['audit_check'] = data['site_audit/audit_check'];
    data['audit_check'].search_keyword_url_id = data['audit_check'].url_id;
    delete data['site_audit/audit_check'];
  }
}
