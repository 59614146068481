import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const usagePercent = (used, limit) => Math.min((used * 100) / limit, 100);

export default class AccountUsageComponent extends Component {
  @service session;

  get user() {
    return this.session.user;
  }

  get urlUsagePercent() {
    return usagePercent(this.user?.urlsUsed, this.user?.url_limit);
  }

  get keywordUsagePercent() {
    return usagePercent(this.user?.keywordsUsed, this.user?.keyword_limit);
  }

  get backlinksUsagePercent() {
    return usagePercent(this.user?.backlinksCount, this.user?.backlinksLimit);
  }
}
