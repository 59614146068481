import Component from '@glimmer/component';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import {
  NwBarController,
  NwLineController,
  NwNoteController,
  sortTooltipItems,
  tooltipLabel,
} from '../../utils/chart/nw-chart-controllers';
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import scales, { displayScales } from '../../utils/chart/scales';
import positionHiderPlugin, {
  loneNotInResultsPoint,
  notInResults,
  notInResultsSegment,
} from '../../utils/chart/position-hider-plugin';
import {
  NOT_IN_RESULTS_COLOR,
  TRANSPARENT_COLOR,
} from '../../utils/chart/colors';
import { format } from 'date-fns';

Chart.register(
  BarElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  NwLineController,
  NwBarController,
  NwNoteController
);

/**
 @class NwTimeSeriesChartComponent

 Renders series data over time

 @argument series {Array[Object]} Array of series data objects to graph
 @argument label {String} Y axis title. Takes precedence over scale name
 @argument isLoading {Boolean} Whether the series data is loading
 @argument statsLoaded {Boolean} Whether the series data is loaded
 @argument grouping {String} Time range to group data by - day, week or month
 @argument hideXAxis {Boolean}
 @argument hidePoints {Boolean}
 @argument borderWidth {Number}
 @argument minMaxYTicks {Boolean}
 @argument hideYAxisTitles {Boolean}
 @argument noReverse {Boolean} Don't reverse the scales
 @argument onKeywordPositionPointClick {Function} Used to open SERP preview
 **/

export default class NwTimeSeriesChartComponent extends Component {
  maxXTicks = 13;
  showPointsUntilDataExceeds = 91;
  pointRadius = 1;
  pointHoverRadius = 4;
  lineWidth = 2;

  get noKeywords() {
    return this.args.url && !this.args.url?.keyword_count;
  }

  get datasets() {
    const series = this.args.isChartsPage
      ? this.removeDataBeforeEarliestDatapoint(
          this.args.series,
          this.args.firstDataPointDate
        )
      : this.args.series;

    return series?.reduce(
      (
        datasets,
        {
          label,
          type,
          data,
          scaleId,
          backgroundColor,
          borderColor,
          pointStyle,
          previewKeyword,
        }
      ) => {
        const borderWidth = this.args.lineWidth ?? this.lineWidth;
        const pointRadius = this.datasetPointRadius(type, data);
        const dataset = {
          label,
          type,
          data,
          yAxisID: scaleId,
          backgroundColor,
          borderColor,
          borderWidth,
          pointRadius,
          hitRadius: previewKeyword ? 5 : 1, // Larget hit detection for points with a SERP preview
          pointStyle,
          previewKeyword,
          barThickness: 'flex',
          fill: type === 'nwBar',
          order: type === 'nwBar' ? 1 : 0, // Draw bar datasets underneath others
        };

        if (scaleId === 'nwStackedValue') {
          dataset.borderWidth = 0;
        }

        if (scaleId === 'nwPosition') {
          dataset.borderColor = notInResults(TRANSPARENT_COLOR, borderColor);
          // Draws segment lines along bottom of chart whenever a
          // position is NOT_IN_RESULTS for 2 or more points
          dataset.segment = {
            borderWidth: notInResultsSegment(10, borderWidth),
            borderColor: notInResultsSegment(NOT_IN_RESULTS_COLOR, borderColor),
          };
          // Lone NOT_IN_RESULTS points are styled to match the
          // segment lines since no segment is rendered for a lone point
          dataset.pointBackgroundColor = notInResults(
            NOT_IN_RESULTS_COLOR,
            backgroundColor
          );
          dataset.pointStyle = notInResults('rect', pointStyle);
          dataset.pointRadius = notInResults(
            loneNotInResultsPoint(8, 0),
            pointRadius
          );
          dataset.pointHoverRadius = notInResults(
            loneNotInResultsPoint(8, 0),
            this.pointHoverRadius
          );
        }

        datasets.push(dataset);
        return datasets;
      },
      []
    );
  }

  get yScales() {
    return this.args.series
      .mapBy('scaleId')
      .uniq()
      .reduce((yScales, scaleId) => {
        const scale = scales[scaleId];

        // Filter out non-visible scales so that position can be determined
        const visibleScales = Object.keys(yScales).filter((scaleId) =>
          displayScales.includes(scaleId)
        );

        yScales[scaleId] = {
          ...scale,
          position: visibleScales.length === 0 ? 'left' : 'right',
          title: {
            ...scale.title,
            text: this.args.label ?? scale.title.text,
          },
        };

        if (this.args.hideYAxisTitles) {
          yScales[scaleId].title.display = false;
        }

        if (this.args.minMaxYTicks) {
          yScales[scaleId].ticks = {
            precision: 2,
            callback: (value, index, ticks) => {
              const tickValues = ticks.map((tick) => tick.value);
              if (
                value === Math.max(...tickValues) ||
                value === Math.min(...tickValues)
              ) {
                if (value >= 1000 && value < 1000000) {
                  return (
                    (value / 1000).toFixed(value % 1000 !== 0 ? 1 : 0) + 'k'
                  );
                } else if (value >= 1000000) {
                  return (
                    (value / 1000000).toFixed(value % 1000000 !== 0 ? 2 : 1) +
                    'M'
                  );
                }
                return value;
              }
              return null; // This hides all other tick labels except for min and max
            },
          };
        }

        if (this.args.noReverse) {
          yScales[scaleId].reverse = false;
        }

        return yScales;
      }, {});
  }

  get scales() {
    return {
      x: {
        display: !this.args.hideXAxis,
        type: 'time',
        stacked: true,
        time: {
          unit: this.args.grouping,
          tooltipFormat: 'EEEE, LLL d, yyyy',
        },
        ticks: {
          source: 'data',
          maxRotation: 0,
          autoSkip: false,
          callback: (tick, index, { length: ticksLength }) => {
            const formatted = format(tick, 'MMM d');
            if (ticksLength <= this.maxXTicks) return formatted;

            const interval = Math.ceil(ticksLength / this.maxXTicks) ?? 0;
            return index % interval === 0 ? formatted : '';
          },
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: false,
      },
      ...this.yScales,
    };
  }

  get chartOptions() {
    return {
      data: {
        datasets: this.datasets,
      },
      plugins: [positionHiderPlugin],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.45,
        animation: {
          duration: 150,
        },
        scales: this.scales,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        // Sets cursor to pointer when "interactable" points are hovered
        onHover: (event, _points, chart) => {
          const { point, dataset } = this.unpackEvent(event, chart);
          if (
            point &&
            dataset.previewKeyword &&
            this.args.onKeywordPositionPointClick
          ) {
            event.native.target.style.cursor = 'pointer';
            return;
          }

          event.native.target.style.cursor = 'default';
        },
        // Calls onKeywordPositionPointClick when keyword position points are clicked to open SERP preview
        onClick: (event, _points, chart) => {
          const { point, dataset } = this.unpackEvent(event, chart);
          // Return early unless dataset has previewKeyword defined
          if (!dataset?.previewKeyword) return;

          const previewDate = point.element.$context.raw?.x;
          this.args.onKeywordPositionPointClick?.(
            dataset.previewKeyword,
            previewDate
          );
        },
        plugins: {
          tooltip: {
            usePointStyle: true,
            displayColors: Boolean(this.datasets?.[0]?.backgroundColor),
            itemSort: sortTooltipItems,
            callbacks: {
              label: tooltipLabel,
            },
          },
        },
      },
    };
  }

  get isTallChart() {
    return this.args.series?.length >= 20;
  }

  removeDataBeforeEarliestDatapoint(seriesData, firstDataPointDate) {
    if (
      !firstDataPointDate ||
      !(
        firstDataPointDate instanceof Date &&
        isFinite(firstDataPointDate.getTime())
      )
    ) {
      return seriesData;
    }

    const minDate = new Date(firstDataPointDate);

    // Remove all data points before the earliest datapoint's date
    return seriesData.map((series) => {
      const filteredData = series.data.filter((dataPoint) => {
        // dataPoint: { x: Date, y: Number }
        return new Date(dataPoint?.x) >= minDate;
      });
      return { ...series, data: filteredData };
    });
  }

  unpackEvent(event, chart) {
    // Get clicked/hovered on point nearest to mouse cursor
    const point = chart.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true }, // Only matches if point intersects the mouse position
      true
    )?.firstObject;
    const dataset = chart.data.datasets[point?.datasetIndex];
    return { point, dataset };
  }

  datasetPointRadius(type, data) {
    if (type === 'nwNote') return 6;
    if (this.args.hidePoints) return 0;

    return data.length < this.showPointsUntilDataExceeds ? this.pointRadius : 0;
  }
}
