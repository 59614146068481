import { parseISO, add, differenceInDays } from 'date-fns';
import format from 'date-fns/format';

const MAX_DAYS_TO_COPY = 3;

const fillGaps = (data, options = { skipValue: false, fillValue: null }) => {
  const fillPoints = [];

  const getNextDate = (index) => {
    const dateStr = data[index + 1]?.[0];
    return dateStr ? parseISO(dateStr) : null;
  };

  data.forEach(([dateStr, value], index) => {
    const nextDate = getNextDate(index);
    // Do nothing when last value is reached
    if (!nextDate) return;

    const date = parseISO(dateStr);

    // Find how many days we need to fill
    let daysToFill = differenceInDays(nextDate, date) - 1; // 1 = expected days between points
    if (daysToFill <= 0) return;

    // Determine whether to copy the previous value or fill
    const fillWith = options.skipValue
      ? null
      : daysToFill <= MAX_DAYS_TO_COPY
      ? value
      : options.fillValue;

    // Create a point for each missing day
    const points = [];
    while (daysToFill > 0) {
      const pointDate = add(date, { days: daysToFill });
      // Using `unshift` because we're working backwards
      points.unshift([format(pointDate, 'yyyy-MM-dd'), fillWith]);
      daysToFill--;
    }

    // Store our fillPoints with fillAfterDateStr as a location reference
    // Use this later to avoid mutating data while we're iterating
    fillPoints.push({ points, fillAfterDateStr: dateStr });
  });

  // Analysed all data – now we can begin mutating
  fillPoints.forEach(({ fillAfterDateStr, points }) => {
    // Find the index where we should fill by looking up fillAfterDateStr
    const fillAfterIndex = data.findIndex(
      ([dateStr]) => dateStr === fillAfterDateStr
    );
    if (fillAfterIndex === -1) return;

    // Finally, insert our fillPoints *after* this index
    data.splice(fillAfterIndex + 1, 0, ...points);
  });

  return data;
};

export default fillGaps;
