import AddonFetchService from 'ember-ajax-fetch/services/fetch';
import { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';

export default class FetchService extends AddonFetchService {
  @service session;

  host = ENV.serverURL;
  namespace = ENV.apiNamespace;
  contentType = 'application/json; charset=utf-8';

  options(url, options) {
    return this.session.token
      ? super.options(url, {
          ...options,
          data: { ...options.data, access_token: this.session.token },
        })
      : super.options(url, options);
  }
}
