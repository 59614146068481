import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSettingsCompetitorsRoute extends Route {
  @service store;
  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    return hash({
      url: this.modelFor('dashboard.url'),
      competitors: this.store.query('competitor', {
        url_id: this.modelFor('dashboard.url').id,
      }),
    });
  }

  deactivate() {
    // Remove unsaved Competitors when leaving the route
    if (this.store.isDestroying || this.store.isDestroyed) return;
    this.store
      .peekAll('competitor')
      .filterBy('isNew')
      .filter((competitor) => !competitor.get('isSaving'))
      .forEach((competitor) => competitor.deleteRecord());
  }
}
