import ApplicationAdapter from './application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class PinnedItemAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    if (Array.isArray(payload)) {
      payload = payload.map((pinnedItem) => ({
        ...pinnedItem,
        url_id: pinnedItem.search_keyword_url_id,
        group_id: pinnedItem.url_group_id,
      }));
    } else if (isObjectType(payload)) {
      payload = {
        ...payload,
        url_id: payload.search_keyword_url_id,
        group_id: payload.url_group_id,
      };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
