import ApplicationAdapter from './application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class KeywordSuggestionAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    const meta = {
      total: headers['X-Total-Count'] || headers['x-total-count'],
      pages: headers['X-Page-Count'] || headers['x-page-count'],
    };
    if (Array.isArray(payload)) {
      payload = { 'keyword-suggestions': payload, meta };
    } else if (isObjectType(payload)) {
      payload = { 'keyword-suggestion': payload, meta };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
