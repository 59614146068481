import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { THEMES } from '../services/theme';
import posthog from 'posthog-js';
import { tracked } from '@glimmer/tracking';

export default class SignupRoute extends Route {
  @service session;
  @service metrics;
  @service phoneInput;
  @service router;
  @service store;
  @service theme;
  @tracked referrerSubpage;

  beforeModel(transition) {
    this.session.prohibitAuthentication();
    this.theme.current = THEMES.BRIGHT;
    // Capture the `ref` param
    const queryParams = transition.to.queryParams;
    this.referrerSubpage = queryParams.ref;
  }

  @action
  didTransition() {
    // Analytics
    this.metrics.trackEvent({ event: 'Signup Form' });

    if (this.referrerSubpage) {
      posthog.capture('signup_visit', {
        subpage: this.referrerSubpage,
        referrer: document.referrer,
      });
    }
    return true;
  }
}
