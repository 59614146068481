import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class FetchPreciseLocationService extends Service {
  @service fetch;

  async fetchLocation({ gl, search }) {
    const data = {
      gl: gl?.toLowerCase(),
      search,
    };

    const locations = await this.fetch.request('/search_locations', { data });
    locations.forEach(
      (location) =>
        (location.description = location.description
          .split(',')
          .map((part) => part.trim())
          .join(', '))
    );

    return locations;
  }
}
