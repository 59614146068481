import Modifier from 'ember-modifier';
import { keyResponder, onKey } from 'ember-keyboard';
import { registerDestructor } from '@ember/destroyable';

/**
 @class TagInteractionModifier

 Handles interactive tagging on an <input> element
 * Calls onSubmit when "Enter" is pressed
 * Blurring when "Escape" is pressed
 * When blurring (by "Escape" or regular focus-loss) calls onBlur
 */
@keyResponder
export default class TagInteractionModifier extends Modifier {
  element = null;
  onSubmit = null;
  onBlur = null;

  constructor(element, args) {
    super(element, args);
    registerDestructor(this, () => this.removeEventListener());
  }

  modify(element, [onSubmit, onBlur]) {
    this.element = element;
    this.onSubmit = onSubmit;
    this.onBlur = onBlur;

    this.removeEventListener();
    this.addEventListener();
  }

  @onKey('Enter')
  submit(event) {
    if (event.srcElement !== this.element) return;
    if (!this.element.value || !this.element.value.trim()) return;

    this.onSubmit?.();
    this.element.value = null;
  }

  @onKey('Escape')
  blur(event) {
    if (event.srcElement !== this.element) return;

    this.element.blur();
  }

  addEventListener() {
    this.element.addEventListener('blur', () => this.onBlur?.());
  }

  removeEventListener() {
    this.element.removeEventListener('blur', () => this.onBlur?.());
  }
}
