import Component from '@glimmer/component';
import { action } from '@ember/object';
import { WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';

export default class RecurringSiteAuditSwitcherComponent extends Component {
  availableIntervals = Object.freeze([WEEKLY, MONTHLY]);

  @action
  setSiteAuditInterval(interval) {
    this.args.url.siteAuditInterval = interval;
  }

  @action
  toggleRecurring() {
    if (!this.args.url.siteAuditInterval) {
      this.args.url.siteAuditInterval = MONTHLY;
    } else {
      this.args.url.siteAuditInterval = null;
    }
  }
}
