import Modifier from 'ember-modifier';
import { svg } from 'nightwatch-web/utils/svg-ui-elements';
import { task, rawTimeout } from 'ember-concurrency';
import { format, sub, parse } from 'date-fns';
import shiftToUTC from 'nightwatch-web/utils/shift-to-utc';
import { registerDestructor } from '@ember/destroyable';

/*
 * Global tooltips;
 * - instantiate it on a variable: const tooltip = Tooltip()
 * - show: tooltip.show({ top offset, left offset, html content })
 * - hide: tooltip.hide()
 * - destroy when no longer needed: tooltip.destroy()
 */
const Tooltip = () => {
  let instance = null;

  function show({ x, y, content }) {
    if (!instance) init();
    instance.innerHTML = content;
    instance.setAttribute('style', `top:${y}px;left:${x}px;display:block`);
  }

  function hide() {
    if (!instance) return;
    instance.setAttribute('style', 'display:none');
    instance.innerHTML = '';
  }

  function init() {
    instance = document.createElement('div');
    instance.className = 'nw-tooltip';
    hide();
    document.body.appendChild(instance);
  }

  function destroy() {
    if (!instance) return;
    instance.remove();
    instance = null;
    return null;
  }

  return { show, hide, destroy };
};

/*
 * Construct an SVG tree of anchor points to append to the main graph.
 * These will create event targets (circles) over the points in the graphs
 * which are just line end markers (non targetable decorations)
 */
const evolutionGraphTooltipAnchors = (points = []) =>
  svg(
    'g',
    {},
    ...points.map((p) =>
      svg('circle', {
        class: 'nw-evolution__tip-anchor js--evolution-tip',
        cx: p?.x,
        cy: p?.y,
        r: 4,
        'data-tooltip': [p.value, p.date, p.growth].join(':'),
      })
    )
  );

/**
 * Extend evolution graphs with anchors for showing tooltips
 * @param  {[Node]} node Minigraph SVG element
 */
export const evolutionGraphTooltips = (node) => {
  // Exit if already initialized
  if (!node || node.dataset.tooltips) return;
  // Mark as initialized
  node.dataset.tooltips = true;
  // Get series data from graph element
  const points = JSON.parse(node.dataset.points);
  // Exit if no points
  if (!points.length) return;
  // Create a date for the last point in series
  const seriesLastDate = shiftToUTC(
    parse(node.dataset.seriesLastDate, 'T', new Date())
  );
  // Add dates to points from last to first
  let pointDate;
  const pointsWithDate = points
    .reverse()
    .map((point) => {
      pointDate = pointDate ? sub(pointDate, { days: 1 }) : seriesLastDate;
      return {
        ...point,
        date: format(pointDate, 'EEEE, MMM dd, yyyy'),
      };
    })
    .reverse();
  // Append tooltip anchors to graph
  node.appendChild(evolutionGraphTooltipAnchors(pointsWithDate));
};

// Create content for evolutionGraph tooltips
const makeTooltipContent = (rank, date, growth) =>
  `<small>${date}</small><br>Position: <b class="nw-evolution__tip--${growth}">${rank}</b>`;

// Get data from tooltip anchor element for content and position
const getTooltipData = (anchorElement) => {
  const [rank, date, growth] = anchorElement.dataset.tooltip.split(':');
  // Position in viewport
  const { left, top, width } = anchorElement.getBoundingClientRect();
  return {
    x: Math.round(left + width / 2),
    y: Math.round(top) + window.scrollY - 10, // -10 to move it up slightly
    content: makeTooltipContent(rank < 0 ? 'no rank' : rank, date, growth),
  };
};

export default class EvolutionTooltips extends Modifier {
  tooltip = null;
  timeout = 0;
  element = null;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, this.cleanup);
  }

  modify(element) {
    this.element = element;
    if (this.tooltip) return;

    this.tooltip = Tooltip();

    // Handle moving mouse over evolutionGraphs
    this.element.addEventListener(
      'mouseover',
      (e) => this.handleTip.perform(e),
      false
    );
    // Handle mouse leaving the component with evolutionGraphs
    this.element.addEventListener('mouseleave', this.tooltip.hide, false);
  }

  cleanup() {
    if (!this?.tooltip) return;

    this.element?.removeEventListener('mouseleave', this.tooltip.hide, false);
    this.element?.removeEventListener(
      'mouseover',
      (e) => this.handleTip.perform(e),
      false
    );
    this.tooltip?.destroy();
  }

  /*
   * Check every 200ms if mouse is over a evolutionGraph or its tooltip anchor
   * - if over evolutionGraph extend the graph with anchors for tooltips
   * - if over tooltip anchors, show tooltip
   * - if over anything else, maybe clear the tooltip
   */
  @task({ restartable: true })
  *handleTip(e) {
    yield rawTimeout(100);
    if (!this.tooltip || !e) return;

    // If mouse is over evolutionGraph, add tooltip anchors to it
    if (e.target.matches('.js--evolution')) {
      evolutionGraphTooltips(e.target);
    }
    // If mouse is over a evolutionGraph anchor, show tooltip
    else if (e.target.matches('.js--evolution-tip')) {
      this.tooltip.show(getTooltipData(e.target));
      this.timeout = Date.now() + 500; // Clear it after 0.5 seconds
    }
    // Check if tooltip needs to be hidden
    else if (this.timeout && Date.now() > this.timeout) {
      this.tooltip.hide();
      this.timeout = 0;
    }
  }
}
