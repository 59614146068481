import { helper } from '@ember/component/helper';

export const competitorObjectPath = (params) => {
  // example: keyword-position-competitor-15
  const columnName = params[0];
  const node = params[1];
  const competitorId = columnName.split('-').reverse()[0];
  if (node) {
    return `competitor_results.${competitorId}.${node}`;
  } else {
    return `competitor_results.${competitorId}`;
  }
};

export default helper(competitorObjectPath);
