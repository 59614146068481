import { add, isAfter, isBefore } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { pluralize } from 'ember-inflector';
import { DEFAULT_GROUPING_TYPE } from '../../constants/chart/grouping';

const detectType = (values) => {
  const [firstVal] = values;
  if (firstVal === null || firstVal === undefined) return 'number';
  return typeof firstVal;
};

const groupMethods = {
  sum(values) {
    switch (detectType(values)) {
      case 'number':
        if (values.every((v) => v === null)) return null;
        return values.reduce((total, current) => total + current, 0);
      case 'object': {
        return values.reduce((acc, object) => {
          Object.keys(object).forEach(
            (key) => (acc[key] = (acc[key] ?? 0) + object[key])
          );
          return acc;
        }, {});
      }
    }

    throw `groupMethods: unsupported data type ${typeof firstVal}`;
  },
  average(values) {
    switch (detectType(values)) {
      case 'number': {
        const total = groupMethods.sum(values);
        if (total === null) return null;
        return total / values.length ?? 0;
      }
      case 'object': {
        return Object.entries(groupMethods.sum(values)).reduce(
          (object, [key, value]) => {
            object[key] = value / values.length ?? 0;
            return object;
          },
          {}
        );
      }
    }

    throw `groupMethods: unsupported data type ${typeof firstVal}`;
  },
};

const groupData = (data, grouping, groupValues) => {
  if (grouping === DEFAULT_GROUPING_TYPE) return data;

  const ranges = data.reduce((accumulator, [dateStr, value]) => {
    const date = parseISO(dateStr);

    const range = accumulator.find(
      (r) => isAfter(date, r.start) && isBefore(date, r.end)
    );

    if (range) {
      range.values.push(value);
    } else {
      accumulator.push({
        start: date,
        end: add(date, { [pluralize(grouping)]: 1 }),
        values: [value],
      });
    }

    return accumulator;
  }, []);

  return ranges.reduce((dataset, range) => {
    dataset.push([range.start, groupValues(range.values)]);
    return dataset;
  }, []);
};

export { groupMethods, groupData };
