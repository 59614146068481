import Model, { attr, belongsTo } from '@ember-data/model';

export default class SiteAuditPage extends Model {
  errorExplanations = {
    connect_timeout: 'Connection timeout',
    timeout: 'Response timeout',
    econnrefused: 'Connection refused',
    nxdomain: 'Non-existing domain',
    too_many_redirects: 'Too many redirects',
    max_body_reached: 'Response too large',
    closed: 'Connection closed',
    bad_status: 'Bad response code',
    invalid_encoding: 'Invalid encoding',
    ["{:tls_alert, 'internal error'}"]: 'TLS internal error',
    ["{:tls_alert, 'handshake failure'}"]: 'TLS handshake failure',
    ["{:tls_alert, 'record overflow'}"]: 'TLS record overflow',
  };
  @attr('string') url;
  @attr('string') urlFrom;
  @attr('string') responseUrl;
  @attr('number') responseCode;
  @attr('number') depth;
  @attr('string') status;
  @attr('string') redirectCode;
  @attr('string') rel;
  @attr('string') statusInfo;
  @attr('number') byteSize;
  @attr('number') loadTime;
  @attr('string') contentType;
  @attr('string') title;
  @attr('number') titleLength;
  @attr('string') alt;
  @attr('string') metaDescription;
  @attr('number') metaDescriptionLength;
  @attr('string') metaCharset;
  @attr('string') metaViewport;
  @attr('string') ogUrl;
  @attr('string') ogDescription;
  @attr('string') ogTitle;
  @attr('string') ogType;
  @attr('string') ogImage;
  @attr('string') h1;
  @attr('number') h1Count;
  @attr('number') h1Length;
  @attr('string') h2;
  @attr('number') h2Length;
  @attr('string') h3;
  @attr('number') h3Length;
  @attr('string') h4;
  @attr('number') internalUrlsCount;
  @attr('number') externalUrlsCount;
  @attr('string') noindex;
  @attr('string') nofollow;
  @attr('number') internalLinkingUrlsCount;
  @attr('boolean') blockedByRobots;
  @attr('boolean') isUniqueTitle;
  @attr('boolean') isUniqueDescription;
  @attr('boolean') indexable;
  @attr('boolean') isUniqueH1;
  @attr('number') imgCount;
  @attr('number') internalBrokenLinksCount;
  @attr('number') missingAltImagesCount;
  @attr() auditCheckStats;
  @attr('string') error;

  @belongsTo('site-audit/crawling-session') crawlingSession;

  // Returns whether this page has a successful status code, that is 2xx HTTP
  // status class.
  get isResponseSuccessful() {
    return (
      this.responseCode && this.responseCode >= 200 && this.responseCode < 300
    );
  }

  get problemsCount() {
    return this.auditCheckStats?.problematic_ids?.length;
  }

  get warningsCount() {
    return this.auditCheckStats?.warning_ids?.length;
  }

  get hasRedirect() {
    return !!this.redirectCode;
  }

  get hasDetails() {
    return this.crawlingSession?.version > 0;
  }

  get redirectOrResponseCode() {
    return this.hasRedirect ? this.redirectCode : this.responseCode;
  }
}
