import ApplicationAdapter from './application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class NoteAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    if (Array.isArray(payload)) {
      payload = payload.map((note) => ({
        ...note,
        url_id: note.search_keyword_url_id,
      }));
    } else if (isObjectType(payload)) {
      payload = {
        ...payload,
        url_id: payload.search_keyword_url_id,
      };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
