import ENV from '../config/environment';
import ActiveModelAdapter from 'active-model-adapter';
import { inject as service } from '@ember/service';
import appendToUrl from '../utils/append-to-url';

export default class ApplicationAdapter extends ActiveModelAdapter {
  @service session;

  host = ENV.serverURL.replace(/\/$/, '');
  namespace = ENV.apiNamespace;
  coalesceFindRequests = true;
  useFetch = true;

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(modelName, id, snapshot, requestType, query);
    url = this.session.token
      ? appendToUrl(url, `access_token=${this.session.token}`)
      : url;

    return url;
  }
}
