import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditViewChecksRoute extends Route {
  @service router;

  activate() {
    window.scrollTo(0, 0);
  }
}
