import LegacyPagination from 'nightwatch-web/components/legacy-pagination';

export default class BacklinksPaginationComponent extends LegacyPagination {
  persistLimit(limit) {
    const searchKeywordUrlId = this.get('url.id');
    const backlinkViewId = this.get('backlinkView.id');
    this.userSettings.saveSetting({
      searchKeywordUrlId,
      backlinkViewId,
      settingsData: {
        backlinks: {
          limit: limit,
        },
      },
    });
  }
}
