import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { capitalize } from '@ember/string';
import posthog from 'posthog-js';
import { startUrlRegex } from '../utils/regexes';

export default class StartController extends Controller {
  @service router;
  @service session;
  @service metrics;
  @service discovery;
  @service notifications;
  @service saveKeywords;
  @service saveCompetitors;
  @tracked url = null;
  @tracked urlId = null;
  @tracked keywordTasks;
  @tracked groupId = null;
  @tracked currentStep = 'url';
  @tracked keywordPreciseLocation = null;
  @tracked type = null;

  queryParams = ['urlId', 'groupId', 'currentStep', 'type'];
  steps = {
    url: 1,
    location: 2,
    integrations: 3,
    keywords: 4,
    competitors: 5,
  };

  @action
  onInsert() {
    // If a currentStep parameter is present but no URL ID, take the user to the first step.
    if (!this.urlId && this.currentStep !== 'url') {
      this.currentStep = 'url';
    }

    // If a URL ID query param is present then skips to 'location' step.
    if (this.urlId && this.currentStep === 'url') {
      this.currentStep = 'location';
    }
  }

  @action
  enteredRoute(url, urlGroups) {
    this.urlGroups = urlGroups;
    this.url = url;
  }

  get isSaving() {
    return (
      this.createUrlTask.isRunning ||
      this.saveKeywords.saveKeywordsTask.isRunning ||
      this.saveCompetitors.saveCompetitorsTask.isRunning ||
      this.saveUrlTask.isRunning
    );
  }

  get stepsAmount() {
    return Object.keys(this.steps).length;
  }

  get currentStepNumber() {
    return this.steps[this.currentStep];
  }

  get currentStepName() {
    return capitalize(Object.keys(this.steps)[this.currentStepNumber - 1]);
  }

  get progressBarPercent() {
    return (this.currentStepNumber / Object.keys(this.steps).length) * 100;
  }

  get showPreviousButton() {
    return this.currentStepNumber >= 3;
  }

  get urlGroup() {
    return (
      this.urlGroups?.findBy('id', this?.groupId) ?? this.urlGroups.firstObject
    );
  }

  get isTableStep() {
    return (
      this.currentStep === 'keywords' || this.currentStep === 'competitors'
    );
  }

  trackStep(step) {
    posthog.capture('screenview', {
      screenName: step,
      route: 'start',
      navigationMethod: 'click',
    });
  }

  @action
  onClickNext() {
    this.trackStep(this.currentStep);
    switch (this.currentStep) {
      case 'url':
        if (!this.url.url) {
          this.notifications.error('Please enter a URL');
          return;
        } else if (!this.validateUrl(this.url.url)) {
          this.notifications.error('Please enter correct URL');
          return;
        }
        this.createUrlTask.perform();
        break;
      case 'keywords':
        this.saveKeywords.saveKeywordsTask.perform(() => {
          this.currentStep = 'competitors';
        });
        break;
      case 'competitors':
        this.saveCompetitors.saveCompetitorsTask.perform(
          () => {
            this.onFinishOnboarding();
          },
          false,
          this.url
        );
        break;
      default:
        this.saveUrlTask.perform();
    }
  }

  @action
  onClickPrevious() {
    this.currentStep = this.previousStep;
    // Invalidate searched keywords cache on navigating back to location setup.
    if (this.previousStep === 'location') this.discovery.keywords = [];
  }

  @task({ restartable: true })
  *saveUrlTask() {
    this.url.urlGroup = this.urlGroup;

    try {
      yield this.url.save();
      this.currentStep = this.nextStep;
      return true;
    } catch {
      this.saveUrlErrors();
    }
  }

  @task({ restartable: true })
  *createUrlTask() {
    const saved = yield this.saveUrlTask.perform();
    if (!saved) return;

    localStorage.removeItem('nw:needs-onboarding');
    this.session.user?.reload();
    this.urlId = this.url.id;
    this.trackAddedUrl();
  }

  get nextStep() {
    const nextStep = Object.keys(this.steps).indexOf(this.currentStep) + 1;
    return Object.keys(this.steps)[nextStep];
  }

  get previousStep() {
    const prevStep = Object.keys(this.steps).indexOf(this.currentStep) - 1;
    return Object.keys(this.steps)[prevStep];
  }

  get domain() {
    return this.url.domain;
  }

  get faviconDomain() {
    const domainString = this.domain?.split('.');
    if (domainString?.length > 1 && domainString?.[1].length > 0) {
      return this.url.domain;
    } else {
      return '';
    }
  }

  onFinishOnboarding() {
    this.discovery.competitors = [];
    this.discovery.keywords = [];
    this.urlId = null;
    this.groupId = null;
    this.router.transitionTo('dashboard.url.keywords', this.url.id);
  }

  trackAddedUrl() {
    this.metrics.trackEvent({
      event: 'Added URLs',
      urls: this.url.url,
    });
  }

  validateUrl(url) {
    return !!url.match(startUrlRegex);
  }

  saveUrlErrors() {
    // Don't show notification if the API responded with a validation error
    // In this case url will be invalid and invalid fields will be shown in the UI
    if (!this.url.isValid && this.url.errors.length) return;

    this.notifications.error(
      'Something went wrong saving the URL. Please contact support',
      { autoClear: true }
    );
  }
}
