import POSITION_VALUES from '../../constants/chart/position-values';

// Util functions for configuring chart.js dataset scriptable options
// See: https://www.chartjs.org/docs/latest/general/options.html#scriptable-options
// The retuned function is called for each point in a dataset from "nwPosition" scale
// Allows dynamic configuration of the display of points based on POSITION_VALUES.NOT_IN_RESULTS

// If point is NOT_IN_RESULTS return or call `notInResults` otherwise return `inResults`
export const notInResults = function (notInResults, inResults) {
  return function (point) {
    if (point?.raw?.y === POSITION_VALUES.NOT_IN_RESULTS)
      return typeof notInResults === 'function'
        ? notInResults(point)
        : notInResults;
    return inResults;
  };
};

// Used to change the diplay of line segments (one or more points) for which
// the values are NOT_IN_RESULTS
export const notInResultsSegment = function (notInResultsVal, inResultsVal) {
  return function (ctx) {
    if (
      ctx.p0.raw.y === POSITION_VALUES.NOT_IN_RESULTS &&
      ctx.p1.raw.y === POSITION_VALUES.NOT_IN_RESULTS
    )
      return notInResultsVal;
    return inResultsVal;
  };
};

// Util function to determine whether a point is standing alone
// Used to alter the display of lone NOT_IN_RESULTS points as they
// will not be included in any segments
// See: https://www.chartjs.org/docs/latest/charts/line.html#segment
const isLoneNotInResultsPoint = (point) => {
  const pointBefore = point.dataset.data[point.dataIndex - 1];
  const pointAfter = point.dataset.data[point.dataIndex + 1];
  return (
    pointBefore?.y !== POSITION_VALUES.NOT_IN_RESULTS &&
    pointAfter?.y !== POSITION_VALUES.NOT_IN_RESULTS
  );
};
export const loneNotInResultsPoint = function (loneVal, groupedVal) {
  return function (point) {
    return isLoneNotInResultsPoint(point) ? loneVal : groupedVal;
  };
};

// Chart.js plugin which interprets POSITION_VALUES into rendered points on the chart
const positionHiderPlugin = {
  beforeDatasetDraw(_chart, args) {
    // Ignore all non-position datasets
    if (args.meta.yScale.id !== 'nwPosition') return;

    args.meta.data.forEach((point) => {
      if (!point?.$context?.raw) return;

      if (point.$context.raw.y === POSITION_VALUES.NOT_IN_RESULTS) {
        point.skip = false; // force point to render even with nullish value
        point.y = args.meta.yScale.bottom; // place at the bottom of the y scale (requried since the scale is reversed)
      }
      if (point.$context.raw.y === POSITION_VALUES.NOT_CRAWLED) {
        point.skip = true; // do not render any pont
      }
    });
  },
};

export default positionHiderPlugin;
