import Service, { inject as service } from '@ember/service';

export default class KeywordListSortingService extends Service {
  @service userSettings;

  persistSorting(params) {
    const { urlId, viewId, sortField, sortDirection } = params;
    this.userSettings.saveSetting({
      searchKeywordUrlId: urlId,
      dynamicViewId: viewId,
      settingsData: {
        keywords: {
          sortField: sortField,
          sortDirection: sortDirection,
        },
      },
    });
  }

  parseFromLocalStorage(params) {
    const { urlId, viewId } = params;
    let resourceSortKey = null;

    // Legacy
    if (urlId) {
      resourceSortKey = `nw:url:${urlId}:keywordSorting`;
    } else if (viewId) {
      resourceSortKey = `nw:url:${viewId}:keywordSorting`;
    }

    let item = localStorage.getItem(resourceSortKey);
    if (!item) {
      item = localStorage.getItem('nw:lastKeywordSorting');
    }

    try {
      const sortParams = JSON.parse(item);
      const { sortField, sortDirection } = sortParams;
      return { sort: sortField, direction: sortDirection };
    } catch (e) {
      return {};
    }
  }

  getSorting({ urlId, viewId }) {
    const searchKeywordUrlId = urlId;
    const dynamicViewId = viewId;

    let defaultSort = 'position';
    let defaultSortDirection = 'asc';
    let sort, sortDirection;

    try {
      const setting = this.userSettings.getSetting({
        searchKeywordUrlId,
        dynamicViewId,
      });
      const settingsData = setting.get('settingsData');
      sort = settingsData.keywords.sortField;
      sortDirection = settingsData.keywords.sortDirection;
    } catch (e) {
      // There's no persisted setting available. Let's try to get it from localStorage
      const paramsFromLocalStorage = this.parseFromLocalStorage({
        urlId,
        viewId,
      });
      sort = paramsFromLocalStorage.sort;
      sortDirection = paramsFromLocalStorage.sortDirection;
    }

    return {
      sort: sort || defaultSort,
      direction: sortDirection || defaultSortDirection,
    };
  }

  applySorting(controller, { urlId, viewId }) {
    const savedParams = this.getSorting({ urlId, viewId });
    const isDefault =
      controller.sort === 'created_at' && controller.direction === 'desc';
    if (isDefault && savedParams.sort && savedParams.direction) {
      controller.sort = savedParams.sort;
      controller.direction = savedParams.direction;
    }
  }
}
