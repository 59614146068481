import Component from '@glimmer/component';
import { action } from '@ember/object';
import { allSearchEngines } from 'nightwatch-web/constants/keyword-data';
import { allEngines } from '../../utils/filters/keyword-page-filter-state';

const engineIcon = {
  google: '/assets/icons/blue/google.svg',
  youtube: '/assets/icons/blue/youtube.svg',
  places: '/assets/icons/blue/googlemaps.svg',
  bing: '/assets/icons/blue/bing.svg',
  yahoo: '/assets/icons/blue/yahoo.svg',
  duckduckgo: '/assets/icons/blue/duckduckgo.svg',
};

export default class NwOnboardingFlowEngineSelectComponent extends Component {
  get options() {
    let engines = this.args.allowAllEngines
      ? [allEngines].concat(allSearchEngines)
      : allSearchEngines;

    // replace icons
    engines = engines.map((engine) => {
      engine.icon = engineIcon[engine.id];
      return engine;
    });

    return engines;
  }

  get selected() {
    return this.options.findBy('id', this.args.selectedId);
  }

  @action onChange(engine) {
    this.args.onSelected(engine.id);
  }
}
