import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { all } from 'rsvp';

export default class DashboardUrlDynamicViewRoute extends Route {
  @service('persistence/keyword-list-sorting') keywordListSorting;
  @service metrics;
  @service store;

  limit = 50;

  async afterModel(dynamicView) {
    const url = this.modelFor('dashboard.url');
    this.controllerFor('dashboard').setSelectedUrl(url);
    dynamicView.filtersSaved = false;
    return all([
      url.loadCompetitors.perform({ reload: false }),
      this.userSettings.loadData({ dynamicViewId: dynamicView.id }),
      dynamicView.loadFilterGroups(),
    ]);
  }

  setupController(controller, dynamicView) {
    super.setupController(controller, dynamicView);
    const url = this.modelFor('dashboard.url');
    controller.enteredRoute(dynamicView, url);
  }

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Open View',
      name: this.controller.get('model.name'),
    });
    return true;
  }
}
