import { and, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Component.extend({
  init(...args) {
    this._super(...args);
    this.set('reasonsMapper', {
      CHANGING_BUSINESS: 'We are changing our business direction',
      MISSING_FEATURES: "It doesn't provide what I expected",
      FOUND_ELSE: 'I found a better service',
      BUGS: 'It has bugs',
      INACCURATE: "It's inaccurate",
      EXPENSIVE: "It's too expensive",
      OTHER: 'Other',
    });
  },
  fetch: service(),
  notifications: service(),
  session: service(),
  reasonsForDropdown: computed('reasonsMapper', function () {
    return Object.keys(this.reasonsMapper);
  }),
  reasonSelected: computed('selectedReason', function () {
    return isPresent(this.selectedReason);
  }),
  submitAvailable: and('selectedReason', 'detailedReason'),
  submitNotAvailable: not('submitAvailable'),
  submitDisabled: or('submitNotAvailable', 'isUnsubscribing'),
  userNeedsHelp: action(function () {
    // This will send a message to Slack so that the support can see it's urgent
    this.fetch.post('/user/notify_internal', {
      data: { event: 'coronavirus_support' },
    });
    this.openSupport();
  }),
  selectReason: action(function (reason) {
    this.set('selectedReason', reason);
    later(() => {
      this.element.querySelector('textarea').focus();
    }, 10);
  }),
  cancelSubscription: action(function () {
    if (this.submitDisabled) {
      return;
    }

    const reasonDetails = this.getProperties(
      'selectedReason',
      'detailedReason'
    );
    const data = {
      reason: this.reasonsMapper[reasonDetails.selectedReason],
      detailed_reason: reasonDetails.detailedReason,
    };

    const promise = this.fetch.post('/user/cancel_subscription', { data });
    this.set('isUnsubscribing', true);
    promise
      .then(() => {
        this.session.user.reload();
        this.notifications.success(
          'Subscription cancelled. We are sad to see you go. ☹️',
          { autoClear: true, clearDuration: 10000 }
        );
        this.goToPlans();
      })
      .catch(() => {
        this.notifications.error(
          'Error while unsubscribing. Please contact support.',
          { autoClear: true, clearDuration: 10000 }
        );
      })
      .finally(() => {
        this.set('isUnsubscribing', false);
      });
  }),
});
