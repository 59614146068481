import ApplicationSerializer from 'nightwatch-web/serializers/application';

export default class SiteAuditViewSerializer extends ApplicationSerializer {
  normalize(modelClass, resourceHash, prop) {
    return super.normalize(
      modelClass,
      {
        ...resourceHash,
        url_id: resourceHash.search_keyword_url_id,
        search_keyword_url_id: undefined,
      },
      prop
    );
  }

  serializeIntoHash(data, type, record, options) {
    super.serializeIntoHash(data, type, record, options);
    data['view'] = data['site_audit/view'];
    data['view'].search_keyword_url_id = data['view'].url_id;
    delete data['site_audit/view'];
  }
}
