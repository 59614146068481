import Model, { belongsTo, attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class AuditCheck extends Model {
  @attr() filterConfig;
  @attr('string') category;
  @attr('string') name;

  @tracked filtersSaving = false;
  @tracked filtersSaved = false;

  @belongsTo('url', { async: false }) url;

  get filterConfigWithUnderscore() {
    return {
      filterGroups: this.filterConfig?.filter_groups,
    };
  }
}
