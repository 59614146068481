import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinksNewController extends Controller {
  @service router;

  @action
  onBacklinksCreated() {
    const params = {
      sort: 'last_visited',
      direction: 'desc',
      page: 1,
      search: null,
    };
    this.router.transitionTo('dashboard.url.backlinks', this.model, {
      queryParams: params,
    });
  }
}
