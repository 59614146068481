import Route from '@ember/routing/route';

export default class DashboardUrlSiteAuditViewChecksIndexRoute extends Route {
  beforeModel(transition) {
    // This situation happens when you are in the
    // Audit Checks route and you click on the Audit Checks again.
    // At that time the screen goes blank because it wants to go to the index template,
    // but the index route is not defined. This is a fix to prevent it.
    transition.abort();
  }
}
