import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { allLocations } from '../../utils/filters/keyword-page-filter-state';
import { tracked } from '@glimmer/tracking';

/**
  @argument {Boolean} allowAllLocations - include "All Locations" in options
  @argument {Boolean} showPlaceholderName - show country name next to the flag
  @argument {String} selectedId
 */
export default class NwSelectorsLocationSelectComponent extends Component {
  @service siteData;
  @tracked lastLocationSelected = {
    id: 'US',
  };

  get options() {
    const options = this.siteData.countriesExtended;
    return this.args.allowAllLocations ? [allLocations, ...options] : options;
  }

  get selected() {
    let selectedId = this.args.selectedId;
    return this.options.findBy('id', selectedId) || this.lastLocationSelected;
  }

  @action
  onChange(location) {
    this.lastLocationSelected = location;
    this.args.onSelected(location.id.toLowerCase());
  }
}
