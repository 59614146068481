import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Controller from '@ember/controller';

export default class SettingsApiAccessController extends Controller {
  @service fetch;
  @service session;

  get reset_token() {
    return this.session.user?.api_access_token;
  }

  isSubmitting = false;

  selectContents(ev) {
    ev.target.select();
  }

  @action
  generate() {
    this.set('isSubmitting', true);
    this.fetch
      .post('/api_access_token')
      .then((rsp) => {
        this.set('apiAccessToken', rsp.api_access_token);
        this.notifications.success('Token generated.', {
          autoClear: true,
        });
      })
      .catch(() => {
        this.notifications.error(
          'There was an error when trying to generate a token. Please try again.',
          { autoClear: true }
        );
      })
      .finally(() => {
        this.set('isSubmitting', false);
        this.session.user.reload();
      });
  }

  @action
  revoke() {
    this.set('isSubmitting', true);
    this.fetch
      .delete('/api_access_token')
      .then(() => {
        this.set('apiAccessToken', null);
        this.notifications.success('Token successfully revoked.', {
          autoClear: true,
        });
      })
      .catch(() => {
        this.notifications.error(
          'There was an error when trying to revoke your current token. Please try again.',
          { autoClear: true }
        );
      })
      .finally(() => {
        this.set('isSubmitting', false);
        this.session.user.reload();
      });
  }
}
