import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSettingsRoute extends Route {
  @service router;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel(transition) {
    if (this.session.user?.isLimited) {
      this.router.transitionTo('dashboard.url');
    }
    if (transition.targetName == 'dashboard.url.settings.index') {
      this.router.transitionTo('dashboard.url.settings.configuration');
    }
  }

  model() {
    return this.modelFor('dashboard.url');
  }

  afterModel(model, params) {
    if (
      params.intent.queryParams &&
      params.intent.queryParams.from === 'dashboard_ga'
    ) {
      this.notifications.info(
        'Connect Google Analytics and Search Console properties to start tracking traffic data.',
        { autoClear: true, clearDuration: 5000 }
      );
    }
  }

  renderTemplate() {
    this.render({ into: 'dashboard' });
  }

  @action
  willTransition(transition) {
    if (transition.targetName == 'dashboard.url.settings.index') {
      this.router.transitionTo('dashboard.url.settings.configuration');
    }
    return true;
  }
}
