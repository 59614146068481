import { helper } from '@ember/component/helper';
import { differenceInDays, formatDistanceToNowStrict } from 'date-fns';

/**
  @helper relativeDay
  A wrapper around formatDistanceToNowStrict to provide more specific output when displaying "Next Crawl".
  Specifically – we want a user to see "in x days" when their crawl has just run.
 */
export const relativeDay = (dateObj) => {
  if (!dateObj) return '/';
  const days = differenceInDays(new Date(), new Date(dateObj));

  if (days === 0)
    return formatDistanceToNowStrict(new Date(dateObj), {
      roundingMethod: 'round',
      addSuffix: true,
    });

  const daysAbs = Math.abs(days);
  const prefix = days < 0 ? 'in ' : '';
  const plural = daysAbs > 1 ? ' days' : ' day';
  const suffix = days > 0 ? ' ago' : '';

  return `${prefix}${daysAbs}${plural}${suffix}`;
};

export default helper((params = []) => relativeDay(params[0]));
