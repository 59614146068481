import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SiteAuditTableColumns from '../../utils/tables/site-audit-table-columns';
import { getOwner } from '@ember/application';
import { isAfter, isBefore } from 'date-fns';
import { AVAILABLE_FILTERS } from 'nightwatch-web/utils/site-audit-properties';
import isTesting from '../../utils/is-testing';
import { later } from '@ember/runloop';

export default class NwSiteAuditPageProviderComponent extends Component {
  @service store;
  @service metrics;
  @service notifications;
  @service fetch;
  @service router;
  @service session;

  @tracked pages;
  @tracked stats;
  @tracked search;
  @tracked checks;
  @tracked newCheck;
  @tracked comparisonId;
  @tracked sort = 'name';
  @tracked filtersObject;
  @tracked siteAuditView;
  @tracked pageForDetails;
  @tracked currentCheck;
  @tracked currentPage = 1;
  @tracked crawlingSessions;
  @tracked direction = 'asc';
  @tracked crawlingSessionId;
  @tracked paginationLimit = 50;
  @tracked comparisonEnabled = false;
  @tracked crawlingInProgress = false;

  availableFilters = AVAILABLE_FILTERS;
  filterConfig = null;

  @tracked columns = new SiteAuditTableColumns({
    owner: getOwner(this),
    filters: this.filtersObject,
  });

  @action
  async onInsert() {
    if (this.isChecksPage) {
      this.loadChecks.perform();
    }
    await this.loadSiteAuditView.perform();
    // this.columns.setColumnsFromFilters(this.filtersObject);
    this.loadCrawlingSessions.perform().then(() => {
      if (!this.currentCrawlingSession) {
        this.redirectToSettings();
        return;
      }
      this.loadStats.perform();
      this.loadPages.perform();
    });
  }

  @task({ drop: true })
  *loadSiteAuditView() {
    if (!this.router.currentRoute.parent.params.view_id) {
      this.siteAuditView = this.store.createRecord('site-audit/view', {
        url: this.args.model.url,
      });
      this.filtersObject = {};
    } else {
      const { view_id } = this.router.currentRoute.parent.params;
      yield this.store.findRecord('site-audit/view', view_id).then((view) => {
        view.set('url', this.args.model.url);
        view.loadCount();
        this.filtersObject = {
          filterGroups: view.filterConfig?.filter_groups ?? [
            {
              filters: [],
            },
          ],
        };
        this.siteAuditView = view;
      });
    }
  }

  @task({ drop: true })
  *loadPages() {
    const { url } = this.args.model;
    this.pages = yield this.store.query('site-audit/page', {
      crawling_session_id: this.currentCrawlingSession.id,
      search_keyword_url_id: url.id,
      limit: this.paginationLimit,
      sort: this.sort,
      direction: this.direction,
      page: this.currentPage,
      search: null,
      filters: encodeURI(JSON.stringify(this.filtersObject)),
    });
  }

  @task({ drop: true })
  *loadCrawlingSessions() {
    const { url } = this.args.model;
    this.crawlingSessions = null;
    this.crawlingSessions = yield this.store.query(
      'site-audit/crawling-session',
      {
        limit: 1000,
        sort: 'created_at',
        direction: 'desc',
        search_keyword_url_id: url.id,
      }
    );
    this.refreshCrawlingSession();
  }

  @task({ drop: true })
  *loadStats() {
    this.stats = null;

    const data = {
      crawling_session_id: this.currentCrawlingSession?.id,
      comparison_crawling_session_id: this.comparisonCrawlingSession?.id,
      filters: encodeURI(JSON.stringify(this.filtersObject)),
      search: this.search,
    };

    this.stats = yield this.fetch.request('/site_audit/stats', { data });
  }

  @task({ drop: true })
  *loadChecks() {
    const { url } = this.args.model;
    this.checks = yield this.store
      .query('site-audit/audit-check', {
        search_keyword_url_id: url.id,
      })
      .then((checks) => {
        checks.forEach((check) => check.set('url', url));
        return checks.toArray(); // toArray needed to make this.checks mutable.
      });
    this.currentCheck = this.checks.firstObject;
    this.filtersObject = {
      filterGroups: this.currentCheck?.filterConfig?.filter_groups,
    };
  }

  get currentCrawlingSession() {
    return this.crawlingSessionId
      ? this.crawlingSessions?.findBy('id', this.crawlingSessionId)
      : this.crawlingSessions?.toArray()[0];
  }

  get hasPageDetails() {
    return this.currentCrawlingSession?.version > 0;
  }

  get comparisonCrawlingSession() {
    return this.comparisonId
      ? this.crawlingSessions?.findBy('id', this.comparisonId)
      : this.crawlingSessions?.toArray()[1];
  }

  get hideCrawlingSessionsSelector() {
    return this.crawlingInProgress || this.isNewView;
  }

  get mostRecentCrawlingSession() {
    return this.crawlingSessions?.firstObject;
  }

  get secondMostRecentCrawlingSession() {
    return (this.crawlingSessions ?? []).toArray()[1];
  }

  get olderCrawlingSessions() {
    return (this.crawlingSessions ?? []).filter((s) => {
      return isBefore(
        new Date(s.createdAt),
        new Date(this.currentCrawlingSession?.createdAt)
      );
    });
  }

  get availableCrawlingSessionsForCurrentSnapshot() {
    return this.comparisonEnabled
      ? this.newerCrawlingSessions
      : this.crawlingSessions;
  }

  get availableCrawlingSessionsForComparisonSnapshot() {
    return this.olderCrawlingSessions;
  }

  get newerCrawlingSessions() {
    return (this.crawlingSessions ?? []).filter((s) => {
      return isAfter(
        new Date(s.createdAt),
        new Date(this.comparisonCrawlingSession?.createdAt)
      );
    });
  }

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  get isNewView() {
    return (
      this.router.currentRouteName === 'dashboard.url.site-audit.views.new'
    );
  }

  get showCrawlButton() {
    return (
      !this.session.user.isLimited &&
      this.router.currentRouteName !== 'dashboard.url.site-audit.view.show' &&
      this.router.currentRouteName !== 'dashboard.url.site-audit.views.new'
    );
  }

  get isChecksPage() {
    return !!this.router.currentRouteName.match('checks');
  }

  @action
  setComparisonCrawlingSession(crawlingSession) {
    this.comparisonId = crawlingSession.id;
    this.comparisonEnabled = true;
    this.loadPages.perform();
  }

  @action
  setCrawlingSession(crawlingSession) {
    // This set will refresh the model
    this.crawlingSessionId = crawlingSession.id;
    this.loadPages.perform();
  }

  @action
  onPageChange(page) {
    this.currentPage = page;
    this.loadPages.perform();
  }

  @action
  onPageLimitChange(limit) {
    this.paginationLimit = limit;
    this.loadPages.perform();
  }

  @action
  onSort(sort, direction) {
    if (!sort) return;

    this.sort = sort;
    this.direction = direction;

    this.metrics.trackEvent({ event: 'Sorted pages' });
    this.loadPages.perform();
  }

  @task({ restartable: true })
  *onFilterChange(filters) {
    if (
      filters?.filterGroups?.length === 1 &&
      filters?.filterGroups?.firstObject?.filters?.firstObject?.name === null
    ) {
      this.filtersObject = undefined;
    } else {
      this.filtersObject = filters;
    }
    this.page = 1;
    yield this.loadPages.perform();
    if (this.isNewView) return;
    this.loadStats.perform();
  }

  @action
  onCheckClick(args) {
    const selectedCheck = this.checks.findBy('id', args);
    this.filtersObject = {
      filterGroups: selectedCheck.filterConfig?.filter_groups,
    };
    this.currentCheck = selectedCheck;
    this.loadPages.perform();
  }

  @action
  setFilterConfig(config) {
    this.filterConfig = config;
  }

  @action
  saveResource() {
    this.siteAuditView.filterConfig = {
      filter_groups: this.filtersObject.filterGroups,
    };
    this.siteAuditView.filtersSaving = true;
    this.siteAuditView
      .save()
      .then((siteAuditView) => {
        siteAuditView.filtersSaved = true;
        this.notifications.success('Site audit view saved successfully', {
          autoClear: true,
        });
        this.router.transitionTo(
          'dashboard.url.site-audit.view.show',
          siteAuditView.id
        );
        siteAuditView.loadCount();
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while saving the site audit view',
          { autoClear: true }
        );
      })
      .finally(() => {
        this.siteAuditView.filtersSaving = false;
      });
  }

  @action
  saveCheckView(check) {
    check.filterConfig = {
      filter_groups: this.filtersObject.filterGroups,
    };
    check.filtersSaving = true;
    check.url_id = this.args.model.url.id;
    check.search_keyword_url_id = this.args.model.url.id;
    const isNew = check.isNew;
    check
      .save()
      .then((savedCheck) => {
        check.filtersSaved = true;
        this.notifications.success('Audit check saved successfully.', {
          autoClear: true,
        });
        if (isNew) {
          this.newCheck = null;
          this.currentCheck = savedCheck;
          // TODO: Send user to the new check view
        }
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while saving the audit check',
          { autoClear: true }
        );
      })
      .finally(() => {
        check.filtersSaving = false;
      });
  }

  @action
  prepareNewCheck() {
    const check = this.store.createRecord('site-audit/audit-check', {
      filterConfig: {},
      url: this.args.model.url,
      category: 'warning',
      name: 'New audit check',
    });
    this.filtersObject = {};
    this.newCheck = check;
    this.currentCheck = check;
    this.checks.pushObject(check);
  }

  @task({ drop: true })
  *deleteCheck(check) {
    if (this.checks.length === 1) {
      return this.notifications.error(
        "Can't delete all checks. At least one must be present.",
        { autoClear: true }
      );
    }
    yield check.destroyRecord().then(
      () => {
        this.notifications.success('Audit check deleted successfully.', {
          autoClear: true,
        });

        this.checks.removeObject(check);
        this.currentCheck = this.checks.lastObject;
        // TODO: Send user to the new check view
      },
      () => {
        this.notifications.error(
          'There was an error while deleting the audit check',
          { autoClear: true }
        );
      }
    );
  }

  @action
  deleteSiteAuditView() {
    if (!confirm('Are you sure you want to delete this view?')) {
      return;
    }

    if (!this.siteAuditView.id) {
      return;
    }

    this.siteAuditView
      .destroyRecord()
      .then(() => {
        this.router.transitionTo('dashboard.url.site-audit');
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while deleting the audit check view',
          { autoClear: true }
        );
      });
  }

  redirectToSettings() {
    this.router.transitionTo(
      'dashboard.url.settings.site-audit',
      this.args.model.url,
      { queryParams: { startCrawling: true } }
    );
  }

  refreshViewCounts() {
    // This will reflect in sidebar view counts when crawling
    (this.args.model.url?.siteAuditViews ?? []).forEach((view) =>
      view.loadCount()
    );
  }

  refreshCrawlingSession() {
    const crawlingSession = this.currentCrawlingSession;
    if (!crawlingSession) {
      this.redirectToSettings();
      return;
    }
    if (crawlingSession.isDone || this.crawlingInProgress) {
      return;
    }

    const _scheduleRefresh = () => {
      if (crawlingSession.isDone) {
        // We get here when we are refreshing and the session just got done.
        // We need one last stats refresh to fetch the newest stats.
        if (this.crawlingInProgress) {
          this.notifications.success('Crawling finished!', {
            autoClear: true,
            clearDuration: 8000,
          });
          this.crawlingInProgress = false;
          this.refreshViewCounts(this.args.model.url);
        } else {
          this.crawlingInProgress = false;
        }
        return;
      }

      this.crawlingInProgress = true;

      if (isTesting) return;

      later(() => this.refreshViewCounts(this.args.model.url), 6000);

      this.loadCrawlingSessions
        .perform()
        .then(() => {
          this.loadPages.perform();
          this.loadStats.perform();
        })
        .catch(() => null);
      later(() => _scheduleRefresh(), 6000);
    };

    _scheduleRefresh();
  }
}
