import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ReportFormatComponent extends Component {
  formats = ['pdf', 'csv', 'html'];

  @action
  setSelectedFormat(format) {
    this.args.report.report_format = format;
    this.args.onReportFormatSelect();
  }
}
