import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class DashboardDynamicViewKeywordsRoute extends Route {
  @service router;
  @service('persistence/keyword-list-sorting') keywordListSorting;

  limit = 50;

  async afterModel(dynamicView, transition) {
    dynamicView.filtersSaved = false;
    this.controllerFor('dashboard').setSelectedDynamicView(dynamicView);
    if (transition.targetName === 'dashboard.dynamic-view.index') {
      this.router.transitionTo('dashboard.dynamic-view.keywords', dynamicView, {
        queryParams: { page: 1 },
      });
    }
    return dynamicView.loadFilterGroups();
  }

  setupController(controller, dynamicView) {
    super.setupController(controller, dynamicView);
    const url = this.modelFor('dashboard.url');
    controller.enteredRoute(dynamicView, url);
  }
}
