import Route from '@ember/routing/route';
import { WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';
import { sub } from 'date-fns';
import { inject as service } from '@ember/service';

export default class DashboardDynamicViewReportRoute extends Route {
  @service store;

  setupController(controller, model) {
    super.setupController(controller, model);
    const dynamicView = this.modelFor('dashboard.dynamic-view');
    controller.set('dynamicView', dynamicView);
    controller.set('whitelabelLogos', this.store.findAll('whitelabel-logo'));
  }

  afterModel(model) {
    if (model.get('scheduled')) {
      model.set('end_time', new Date());
      const reportInterval = String(model.get('report_interval'));
      if (reportInterval === MONTHLY.toString()) {
        model.set('start_time', sub(new Date(), { days: 30 }));
      } else if (reportInterval === WEEKLY.toString()) {
        model.set('start_time', sub(new Date(), { days: 7 }));
      }
    }

    if (!model.get('recipients')) {
      model.set('recipients', ['']);
    }
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }
}
