import { action } from '@ember/object';
import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ReportBlocksComponent extends Component {
  @service('reports/report-utils') reportUtils;

  get blockIcons() {
    return this.reportUtils.blockIcons;
  }

  @action
  sortEndAction() {
    // Otherwise the block gets stuck in a "dragging" state ...
    later(() => {
      this.args.refreshPreview();
    }, 100);
  }
}
