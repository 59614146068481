import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 @argument currentPage
 @argument pages
 @argument limit
 @argument onLimitUpdated
 @argument onPageUpdated
 */
export default class NwKeywordPagePaginationComponent extends Component {
  @service userSettings;

  limits = [50, 100, 250, 500];
  defaultLimit = 50;

  get pagesList() {
    const pagesCount = Number.parseInt(this.args.pages, 10);
    let pages =
      pagesCount && Number.parseInt(pagesCount, 10) > 0
        ? [...Array(pagesCount).keys()].map((p) => ++p)
        : [];

    if (pages.length > 10) {
      // show first, four pages before current, four pages after current and last
      const currentPage = this.args.currentPage;

      const left = [...Array(4).keys()]
        .map((n) => currentPage - (4 - n))
        .filter((p) => p > 1);
      const right = [...Array(4).keys()]
        .map((n) => currentPage + (1 + n))
        .filter((p) => p < pagesCount);

      pages = [1]
        .concat(left)
        .concat([currentPage])
        .concat(right)
        .concat([pagesCount])
        .uniq();

      const pagesWithTruncation = [];
      for (let index = 0; index < pages.length; index++) {
        const page = pages[index];
        pagesWithTruncation.push(page);
        if (pages[index + 1] && pages[index + 1] - page !== 1) {
          pagesWithTruncation.push(null); //ellipsis in template
        }
      }

      pages = pagesWithTruncation;
    }

    return pages;
  }

  get paginationShown() {
    return this.pagesList.length > 1 || this.args.limit > this.defaultLimit;
  }

  get previousPage() {
    const previous = Number.parseInt(this.args.currentPage, 10) - 1;
    return this.pagesList.includes(previous) ? previous : null;
  }

  get nextPage() {
    const next = Number.parseInt(this.args.currentPage, 10) + 1;
    return this.pagesList.includes(next) ? next : null;
  }
}
