import { inject as service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class View extends Model {
  @service fetch;

  @attr('string') name;
  @attr() filterConfig;

  @belongsTo('url', { async: false }) url;

  @tracked filtersSaving = false;
  @tracked filtersSaved = false;

  get filterConfigWithUnderscore() {
    return {
      filterGroups: this.get('filterConfig.filter_groups'),
    };
  }

  loadCount() {
    if (!this.id) return;
    this.fetch
      .request(`/site_audit/views/${this.id}/page_count`)
      .then((data) => {
        this.set('pageCount', data.page_count);
      });
  }
}
