import { tracked } from '@glimmer/tracking';
import { setOwner } from '@ember/application';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import isEqual from 'lodash-es/isEqual';
import { action } from '@ember/object';

export const allLocations = { id: 'globe', label: 'All Locations' };
export const allEngines = {
  id: 'all',
  text: 'All Engines',
  icon: '/assets/icons/icon-search-bl.svg',
};

export default class KeywordPageFilterState {
  @service store;
  @service filterGroupHandling;
  @service siteData;

  @tracked cache;

  // Quick filter properties
  @tracked selectedEngineId = allEngines.id;
  @tracked selectedLocationId = allLocations.id;
  @tracked selectedTags = [];

  constructor({ owner, onChange, queryParamFilters }) {
    setOwner(this, owner);
    this.onChange = onChange;
    this.queryParamFilters = queryParamFilters;
  }

  setup(dynamicView) {
    this.dynamicView = dynamicView;
    this.selectedEngineId = allEngines.id;
    this.selectedLocationId = allLocations.id;
    this.selectedTags = [];

    if (this.queryParamFilters && this.queryParamFilters !== 'undefined') {
      // Use the filters from the query param if they are present
      return this.setupQueryParamFilters(dynamicView);
    }
    // Prepare a blank filterGroup if there are none
    if (dynamicView.filterGroups.length === 0) {
      this.createBlankFilterGroup(dynamicView);
    } else {
      this.updateCache();
    }
  }

  setupQueryParamFilters(dynamicView) {
    try {
      this.filterGroupHandling.applyQueryParamFilters(
        this.queryParamFilters,
        dynamicView
      );
      this.filterGroupHandling.validateFilters(dynamicView, false);
      if (this.dynamicView.filtersValid) {
        this.updateCacheWithQueryParams();
      } else {
        // Fallback if somehow the filters aren't valid
        dynamicView.filterGroups = [];
        this.createBlankFilterGroup(dynamicView);
      }
    } catch (_) {
      // Fallback if somehow the filters aren't valid
      dynamicView.filterGroups = [];
      this.createBlankFilterGroup(dynamicView);
    }
  }

  createBlankFilterGroup(dynamicView) {
    this.filterGroupHandling.prepareNewFilterGroup(dynamicView);
    this.updateCache();
  }

  updateCacheWithQueryParams() {
    this.cache = this.queryParamFilters;
  }

  updateCache() {
    this.cache = this.encodedFilterGroups;
  }

  @task({ restartable: true })
  *changedTask() {
    // Must validate filters before checking filtersDidChange due to checks in advancedFilterGroups getter
    this.filterGroupHandling.validateFilters(this.dynamicView, false);
    // Bail if the filters haven't changed
    if (!this.filtersDidChange) return;

    // Debounce 150ms
    yield timeout(150);

    this.dynamicView.filtersSaved = false;

    // Cache filters *after* checking valueChanged
    this.updateCache();
    this.onChange();
  }

  get advancedFilterCount() {
    return this.dynamicView?.filterGroups
      .toArray()
      .flatMap((fg) => fg.visibleFilters)
      .filterBy('hasValues').length;
  }

  get encodedFilterGroups() {
    const filterGroups = []
      .concat(this.advancedFilterGroups)
      .concat(this.quickFilterGroups);

    return JSON.stringify(filterGroups);
  }

  get advancedFilterGroups() {
    if (this.valueChanged && this.isSingleEmptyValue) return [];

    if (this.dynamicView.filtersValid || this.advancedFiltersBlank) {
      const appliedFilters = this.dynamicView.serializeFilters();
      if (!appliedFilters.length) return [];

      return appliedFilters;
    }
    return [];
  }

  get quickFilterGroups() {
    const filterGroups = [];

    if (this.selectedTags.length) {
      filterGroups.push({
        filters: this.selectedTags.map((tag) => ({
          field: 'keyword_tag',
          condition: 'equals',
          value: tag,
        })),
      });
    }

    if (this.selectedLocationId !== 'globe') {
      filterGroups.push({
        filters: [
          {
            field: 'location',
            condition: 'equals',
            value: this.siteData.countriesExtended.findBy(
              'id',
              this.selectedLocationId.toUpperCase()
            ).label,
          },
        ],
      });
    }

    if (this.selectedEngineId !== 'all') {
      filterGroups.push({
        filters: [
          {
            field: 'engine',
            condition: 'equals',
            value: this.selectedEngineId,
          },
        ],
      });
    }

    return filterGroups;
  }

  get advancedFiltersBlank() {
    // After nullifying the first and only filter
    const { visibleFilterGroups } = this.dynamicView;
    return (
      visibleFilterGroups.length === 1 &&
      visibleFilterGroups?.firstObject?.filters?.length === 1 &&
      visibleFilterGroups?.firstObject?.filters?.firstObject?.isBlank
    );
  }

  get valueChanged() {
    return (
      this.cache &&
      this.cache?.firstObject?.filters?.firstObject?.value !==
        this.dynamicView.filterGroups?.firstObject?.filters?.firstObject?.value
    );
  }

  get isSingleEmptyValue() {
    const { filterGroups } = this.dynamicView;
    const firstGroup = filterGroups.firstObject;
    return (
      filterGroups.length === 1 &&
      firstGroup?.filters?.length === 1 &&
      firstGroup?.filters?.firstObject?.field &&
      firstGroup?.filters?.firstObject?.condition &&
      !['present', 'absent'].includes(
        firstGroup?.filters?.firstObject?.condition
      ) &&
      ['', null, undefined].includes(firstGroup?.filters?.firstObject?.value)
    );
  }

  get filtersDidChange() {
    return this.cache && !isEqual(this.cache, this.encodedFilterGroups);
  }

  @action
  changed() {
    this.changedTask.perform();
  }

  @action
  selectTag(tag) {
    this.selectedTags = this.selectedTags.concat(tag).uniq().sort();
    this.changed();
  }

  @action
  removeTag(tag) {
    this.selectedTags = this.selectedTags.without(tag);
    this.changed();
  }
}
