export default (value) => {
  // http://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
  if (value == null) {
    return null;
  }

  if (isNaN(value)) {
    return value;
  }

  const absNumber = Math.abs(value);
  let abbreviated;

  if (absNumber >= 1_000_000_000) {
    abbreviated = (absNumber / 1_000_000_000).toFixed(1) + 'B';
  } else if (absNumber >= 1_000_000) {
    abbreviated = (absNumber / 1_000_000).toFixed(1) + 'M';
  } else if (absNumber >= 1_000) {
    abbreviated = (absNumber / 1_000).toFixed(1) + 'K';
  } else {
    abbreviated = absNumber.toString();
  }

  // Add the negative sign back if the original number was negative
  return value < 0 ? '-' + abbreviated : abbreviated;

  // return newValue;
};
