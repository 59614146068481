import Component from '@ember/component';
import {action, computed} from '@ember/object';
import {bool, reads} from '@ember/object/computed';
import {task, timeout} from 'ember-concurrency';
import {inject as service} from '@ember/service';
import {endOfDay, format, startOfDay, sub} from 'date-fns';
import {niceDate} from 'nightwatch-web/helpers/nice-date';
import ENV from 'nightwatch-web/config/environment';
import shiftToUTC from 'nightwatch-web/utils/shift-to-utc';

const {apiDateFormat} = ENV;
const ATTEMPTS_AMOUNT = 2; // current and previous day
const POSITION_TYPES = [
  'ai_overview_snippet',
  'organic',
  'knowledge_panel',
  'featured_snippet',
  'local_pack',
  'carousel',
];

// Build arrays of matching positions for each result, Ex:
// { organic: [0, 5] }: the 1st and 6th item in the organic SERPs array match
const getMatchingPositions = (matches = []) => {
  const matching = {};
  POSITION_TYPES.forEach((type) => {
    matching[type] = [];
  });
  if (!matches.length) return matching;
  for (const match of matches) {
    // -1 for zero-indexing
    matching[match.position_type].push(match.position - 1);
  }
  return matching;
};

export default Component.extend({
  classNames: ['nw-serps'],
  store: service(),
  externalScriptLoader: service(),
  url: null,
  keyword: null,
  timestamp: null,
  utcDate: null,
  compareDate: null,
  serpId: null,
  canShowTextChange: bool('compareDate'),
  isLoading: reads('fetchSerpPreviewTask.isRunning'),
  isError: reads('fetchSerpPreviewTask.last.isError'),
  serpPreview: reads('fetchSerpPreviewTask.lastSuccessful.value'),
  serpPreviewCompare: reads('fetchSerpPreviewCompareTask.lastSuccessful.value'),

  didReceiveAttrs() {
    this._super(...arguments);
    const {timestamp, keyword} = this;
    if (timestamp && keyword) {
      // Timestamp from graph *must* be shifted to UTC date before it is manipulated or formatted
      const utcDate = shiftToUTC(new Date(timestamp));
      const compareDate = sub(utcDate, {days: 1});

      this.setProperties({
        utcDate,
        compareDate,
      });

      // Fetch initial serps
      this.fetchSerpPreviewTask.perform();
      // Also fetch previous day's serps for immediate comparison
      this.fetchSerpPreviewCompareTask.perform();
    }
  },

  serpPreviewForUrl: computed(
    'serpPreview.id',
    'serpPreviewCompare.id',
    function () {
      const {serpPreview, serpPreviewCompare} = this;
      if (!serpPreview) return;

      const showComparison = !!serpPreviewCompare;
      // NOTE: The first item in urlMatches matches current url
      // This is temporary and will change with the new API in the future
      // then the matching url will have to be filtered out by `urlMatches[].url_id`
      const matchingPositions = getMatchingPositions(
        serpPreview.urlMatches?.[0]?.matches
      );

      // Defaults for view
      const serpPreviewData = {
        date: serpPreview.date,
        query: serpPreview.query,
        isGoogle: serpPreview.isGoogle,
        isGooglePlaces: serpPreview.isGooglePlaces,
        hasOrganic: false,
        hasLocalPack: false,
        hasCarousel: false,
        hasKnowledgePanel: false,
        hasFeaturedSnippet: false,
        hasAiOverviewSnippet: false,
      };


      if (serpPreview.hasAiOverviewSnippet) {
        serpPreviewData.hasAiOverviewSnippet = true;
        serpPreviewData.aiOverviewSnippet = serpPreview.aiOverviewSnippet
          .map((result) => result.serialize())
          .map((result, idx) => ({
            ...result,
            isMatching: matchingPositions['ai_overview_snippet'].indexOf(idx) > -1,
          }));
      }

      if (serpPreview.hasFeaturedSnippet) {
        serpPreviewData.hasFeaturedSnippet = true;
        serpPreviewData.featuredSnippet = serpPreview.featuredSnippet
          .map((result) => result.serialize())
          .map((result, idx) => ({
            ...result,
            isMatching: matchingPositions['featured_snippet'].indexOf(idx) > -1,
          }));
      }


      if (serpPreview.hasLocalPack) {
        serpPreviewData.hasLocalPack = true;
        serpPreviewData.localPack = serpPreview.localPack
          .map((result) => result.serialize())
          .map((result, idx) => ({
            ...result,
            isMatching: matchingPositions['local_pack'].indexOf(idx) > -1,
          }));
      }

      if (serpPreview.hasKnowledgePanel) {
        serpPreviewData.hasKnowledgePanel = true;
        serpPreviewData.knowledgePanel = serpPreview.knowledgePanel
          .map((result) => result.serialize())
          .map((result, idx) => ({
            ...result,
            isMatching: matchingPositions['knowledge_panel'].indexOf(idx) > -1,
          }));
      }

      if (serpPreview.hasOrganic) {
        const serpCompareOrganicResults = showComparison
          ? serpPreviewCompare.organic.map((result) => result.serialize())
          : null;
        serpPreviewData.hasOrganic = true;
        serpPreviewData.organic = serpPreview.organic
          .map((result) => result.serialize())
          .map((result, idx) => {
            const patchedResult = {
              ...result,
              rank: idx + 1,
              isMatching: matchingPositions['organic'].indexOf(idx) > -1,
              rankDiff: null,
              titleDiff: null,
              descriptionDiff: null,
            };
            if (showComparison) {
              const matchingIdx = serpCompareOrganicResults.findIndex(
                (compareResult) =>
                  result.url !== null && compareResult.url === result.url
              );
              // Exit if no result to compare to
              if (matchingIdx < 0) return patchedResult;
              // Determine rank change by substracting positions in array
              const rankDiff = idx - matchingIdx;
              if (rankDiff !== 0) {
                patchedResult.rankDiff = {
                  change: rankDiff < 0 ? 'up' : 'down',
                  amount: Math.abs(rankDiff),
                };
              }
              // Grab title and desc from compareResult
              patchedResult.titleDiff =
                serpCompareOrganicResults[matchingIdx].title;
              patchedResult.descriptionDiff =
                serpCompareOrganicResults[matchingIdx].description;
            }
            return patchedResult;
          });
      }

      return serpPreviewData;
    }
  ),

  fetchSerpPreviewTask: task(function* () {
    return yield this.fetchSerpPreviewForDateTask.perform(this.utcDate);
  }),

  fetchSerpPreviewCompareTask: task(function* () {
    if (!this.compareDate) return;
    return yield this.fetchSerpPreviewForDateTask.perform(this.compareDate);
  }),

  fetchResultsForDateTask: task(function* (date) {
    if (!date) return;
    const keyword = yield this.keyword;
    if (!keyword) return;
    let counter = 0;
    let results = [];
    while (results.length === 0 && counter < ATTEMPTS_AMOUNT) {
      const tryDate = sub(date, {
        days: counter,
      });

      try {
        results = yield this.store.query('result', {
          keyword_id: keyword.id,
          time_start: format(startOfDay(tryDate), apiDateFormat),
          time_end: format(endOfDay(tryDate), apiDateFormat),
        });
      } catch {
        results = [];
      }
      counter++;
    }
    return results;
  }).enqueue(),

  fetchSerpPreviewForDateTask: task(function* (date) {
    const results = yield this.fetchResultsForDateTask.perform(date);
    const resultId = results?.get('firstObject.id');

    if (!resultId) {
      this.notifications.info(
        `We have no comparable data for ${niceDate([date])}.`,
        {autoClear: true}
      );
      this.set('compareDate', null);
      return null;
    }
    const query = {
      version: 2,
      new_query_api: true,
      s_date: format(date, 'yyyy-LL-dd'),
    };
    this.set('serpId', resultId);
    const result = yield this.store.findRecord('serp-preview', resultId, {
      adapterOptions: {query},
    });

    if (!result.keywordId) {
      this.notifications.info(
        `The SERP data is retained for 5 months for your account. If you'd like to increase your retention interval, please contact our support.`,
        {autoClear: true, timeout: 5000}
      );
    }

    return result;
  }).enqueue(),
  closePanel: action(function () {
    this.onClose();
  }),
  fetchSerpPreview: action(function () {
    this.fetchSerpPreviewTask.perform();
  }),
  selectCompareDate: action(function (date) {
    this.set('compareDate', date || null);
    this.fetchSerpPreviewCompareTask.perform();
  }),
  notifyNoSerpData: task(function* (date) {
    yield timeout(800);
    if (!this.serpPreviewCompare && !this.serpPreview) {
      this.notifications.warning(
        `We have no data for the keyword "${this.keyword.query}" yet.`,
        {autoClear: true}
      );
    } else if (!this.serpPreviewCompare && this.serpPreview) {
      this.notifications.info(
        `We have no comparison data for ${niceDate([date])} yet.`,
        {autoClear: true}
      );
    } else if (this.serpPreviewCompare && !this.serpPreview) {
      this.notifications.info(
        `We have no SERP data for ${niceDate([date])} yet.`,
        {autoClear: true}
      );
    }
  }),
});
