import Model, { belongsTo, attr } from '@ember-data/model';

export default class WhitelabelDomain extends Model {
  @attr('number') whitelabelLogoId;
  @attr('string') title;
  @attr('string') domain;
  @attr('boolean') enabled;

  @belongsTo('user', { async: false }) user;
}
