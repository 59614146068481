import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinkViewSettingsRoute extends Route {
  @service router;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    const { backlink_view_id } = this.paramsFor(
      'dashboard.url.backlink-view-settings'
    );
    if (this.session.user?.isLimited) {
      this.router.transitionTo('dashboard.url.backlink-view', backlink_view_id);
    }
  }
}
