import { modifier } from 'ember-modifier';
import { guidFor } from '@ember/object/internals';

const copyOnClick = async (
  element,
  [clipboardContent],
  { onSuccess, onError }
) => {
  let copy = await import('clipboard-copy');
  copy = copy?.default;
  const className = `copy-on-click__${guidFor(element)}`;

  element.classList.add(className);

  element.addEventListener('click', async () => {
    if (!clipboardContent) return;
    if (!copy) throw new Error('clipboard-copy could not be loaded');
    await copy(clipboardContent)
      .then((event) => onSuccess?.(event))
      .catch((event) => onError?.(event));
  });

  return () => element.removeEventListener('click', () => {});
};

export default modifier(copyOnClick, { eager: false });
