import Service, { inject as service } from '@ember/service';

export default class PaymentAmountCalculatorService extends Service {
  @service fetch;

  fetchVatRate(country, vatNumber) {
    const data = { country, vat_number: vatNumber };
    return this.fetch.request('/billing/vat_rate', { data });
  }
}
