import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import localStorage from 'ember-local-storage-decorator';
import isTesting from '../../utils/is-testing';
import { DEFAULT_GROUPING_TYPE } from '../../constants/chart/grouping';

/**
 @class NwBacklinksOverviewComponent
 @argument url
 @argument dynamicView
 @argument startDate
 @argument endDate
 @argument backlinkStats
 @argument statsLoaded
 @argument isLoading
 */

export default class NwBacklinksOverviewComponent extends Component {
  @service('chart/nw-graph-series') nwGraphSeries;

  @tracked grouping = DEFAULT_GROUPING_TYPE;

  @localStorage('nw:backlinks-overview:show-graph') showGraph = true;
  @localStorage('nw:backlinks-overview:selected-metric') selectedMetric =
    'active-backlinks-box';
  @localStorage('nw:backlinks:backlink-diversity-category') diversityCategory =
    'referring_domains_series';

  constructor(owner, args) {
    super(owner, args);
    if (isTesting) this.showGraph = false;
  }

  get interval() {
    return { start: this.args.startDate, end: this.args.endDate };
  }

  get backlinkStats() {
    return this.args.backlinkStats;
  }

  get newLostChangeMarks() {
    const up = this.backlinkStats?.new ?? 0;
    const down = this.backlinkStats?.lost ?? 0;
    const stagnant = this.backlinkStats?.no_change ?? 0;
    const percentFactor = 100 / (up + down + stagnant);
    let firstMark = percentFactor * up;
    let secondMark = percentFactor * down;

    const minimumPercent = 10;
    const markSum = firstMark + secondMark;
    if (markSum > 0 && markSum < minimumPercent) {
      firstMark = (firstMark / markSum) * minimumPercent;
      secondMark = (secondMark / markSum) * minimumPercent;
    }

    return {
      firstMark: firstMark,
      secondMark: secondMark,
    };
  }

  get selectedGraph() {
    switch (this.selectedMetric) {
      case 'active-backlinks-box':
        return {
          series: this.activeBacklinksSeries,
        };
      case 'backlink-diversity-box':
        return {
          series: this.backlinkDiversitySeries,
        };
      case 'backlink-quality-box':
        return {
          series: this.backlinkQualitySeries,
          label: 'Backlink Quality',
        };
      case 'new-lost-box':
        return {
          series: this.backlinksNewLostSeries,
          label: 'New / Lost',
        };
      default:
        throw `selectedGraph: unknown metric: ${this.selectedMetric}`;
    }
  }

  get activeBacklinksSeries() {
    const data = this.backlinkStats?.all_series ?? [];
    return this.nwGraphSeries.backlinkSeries(
      data,
      this.grouping,
      this.interval
    );
  }

  get singleBacklinkQualitySeries() {
    return this.nwGraphSeries.singleBacklinkQualitySeries(
      this.args.backlinkStats
    );
  }

  get backlinkDiversitySeries() {
    const category = this.diversityCategory;
    const data = this.backlinkStats?.[category] ?? [];
    return this.nwGraphSeries.backlinkDiversitySeries(
      category,
      data,
      this.grouping,
      this.interval
    );
  }

  get backlinkQualitySeries() {
    let data = this.backlinkStats ?? [];
    return this.nwGraphSeries.backlinkQualitySeries(
      data,
      this.grouping,
      this.interval
    );
  }

  get backlinksNewLostSeries() {
    let data = this.backlinkStats ?? [];
    return this.nwGraphSeries.backlinksNewLostSeries(
      data,
      this.grouping,
      this.interval
    );
  }

  @action
  selectMetric(metricName) {
    this.selectedMetric = metricName;
    this.showGraph = true;
  }

  @action
  setGrouping(grouping) {
    this.grouping = grouping;
  }
}
