import { Chart } from 'chart.js';
import graphColors from 'nightwatch-web/constants/graph-colors';

// Default border color - relatively visible on both theme backgrounds
Chart.defaults.borderColor = 'rgba(89, 89, 80, 0.3)';

let index = 0;

export const nextColor = () => {
  if (!graphColors[index]) index = 0;

  const color = graphColors[index];
  index = index + 1;
  return color;
};

export const resetColors = () => {
  index = 0;
};

export const NOT_IN_RESULTS_COLOR = 'rgba(255, 0, 0, 0.3)';
export const TRANSPARENT_COLOR = 'rgba(255, 255, 255, 0)';
