import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import isTesting from 'nightwatch-web/utils/is-testing';
import { later } from '@ember/runloop';
import Route from '@ember/routing/route';
import { differenceInDays } from 'date-fns';

export default class DashboardUrlKeywordsIndexRoute extends Route {
  @service dashboardNotifications;
  @service siteData;
  @service metrics;
  @service session;
  @service store;

  dynamicView = null;

  async afterModel(url) {
    await url.loadCompetitors.perform({ reload: false });
  }

  setupController(controller) {
    super.setupController(controller);
    const url = this.modelFor('dashboard.url');
    this.dynamicView = this.store.createRecord('dynamic-view', { url });
    controller.enteredRoute(url, this.dynamicView);
  }

  showFreshUserNotifications() {
    // return if there is no keywords
    const { user } = this.session;
    const keywords = this.controller.get('keywords');
    if (!keywords) {
      return;
    }
    if (this.get('siteData.isWhiteLabel')) {
      return;
    }
    if (this.get('user.isLimited')) {
      return;
    }
    const createdAt = new Date(user.createdAt);
    const daysAgoSignedUp = differenceInDays(createdAt, new Date());
    const storageKey = 'nw:inaccurateRanksNotificationShown';

    const isFresh = daysAgoSignedUp >= 5 && daysAgoSignedUp <= 8;
    if (!isFresh) return;
    const hasBeenShown = String(localStorage.getItem(storageKey)) === 'true';
    if (hasBeenShown) return;

    localStorage.setItem(storageKey, 'true');
    const dashboardController = this.controllerFor('dashboard');

    dashboardController.set('inaccurateRankingsNotificationShown', true);
  }

  @action
  willTransition() {
    this.dashboardNotifications.clearUrlKeywordsNotifications();
    return true;
  }
  deactivate() {
    // Check if the dynamicView is present and is a new (unsaved) record
    if (this.dynamicView && this.dynamicView.isNew) {
      // Rollback the attributes to clear the unsaved record from the store
      this.dynamicView.rollbackAttributes();
    }
    // Clearing the ref, to prevent memory leak
    this.dynamicView = null;
  }

  @action
  didTransition() {
    if (isTesting) return true;
    later(
      this,
      () => {
        this.showFreshUserNotifications();
      },
      5000
    );
    this.metrics.trackEvent({
      event: 'Open URL',
      url: this.modelFor('dashboard.url').get('url'),
    });
    return true;
  }
}
