import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import { isObjectType } from 'nightwatch-web/utils/is-type';
import appendToUrl from '../utils/append-to-url';

export default class BacklinkAdapter extends ApplicationAdapter {
  @service userData;

  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    const meta = {
      total: headers['X-Total-Count'] || headers['x-total-count'],
      pages: headers['X-Page-Count'] || headers['x-page-count'],
    };
    if (Array.isArray(payload)) {
      const transformedPayload = payload.map((backlink) => ({
        ...backlink,
        url_id: backlink.search_keyword_url_id,
      }));
      payload = {
        backlinks: transformedPayload,
        meta,
      };
    } else if (isObjectType(payload)) {
      payload = {
        backlink: {
          ...payload,
          url_id: payload.search_keyword_url_id,
        },
        meta,
      };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(modelName, id, snapshot, requestType, query);
    url = this.userData.session?.user?.accountId
      ? appendToUrl(url, `acc_id=${this.userData.session?.user?.accountId}`)
      : url;

    return url;
  }
}
