import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class NwSharePanelComponent extends Component {
  @action
  openFbLink(url) {
    let link = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    this.openSharePopup(link, 'fb');
  }
  @action
  openTwLink(url, shareText) {
    let link = `https://twitter.com/intent/tweet?url=${url}&text=${shareText}`;
    this.openSharePopup(link);
  }

  openSharePopup(url, platform) {
    let newWindow;
    // Facebook opens a fixed width popup, otherwise you get stretched mobile UI in a new tab
    if (platform === 'fb') {
      newWindow = window.open(
        url,
        'Facebook',
        'location=no,toolbar=no,menubar=no,scrollbars=no,status=no, width=600, height=600'
      );
    } else {
      newWindow = window.open(url);
    }
    newWindow?.focus();
  }
}
