const blocksForCompetitorTemplate = ({ url, graphs, competitors }) => {
  let [competitorGraph] = graphs.filter((g) => {
    return g.get('name').toLowerCase().includes('competitor');
  });

  // "Virtual" graph
  if (!competitorGraph) {
    competitorGraph = { id: 'all_competitors' };
  }

  const graphBlock = {
    name: 'graph',
    url_id: url.id,
    graph_id: competitorGraph.id,
  };

  const maxCols = 3;
  const competitorColumns = competitors
    .map((c) => {
      return `keyword-position-competitor-${c.id}`;
    })
    .slice(0, maxCols);

  const selectedCompetitorColumns = competitorColumns.map((c) =>
    Object.create({ name: c })
  );

  const columns = ['keyword-position', ...competitorColumns];
  const selectedColumns = [
    Object.create({ name: 'keyword-position' }),
    ...selectedCompetitorColumns,
  ];

  const keywordListBlock = {
    name: 'keyword_list',
    url_id: url.id,
    columns: columns,
    selectedColumns: selectedColumns,
    sort: 'keyword-position',
    sort_direction: 'asc',
  };

  return [graphBlock, keywordListBlock];
};

const blocksForProgressTemplate = ({ url, dynamicView }) => {
  const columns = [
    'keyword-start-position',
    'keyword-end-position',
    'keyword-position-change',
    'keyword-position-max',
  ];
  const selectedColumns = columns.map((c) => Object.create({ name: c }));

  const keywordListBlock = {
    name: 'keyword_list',
    url_id: url?.id,
    view_id: dynamicView?.id,
    columns: columns,
    selectedColumns: selectedColumns,
    sort: 'keyword-position-change',
    sort_direction: 'desc',
  };

  // keyword-start-position, keyword-end-position, position-change

  return [keywordListBlock];
};

export { blocksForCompetitorTemplate, blocksForProgressTemplate };
