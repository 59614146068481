import ApplicationAdapter from 'nightwatch-web/adapters/application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class SerpPreviewAdapter extends ApplicationAdapter {
  pathForType() {
    return 'serp_data';
  }
  findRecord(_store, type, id, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.query) {
      const url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      return this.ajax(url, 'GET', { data: snapshot.adapterOptions.query });
    }
    return super.findRecord(...arguments);
  }

  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    // when API returns payload with `_id: null`, extract the requested id from endpoint URL
    if (isObjectType(payload)) {
      payload = {
        ...payload,
        _id: payload._id ?? requestData.url.split('/').pop().split('?')[0],
      };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
