import ApplicationSerializer from './application';

export default class UserSerializer extends ApplicationSerializer {
  serializeIntoHash(data, _type, record, options) {
    const payload = this.serialize(record, options);
    payload.id = record.id;
    data['user'] = payload;

    const additionalParams = [
      'report_hour',
      'current_password',
      'password',
      'password_confirm',
      'is_company',
      'send_invoice_emails',
      'invoice_email',
      'invoice_name',
      'invoice_address',
      'invoice_state',
      'invoice_city',
      'invoice_postcode',
      'invoice_country',
      'invoice_vat_number',
      'last_notifications_read_at',
      'announcement_seen_at',
    ];

    additionalParams.forEach((param) => (data[param] = record.attr(param)));

    return data;
  }
}
