import { helper } from '@ember/component/helper';

export function round([value, decimals]) {
  if (value === null || value === undefined) {
    return null;
  } else if (value === 0) {
    return 0;
  } else {
    decimals = decimals || 0;
    if (decimals === 0) {
      return parseInt(value);
    } else {
      decimals -= 1;
    }
    const factor = Math.pow(10, decimals) * 10;
    return Math.round(value * factor) / factor;
  }
}

export default helper(round);
