import { BarController, LineController, ScatterController } from 'chart.js';
import POSITION_VALUES from '../../constants/chart/position-values';

const sortTooltipItems = (a, b) => {
  // Always show notes last
  if (b.dataset.type === 'nwNote') return -1;

  return a.datasetIndex > b.datasetIndex;
};

const tooltipLabel = (point) => {
  if (point.dataset.type === 'nwNote') return [`Notes:`, ...formatNotes(point)];
  if (point.dataset.yAxisID === 'nwPosition')
    return `${point.dataset.label}: ${
      point.raw.y === POSITION_VALUES.NOT_IN_RESULTS
        ? 'Not in results'
        : point.formattedValue
    }`;
  return `${point.dataset.label}: ${point.formattedValue}`;
};

const formatNotes = (point) => {
  const MAX_WORDS = 12;
  const MAX_LINES = 15;
  const MAX_WIDTH = 100;

  const words = point.raw.txt.split(' ');
  // Split note into arrays of MAX_WORDS words
  let lines = [...Array(Math.ceil(words.length / MAX_WORDS))].map(() =>
    words.splice(0, MAX_WORDS).join(' ')
  );

  // If note is too long to fit on canvas vertically, truncate with '…'
  if (lines.length > MAX_LINES) {
    lines = lines.splice(0, MAX_LINES);
    lines[MAX_LINES - 1] += '…';
  }
  // Truncate any lines that are too long horizontally
  return lines.map((line) =>
    line.length > MAX_WIDTH ? line.substring(0, MAX_WIDTH) + '…' : line
  );
};

const drawTooltip = (controller) => {
  const { chart } = controller;
  if (!chart.tooltip?._active?.length) return;

  const [activePoint] = chart.tooltip._active,
    canvasContext = chart.ctx,
    x = activePoint.element.x,
    topY = chart.scales.y.top,
    bottomY = chart.scales.y.bottom;

  // Render line on canvas
  canvasContext.save();
  canvasContext.beginPath();
  canvasContext.moveTo(x, bottomY);
  canvasContext.lineTo(x, topY);
  canvasContext.lineWidth = 1;
  canvasContext.strokeStyle = '#b4b4b4';
  canvasContext.stroke();
  canvasContext.restore();
};

class NwLineController extends LineController {
  draw() {
    drawTooltip(this);
    super.draw();
  }
}

class NwBarController extends BarController {
  draw() {
    drawTooltip(this);
    super.draw();
  }
}

class NwNoteController extends ScatterController {
  draw() {
    this.drawNotePoints();
    super.draw();
  }

  drawNotePoints() {
    const points = this._cachedMeta.data;
    points.forEach((point) => {
      point.skip = !point.$context?.raw.txt;
    });
  }
}

NwLineController.id = 'nwLine';
NwBarController.id = 'nwBar';
NwNoteController.id = 'nwNote';

export {
  NwLineController,
  NwBarController,
  NwNoteController,
  tooltipLabel,
  sortTooltipItems,
};
