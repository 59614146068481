import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import localStorage from 'ember-local-storage-decorator';
import { tracked } from '@glimmer/tracking';
export default class NwSidebarComponent extends Component {
  @service router;
  @service pinnedItems;

  @tracked showSidebar = true;

  alphabeticalSorting = ['name'];

  @localStorage('nw:sidebar:width') width;
  @localStorage('nw:sidebar:second-layer-width') secondLayerWidth;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', this.onSectionChange);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.router.off('routeDidChange', this.onSectionChange);
  }

  @action
  onSectionChange() {
    this.showSidebar = true;
  }

  get sortedGraphs() {
    return this.url.graphs
      .sortBy(...this.alphabeticalSorting)
      .filter((graph) => {
        return graph.isLoaded && !graph.isNew;
      });
  }

  get currentSection() {
    const route = this.router.currentRouteName;
    if (
      route.match('dashboard.url.keywords') ||
      route.match('dashboard.url.dynamic-view')
    )
      return 'rankings';
    if (route.match('dashboard.url.chart')) return 'graphs';
    if (route.match('dashboard.url.backlink')) return 'backlinks';
    if (route.match('dashboard.url.site-audit')) return 'site-audit';
    return '';
  }

  get currentSectionTitle() {
    return (
      this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)
    );
  }

  get currentSectionIcon() {
    switch (this.currentSection) {
      case 'rankings':
        return 'icon-chart-bar';
      case 'graphs':
        return 'icon-trending-up';
      case 'site-audit':
        return 'icon-presentation-chart-bar';
      default:
        return 'icon-users';
    }
  }

  get isCurrentSectionToggleable() {
    switch (this.currentSection) {
      case 'rankings':
        return true;
      case 'graphs':
        return true;
      case 'site-audit':
        return true;
      default:
        return false;
    }
  }

  get currentUser() {
    return this.args.currentUser;
  }

  get url() {
    return this.args.selectedUrl;
  }

  get sortedDynamicViews() {
    return this.url?.dynamicViews
      .toArray()
      .rejectBy('isNew')
      .sortBy(...this.alphabeticalSorting);
  }

  get sortedBacklinkViews() {
    return this.url?.backlinkViews
      .toArray()
      .rejectBy('isNew')
      .sortBy(...this.alphabeticalSorting);
  }

  get sortedSiteAuditViews() {
    return this.url?.siteAuditViews
      .toArray()
      .rejectBy('isNew')
      .sortBy(...this.alphabeticalSorting);
  }

  get searchInputClass() {
    const classes = ['nw-sidebar-search-input'];
    if (this.args.isSearch) classes.push('has-text');
    if (this.isSearchExpanded) classes.push('expanded');
    return classes.join(' ');
  }

  get showBacklinksSection() {
    if (!this.currentUser) return false;
    if (parseInt(this.currentUser?.id) === 3) return false; // If user is admin demo account, don't show backlinks section
    const isYoutube = this.url?.isYoutube;
    const isLimited = this.currentUser?.isLimited;
    const enabledInPlan = false;
    const enabledOnUrl = false;
    if (this.currentUser?.backlinksLimit === null) return false;
    if (!this.currentUser?.backlinksVisible) return false;
    if (isYoutube) return false;
    return isLimited ? enabledInPlan && enabledOnUrl : true;
  }

  get showSiteAuditSection() {
    const isYoutube = this.url?.isYoutube;
    const isLimited = this.currentUser?.isLimited;
    const enabledInPlan = this.currentUser?.siteAuditEnabled;
    const urlHasCrawlingSessions = this.url?.lastCrawlingSession;
    if (isYoutube) return false;
    return isLimited ? enabledInPlan && urlHasCrawlingSessions : true;
  }

  @action
  nullifySearch() {
    this.isSearchExpanded = false;
    this.args.nullifySearch();
  }

  @action
  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  @action
  onSidebarResize(width) {
    this.width = width;
    this.pinnedItems.callTheResizeHandler();
  }
}
