import Route from '@ember/routing/route';

export default class DashboardUrlSiteAuditViewShowRoute extends Route {
  get viewName() {
    return this.router.currentRoute?.attributes?.name;
  }

  activate() {
    window.scrollTo(0, 0);
  }
}
