import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
  @class CandidatesService

  Loads and caches 'candidates' for adding reports and pinned items.
 */
export default class CandidatesService extends Service {
  @service fetch;

  @tracked reports = [];
  @tracked pinnedItemSearch;

  @tracked _pinnedItems = [];
  get pinnedItems() {
    if (!this.pinnedItemSearch) return this._pinnedItems;
    return this._pinnedItems.filter((c) => {
      return [c.name, c.url_name]
        .join('')
        .toLowerCase()
        .includes(this.pinnedItemSearch);
    });
  }

  get pinnedItemsLoading() {
    return this.loadPinnedItems.isRunning && this._pinnedItems.length === 0;
  }

  @task({ drop: true })
  *loadReports() {
    try {
      const result = yield this.fetch.request('/pinned_items/candidates');
      this.reports = result.filter(
        (c) => c.type === 'url' || c.type === 'dynamic_view'
      );
    } catch (error) {
      if (error.message === 'The ajax operation was aborted') return;
      throw error;
    }
  }

  @task({ drop: true })
  *loadPinnedItems() {
    try {
      this._pinnedItems = yield this.fetch.request('/pinned_items/candidates', {
        data: {
          new_version: true,
        },
      });
    } catch (error) {
      if (error.message === 'The ajax operation was aborted') return;
      throw error;
    }
  }
}
