import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSettingsSiteAuditRoute extends Route {
  @service session;
  @service router;

  beforeModel() {
    if (!this.session.user.siteAuditEnabled) {
      this.router.transitionTo('dashboard.url.site-audit-disabled');
    }
  }
}
