import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class TagsContainer extends Component {
  @task({ enqueue: true })
  *saveModel() {
    if (!this.args.withSave) return;

    yield this.args.taggableModel.save();
  }

  @action
  addTag(tag) {
    if (this.args.taggableModel.tags.includes(tag)) return;

    this.args.taggableModel.tags.pushObject(tag);
    this.saveModel.perform();
  }

  @action
  deleteTag(tag) {
    if (!this.args.taggableModel.tags.includes(tag)) return;

    this.args.taggableModel.tags.removeObject(tag);
    this.saveModel.perform();
  }
}
