import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardDynamicViewRoute extends Route {
  @service session;
  @service metrics;
  @service store;
  @service('persistence/keyword-list-sorting') keywordListSorting;

  beforeModel() {
    this.session.requireAuthentication();
  }

  afterModel(model) {
    this.controllerFor('dashboard').setSelectedDynamicView(model);
    return this.userSettings.loadData({ dynamicViewId: model.id });
  }

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Open View',
      name: this.controller.get('model.name'),
    });
    return true;
  }
}
