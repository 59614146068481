import ApplicationSerializer from './application';

const transformAttributes = (attrs) => {
  return { ...attrs, competitors: undefined };
};

export default class UrlSerializer extends ApplicationSerializer {
  serializeIntoHash(data, type, record, options) {
    const hash = super.serializeIntoHash(data, type, record, options);

    return { ...hash, source: record.attr('source') };
  }

  normalizeArrayResponse(store, primaryType, payload) {
    payload = payload.map((u) => transformAttributes(u));
    return super.normalizeArrayResponse(store, primaryType, payload);
  }

  normalizeSingleResponse(store, primaryType, payload, recordId) {
    payload = transformAttributes(payload);
    return super.normalizeSingleResponse(store, primaryType, payload, recordId);
  }
}
