import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class DashboardRoute extends Route {
  @service router;
  @service session;
  @service dashboardNotifications;
  @service dashboardState;
  @service metrics;
  @service store;

  get user() {
    return this.session.user;
  }

  showDashboardNotifications() {
    this.dashboardNotifications.showSubscriptionNotifications(this.user);
    this.dashboardNotifications.showNotice();
  }

  handleOnboarding() {
    if (JSON.parse(localStorage.getItem('nw:needs-onboarding'))) {
      if (this.get('session.user.urlsUsed') > 0) {
        localStorage.removeItem('nw:needs-onboarding');
      } else {
        this.router.transitionTo('onboarding', {
          queryParams: { type: 'new' },
        });
      }
    }
  }

  beforeModel() {
    this.session.requireAuthentication();
    this.handleOnboarding();
    this.session.requireActive();
    this.showDashboardNotifications();
  }

  model() {
    return this.store.query('url-group', { static: true });
  }

  setupController(controller, urlGroups) {
    super.setupController(controller, urlGroups);
    this.metrics.trackEvent({ event: 'Open Dashboard' });

    controller.set('urlGroups', urlGroups);

    const urlGroupIdToActivate =
      controller.get('selectedUrl.urlGroup.id') ??
      controller.get('selectedDynamicView.urlGroup.id') ??
      this.getMostRecentlySelectedGroupId(urlGroups) ??
      urlGroups.firstObject?.id;

    if (!urlGroupIdToActivate) return;

    const urlGroup = urlGroups.findBy('id', urlGroupIdToActivate);
    controller.activateUrlGroup(urlGroup);
  }

  getMostRecentlySelectedGroupId(urlGroups) {
    const groupId = this.dashboardState.mostRecentlySelectedUrlGroupId;
    const urlGroup = urlGroups.findBy('id', String(groupId));
    return urlGroup?.id;
  }
}
