import { addDays, format } from 'date-fns';
import randomInt from '../random-int';

const getPlaceholderLine = (hideAxisLabels) => {
  const data = [...Array(15).keys()].map((_, i) => {
    return {
      x: format(addDays(new Date(), i), 'yyyy-MM-dd'),
      y: `${randomInt(50, 80)}.${randomInt(10, 80)}`,
    };
  });

  const datasets = [
    {
      data,
      label: 'Currently processing your site',
      scaleId: 'nwPosition',
      type: 'nwLine',
      borderColor: '#89d63a',
    },
  ];

  return {
    type: 'nwLine',
    data: { datasets },
    options: {
      tension: 0.45,
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          display: !hideAxisLabels,
          type: 'time',
          stacked: true,
          time: {
            unit: 'day',
            tooltipFormat: 'EEEE, LLL d, yyyy',
          },
          ticks: {
            source: 'data',
            maxRotation: 0,
            autoSkip: false,
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          display: !hideAxisLabels,
        },
        nwPosition: {
          display: 'auto',
          type: 'linear',
          title: {
            display: true,
            text: 'Position',
          },
          reverse: true,
          description: 'Scale for ranks (avg. position, position)',
          position: 'left',
        },
      },
    },
  };
};

export default getPlaceholderLine;
