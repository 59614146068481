import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardDynamicViewSettingsController extends Controller {
  @service router;

  @controller dashboard;

  get urlGroups() {
    return this.get('dashboard.urlGroups').toArray();
  }

  @action
  afterDelete() {
    this.router.transitionTo('dashboard.overview');
  }

  @action
  onDynamicViewUpdate(dynamicView, oldGroup) {
    if (!oldGroup) return;

    oldGroup.reload();
    dynamicView.urlGroup.reload();
    this.dashboard.deactivateUrlGroup(oldGroup);
    this.dashboard.activateUrlGroup(dynamicView.urlGroup, true);
  }
}
