import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class PlansCancelController extends Controller {
  @service router;
  @service intercom;

  @action
  goToPlans() {
    this.router.transitionTo('plans');
  }

  @action
  openSupport() {
    this.router.transitionTo('plans');
    this.intercom.showNewMessage();
  }
}
