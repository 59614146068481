import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isPast } from 'date-fns';
import { hash } from 'rsvp';

export default class ResearchRoute extends Route {
  @service router;
  @service store;
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication();
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model);
  }
}
