import { helper } from '@ember/component/helper';
import { isDate, isValid, isSameDay } from 'date-fns';

export const dateIsToday = ([date]) => {
  if (!date || !isDate(date) || !isValid(date)) return false;

  return isSameDay(date, new Date());
};

export default helper(dateIsToday);
