import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PlansRoute extends Route {
  @service session;
  @service metrics;
  @service store;

  beforeModel() {
    this.session.requireAuthentication();
    this.session.user?.reload();
  }

  @action
  willTransition() {
    this.session.user?.rollbackAttributes();
    return true;
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Pricing Plans', source: 'app' });
    return true;
  }
}
