import Service, { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';
import underscorifyKeys from 'nightwatch-web/utils/underscorify-keys';

export default class IntercomService extends Service {
  @service session;
  @service metrics;
  @service siteData;

  get appId() {
    return ENV.intercom.appId;
  }

  get enabled() {
    return (
      !this.session.isAdminViewingUser && !this.siteData.hideNightwatchBranding
    );
  }

  showMessages() {
    window?.Intercom?.('showMessages');
  }

  showNewMessage() {
    window?.Intercom?.('showNewMessage');
  }

  showNewMessageWithPrepopulatedMessage(message) {
    window?.Intercom?.('showNewMessage', message);
  }

  shutdown() {
    window?.Intercom?.('shutdown');
  }

  afterLogin(userMetrics) {
    if (!this.enabled) return;

    this.activateMetricsAdapter();
    this.metrics.identify('Intercom', {
      ...underscorifyKeys(userMetrics),
      created_at: userMetrics.createdAtUnix,
      company: {
        company_id: userMetrics.accountId,
        name: userMetrics.name,
        plan: userMetrics.planName,
      },
    });

    if (
      !this.session.user?.email ||
      this.siteData.isWhiteLabel ||
      this.siteData.hideNightwatchBranding
    ) {
      return;
    }
  }

  activateMetricsAdapter() {
    const { appId } = this;
    if (!appId) return;

    this.metrics.activateAdapters([
      ...ENV.metricsAdapters,
      {
        name: 'Intercom',
        config: { appId },
      },
    ]);
  }
}
