import Controller from '@ember/controller';
import { action } from '@ember/object';
import refreshRoute from 'nightwatch-web/utils/refresh-route';

export default class SettingsNotesController extends Controller {
  @action
  refreshRoute() {
    refreshRoute(this, 'settings.notes');
  }
}
