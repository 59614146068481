import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { isBlank } from '@ember/utils';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import shakeElement from '../utils/dom/shake-element';
import localStorage from 'ember-local-storage-decorator';

export default class DashboardController extends Controller {
  @service router;
  @service generalSearch;
  @service metrics;
  @service session;
  @service dashboardState;
  @service store;

  @tracked showUrlSelector = false;
  @tracked selectedUrl = null;

  activatedGroupIds = A();
  @tracked deleteGroupModalShown = false;
  @tracked groupModalShown = false;
  isSearch = false;
  inaccurateRankingsNotificationShown = false;
  @tracked groupForModal = null;
  @tracked groupForDeleteModal = null;
  urlGroups = null;

  @localStorage('nw:show-sidebar') showSidebar = true;

  @computed('router.currentURL', 'session.user.urlsUsed')
  get showNoUrlsScreen() {
    return (
      this.session?.user?.urlsUsed === 0 &&
      this.get('router.currentURL').indexOf('urls/new') === -1
    );
  }

  @computed('isSearch', 'urlGroups.[]', 'generalSearch.results.url_groups.[]')
  get filteredUrlGroups() {
    if (this.isSearch && !isBlank(this.get('generalSearch.searchGroupIds'))) {
      return this.urlGroups.filter((urlGroup) => {
        return this.get('generalSearch.searchGroupIds')
          .map(String)
          .includes(String(urlGroup.id));
      });
    } else {
      return this.urlGroups;
    }
  }

  @computed(
    'isSearch',
    'activatedGroupIds.[]',
    'generalSearch.searchGroupIds.[]'
  )
  get openedGroupIds() {
    if (this.isSearch && !isBlank(this.get('generalSearch.searchGroupIds'))) {
      const searchGroupIdsWithNestedResult = this.get(
        'generalSearch.searchGroupIdsWithNestedResult'
      );
      this.loadGroupItems(searchGroupIdsWithNestedResult);
      return searchGroupIdsWithNestedResult.concat(this.activatedGroupIds);
    } else {
      return this.activatedGroupIds;
    }
  }

  @computed('urlGroups.[]', 'openedGroupIds.[]')
  get openedGroups() {
    if (!this.urlGroups) {
      return null;
    }
    return this.urlGroups.filter((urlGroup) => {
      return this.openedGroupIds.map(String).includes(String(urlGroup.id));
    });
  }

  loadGroupItems(urlGroupIds) {
    urlGroupIds.forEach((urlGroupId) => {
      const urlGroup = this.urlGroups.findBy('id', String(urlGroupId));
      urlGroup?.load.perform();
    });
  }

  // ensure correct group when transitioning to url/view in a non-full router transition (otherwise routes takes care of that)
  @action
  ensureSelectedGroup(groupId) {
    if (!groupId) return;
    if (String(this.dashboardState?.currentUrlGroup?.id) !== String(groupId)) {
      const groupToSwitch = this.store.peekRecord('urlGroup', groupId);
      if (!this.urlGroups) {
        this.set('urlGroups', [groupToSwitch]);
      } // coming from onboarding
      if (groupToSwitch) {
        this.activateUrlGroup(groupToSwitch);
      }
    }
  }

  @action
  showHideSidebar() {
    // this.showSidebar = !this.showSidebar;
  }

  @action
  showHideUrlSelector(boolean) {
    boolean && this.showUrlSelector
      ? (this.showUrlSelector = false)
      : (this.showUrlSelector = boolean);
  }

  @action
  hideInaccurateRankingsNotification() {
    this.set('inaccurateRankingsNotificationShown', false);
  }

  @action
  setSelectedUrl(url) {
    if (this.get('selectedUrl.id') === (url ? url.id : undefined)) {
      return;
    }

    if (url) {
      this.ensureSelectedGroup(url.get('urlGroup.id'));
      url.loadKeywordSuggestionCount();
      url.loadRelationships.perform();
    }

    this.set('selectedDynamicView', null);
    this.set('selectedUrl', url);
  }

  @action
  unsetSelectedUrl() {
    this.set('selectedDynamicView', null);
    this.set('selectedUrl', null);
  }

  @action
  setSelectedDynamicView(dynamicView) {
    if (this.get('selectedDynamicView.id') === dynamicView?.id) return;
    if (dynamicView?.get('urlGroup.id')) {
      this.ensureSelectedGroup(dynamicView.get('urlGroup.id'));
    }
    this.set('selectedUrl', null);
    this.set('selectedDynamicView', dynamicView);
  }

  @action
  activateUrlGroup(urlGroup, forceReloadItems = false) {
    this.dashboardState.currentUrlGroup = urlGroup;

    if (!forceReloadItems) {
      if (
        this.openedGroups.mapBy('id').map(String).includes(String(urlGroup?.id))
      ) {
        return;
      }
    }

    this.loadGroupItems([urlGroup?.id], forceReloadItems);
    this.activatedGroupIds.pushObject(String(urlGroup?.id));
    this.dashboardState.mostRecentlySelectedUrlGroupId = urlGroup?.id;
  }

  @action
  deactivateUrlGroup(urlGroup) {
    this.activatedGroupIds.removeObject(String(urlGroup.id));
  }

  @action
  toggleUrlGroup(urlGroup) {
    if (this.activatedGroupIds.includes(String(urlGroup.id))) {
      this.deactivateUrlGroup(urlGroup);
    } else {
      this.metrics.trackEvent({
        event: 'Open Group',
        name: urlGroup.get('name'),
      });
      this.activateUrlGroup(urlGroup);
    }
  }

  @action
  nullifySearch() {
    this.set('isSearch', false);
    this.set('searchQuery', '');
    return false;
  }

  @action
  onSearchChange(event) {
    const string = event.target.value;
    const isSearch = string && string.trim().length > 0;
    this.set('isSearch', isSearch);
    if (isSearch) {
      this.set('searchQuery', string);
      this.generalSearch.search(string);
    } else {
      this.set('searchQuery', '');
    }
  }

  @action
  toggleGroupModal(group) {
    if (!this.groupModalShown) {
      this.metrics.trackEvent({ event: 'Create Group' });
      this.groupForModal = group ?? this.store.createRecord('url-group');
    } else {
      if (this?.groupForModal?.hasDirtyAttributes)
        this.groupForModal.rollbackAttributes();
      this.groupForModal = null;
    }
    this.groupModalShown = !this.groupModalShown;
    return false;
  }

  @action
  saveGroup(group, ev) {
    return group
      .save()
      .then((group) => {
        this.groupModalShown = !this.groupModalShown;

        // hack to add an object to model array without reloading
        const urlGroupsArray = this.urlGroups.toArray();
        urlGroupsArray.addObjects(this.urlGroups);
        urlGroupsArray.addObject(group);
        this.set('urlGroups', urlGroupsArray);

        this.activateUrlGroup(group);
        this.metrics.trackEvent({
          event: 'Saved Group',
          group: group.get('name'),
        });
      })
      .catch(() => {
        shakeElement(ev.submitter);
      });
  }

  @action
  deleteGroup(group) {
    const groupId = group.id;
    group.destroyRecord().then(() => this.session.user?.reload());
    const urlGroupsArray = this.urlGroups.toArray();
    urlGroupsArray.removeObject(group);
    this.set('urlGroups', urlGroupsArray);
    this.notifications.success('Group deleted.', {
      autoClear: true,
      clearDuration: 3500,
    });

    this.deleteGroupModalShown = !this.deleteGroupModalShown;
    if (String(this.dashboardState?.currentUrlGroup?.id) === String(groupId)) {
      const groupToActivate = this.store
        .peekAll('url-group')
        .filter((g) => g.id !== group.id)
        .get('firstObject');
      if (groupToActivate) {
        this.activateUrlGroup(groupToActivate);
      }
      this.router.transitionTo('dashboard.overview');
    }

    return false;
  }

  @action
  toggleDeleteGroupModal(group) {
    if (!this.deleteGroupModalShown) {
      this.toggleGroupModal(null);
      this.groupForDeleteModal = group;
    } else {
      this.toggleGroupModal(this.groupForDeleteModal);
      this.groupForDeleteModal = null;
    }

    this.deleteGroupModalShown = !this.deleteGroupModalShown;
    return false;
  }
}
