import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import flattenPowerSelectOptions from 'nightwatch-web/utils/flatten-power-select-options';

export default class KeywordListComponent extends Component {
  @tracked selectedDropdownColumns = [];
  @tracked keywordSortColumn;
  @tracked keywordSortDirection;

  /**
   * Selected options recorded in the state can't be used to directly
   * apply the active states to power-select components because of a
   * different object structure. Here we build the correct selected
   * state objects from the original options used for dropdowns.
   */
  setSelectedDropdownColumns(selectedColumns) {
    const columnsForDropdown = flattenPowerSelectOptions(
      this.args.columnsForDropdown
    );
    this.selectedDropdownColumns = selectedColumns.map((selectedColumn) =>
      columnsForDropdown
        .filter((option) => option.id === selectedColumn.name)
        .shift()
    );
  }

  @action
  onInsert() {
    const selectedColumns = this.args.blockObject.selectedColumns;
    this.setSelectedDropdownColumns(selectedColumns);
    this.keywordSortDirection = this.args.blockObject.sort_direction;
    this.keywordSortColumn = this.args.blockObject.sort;
  }

  @action
  changeKeywordSort(block, name, direction) {
    this.keywordSortColumn = name;
    this.keywordSortDirection = direction;
    this.args.onSortChange(block, name, direction);
  }

  @action
  selectedColumnsChanged(columnIdx, newSelectedColumn) {
    const block = this.args.blockObject;
    const selectedColumns = get(block, 'selectedColumns');
    set(selectedColumns.objectAt(columnIdx), 'name', newSelectedColumn.id);
    this.setSelectedDropdownColumns(selectedColumns);
    this.args.onSelectedColumnsChanged(block, selectedColumns.mapBy('name'));
  }
}
