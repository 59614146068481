import { action } from '@ember/object';
import Controller from '@ember/controller';
import backlinkViewControllerMixin from 'nightwatch-web/mixins/backlink-view-controller';

export default class DashboardUrlBacklinkViewNewController extends Controller.extend(
  backlinkViewControllerMixin
) {
  statsDisabled = true;

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Create Backlink View' });
    return true;
  }
}
