import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsSubuserController extends Controller {
  @service router;

  @action
  transitionToUsers() {
    this.router.transitionTo('settings.subusers');
  }
}
