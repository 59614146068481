export const initialize = (application) => {
  application.inject('controller', 'userSettings', 'service:userSettings');
  application.inject('route', 'userSettings', 'service:userSettings');
  application.inject('component', 'userSettings', 'service:userSettings');
};

export default {
  name: 'user-settings',
  initialize,
};
