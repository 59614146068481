import Service, { inject as service } from '@ember/service';

const LOCAL_STORAGE_KEY = 'nw:anonymous-userdata';

export default class UserDataService extends Service {
  @service session;
  @service fetch;

  // Stores and retrieve data in localStorage using the local property
  get local() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  }
  set local(data) {
    if (!data) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return;
    }

    const existingData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const dataToSave = { ...existingData, ...data };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dataToSave));
  }

  store(planIdParam) {
    this.local = this.generate(planIdParam);
  }

  // Takes locally stored data and saves it to user_info
  async save() {
    if (!this.local) return;

    const { user } = this.session;

    const userInfo = user?.user_info ?? {};
    const combinedData = { ...userInfo, ...this.local };
    this.local = null;

    user.user_info = combinedData;

    await user.save();
    await this.fetch.post('/notify_new_user');
  }

  generate(planIdParam) {
    let data = {};

    // Add attempted plan_id if present in queryParams
    const planId = Number.parseInt(planIdParam, 10);
    if (!Number.isNaN(planId)) data.attempted_plan = planId;

    // Cookie "nw-user-info" matches the script to capture cookies
    // on landing page at www.nightwatch.io (and maybe other subdomains)
    const cookieData = document.cookie.replace(
      /(?:(?:^|.*;\s*)nw-user-info\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    if (cookieData) {
      try {
        const decodedCookieData = JSON.parse(decodeURIComponent(cookieData));
        data = { ...data, ...decodedCookieData };
        // To delete the cookie we need to specify the ".domain.tld" part of hostname
        const domain = window.location.hostname.split('.').slice(-2).join('.');
        document.cookie = `nw-user-info=;max-age=0;domain=.${domain}`;
      } catch {
        // Should fail silently
      }
    } else {
      // When no cookie, set landing page to app's URL
      data.landing = window.location.origin;
      // Also attempt to add referrer
      if (document.referrer) {
        data.referrer = document.referrer;
      }
    }
    return data;
  }
}
