import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { allSettled, task } from 'ember-concurrency';

export default class FetchTagsService extends Service {
  @service fetch;
  @service notifications;
  @tracked keywordTagCache = null;
  lastFetchedUrlId = null;

  @task({ restartable: true })
  *fetchKeywordTags(url, forceReload = false) {
    if (!url || (this.lastFetchedUrlId === url.id && forceReload === false))
      return;
    const tags = yield this.fetch.request('/keyword_tags', {
      data: { url_id: url.id },
    });
    this.keywordTagCache = [...tags.sortBy('name')];
    this.lastFetchedUrlId = url.id;
  }

  @task
  *saveModelTask(model) {
    try {
      yield model.save();
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Adds a tag to selected models and optionally saves the models.
   * This task will be dropped if called again before completion.
   *
   * @param {String} tagName - The tag to add to each model.
   * @param {Array} models - The models to which the tag will be added.
   * @param {Boolean} saveAfterAdd - Determines whether models should be saved after adding the tag.
   */
  @task({ drop: true })
  *addTagToSelectedModels(tagName, models, saveAfterAdd = true) {
    //Prepare an array to hold save tasks.
    let saveTasks = [];

    // Process each model individually to find duplicate tags.
    for (const model of models) {
      const tagIndex = model.tags.indexOf(tagName);

      // If the duplicate tag is found, send an error notification.
      if (tagIndex > -1) {
        this.sendErrorNotification(
          `Tag '${tagName}' already exists on ${
            model.query ? `'${model.query}'` : 'this keyword'
          }.`
        );
        // If the tag is not found, add it to the model.
      } else {
        model.tags.pushObject(tagName);
        if (saveAfterAdd) {
          saveTasks.push(this.saveModelTask.perform(model));
        }
      }
    }

    if (saveTasks.length > 0) {
      yield allSettled(saveTasks);
      this.sendSuccessNotification();
    }
  }

  @task({ drop: true })
  *removeTagFromSelectedModels(tagName, models, saveAfterAdd = true) {
    models.forEach((model) => {
      model.tags.removeObject(tagName);
    });
    if (!saveAfterAdd) return;
    const tasks = models?.map((model) => this.saveModelTask.perform(model));
    yield allSettled(tasks);
    this.sendSuccessNotification();
  }

  sendSuccessNotification() {
    this.notifications.success('Selected items updated successfully.', {
      autoClear: true,
      clearDuration: 3500,
    });
  }

  sendErrorNotification(error) {
    this.notifications.error(
      error ?? 'An error occurred while updating selected items.',
      {
        autoClear: true,
        clearDuration: 3500,
      }
    );
  }

  fetchBacklinkTags(url) {
    return this.fetch.request('/backlink_tags', { data: { url_id: url.id } });
  }
}
