import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SubuserList extends Component {
  @service router;
  @service fetch;
  @service notifications;

  @action
  deleteSubuser(subuser) {
    if (confirm('Are you sure you want to delete this user?')) {
      subuser.destroyRecord().catch((error) => {
        this.notifications.error(`Error: ${error.errors[0].detail}`, {
          autoClear: true,
          clearDuration: 10000,
        });
      });
    }
  }

  @action
  sendInviteEmail(subuser) {
    if (subuser.hasLoggedIn) return;
    const promise = this.fetch.post(
      `/subusers/${subuser.id}/send_invite_email`
    );
    promise.catch(() => {
      this.notifications.error(
        'An error occurred while sending invite email.',
        { autoClear: true, clearDuration: 3500 }
      );
    });
    return promise.then(() => {
      this.notifications.success('Email sent.', {
        autoClear: true,
        clearDuration: 3500,
      });
    });
  }

  @action
  edit(subuser) {
    window.scrollTo(0, 0);
    return this.router.transitionTo('settings.subusers.subuser', subuser.id);
  }
}
