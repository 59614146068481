import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CsvExportComponent extends Component {
  @tracked isGenerating = false;

  @action
  onStatusUpdate({ status }) {
    this.isGenerating = status === 'generate_start';
  }
}
