import Modifier from 'ember-modifier';
import { Chart } from 'chart.js';
import isTesting from 'nightwatch-web/utils/is-testing';

/**
  @class ChartJs

  Renders Chart.js charts

  @argument chartOptions {Object} Chart.js data and options
  @argument skipAnimation {Boolean} Skip animating the chart when updating
 */
export default class ChartJs extends Modifier {
  chart = null;

  didReceiveArguments() {
    if (this.chart) return this.updateChart();

    this.renderChart();
  }

  willRemove() {
    this.chart?.destroy();
  }

  renderChart() {
    const { chartOptions } = this.args.named;
    this.chart = new Chart(this.element, {
      ...chartOptions,
      options: {
        ...chartOptions.options,
        animation: isTesting ? false : chartOptions.options.animation,
      },
    });
  }

  updateChart() {
    const { chartOptions, skipAnimation } = this.args.named;
    this.chart.data = chartOptions.data;
    this.chart.options = chartOptions.options;
    this.chart.update(skipAnimation ? 'none' : null);
  }
}
