import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsWhitelabelRoute extends Route {
  @service router;
  @service session;
  @service store;

  beforeModel() {
    if (!this.session.user.agency) {
      this.router.transitionTo('dashboard.overview');
    }
  }

  model() {
    return hash({
      whitelabelDomain: this.store
        .findAll('whitelabel-domain')
        .then((whitelabelDomains) => {
          if (whitelabelDomains.get('length') > 0) {
            return whitelabelDomains.get('firstObject');
          } else {
            return this.store.createRecord('whitelabel-domain', {
              user: this.session.user,
            });
          }
        }),
      whitelabelLogos: this.store.findAll('whitelabel-logo'),
    });
  }

  afterModel(model) {
    if (!model.whitelabelDomain.get('isNew')) {
      const defaultWhitelabelLogoId =
        model.whitelabelDomain.get('whitelabelLogoId');
      const logo = model.whitelabelLogos.findBy(
        'id',
        String(defaultWhitelabelLogoId)
      );
      if (logo) {
        logo.set('default', true);
      }
    }
  }
}
