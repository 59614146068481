import { alias, mapBy } from '@ember/object/computed';
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { A } from '@ember/array';
import { debounce } from '@ember/runloop';
import EmberObject, { computed } from '@ember/object';
import filterGroupObject from 'nightwatch-web/utils/filters/filter-group-object';
import filterObject from 'nightwatch-web/utils/filters/filter-object';
import { v4 } from 'ember-uuid';
import isEqual from 'lodash-es/isEqual';
import flatten from 'lodash-es/flatten';

const CHANGE_SOURCES = {
  FILTER: 'filter',
  OPERATOR: 'operator',
  INPUT: 'value',
};
const filtersContainerClassName = 'dynamic-filters';
const filtersContainerRowClassName = 'or-row';

export default Component.extend({
  limit: 10,

  init() {
    this._super(...arguments);
    this.setProperties({ filtersObj: this.filtersObj || {} });
    this.addObserver('isSaveDisabled', this, 'actualizeSaveButton');
  },

  willDestroyElement() {
    this._super(...arguments);
    this.removeObserver('isSaveDisabled', this, 'actualizeSaveButton');
  },

  actualizeSaveButton() {
    this.set('resource.isSaveDisabled', this.isSaveDisabled);
  },

  setFocusToInput() {
    const filtersContainer = this.element?.querySelector(
      `.${filtersContainerClassName}`
    );
    if (!filtersContainer) return;
    const element = Array.from(
      filtersContainer.querySelectorAll(
        `.${filtersContainerRowClassName} input`
      )
    )[this.get('mostRecentChangeSource.inputIndex')];
    if (element) element.focus();
  },

  didReceiveAttrs() {
    this._super();
    const filtersObj = this.filtersObj;
    if (!filtersObj) return;
    if (isEqual(this.cachedFiltersObj, filtersObj)) return;
    // If there are filters in the query param, de-serialize them.
    const filtersJson =
      Object.keys(this.filtersObj).length > 0
        ? filtersObj
        : { filterGroups: [{ filters: [] }] };
    // Create filterConfig

    this.set('filterConfig', this.emberifyFilterConfig(filtersJson));
    // Are the filters empty? Add the first blank filter.
    const firstFilterGroup = this.get('filterConfig.filterGroups.firstObject');
    if (firstFilterGroup?.get('filters.length') === 0) {
      this.addNewFilter(firstFilterGroup);
    }
    if (this.get('mostRecentChangeSource.source') == CHANGE_SOURCES.INPUT) {
      debounce(this, 'setFocusToInput', 50);
    }
    this.set('cachedFiltersObj', filtersObj);
  },

  namePresent: computed('resource.name', function () {
    return isPresent(this.get('resource.name'));
  }),
  filterGroups: alias('filterConfig.filterGroups'),

  filterGroupFilters: mapBy('filterGroups', 'filters'),

  allFilters: computed('filterGroupFilters.@each.length', function () {
    return flatten(this.filterGroupFilters);
  }),
  allFiltersCount: alias('allFilters.length'),

  anyFilledFilters: computed('allFilters.[]', function () {
    return this.allFilters.some((f) => !f.get('isEmpty'));
  }),

  filtersValid: computed('allFilters.[]', function () {
    return this.allFilters.every((f) => f.get('isValid'));
  }),

  hasPresets: computed('filterPresets', function () {
    return isPresent(this.filterPresets);
  }),

  isLimitReached: computed('allFiltersCount', 'limit', function () {
    const { allFiltersCount, limit } = this;
    return isPresent(limit) && allFiltersCount >= limit;
  }),
  isAddingDisabled: alias('isLimitReached'),
  showWarning: alias('isLimitReached'),

  isSaveDisabled: computed(
    'allFiltersCount',
    'limit',
    'filtersValid',
    'anyFilledFilters',
    function () {
      const { allFiltersCount, limit, filtersValid, anyFilledFilters } = this;
      return (
        (isPresent(limit) && allFiltersCount > limit) ||
        !(filtersValid && anyFilledFilters));
    }
  ),

  warningMessage: computed('allFiltersCount', 'limit', function () {
    const { allFiltersCount, limit } = this;

    if (allFiltersCount === limit) {
      return `You can have a maximum of ${limit} filters.`;
    }

    return `You can only have ${limit} filters. Changes to views with more than ${limit} filters can not be saved.`;
  }),

  saveButtonEnabled: computed(
    'namePresent',
    'filtersValid',
    'anyFilledFilters',
    'filterConfig',
    function () {
      return this.namePresent && this.filtersValid && this.anyFilledFilters;
    }
  ),

  // Convert JSON to Ember structure to so that the template is reactive to it.
  emberifyFilterConfig(filterConfig) {
    const obj = EmberObject.create({ filterGroups: A() });
    (filterConfig.filterGroups || []).forEach((fg) => {
      const fgObject = filterGroupObject.create({
        id: v4(),
        filterConfig: obj,
        filters: A(),
      });
      obj.get('filterGroups').pushObject(fgObject);
      (fg.filters || []).forEach((f) => {
        const params = {
          id: v4(),
          availableFilters: this.availableFilters,
          filterGroup: fgObject,
        };
        const fObject = filterObject.create(Object.assign(params, f));
        fgObject.get('filters').pushObject(fObject);
      });
    });
    return obj;
  },

  // De-emberize to use for querying.
  serializeFilterConfig(filterConfig) {
    const jsonConfig = { filterGroups: [] };
    if (!filterConfig) return jsonConfig;
    (filterConfig.get('filterGroups') || []).forEach((fg) => {
      const filters = (fg.get('filters') || [])
        .map((f) =>
          f.get('isValid')
            ? f.getProperties(['name', 'operator', 'value'])
            : false
        )
        .filter((f) => f);
      jsonConfig.filterGroups.push({ filters: filters });
    });
    return jsonConfig;
  },

  filtersDidChange(source) {
    this.set('mostRecentChangeSource', source);
    this.notifyPropertyChange('filterConfig');
    const filtersJson = this.serializeFilterConfig(this.filterConfig);
    this.setFilters?.perform
      ? this.setFilters.perform(filtersJson)
      : this.setFilters(filtersJson);
    this.setFilterConfig(this.filterConfig);
  },

  addNewFilter: action(function (filterGroup) {
    if (this.isAddingDisabled) return;
    if (!filterGroup) return;
    const params = {
      id: v4(),
      availableFilters: this.availableFilters,
      filterGroup: filterGroup,
    };
    const fObject = filterObject.create(params);
    filterGroup.get('filters').pushObject(fObject);
  }),
  addNewFilterGroup: action(function () {
    if (this.isAddingDisabled) return;
    const filterConfig = this.filterConfig;
    if (!filterConfig) return;
    const fgObject = filterGroupObject.create({
      id: v4(),
      filters: A(),
      filterConfig: filterConfig,
    });
    this.addNewFilter(fgObject);
    filterConfig.get('filterGroups').pushObject(fgObject);
  }),
  removeFilter: action(function (filterGroup, filter) {
    if (!filterGroup) return;
    // If it's the only filter, nullify it instead of removing it.
    if (filterGroup.get('filterConfig.filterGroups.length') === 1) {
      if (filterGroup.get('filters.length') === 1) {
        filter.nullify();
        this.filtersDidChange();
        return;
      }
    }
    filterGroup.get('filters').removeObject(filter);
    // If filter group is now empty, remove it from the filter config.
    if (filterGroup.get('filters.length') === 0) {
      filterGroup.get('filterConfig.filterGroups').removeObject(filterGroup);
    }
    this.filtersDidChange();
  }),
  onSelectedOperatorChange: action(function (filter, operatorName) {
    filter.set('operator', operatorName);
    if (filter.get('isPresenceOperator') || isPresent(filter.get('value'))) {
      this.filtersDidChange({ source: CHANGE_SOURCES.OPERATOR });
    }
  }),
  onSelectedFilterChange: action(function (filter, filterName) {
    // When new filter is set, nullify the input
    filter.setProperties({
      name: filterName,
      hasCurrentFocus: true,
    });
    const operator = filter.get('availableOperators.firstObject.name');
    const value = filter.get('predefinedValues.firstObject');
    filter.setProperties({ operator: operator, value: value });
    if (value) {
      this.filtersDidChange({ source: CHANGE_SOURCES.FILTER });
    }
  }),
  onValueChange: action(function (filter, valueOrEvent) {
    let value, event;
    const isFromInput = valueOrEvent.constructor === InputEvent;
    if (isFromInput) {
      event = valueOrEvent;
      value = valueOrEvent.currentTarget.value;
    } else {
      value = valueOrEvent;
    }
    filter.setProperties({ value: value });
    if (filter.get('isValid')) {
      // On every value change filters update and all inputs are rerendered.
      // This is causing the input to lose focus. With this trick we save
      // the position of the currently focused input so we can re-focus it
      // after new filters are set.
      let inputIndex;
      if (isFromInput) {
        const dynamicFiltersContainer = event.srcElement.closest(
          `.${filtersContainerClassName}`
        );
        if (dynamicFiltersContainer) {
          inputIndex = Array.from(
            dynamicFiltersContainer.querySelectorAll(
              `.${filtersContainerRowClassName} input`
            )
          ).indexOf(event.srcElement);
        }
      }
      debounce(
        this,
        'filtersDidChange',
        {
          source: CHANGE_SOURCES.INPUT,
          event,
          inputIndex,
        },
        150
      );
    }
  }),
});
