import Modifier from 'ember-modifier';
import { Chart } from 'chart.js';
import isTesting from 'nightwatch-web/utils/is-testing';
import getPlaceholderLine from '../utils/chart/get-placeholder-line';

/**
 * @class ChartJsPlaceholder
 *
 * Renders Chart.js placeholder chart with animation
 */
export default class ChartJsPlaceholder extends Modifier {
  chart = null;

  modify(element, _, { hideAxisLabels }) {
    if (this.chart) {
      this.chart?.destroy();
    }

    this.renderChart(element, hideAxisLabels);
  }

  renderChart(element, hideAxisLabels) {
    const chartOptions = getPlaceholderLine(hideAxisLabels);
    this.chart = new Chart(element, {
      ...chartOptions,
      options: {
        ...chartOptions.options,
        animation: isTesting ? false : chartOptions.options.animation,
      },
    });
  }
}
