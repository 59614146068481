import Route from '@ember/routing/route';
import isTesting from 'nightwatch-web/utils/is-testing';
import { inject as service } from '@ember/service';

export default class DashboardUrlSettingsBacklinksRoute extends Route {
  @service session;
  @service notifications;
  @service router;

  beforeModel() {
    if (this.session.user.backlinksVisible) return;

    this.router.transitionTo('dashboard.url.settings.configuration');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    const url = model;
    const ignoredDomains = controller.ignored_domains;

    if (ignoredDomains && ignoredDomains.length > 0) {
      const existingIgnoredDomains =
        url.get('backlinksSettings.ignored_domains.content') || [];
      const newIgnoredDomains = existingIgnoredDomains
        .concat(ignoredDomains)
        .uniq()
        .compact();

      this.notifications.info(
        'Selected domains were added to the input below. Click on save button for new settings to take effect.',
        {
          autoClear: true,
          clearDuration: isTesting ? 0 : 5000,
        }
      );
      url.set('backlinksSettings.ignored_domains', newIgnoredDomains);
    }
  }

  resetController(controller) {
    controller.setProperties({
      ignored_domains: [],
    });
  }
}
