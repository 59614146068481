import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordsNewController extends Controller {
  @service router;
  @service session;

  @action
  onKeywordsAdd() {
    this.session.user.reload();
    this.url.reload(); // Refresh keyword_count
    this.router.transitionTo('dashboard.url.keywords', this.url, {
      queryParams: { page: 1, direction: 'desc', sort: 'created_at' },
    });
  }
}
