import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlIndexRoute extends Route {
  @service router;

  afterModel(model, transition) {
    if (transition.targetName === 'dashboard.url.index') {
      this.router.transitionTo('dashboard.url.dashboard', model);
    }
  }
}
