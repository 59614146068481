import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class DashboardUrlSiteAuditAllIndexRoute extends Route {
  @service router;
  @service('persistence/site-audit-settings') siteAuditSettings;

  activate() {
    window.scrollTo(0, 0);
  }
}
