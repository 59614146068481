import { helper } from '@ember/component/helper';
import { parse } from 'date-fns';

export const parseDate = ([
  dateString,
  formatString,
  referenceDate = new Date(),
  options = {},
]) => {
  return parse(dateString, formatString, referenceDate, options);
};

export default helper(parseDate);
