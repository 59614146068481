import { isPresent, isBlank } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import siteData from '../constants/site-data';
import { tracked } from '@glimmer/tracking';

export default class SiteDataService extends Service {
  @service fetch;
  @service session;
  @service intercom;
  @service metrics;

  timezones = siteData.timezones;
  countries = siteData.countries;
  languages = siteData.languages;
  countryLanguages = siteData.countryLanguages;
  countriesExtended = siteData.countriesExtended;

  init() {
    super.init(...arguments);
    this.siteTitle = 'Nightwatch';
    this.siteLogoUrl = '/assets/images/trimmed-owl-logo.svg';
  }

  @tracked _siteTitle;
  get siteTitle() {
    return this._siteTitle;
  }
  set siteTitle(value) {
    if (isBlank(value)) return;
    this._siteTitle = value;
    this.updateFavicon();
    this.updateLiveChat();
  }

  @tracked _siteLogoUrl;
  get siteLogoUrl() {
    return this._siteLogoUrl;
  }
  set siteLogoUrl(value) {
    if (isBlank(value)) return;
    this._siteLogoUrl = value;
  }

  get isWhiteLabel() {
    return isPresent(this.siteTitle) && this.siteTitle !== 'Nightwatch';
  }

  get hideNightwatchBranding() {
    if (this.session?.user?.isOwner) return false;
    return this.isWhiteLabel;
  }

  afterLoadUser(user) {
    if (!user?.id) return;

    this.siteLogoUrl = user.site?.whitelabel_logo_url;
    this.siteTitle = user.site?.whitelabel_title;
  }

  // for cases when we cant get this info from session.user (login, signup,...)
  async load() {
    const data = await this.fetch.request('/site_data/site_data');
    this.siteLogoUrl = data.whitelabel_logo_url;
    this.siteTitle = data.whitelabel_title;
    return data;
  }

  updateLiveChat() {
    if (
      (this.isWhiteLabel && this.session.user?.isLimited) ||
      isBlank(this.session.user?.id)
    ) {
      this.intercom.shutdown();
    }
  }

  updateFavicon() {
    const link = document.querySelector("link[rel*='icon']");
    if (!link) return;
    link.href = this.isWhiteLabel
      ? '/assets/icons/favicon-whitelabel.ico'
      : '/assets/icons/favicon.ico';
  }
}
