import Component from '@glimmer/component';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { debounce, next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class NwKeywordTableRowTranslationComponent extends Component {
  @service notifications;

  @tracked showInput = false;
  @tracked hasChanged = false;

  @action
  handleInput(event) {
    const value = event.target.value;
    this.args.keyword.translation = value;
    this.hasChanged = true;

    // Debounce the save operation
    debounce(this, this.saveKeyword, this.args.keyword, 500);
  }

  @action
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveKeyword(this.args.keyword);
    }
  }

  @action
  toggleShowInput() {
    this.args.keyword.translation ||= '';
    this.showInput = true;
    this.hasChanged = false;
  }

  @action
  handleBlur() {
    next(this, () => {
      this.showInput = false;

      // Avoid saving if there were no changes
      if (!this.hasChanged) {
        return;
      }

      // Handle the case when translation is cleared
      if (!this.args.keyword.translation?.trim()) {
        this.args.keyword.translation = null;
        this.saveKeyword(this.args.keyword);
      }
    });
  }

  saveKeyword(keyword) {
    this.saveTranslation.perform(keyword);
  }

  @task({ drop: true })
  *saveTranslation(keyword) {
    try {
      yield keyword.save();
      this.notifications.success('Translation saved successfully.', {
        autoClear: true,
      });
    } catch (error) {
      console.error('Error saving translation:', error);
    }
  }
}
