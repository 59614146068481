import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsSubusersSubuserRoute extends Route {
  @service store;

  model(params) {
    return hash({
      subuser: this.store.find('subuser', params.subuser_id),
      urlGroups: this.store.query('url-group', {
        static: true,
        include_default: false,
      }),
    });
  }

  @action
  willTransition() {
    this.controller.model.subuser?.rollbackAttributes();
  }
}
