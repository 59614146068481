import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NwPlansComponent extends Component {
  @service router;
  @service stripe;
  @service session;

  constructor(owner, args) {
    super(owner, args);
    window.didNotDoTrial = this.session.user.didNotDoTrial;
    // Listens for events emitted from the iframe when a plan button is clicked.
    window.onmessage = (event) => {
      if (
        typeof event.data === 'string' &&
        event.data?.match('plan-redirect')
      ) {
        const planId = event.data?.split('plan-redirect-')?.[1];
        const email = this.session.user.email;
        const noTrial = !this.session.user.didNotDoTrial;
        if (this.session.user.stripeCustomer) {
          // If the user's plan is cancelled or expired or during trial, take them to the checkout for a new plan
          if (
            this.session.user.isExpired ||
            this.session.user.isCancelled ||
            this.session.user.isTrial
          ) {
            this.stripe.redirectToCheckout.perform({
              planId,
              email,
              noTrial,
            });
          } else {
            // If the user has a plan, take them to the billing portal where they can upgrade.
            this.stripe.redirectToBillingPortal.perform();
          }
        } else {
          this.router.transitionTo('plan', planId);
        }
      }
    };
  }
}
