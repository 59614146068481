import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsSubusersRoute extends Route {
  @service router;
  @service session;
  @service store;

  beforeModel() {
    if (!this.session.user.agency) {
      this.router.transitionTo('dashboard.overview');
    }
  }

  model() {
    return this.store.findAll('subuser');
  }
}
