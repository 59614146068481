import Component from '@glimmer/component';
import { rawTimeout, task } from 'ember-concurrency';
import { action } from '@ember/object';
import { format, sub, add, startOfDay, parseISO } from 'date-fns';

export default class NwDatepickerComponent extends Component {
  datePickerElement;

  get invalidDates() {
    return (
      !this.datePickerElement?.value ||
      new Date(this.datePickerElement?.value) > new Date(this.args.maxDate) ||
      new Date(this.datePickerElement?.value) < new Date(this.args.minDate) ||
      new Date(this.datePickerElement?.value) <
        sub(new Date(this.maxDate), { years: 8 }) ||
      new Date(this.datePickerElement?.value) >
        add(new Date(this.maxDate), { years: 8 })
    );
  }

  @action
  onInsert(element) {
    this.datePickerElement = element;
    this.updateDatePickerValues();
  }

  updateDatePickerValues() {
    this.setMinDate();
    this.setMaxDate();
    if (!this.args.date) return;
    try {
      this.datePickerElement.value = format(
        new Date(this.args.date),
        'yyyy-MM-dd'
      );
    } catch (e) {
      console.error(e);
    }
  }

  @task({ restartable: true })
  *onChange(date, typing = false) {
    yield rawTimeout(typing ? 1000 : 500);
    if (this.invalidDates) return;
    this.args.onChange(startOfDay(parseISO(date)));
  }

  setMinDate() {
    if (!this.args.minDate) return;
    this.datePickerElement.min = format(
      new Date(this.args.minDate),
      'yyyy-MM-dd'
    );
  }

  setMaxDate() {
    if (!this.args.maxDate && !this.args.maxDateToday) return;
    const maxDate = this.args.maxDateToday
      ? new Date()
      : new Date(this.args.maxDate);
    this.datePickerElement.max = format(maxDate, 'yyyy-MM-dd');
  }
}
