import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class NotificationsRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
  };

  beforeModel() {
    this.session.requireAuthentication();
  }

  model(params) {
    return this.store.query('user-notification', {
      limit: 25,
      page: params.page || 1,
    });
  }

  @action
  loading() {
    return false; //Do not show loading spinner when clicking on pages
  }
}
