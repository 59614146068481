import Route from '@ember/routing/route';

export default class DashboardUrlDashboardRoute extends Route {
  activate() {
    window.scrollTo(0, 0);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    // Fetch only when there is no stats present
    controller.fetchStats();
  }
}
