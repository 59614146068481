import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

import { AVAILABLE_FILTERS } from 'nightwatch-web/utils/site-audit-properties';

export default class DashboardUrlSiteAuditViewsNewController extends Controller {
  @service router;
  @service fetch;

  queryParams = ['page', 'sort', 'direction', 'params', 'search', 'filters'];
  page = 1;
  limit = 50;
  sort = 'created_at';
  direction = 'desc';
  search = null;
  isLoading = false;
  filters = null;
  availableFilters = AVAILABLE_FILTERS;

  get view() {
    return this.model.view;
  }

  get pages() {
    return this.model.pages;
  }

  get url() {
    return this.model.url;
  }

  get filtersObj() {
    if (!this.filters) {
      return {};
    }

    try {
      return JSON.parse(decodeURIComponent(this.filters));
    } catch (err) {
      return {};
    }
  }

  @action
  setFilters(filters) {
    if (
      filters?.filterGroups?.length === 1 &&
      filters?.filterGroups?.firstObject?.filters?.firstObject?.name === null
    ) {
      this.setProperties({ filters: null, page: 1, emptyFilters: true });
    } else {
      this.setProperties({ page: 1 });
      const urlSafeFilters = encodeURI(JSON.stringify(filters));
      this.setProperties({ filters: urlSafeFilters, emptyFilters: false });
    }
  }

  @action
  setFilterConfig(filterConfig) {
    this.set('filterConfig', filterConfig);
  }

  @action
  saveResource(siteAuditView) {
    siteAuditView.set('filterConfig', {
      filter_groups: this.get('filtersObj.filterGroups'),
    });
    const promise = siteAuditView.save();
    promise.then(
      (siteAuditView) => {
        this.notifications.success('Site audit view saved successfully', {
          autoClear: true,
        });
        this.router.transitionTo(
          'dashboard.url.site-audit.view.show',
          siteAuditView.id
        );
        siteAuditView.loadCount();
      },
      () => {
        this.notifications.error(
          'There was an error while saving the site audit view',
          { autoClear: true }
        );
      }
    );
  }
}
