import { helper } from '@ember/component/helper';
import { formatDuration, intervalToDuration } from 'date-fns';

export function duration([
  start = new Date(),
  end = new Date(),
  formatString,
  shortFormSeconds = false,
  formatTime = false,
]) {
  const duration = intervalToDuration({ start, end });
  if (shortFormSeconds) return `${duration.seconds}s`;
  if (formatTime) {
    return formatDuration(duration, formatString);
  }

  const format = formatString
    ? formatString.split(',')
    : ['hours', 'minutes', 'seconds'];

  return formatDuration(duration, { format });
}

export default helper(duration);
