import ApplicationSerializer from './application';

export default class DynamicViewSerializer extends ApplicationSerializer {
  normalize(modelClass, resourceHash, prop) {
    return super.normalize(
      modelClass,
      {
        ...resourceHash,
        url_id: resourceHash.search_keyword_url_id,
        search_keyword_url_id: undefined,
      },
      prop
    );
  }

  serializeBelongsTo(snapshot, json, relationship) {
    let { key } = relationship;
    const belongsTo = snapshot.belongsTo(key);
    if (key === 'url') {
      if (belongsTo) {
        return (json['search_keyword_url_id'] = belongsTo.id);
      }
    } else {
      key = this.keyForRelationship(relationship.key, 'belongsTo', 'serialize');
      if (belongsTo) {
        return (json[key] = belongsTo.id);
      }
    }
  }
}
