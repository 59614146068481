export const initialize = (application) => {
  application.inject('controller', 'siteData', 'service:siteData');
  application.inject('route', 'siteData', 'service:siteData');
  application.inject('component', 'siteData', 'service:siteData');
};

export default {
  name: 'site-data',
  initialize,
};
