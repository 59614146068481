import Service, { inject as service } from '@ember/service';

export default class LocatorService extends Service {
  @service fetch;

  getCountry() {
    const url = `https://freegeoip.net/json/`;
    return this.fetch.request(url);
  }
}
