import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import { action, computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';
import xor from 'lodash-es/xor';
import isEmpty from 'lodash-es/isEmpty';
import { format } from 'date-fns';
import { sub } from 'date-fns';
import refreshRoute from 'nightwatch-web/utils/refresh-route';
import isTesting from 'nightwatch-web/utils/is-testing';
import { tracked } from '@glimmer/tracking';

export default Mixin.create({
  fetch: service(),
  session: service(),
  metrics: service(),
  router: service(),
  userData: service(),
  backlinksNotifications: service('notifications/backlinks-notifications'),
  queryParams: [
    'page',
    'sort',
    'direction',
    'params',
    'search',
    'filters',
    'one_link_per_domain',
    'root_domain',
    'domain',
    'limit',
  ],
  page: 1,
  limit: 50,
  sort: 'first_seen',
  direction: 'desc',
  emptyFilters: false,
  search: null,
  filters: null,
  one_link_per_domain: false,
  domain: null,
  root_domain: null,
  backlinks: alias('model.backlinks'),
  backlinkView: alias('model.backlinkView'),
  stats: tracked(),
  url: alias('model.url'),
  statsDateRangeType: 'day',
  statsDateFrom: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd'),
  statsDateTo: format(new Date(), 'yyyy-MM-dd'),
  csvExportUrl: computed(
    'url',
    'filters',
    'search',
    'one_link_per_domain',
    function () {
      const token = this.session.token;
      const urlId = this.url.id;
      const filters = this.filters || '';
      const search = this.search || '';
      const oneLinkPerDomain = this.one_link_per_domain || '';
      return `${ENV.apiBaseURL}backlinks/export_csv?access_token=${token}&url_id=${urlId}&filters=${filters}&search=${search}&one_link_per_domain=${oneLinkPerDomain}`;
    }
  ),
  isSearch: computed('search', 'filters', function () {
    return isPresent(this.search) || isPresent(this.filters);
  }),
  limitReached: computed('session.user.backlinksRemaining', function () {
    return this.get('session.user.backlinksRemaining') <= 0;
  }),
  selectedBacklinks: A(),
  allBacklinksSelected: computed('selectedBacklinks.[]', function () {
    if (!this.backlinks) return false;
    return isEmpty(
      xor(this.selectedBacklinks.mapBy('id'), this.backlinks.mapBy('id'))
    );
  }),
  someBacklinksSelected: computed('selectedBacklinks.[]', function () {
    return this.get('selectedBacklinks.length') > 0;
  }),
  filtersObj: computed('filters', function () {
    if (!this.filters) return {};
    try {
      return JSON.parse(decodeURI(this.filters));
    } catch (err) {
      return {};
    }
  }),

  searchBacklinks(searchTerm) {
    this.setProperties({
      page: 1,
      search: searchTerm,
    });
  },

  fetchStats() {
    if (this.statsDisabled) return;
    const dateFrom = this.statsDateFrom;
    const dateTo = this.statsDateTo;
    const urlId = this.get('model.url.id');
    const filters = this.filters;
    const groupByDomain = this.one_link_per_domain;
    this.set('statsLoading', true);
    this.fetch
      .request('/backlink_stats', {
        data: {
          url_id: urlId,
          filters: filters,
          date_from: dateFrom,
          date_to: dateTo,
          group_by_domain: groupByDomain,
          for_overview: true,
          acc_id: this.userData.session?.user?.accountId,
        },
      })
      .then((stats) => (this.stats = stats))
      .finally(() => {
        this.set('statsLoading', false);
      });
  },

  updateBacklinkViewConfig(props) {
    let obj = this.get('backlinkView.filterConfig') || {};
    obj = Object.assign(obj, props);
    this.set('backlinkView.filterConfig', obj);
  },

  setDateRange: action(function ({ type, start, end }) {
    const formattedDateFrom = format(start, 'yyyy-MM-dd');
    const formattedDateTo = format(end, 'yyyy-MM-dd');
    this.set('statsDateRangeType', type);
    this.set('statsDateFrom', formattedDateFrom);
    this.set('statsDateTo', formattedDateTo);
    this.fetchStats();
  }),

  addBacklinksToIgnoreList: action(function (backlinks) {
    if (backlinks.toArray) {
      backlinks = backlinks.toArray();
    } else {
      backlinks = [backlinks];
    }
    const url = this.get('model.url');
    const ignoreDomains = backlinks.mapBy('domain');
    this.router.transitionTo('dashboard.url.settings.backlinks', url, {
      queryParams: { ignored_domains: ignoreDomains },
    });
  }),

  deleteBacklinks: action(function (backlinks) {
    if (backlinks.toArray) {
      backlinks = backlinks.toArray();
    } else {
      backlinks = [backlinks];
    }

    const backlinksDisplay = backlinks.length === 1 ? 'backlink' : 'backlinks';
    if (
      !confirm(
        `Are you sure you want to delete ${backlinks.length} ${backlinksDisplay}?`
      )
    ) {
      return;
    }
    const url = this.get('model.url');
    const backlinkIds = backlinks.map((b) => b.id);
    const data = { backlink_ids: backlinkIds, url_id: url.id };
    const promise = this.fetch.post('/backlinks/bulk_destroy', { data });
    const refreshRouteName = this.router.currentRouteName;

    promise.then(() => {
      this.notifications.success(`Selected ${backlinksDisplay} deleted.`, {
        autoClear: true,
        clearDuration: isTesting ? 0 : 3500,
      });
      refreshRoute(this, refreshRouteName);
      this.fetchStats();
      url.reload();
      this.session.user.reload();
    });
    promise.catch(() => {
      this.notifications.error(
        `An error occured while deleting selected backlinks.`,
        { autoClear: true, clearDuration: 3500 }
      );
    });
    return promise;
  }),

  deleteSelectedBacklinks: action(function () {
    this.deleteBacklinks(this.selectedBacklinks);
  }),

  addSelectedBacklinksToIgnoreList: action(function () {
    this.addBacklinksToIgnoreList(this.selectedBacklinks);
  }),

  saveResource: action(function (backlinkView) {
    const updateParams = Object.assign(
      { filter_groups: this.get('filtersObj.filterGroups') },
      {
        one_link_per_domain: this.one_link_per_domain,
      }
    );

    this.updateBacklinkViewConfig(updateParams);
    backlinkView.filtersSaving = true;
    const isNew = backlinkView.get('isNew');
    const promise = backlinkView.save();

    promise
      .then((backlinkView) => {
        backlinkView.filtersSaved = true;
        backlinkView.loadCount();
        if (isNew) {
          this.router.transitionTo(
            'dashboard.url.backlink-view',
            backlinkView.id
          );
        }
        this.metrics.trackEvent({
          event: 'Saved Backlink View',
          name: backlinkView.get('name'),
        });
        this.notifications.success('Backlink View saved successfully.', {
          autoClear: true,
          clearDuration: 4000,
        });
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while saving backlink view.',
          { autoClear: true, clearDuration: 4000 }
        );
      })
      .finally(() => {
        backlinkView.filtersSaving = false;
      });
  }),
  toggleOneLinkPerDomain: action(function () {
    this.toggleProperty('one_link_per_domain');
    this.fetchStats();
    this.set('page', 1);
  }),
  toggleFilters: action(function () {
    this.toggleProperty('filtersShown');
    localStorage.setItem(
      'nw:backlinks:filters-shown',
      JSON.stringify(this.filtersShown)
    );
  }),
  setFilterConfig: action(function (filterConfig) {
    this.set('filterConfig', filterConfig);
  }),
  setFilters: action(function (filters) {
    if (
      filters?.filterGroups?.length === 1 &&
      filters?.filterGroups?.firstObject?.filters?.firstObject?.name === null
    ) {
      this.setProperties({ filters: null, page: 1, emptyFilters: true });
    } else {
      const urlSafeFilters = encodeURI(JSON.stringify(filters));
      this.setProperties({ filters: urlSafeFilters, emptyFilters: false });
    }
    this.fetchStats();
  }),
  onSearchChange: action(function (searchTerm) {
    debounce(this, 'searchBacklinks', searchTerm, 150);
  }),
  onSelectAllToggleChange: action(function (selectAll) {
    if (selectAll) {
      this.set('selectedBacklinks', this.backlinks.toArray().concat());
    } else {
      this.set('selectedBacklinks', A());
    }
  }),
  toggleBacklinkSelected: action(function (backlink) {
    let selectedBacklinks = this.selectedBacklinks.concat();

    const addedBacklink = selectedBacklinks.findBy('id', backlink.id);

    if (addedBacklink) {
      selectedBacklinks.removeObject(addedBacklink);
    } else {
      selectedBacklinks.pushObject(backlink);
    }

    this.set('selectedBacklinks', selectedBacklinks);
  }),

  availableFilters: A([
    {
      name: 'url_from',
      displayName: 'URL',
      type: 'string',
    },
    {
      name: 'root_domain',
      displayName: 'Domain',
      type: 'string',
    },
    {
      name: 'url_rating',
      displayName: 'URL Rating',
      type: 'number',
    },
    {
      name: 'domain_rating',
      displayName: 'Domain Rating',
      type: 'number',
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'values',
      availableValues: ['active', 'not_present', 'link_dead'],
    },
    {
      name: 'rel',
      displayName: 'Rel',
      type: 'values',
      availableValues: ['follow', 'nofollow'],
    },
    {
      name: 'first_seen',
      displayName: 'First Seen',
      type: 'date',
    },
    {
      name: 'url_to',
      displayName: 'Landing Page',
      type: 'string',
    },
    {
      name: 'title',
      displayName: 'Page Title',
      type: 'string',
    },
    {
      name: 'anchor',
      displayName: 'Anchor Text',
      type: 'string',
    },
    {
      name: 'source',
      displayName: 'Source',
      type: 'values',
      availableValues: ['Auto-Discovery', 'Manual'],
    },
    {
      name: 'tags',
      displayName: 'Backlink tag',
      type: 'array',
    },
  ]),
});
