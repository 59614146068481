import { setOwner } from '@ember/application';
import { task } from 'ember-concurrency';
import underscorifyKeys from '../underscorify-keys';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DataRequester {
  @service fetch;

  @tracked data = [];

  endpoint;
  lastFingerprint;

  constructor(owner, endpoint) {
    setOwner(this, owner);
    this.endpoint = endpoint;
  }

  @task({ restartable: true })
  *fetchData(params) {
    if (params === null) return;

    const fingerprint = this.requestFingerprint(params);
    if (fingerprint === this.lastFingerprint) return;

    const abortController = new AbortController();

    let fetchedData;
    try {
      fetchedData = yield this.request(params, abortController);
    } finally {
      abortController.abort();
    }

    this.lastFingerprint = fingerprint;
    this.data = fetchedData;
  }

  requestFingerprint(params) {
    return JSON.stringify(params);
  }

  request(params, abortController) {
    return this.fetch.request(this.endpoint, {
      data: underscorifyKeys(params),
      signal: abortController.signal,
      contentType: false, // API throws errors if contentType is set
    });
  }

  reset() {
    this.fetchData.cancelAll();
    this.data = [];
    this.lastFingerprint = null;
  }
}
