import isTesting from 'nightwatch-web/utils/is-testing';
import loadExternalScripts from 'nightwatch-web/utils/load-external-scripts';

/**
  @function trackUnbounceConversion

  Unbounce runs paid ads and landing pages for us. This function needs to be
  called when a user has "converted" so that Unbounce can track conversions
  for the ads and landing pages.

  An Unbounce metrics adapter would be preferred, but the script is inflexible
 */
const trackUnbounceConversion = () => {
  if (isTesting) return;

  window._ubaq = [];
  window._ubaq.push(['trackGoal', 'convert']);

  const ubScriptSrc =
    (document.location.protocol === 'https:' ? 'https://' : 'http://') +
    'd3pkntwtp2ukl5.cloudfront.net/uba.js';

  loadExternalScripts([ubScriptSrc]);
};

export default trackUnbounceConversion;
