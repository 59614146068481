import ApplicationAdapter from './application';

export default class ResultAdapter extends ApplicationAdapter {
  buildURL(_modelName, _id, snapshot, _requestType, query) {
    const keywordId =
      query && query.keyword_id
        ? query.keyword_id
        : snapshot && snapshot.belongsTo
        ? snapshot.belongsTo('keyword')?.id
        : undefined;

    return super
      .buildURL(...arguments)
      .replace(/\/results/, `/keywords/${keywordId}/results`);
  }

  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    // some results may not have an ID and that's no good for Ember data
    if (Array.isArray(payload)) {
      payload = payload.map((result) => ({
        ...result,
        id: result.id || new Date(result.created_at).getTime(),
      }));
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
