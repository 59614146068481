const KEYWORD_DISTRIBUTION_PROPS = Object.freeze({
  name: 'keyword_distribution',
  title: 'Keyword Distribution',
  label: 'Keyword Distribution',
  type: 'nwBar',
  scaleId: 'nwStackedValue',
  icon: '/assets/icons/distribution-icon.png',
  // Required so that object series will be detected and rendered
  fillGapsValue: {
    no_rank: null,
    top_3: null,
    top_10: null,
    top_100: null,
  },
});

export default KEYWORD_DISTRIBUTION_PROPS;
