import ApplicationSerializer from './application';

const addHttpPrefix = (url) => {
  if (url && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
    return 'http://' + url;
  }
  return url;
};

const sanitizeDomain = (url) =>
  url ? url.replace('http://', '').replace('https://', '') : null;

const transformAttributes = (attrs) => {
  const competitorUrl = attrs.url;
  return {
    ...attrs,
    search_keyword_url_id: undefined,
    url: undefined,
    url_id: attrs.search_keyword_url_id,
    competitor_url: sanitizeDomain(competitorUrl),
  };
};

export default class CompetitorSerializer extends ApplicationSerializer {
  serializeIntoHash(data, _type, record, options) {
    const payload = super.serialize(record, options);
    payload.competitor_id = record.id;
    payload.url = addHttpPrefix(record.attr('competitor_url'));
    data['competitor'] = payload;
    return data;
  }

  normalizeArrayResponse(store, primaryType, payload) {
    payload = payload.map((c) => transformAttributes(c));
    return super.normalizeArrayResponse(store, primaryType, payload);
  }

  normalizeSingleResponse(store, primaryType, payload, recordId) {
    payload = transformAttributes(payload);
    return super.normalizeSingleResponse(store, primaryType, payload, recordId);
  }
}
