import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { THEMES } from '../services/theme';

export default class SignupFormRoute extends Route {
  @service theme;
  @service session;
  @service router;
  @service siteData;

  model(params, transition) {
    return {
      signupToken: params.token || transition.to.queryParams.token || null,
      isInvitation: window.location.pathname.startsWith('/redeem-invitation'),
    };
  }

  beforeModel() {
    this.session.prohibitAuthentication();
    if (
      this.siteData.isWhiteLabel &&
      !window.location.pathname.startsWith('/redeem-invitation')
    ) {
      this.router.transitionTo('signup');
    }
    this.theme.current = THEMES.BRIGHT;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('isWhiteLabel', this.siteData.isWhiteLabel);
  }
}
