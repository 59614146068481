import delay from '../../utils/delay';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import isTesting from 'nightwatch-web/utils/is-testing';
import { fadeIn, fadeOut } from '../../utils/dom/fade-element';

export default class LoaderOverlay extends Component {
  @tracked progressMessage = null;

  async startProgress() {
    const messages = this.args.messages;
    for (const message of messages) {
      if (messages.length === 1) await delay(300);
      this.progressMessage = message.msg;
      await delay(message.duration);
    }
  }

  @action
  async onInsert() {
    await fadeIn('#splash-overlay');
    await this.startProgress();
    await delay(isTesting ? 0 : 100);
    if (this.args.onProgressFinish) {
      this.args.onProgressFinish();
    }
    await fadeOut('#splash-overlay');
  }
}
