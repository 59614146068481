import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class NwChangelogProviderComponent extends Component {
  @service fetch;
  @tracked changelog;
  @tracked currentPage = 0;

  get maxDate() {
    // PRs before Thu Jan 28 2021 are less descriptive than the rest, so we cut off from there.
    return !!this.changelog?.find((e) => e.date === 'Thu Jan 28 2021');
  }

  @task({ drop: true })
  *getChangelog() {
    if (this.maxDate) return;
    this.currentPage = this.currentPage + 1;
    let controller = new AbortController();
    let signal = controller.signal;

    try {
      let request = yield fetch(
        `https://nw-github-changelog-generator.nightwatch.workers.dev/?page=${this.currentPage}`,
        { signal }
      );
      const response = yield request.json();
      if (this.changelog?.length > 0) {
        this.changelog.push(...response);
      } else {
        this.changelog = response;
      }
    } finally {
      controller.abort();
    }
  }
}
