import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class DashboardUrlRoute extends Route {
  @service router;
  @service userSettings;
  @service store;

  model(params) {
    return this.store.findRecord('url', params.url_id);
  }

  afterModel(model, transition) {
    this.controllerFor('dashboard').setSelectedUrl(model);

    return this.userSettings
      .loadData({ searchKeywordUrlId: model.id })
      .then(() => {
        if (transition.targetName === 'dashboard.url.index') {
          this.router.transitionTo('dashboard.url.keywords', model, {
            queryParams: { page: 1 },
          });
        }
      });
  }
}
