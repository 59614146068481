import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ChartSaveComponent extends Component {
  @service notifications;
  @service metrics;
  @service router;

  @tracked name;

  @action
  initName() {
    this.name = this.args.graph.name;
  }

  @task({ drop: true })
  *save() {
    const { graph } = this.args;

    graph.name = this.name;
    yield graph.save();

    this.afterSave();
  }

  afterSave() {
    const { graph } = this.args;
    this.router.transitionTo('dashboard.url.chart', graph);

    this.notifications.success('Graph saved.', {
      autoClear: true,
      clearDuration: 2500,
    });
    this.metrics.trackEvent({
      event: 'Saved Graph',
      name: graph.name,
      num_dimensions: graph.series.length,
    });
  }
}
