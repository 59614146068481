/**
 * Flatten multidimensional `power-select` options for easier processing
 */
const flattenPowerSelectOptions = (options) => {
  const flatOptions = [];
  options.forEach((option) => {
    if (Array.isArray(option.options)) {
      flatOptions.push(...flattenPowerSelectOptions(option.options));
    } else {
      flatOptions.push(option);
    }
  });
  return flatOptions;
};

export default flattenPowerSelectOptions;
