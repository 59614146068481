import { helper } from '@ember/component/helper';

/**
  @helper run

  Calls all passed functions syncronously.

  Alternative to pipe from ember-composable-helpers.

  Helps to avoid task cancellation errors which are reported
  when performing ember-concurrency tasks in a pipe.

  See: http://ember-concurrency.com/docs/task-cancelation-help
 */
export function run(fns = []) {
  return () => fns.forEach((fn) => fn());
}

export default helper(run);
