import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordsNewRoute extends Route {
  @service metrics;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    return this.store.createRecord('keyword');
  }

  setupController(controller, model) {
    // Unload previous unsaved keyword model
    if (controller.model?.isNew) {
      controller.model.unloadRecord();
    }
    super.setupController(controller, model);
    controller.set('url', this.modelFor('dashboard.url'));
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Add Keywords',
      url: this.modelFor('dashboard.url').get('url'),
    });
    return true;
  }
}
