import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { format } from 'date-fns';
import KeywordStatsResource from '../../../resources/keyword-stats';
import { getOwner } from '@ember/application';

export default class DashboardUrlDashboardController extends Controller {
  @service session;
  @service userData;
  @service('persistence/dashboard') dashboardPersistence;
  @service fetch;

  @tracked selectedDateRange;

  @tracked trafficStatsLoading = false;
  @tracked backlinkStatsLoading = false;
  @tracked urlStatsLoading = false;

  @tracked trafficStats = {};
  @tracked backlinkStats = {};

  keywordStats = new KeywordStatsResource({ owner: getOwner(this) });

  get url() {
    return this.model;
  }

  get googleAnalyticsConnected() {
    return (
      isPresent(this.url.google_analytics_profile_id) ||
      isPresent(this.url.google_analytics_property_id)
    );
  }

  get noKeywords() {
    return this.url && !this.url?.keyword_count;
  }

  get noData() {
    if (!this.keywordStats.data) {
      return false;
    }

    return (
      Object.values(this.keywordStats?.data)
        ?.filter((e) => typeof e === 'number')
        ?.reduce((a, b) => a + b, 0) === 0
    );
  }

  init() {
    super.init(...arguments);
    const { type, start, end } = this.dashboardPersistence.parseDateRange();
    this.selectedDateRange = { type, start, end };
  }

  get statsDateFrom() {
    return format(this.selectedDateRange.start, 'yyyy-MM-dd');
  }

  get statsDateTo() {
    return format(this.selectedDateRange.end, 'yyyy-MM-dd');
  }

  fetchStats() {
    this.fetchBacklinkStats();
    this.fetchTrafficStats();
    this.fetchKeywordStats();
    this.fetchUrlStats();
  }

  fetchTrafficStats() {
    const dateFrom = this.statsDateFrom;
    const dateTo = this.statsDateTo;
    const urlId = this.url.id;
    this.trafficStatsLoading = true;
    this.fetch
      .request('/traffic_stats', {
        data: {
          url_id: urlId,
          date_from: dateFrom,
          date_to: dateTo,
        },
      })
      .then((stats) => {
        this.trafficStats = stats;
      })
      .finally(() => {
        this.trafficStatsLoading = false;
      });
  }

  fetchKeywordStats() {
    const { url } = this;
    const { start, end } = this.selectedDateRange;
    this.keywordStats.load({ url, startDate: start, endDate: end });
  }

  fetchBacklinkStats() {
    const urlId = this.url.id;
    this.backlinkStatsLoading = true;
    this.fetch
      .request('/backlink_stats', {
        data: {
          url_id: urlId,
          date_from: this.statsDateFrom,
          date_to: this.statsDateTo,
          for_overview: true,
          acc_id: this.userData.session?.user?.accountId,
        },
      })
      .then((stats) => {
        this.backlinkStats = stats;
      })
      .finally(() => {
        this.backlinkStatsLoading = false;
      });
  }

  fetchUrlStats() {
    const dateFrom = this.statsDateFrom;
    const dateTo = this.statsDateTo;
    this.urlStatsLoading = true;
    this.url.loadStats(dateFrom, dateTo).finally(() => {
      this.urlStatsLoading = false;
    });
  }

  @action
  setDateRange({ start, end, type }) {
    this.dashboardPersistence.persistDateRange(type);
    this.selectedDateRange = { type, start, end };
    this.fetchStats();
  }
}
