export default (url) => {
  if (!url) {
    return null;
  }

  if (url.indexOf('.') === -1) {
    return null;
  }

  let hostname = '';
  // Find & remove protocol (schema) and get hostname

  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // Find & remove port number
  hostname = hostname.split(':')[0];
  // Find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname.split('.').reverse()[0]; // tld
};
