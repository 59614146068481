export default [
  '#edaf8e',
  '#b93550',
  '#945bef',
  '#ec7a46',
  '#0ec835',
  '#f09ed1',
  '#aaa066',
  '#00fcfc',
  '#32c45c',
  '#f1d1c8',
  '#3f914a',
  '#607e9a',
  '#d6ff00',
  '#0550c2',
  '#40bebc',
  '#728c95',
  '#ff6294',
  '#d22b2b',
  '#c1de76',
  '#54b8c5',
  '#8ab718',
  '#fc7c5f',
  '#e262ff',
  '#969696',
  '#abffe6',
];
