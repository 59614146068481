import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DashboardDynamicViewKeywordsController extends Controller {
  @service('persistence/keyword-list-sorting') keywordListSorting;

  queryParams = ['page', 'direction', 'sort'];

  @tracked dynamicView;

  @tracked page = 1;
  @tracked sort = 'created_at';
  @tracked direction = 'desc';

  enteredRoute(dynamicView) {
    this.keywordListSorting.applySorting(this, { viewId: dynamicView.id });
    this.dynamicView = dynamicView;
  }

  @action
  updateParams({ page, sort, direction }) {
    this.page = page ?? this.page;
    this.sort = sort ?? this.sort;
    this.direction = direction ?? this.direction;
  }
}
