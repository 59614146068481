import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class BacklinkRowComponent extends Component {
  @service session;

  get isSelected() {
    return this.args.selectedBacklinks.includes(this.args.backlink);
  }

  get isProcessing() {
    return this.args.backlink?.isProcessing;
  }

  get isLimited() {
    return this.session.user.isLimited;
  }

  get tagName() {
    if (this.isSelected) {
      return 'nw-classic-table__row--selected';
    } else if (this.isProcessing) {
      return 'nw-classic-table__row--processing';
    } else if (this.isLimited) {
      return 'nw-classic-table__row--offset-right';
    } else {
      return 'nw-classic-table__row';
    }
  }

  @action
  click() {
    this.args.toggleBacklinkSelected(this.args.backlink);
  }
}
