import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class NwDimensionSelectorInterface extends Component {
  get selectedGroup() {
    return Object.values(this.args.dimensionGroups).findBy('active');
  }

  /**
   * Load more keywords, it is important to call this function only on the group that has server side pagination
   * @returns {*}
   */
  get loadMore() {
    const group = Object.values(this.args.dimensionGroups).findBy('active');
    if (group.label === 'Keywords') {
      return group.loadTask.perform;
    }
  }

  @action
  selectGroup(dimensionGroup) {
    Object.values(this.args.dimensionGroups).forEach(
      (dg) => (dg.active = false)
    );
    dimensionGroup.active = true;
  }
}
