import { isWithinInterval, formatISO } from 'date-fns';
import demarcateData from './demarcate-data';
import fillGaps from './fill-gaps';
import { groupBy } from 'lodash';
import { NOTE_IMAGE } from '../base64-images';

const noteSeries = (notes, interval) => {
  const notesInInterval = notes
    .sortBy('date')
    .filter(({ date }) => isWithinInterval(date, interval));

  const notesByDate = groupBy(notesInInterval, ({ date }) =>
    formatISO(date, { representation: 'date' })
  );

  let data = Object.entries(notesByDate).map(([isoDateString, notes]) => {
    return [isoDateString, notes.mapBy('text').join(' ')];
  });

  data = demarcateData(data, interval);
  data = fillGaps(data, { skipValue: true });
  data = data.map(([dateString, txt]) => ({
    x: dateString,
    y: null,
    txt,
  }));

  const point = new Image();
  point.src = NOTE_IMAGE;

  return {
    type: 'nwNote',
    data,
    scaleId: 'nwNotes',
    backgroundColor: '#3e70ec',
    borderColor: '#000',
    pointStyle: point,
  };
};

export default noteSeries;
