import EmberObject, { computed } from '@ember/object';
import { isPresent, isBlank } from '@ember/utils';
import allFilterTypes from 'nightwatch-web/constants/filters/filter-types';
import flatten from 'lodash-es/flatten';

export default EmberObject.extend({
  isFirstAndNullified: computed(
    'name',
    'operator',
    'value',
    'filterGroup.filters.[]',
    'filterGroup.filterConfig.filterGroups.[]',
    function () {
      const isFirstAndOnlyGroup =
        this.get('filterGroup.filterConfig.filterGroups.length') === 1;
      const isFirstAndOnlyFilter = this.get('filterGroup.filters.length') === 1;

      return (
        isFirstAndOnlyGroup &&
        isFirstAndOnlyFilter &&
        isBlank(this.name) &&
        isBlank(this.operator) &&
        isBlank(this.value)
      );
    }
  ),
  isLast: computed('filterGroup.filters.[]', function () {
    return this.get('filterGroup.filters.lastObject.id') === this.id;
  }),
  currentFilter: computed('availableFilters.[]', 'name', function () {
    return this.availableFilters.findBy('name', this.name);
  }),
  availableFilterNames: computed('availableFilters.[]', function () {
    return this.availableFilters.mapBy('name');
  }),
  filterDisplayName: computed('name', function () {
    const filter = this.currentFilter;
    if (filter) {
      return this.currentFilter.displayName;
    }
  }),
  isPresenceOperator: computed('operator', function () {
    return ['is_present', 'is_blank'].includes(this.operator);
  }),
  disableInput: computed('hasPredefinedValues', 'operator', function () {
    return this.hasPredefinedValues || this.isPresenceOperator;
  }),
  hasPredefinedValues: computed('currentFilter', function () {
    const filter = this.currentFilter;
    if (filter) {
      return this.currentFilter.type === 'values';
    }
  }),
  isDateType: computed('currentFilter', function () {
    const filter = this.currentFilter;
    if (filter) {
      return this.get('currentFilter.type').indexOf('date') !== -1;
    }
  }),
  dateValue: computed('value', function () {
    if (this.value) {
      return new Date(this.value);
    }
  }),
  predefinedValues: computed('currentFilter', function () {
    const filter = this.currentFilter;
    if (filter) {
      return this.currentFilter.availableValues;
    }
  }),
  isValid: computed('errors.[]', function () {
    return this.get('errors.length') === 0;
  }),
  isEmpty: computed('name', 'operator', 'value', function () {
    if (this.hasPredefinedValues) {
      return isBlank(this.value);
    } else if (this.isPresenceOperator) {
      return false;
    } else {
      return isBlank(this.operator) || isBlank(this.value);
    }
  }),
  errors: computed('currentFilter', 'value', function () {
    const filter = this.currentFilter;
    const errors = [];
    if (filter) {
      const filterType = this.currentFilter.type;
      const value = this.value;
      if (filterType === 'number' && isPresent(value) && isNaN(value)) {
        errors.push('Enter a number');
      }
    }
    return errors;
  }),
  availableOperators: computed('name', function () {
    if (!this.currentFilter) {
      return [];
    }

    const filterType = this.currentFilter.type;
    let filterTypes = [];

    if (filterType.constructor === Array) {
      filterTypes = filterType;
    } else {
      filterTypes.push(filterType);
    }

    let operators = [];
    filterTypes.forEach((t) => {
      operators.push(allFilterTypes[t]);
    });

    return flatten(operators);
  }),
  availableOperatorNames: computed('availableOperators.[]', function () {
    return this.availableOperators.mapBy('name');
  }),
  nullify() {
    this.setProperties({
      name: null,
      operator: null,
      value: null,
    });
  },
});
