import ApplicationSerializer from './application';

export default class NoteSerializer extends ApplicationSerializer {
  serializeIntoHash(data, type, record) {
    super.serializeIntoHash(data, type, record);
    if (record.belongsTo('url')) {
      data.url_id = record.belongsTo('url').id;
      data.note.search_keyword_url_id = data.url_id;
    }
  }
}
