import Service, { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';
import { rawTimeout, task } from 'ember-concurrency';
import underscorifyKeys from 'nightwatch-web/utils/underscorify-keys';
import loadExternalScripts from 'nightwatch-web/utils/load-external-scripts';
import isTesting from 'nightwatch-web/utils/is-testing';
import { tracked } from '@glimmer/tracking';

export default class StripeService extends Service {
  @service fetch;

  @tracked checkoutButtonId = null;

  @task({ drop: true })
  *loadStripeScripts() {
    if (isTesting) return;
    yield loadExternalScripts(['https://js.stripe.com/v3/']);
  }

  @task({ drop: true })
  *redirectToCheckout({ planId, buttonId, noTrial = false }) {
    this.checkoutButtonId = buttonId;
    try {
      yield this.loadStripeScripts.perform();
      const referral = window?.Rewardful?.referral;
      const response = yield this.fetch.post('/stripe/checkout_sessions', {
        data: underscorifyKeys({ planId, referral, noTrial }),
      });
      const stripe = window.Stripe(ENV.STRIPE_PUBLISHABLE_KEY);
      yield stripe.redirectToCheckout({ sessionId: response.sessionId });
    } finally {
      this.checkoutButtonId = null;
    }
  }

  @task({ drop: true })
  *redirectToBillingPortal(allowCancel = false) {
    allowCancel = allowCancel === true;
    const returnUrl = window.location.href;
    const response = yield this.fetch.post('/stripe/customer_portal_sessions', {
      data: underscorifyKeys({ returnUrl, allowCancel }),
    });
    window.location = response.url;
    /*
      At this point a browser redirect will probably be initiated
      Keep the task running for 2 seconds for UI state purposes
    */
    yield rawTimeout(2000);
  }
}
