import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PinnedItemCandidatesComponent extends Component {
  @tracked sort = null;
  @tracked direction = 'asc';

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  get sortedItems() {
    if (!this.sort) return this.args.candidates;
    let items = this.args.candidates.sortBy(this.sort);
    if (this.direction === 'desc') items = items.reverse();
    return items;
  }

  @action
  pinItem(item) {
    item.isPinned = true;
    this.args.onPinItem(item);
  }

  @action
  sortItems(sort, direction) {
    this.sort = sort;
    this.direction = direction;
  }
}
