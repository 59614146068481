import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';

export default class DashboardUrlSettingsController extends Controller {
  @controller dashboard;
  @service session;

  queryParams = ['from'];
  from = null;

  get urlGroups() {
    return this.get('dashboard.urlGroups');
  }
}
