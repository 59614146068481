import { helper } from '@ember/component/helper';

export function dwmChangeValue([value]) {
  if (typeof value !== 'number' || isNaN(value)) {
    return '/';
  }
  return value >= 10_000 || value <= -10_000 ? '∞' : Math.abs(value);
}

export default helper(dwmChangeValue);
