import { action } from '@ember/object';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import KeywordTableColumns from './keyword-table-columns';
import isTesting from '../is-testing';

const SELECTED_COLUMNS_MAX = 13;

const defaultColumnNames = ['indexable', 'meta_description', 'h1', 'load_time'];

const siteAuditColumns = [
  {
    name: 'url',
    displayName: 'Page URL',
    sortProperty: 'url',
  },
  {
    name: 'url_from',
    displayName: 'Linked from',
    sortProperty: 'url_from',
  },
  {
    name: 'response_code',
    displayName: 'Response code',
    sortProperty: 'response_code',
  },
  {
    name: 'redirect_code',
    displayName: 'Redirect code',
    sortProperty: 'redirect_code',
  },
  {
    name: 'nofollow',
    displayName: 'Nofollow',
    sortProperty: 'nofollow',
  },
  {
    name: 'noindex',
    displayName: 'Noindex',
    sortProperty: 'noindex',
  },
  {
    name: 'internal_linking_urls_count',
    displayName: 'Linking pages',
    sortProperty: 'internal_linking_urls_count',
  },
  {
    name: 'internal_broken_links_count',
    displayName: 'Broken links count',
    sortProperty: 'internal_broken_links_count',
  },
  {
    name: 'missing_alt_images_count',
    displayName: 'Missing alt images count',
    sortProperty: 'missing_alt_images_count',
  },
  {
    name: 'indexable',
    displayName: 'Indexable',
    sortProperty: 'indexable',
  },
  {
    name: 'internal_urls_count',
    displayName: 'Internal links',
    sortProperty: 'internal_urls_count',
  },
  {
    name: 'external_urls_count',
    displayName: 'External links',
    sortProperty: 'external_urls_count',
  },
  {
    name: 'load_time',
    displayName: 'Load time (ms)',
    sortProperty: 'load_time',
  },
  {
    name: 'byte_size',
    displayName: 'Byte size',
    sortProperty: 'byte_size',
  },
  {
    name: 'title',
    displayName: 'Page title',
    sortProperty: 'title',
  },
  {
    name: 'title_length',
    displayName: 'Title Length',
    sortProperty: 'title_length',
  },
  {
    name: 'meta_description',
    displayName: 'Description',
    sortProperty: 'meta_description',
  },
  {
    name: 'meta_description_length',
    displayName: 'Description Length',
    sortProperty: 'meta_description_length',
  },
  {
    name: 'depth',
    displayName: 'Page Depth',
    sortProperty: 'depth',
  },
  {
    name: 'h1',
    displayName: 'H1 Text',
    sortProperty: 'h1',
  },
  {
    name: 'h1_count',
    displayName: 'H1 Count',
    sortProperty: 'h1_count',
  },
  {
    name: 'h1_length',
    displayName: 'H1 Length',
    sortProperty: 'h1_length',
  },
  {
    name: 'h2',
    displayName: 'H2 Text',
    sortProperty: 'h2',
  },
  {
    name: 'h2_length',
    displayName: 'H2 Length',
    sortProperty: 'h2_length',
  },
  {
    name: 'h3',
    displayName: 'H3 Text',
    sortProperty: 'h3',
  },
  {
    name: 'h3_length',
    displayName: 'H3 Length',
    sortProperty: 'h3_length',
  },
  {
    name: 'meta_charset',
    displayName: 'Charset',
    sortProperty: 'meta_charset',
  },
  {
    name: 'meta_viewport',
    displayName: 'Viewport',
    sortProperty: 'meta_viewport',
  },
  {
    name: 'og_title',
    displayName: 'OG title',
    sortProperty: 'og_title',
  },
  {
    name: 'og_description',
    displayName: 'OG description',
    sortProperty: 'og_description',
  },
  {
    name: 'og_image',
    displayName: 'OG image',
    sortProperty: 'og_image',
  },
  {
    name: 'og_url',
    displayName: 'OG URL',
    sortProperty: 'og_url',
  },
  {
    name: 'blocked_by_robots',
    displayName: 'Blocked by robots',
    sortProperty: 'blocked_by_robots',
  },
  {
    name: 'is_unique_title',
    displayName: 'Unique title',
    sortProperty: 'is_unique_title',
  },
  {
    name: 'is_unique_description',
    displayName: 'Unique description',
    sortProperty: 'is_unique_description',
  },
  {
    name: 'is_unique_h1',
    displayName: 'Unique H1',
    sortProperty: 'is_unique_h1',
  },
];

export default class SiteAuditTableColumns extends KeywordTableColumns {
  @service notifications;

  default = {
    name: 'url',
    displayName: 'Page URL',
    sortProperty: 'url',
  };

  constructor({ owner, filters }) {
    super({ owner, filters });
    setOwner(this, owner);
    if (filters?.filterGroups?.length > 0) {
      this.selected = this.getColumnsFromFilters(filters);
    } else {
      this.selected = defaultColumnNames.map((filterName) =>
        siteAuditColumns.find((column) => column.name === filterName)
      );
    }
  }

  withSelected(columns) {
    const isSelected = (column) =>
      this.selectedNames.any((selectedName) => selectedName === column?.name);
    return columns.map((column) => ({
      ...column,
      selected: isSelected(column),
    }));
  }

  get allColumns() {
    return [].concat(siteAuditColumns);
  }

  get switcherOptions() {
    return [].concat(this.withSelected?.(siteAuditColumns));
  }

  @action
  switch(columnName, index) {
    const column = this.allColumns.findBy('name', columnName);
    if (!column) return;

    if (this.selected.length >= SELECTED_COLUMNS_MAX) {
      this.notifications.remove(this.notification);
      this.notification = this.notifications.error(
        `Maximum number of columns (${SELECTED_COLUMNS_MAX}) selected.`,
        { autoClear: true }
      );
      return;
    }

    const columns = this.selected.slice();
    columns.splice(index, 1, column);
    this.selected = columns;
  }

  @action
  drop(targetColumn, draggedColumn) {
    const columns = this.selected.slice();
    const bottomIndex = columns.indexOf(targetColumn);
    const topIndex = columns.indexOf(draggedColumn);
    columns.splice(topIndex, 1);
    columns.splice(bottomIndex, 0, draggedColumn);
    this.selected = columns;
  }

  setColumnsFromFilters(filters) {
    if (isTesting || !filters || !filters?.filterGroups?.length) return;
    this.selected = this.getColumnsFromFilters(filters);
  }

  getColumnsFromFilters(filters) {
    // Prepends filter names to the columns with default columns
    const filterNames = filters.filterGroups.flatMap((filter) =>
      filter.filters.flatMap((fi) => fi?.name)
    );
    if (filterNames[0] === 'url') {
      filterNames.shift(); // We don't need to display page URL in the table because it's the default column.
    }
    const allFilterNames = [...filterNames, ...defaultColumnNames];
    return allFilterNames.map((filterName) =>
      siteAuditColumns.find((column) => column?.name === filterName)
    );
  }
}
