import isTesting from 'nightwatch-web/utils/is-testing';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default class ReportPreviewComponent extends Component {
  @service siteData;

  @tracked previewLoading = false;
  @tracked timerRunning = false;
  @tracked warning = false;
  @tracked terminate = false;

  testing = isTesting;

  @action
  onInsert() {
    if (this.testing) return;
    if (this.args.previewUrl) {
      this.resetTimerProperties();
      this.startTimer();
      this.previewLoading = true;
    }
  }

  resetTimerProperties() {
    this.timerRunning = false;
    this.warning = false;
    this.terminate = false;
  }

  stopTimer() {
    this.timerRunning = false;
  }

  startTimer() {
    this.timerRunning = true;
    const step = 1000;
    const terminateTime = 2 * 60 * step;
    const warningTime = 30 * step;
    let time = 0;

    const _loop = () => {
      if (this.isDestroying || this.isDestroyed) return;

      time = time + step;

      if (time >= warningTime) {
        this.warning = true;
      }
      if (time >= terminateTime) {
        this.terminate = true;
        this.timerRunning = false;
        this.previewLoading = false;
        this.previewUrl = null;
      }
      if (this.timerRunning) {
        later(() => {
          _loop();
        }, step);
      }
    };

    _loop();
  }

  @action
  iframeLoad() {
    this.previewLoading = false;
    this.stopTimer();
  }
}
