import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
  @argument url
  @argument selectedKeywords
  @argument keywordsDeletable
  @argument deleteSelectedKeywords
 */
export default class SelectedKeywordActionsComponent extends Component {
  @service fetchTags;
  @service notifications;

  @tracked deleteKeywordsModalShown = false;

  get keywordTags() {
    return this.fetchTags.keywordTagCache;
  }

  get selectedKeywordsForCopy() {
    return this.args.selectedKeywords.mapBy('query').join('\n');
  }

  get disabled() {
    return this.args.selectedKeywords.length === 0;
  }

  get selectedKeywords() {
    if (!this.args.keywords) return [];

    return this.args.keywords.filter((keyword) =>
      this.selectedKeywordIds.includes(keyword.id)
    );
  }

  @action
  copySuccess() {
    this.notifications.success('Selected keywords copied to clipboard.', {
      autoClear: true,
      clearDuration: 3500,
    });
  }

  @action
  copyError() {
    this.notifications.error(
      'Something went wrong while copying the keywords. Please contact support',
      { autoClear: true }
    );
  }

  @action
  toggleDeleteKeywordsModal() {
    this.deleteKeywordsModalShown = !this.deleteKeywordsModalShown;
    return false;
  }
}
