import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export const DEFAULT_DATE_RANGE_TYPE = '30_days';

export default class Graph extends Model {
  @attr('string') name;
  @attr('day-date') dateFrom;
  @attr('day-date') dateTo;
  @attr('string') relativeDateRange;
  @attr('string') grouping;

  @hasMany('graph/series', { async: false }) series;
  @belongsTo('url', { async: false }) url;

  async save() {
    const unloadSeries = this.isNew;
    await super.save();
    /*
      Unload all unsaved series after a new graph has finished saving.

      Otherwise upon creating a new graph the original (unsaved) series
      are still in the store along with the new saved ones.
     */
    if (unloadSeries) this.series.filterBy('isNew').invoke('unloadRecord');
    return this;
  }
}
