import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import groupBy from 'nightwatch-web/utils/group-by';

export default class NotificationsController extends Controller {
  queryParams = ['page'];
  page = 1;
  allNotifications = [];

  @service userNotifications;

  @groupBy('notifications', 'createdAtDateString') notificationsByDate;

  set notifications(notifications) {
    this.set('allNotifications', notifications);
  }

  get notifications() {
    return this.model;
  }

  @action
  onNotificationClick(notification) {
    this.userNotifications.goToNotification(notification);
  }
}
