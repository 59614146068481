import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import ENV from '../config/environment';
import { task } from 'ember-concurrency';
import loadExternalScripts from 'nightwatch-web/utils/load-external-scripts';

const FAYE_CLIENT_URL = `${ENV.fayeURL}/client.js`;

export default class FayeService extends Service.extend(Evented) {
  @service session;
  @service store;

  client = null;

  @task({ drop: true })
  *subscribe() {
    if (!ENV.fayeURL) return;
    if (this.client) return;

    yield loadExternalScripts([FAYE_CLIENT_URL]);

    const { token, user } = this.session;

    this.client = new window.Faye.Client(ENV.fayeURL);
    this.client.disable('autodisconnect');
    this.client.addExtension({
      outgoing(message, callback) {
        message.ext = { ...message.ext, access_token: token };
        callback(message);
      },
    });

    const channel = `/users/${user.id}/accounts/${user.accountId}`;
    this.client.subscribe(channel, ({ eventName, eventParams }) => {
      this.trigger(`push:${eventName}`, eventParams);
    });
  }

  handlePushUpdates({ onKeywordPositionUpdate }) {
    this.subscribe.perform();
    this.on('push:notifications:new', () => {
      // Reload to refresh notifications count shown in the navbar
      this.session?.user?.reload();
    });

    this.on('push:keyword:position_update', ({ id }) => {
      const keyword = this.store.peekRecord('keyword', id);
      if (!keyword) return;

      keyword.reload();
      onKeywordPositionUpdate();
    });

    this.on('push:url:seo_metrics_update', ({ id }) => {
      const url = this.store.peekRecord('url', id);
      if (!url) return;

      url.loadStats();
    });
  }
}
