import { inject as service } from '@ember/service';
import Model, { belongsTo, attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class BacklinkView extends Model {
  @service fetch;

  @attr('string') name;
  @attr() filterConfig;
  @attr('string') filterConfigString;

  @belongsTo('url', { async: false }) url;

  @tracked filtersSaving = false;
  @tracked filtersSaved = false;

  loadCount() {
    this.fetch
      .request(`/backlink_views/${this.id}/backlink_count`)
      .then((data) => {
        this.set('backlinkCount', data.backlink_count);
      });
  }
}
