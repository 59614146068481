import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import isEqual from 'lodash-es/isEqual';

export default Component.extend({
  init(...args) {
    this._super(...args);
    this.columnDisplayNames = {
      urlFrom: 'URL',
      ipFrom: 'IP',
      urlTo: 'Landing Page',
      urlRating: 'URL Rating',
      domainRating: 'Domain Rating',
      firstSeen: 'First Seen',
      alt: 'Image Alt',
      rootDomain: 'Domain',
    };
    this.dropdownDisplayNames = {
      urlRating: 'URL Rating',
      domainRating: 'Domain Rating',
      title: 'Page Title',
    };
  },
  userSettings: service(),
  availableColumns: A([
    'urlFrom',
    'rootDomain',
    'urlRating',
    'domainRating',
    'ipFrom',
    'urlTo',
    'firstSeen',
    'title',
    'lastVisited',
    'linkType',
    'alt',
    'redirect',
    'anchor',
    'rel',
    'status',
    'source',
  ]),
  defaultColumns: A(['urlFrom', 'urlRating', 'status', 'urlTo', 'firstSeen']),
  didReceiveAttrs() {
    this._super();

    // When url or view changes, set the view columns
    const urlId = this.url ? this.get('url.id') : null;
    const backlinkViewId = this.backlinkView
      ? this.get('backlinkView.id')
      : null;
    const combinedResourceId = [urlId, backlinkViewId].compact().join('-');

    if (combinedResourceId != this._previousCombinedResourceId) {
      this.setSelectedColumns();
    }

    this.set('_previousCombinedResourceId', combinedResourceId);
  },

  setSelectedColumns() {
    const selectedColumns = this.getPersistedColumns();

    // We removed these columns and users might still have them saved
    [
      'domainAuthority',
      'domain_authority',
      'pageAuthority',
      'page_authority',
    ].forEach((c) => {
      const index = selectedColumns.indexOf(c);
      if (index != -1) {
        selectedColumns[index] =
          c.indexOf('page') != -1 ? 'urlRating' : 'domainRating';
        this.persistSelectedColumns(selectedColumns);
      }
    });

    this.set('selectedColumns', selectedColumns);
  },
  oppositeDirection: computed('direction', function () {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }),
  columnsAreDefault: computed('selectedColumns.[]', function () {
    return isEqual(this.defaultColumns.toArray(), this.selectedColumns);
  }),
  getPersistedColumnsFromUserSettings() {
    const searchKeywordUrlId = this.get('url.id');
    const backlinkViewId = this.get('backlinkView.id');
    try {
      return this.userSettings
        .getSetting({
          searchKeywordUrlId,
          backlinkViewId,
        })
        .get('settingsData').backlinks.columns;
    } catch (e) {
      return null;
    }
  },
  getPersistedColumns() {
    const columnsFromUserSettings = this.getPersistedColumnsFromUserSettings();
    return isPresent(columnsFromUserSettings)
      ? columnsFromUserSettings
      : this.defaultColumns.toArray();
  },
  persistSelectedColumns(columnNames) {
    if (this.isNewView) return;
    const searchKeywordUrlId = this.get('url.id');
    const backlinkViewId = this.get('backlinkView.id');
    return this.userSettings.saveSetting({
      searchKeywordUrlId,
      backlinkViewId,
      settingsData: {
        backlinks: {
          columns: columnNames,
        },
      },
    });
  },
  persistSorting: action(function (sort, direction) {
    if (this.isNewView) return;
    const searchKeywordUrlId = this.get('url.id');
    const backlinkViewId = this.get('backlinkView.id');
    this.userSettings.saveSetting({
      searchKeywordUrlId,
      backlinkViewId,
      settingsData: {
        backlinks: {
          sortField: sort,
          sortDirection: direction,
        },
      },
    });
  }),
  switchColumn: action(function (index, newColumnName) {
    const selectedColumns = this.selectedColumns.toArray();
    let newSelectedColumns = selectedColumns.concat();
    newSelectedColumns[index] = newColumnName;
    this.persistSelectedColumns(newSelectedColumns);
    this.set('selectedColumns', newSelectedColumns);
  }),
  revertColumns: action(function () {
    const columns = this.defaultColumns.toArray();
    this.set('selectedColumns', this.defaultColumns.toArray());
    this.persistSelectedColumns(columns);
  }),
});
