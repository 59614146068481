import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  sub,
  format,
  startOfDay,
  endOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
} from 'date-fns';

const SNAPSHOT_PERIODS = ['week', 'month', 'quarter', 'year'];
const NONE_OPTION = { label: 'none', snapshot: null };

/**
  @class SnapshotSelectorComponent

  A dropdown selector for choosing Site Audit crawling "snapshots".

  Provides options based on recent periods, and a calendar to pick specific snapshots.

  @argument snapshots {Array[CrawlingSession]}
  @argument selectedSnapshotId {String} null|<id>|"last_week"|"last_month"|"last_quarter"
  @argument onChange {function(selectedSnapshotId)}
 */
export default class SnapshotSelectorComponent extends Component {
  @tracked isOpen = false;
  close() {}

  get options() {
    const options = [NONE_OPTION];

    const snapshotInLastPeriod = (period) => {
      return (snapshot) => {
        switch (period) {
          case 'week':
            return (
              new Date(snapshot.startedAt) >
                startOfWeek(sub(new Date(), { weeks: 1 })) &&
              new Date(snapshot.startedAt) <
                endOfWeek(sub(new Date(), { weeks: 1 }))
            );
          case 'month':
            return (
              new Date(snapshot.startedAt) >
                startOfMonth(sub(new Date(), { months: 1 })) &&
              new Date(snapshot.startedAt) <
                endOfMonth(sub(new Date(), { months: 1 }))
            );
          case 'year':
            return (
              new Date(snapshot.startedAt) >
                startOfYear(sub(new Date(), { years: 1 })) &&
              new Date(snapshot.startedAt) <
                endOfYear(sub(new Date(), { years: 1 }))
            );
          case 'quarter':
            return (
              new Date(snapshot.startedAt) >
                startOfQuarter(subQuarters(new Date(), 1)) &&
              new Date(snapshot.startedAt) <
                endOfQuarter(subQuarters(new Date(), 1))
            );
          default:
            return new Error('Invalid period type', period);
        }
      };
    };

    SNAPSHOT_PERIODS.forEach((period) => {
      const snapshot = this.args.snapshots.find(snapshotInLastPeriod(period));
      if (snapshot) {
        options.push({
          label: `last_${period}`,
          snapshot: { id: `last_${period}` },
        });
      }
    });

    const snapshotDates = this.args.snapshots
      .uniqBy('startedAtDateString')
      // turn dates to start of day timestamps
      .map((snapshot) => startOfDay(new Date(snapshot.startedAt)))
      // filter out unique values
      .filter((time, idx, arr) => arr.indexOf(time) === idx)
      // Sort from old to new
      .sort()
      // turn timestamps back to dates
      .map((snapshot) => {
        return {
          label: format(new Date(snapshot), 'MMM dd, yyyy'),
          date: new Date(snapshot),
        };
      });

    options.push({
      groupName: 'Snapshots',
      options: snapshotDates,
    });

    return options;
  }

  get selectedOption() {
    const { selectedSnapshotId, snapshots } = this.args;

    const periodOption = this.options.find(
      (option) => option.snapshot?.id === selectedSnapshotId
    );
    if (periodOption) return periodOption;

    const snapshot = snapshots.findBy('id', selectedSnapshotId);
    if (snapshot) {
      return {
        label: format(snapshot.startedAt, 'MMMM dd, yyyy'),
        snapshot,
      };
    }

    return NONE_OPTION;
  }

  @action
  snapshotsUpdated() {
    this.calendarMonth = this.maxDate;
  }

  @action
  onOpen(powerSelect) {
    this.isOpen = true;
    // Store the close action so that this component can close the PowerSelect
    this.close = powerSelect.actions.close;
  }

  @action
  onSelected(option) {
    if (option.date) {
      this.onDateSelected(option.date);
    } else {
      this.args.onChange(option.snapshot?.id);
      this.close();
    }
  }

  onDateSelected(date) {
    const matchingSnapshot = this.args.snapshots.find(
      (snapshot) =>
        startOfDay(new Date(snapshot.startedAt)).getDay() === date.getDay()
    );
    if (!matchingSnapshot) return;
    this.args.onChange(matchingSnapshot.id);
    this.close();
  }
}
