import Component from '@glimmer/component';
import { Chart, ArcElement, PieController, Tooltip } from 'chart.js';

Chart.register(ArcElement, PieController, Tooltip);

/**
  @class NwPieChartComponent

  Renders a pie chart of series data

  @argument series {Array[Number]} Array of data to graph
  @argument isLoading {Boolean} Whether the series data is loading
  @argument type {String} Optional - 'doughnut' or 'pie'
  @argument small {Boolean} Smaller size variant
  @argument disableTooltip {Boolean} Disables tooltip
 */

export default class NwPieChartComponent extends Component {
  get type() {
    return this.args.type ?? 'pie';
  }

  get datasets() {
    const { series } = this.args;
    return [
      {
        data: series.mapBy('data'),
        backgroundColor: series.mapBy('backgroundColor'),
      },
    ];
  }

  get labels() {
    return this.args.series.mapBy('label');
  }

  get chartOptions() {
    const { type, labels, datasets } = this;
    return {
      type,
      data: {
        responsive: true,
        maintainAspectRatio: false,
        labels,
        datasets,
      },
      options: {
        animation: {
          duration: 150,
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          tooltip: {
            enabled: !this.args.disableTooltip,
          },
        },
      },
    };
  }
}
