import { helper } from '@ember/component/helper';

export function formatAmountWhole([number]) {
  const parsed = Number.parseFloat(number);

  if (isNaN(parsed)) {
    return '';
  }

  return parsed.toLocaleString('en-US').split('.')[0];
}

export default helper(formatAmountWhole);
