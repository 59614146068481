import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlDynamicViewSettingsRoute extends Route {
  @service router;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    if (this.session.user?.isLimited) {
      this.router.transitionTo('dashboard.url.dynamic-view');
    }
  }

  afterModel(model) {
    return model.set('url', this.modelFor('dashboard.url'));
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }
}
