import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlReportsRoute extends Route {
  @service session;
  @service metrics;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    this.session.requireAuthentication();
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Open Reports' });
    return true;
  }
}
