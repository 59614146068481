import ApplicationAdapter from './application';

export default class FilterGroupAdapter extends ApplicationAdapter {
  buildURL(_modelName, _id, snapshot, _requestType, query) {
    const defaultUrl = super.buildURL(...arguments);
    const dynamicViewId =
      query && query.dynamic_view_id
        ? query.dynamic_view_id
        : snapshot && snapshot.belongsTo
        ? snapshot.belongsTo('dynamicView')?.id
        : undefined;
    return defaultUrl.replace(
      /\/filter_groups/,
      `/dynamic_views/${dynamicViewId}/filter_groups`
    );
  }
}
