import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {THEMES} from "../services/theme";

export default class PasswordResetRoute extends Route {
  @service session;
  @service router;
  @service theme;
  @service siteData;

  beforeModel() {
    this.session.prohibitAuthentication();
  }

  deactivate() {
    this.controller.setProperties({
      successMessage: null,
      errorMessage: null,
      reset_token: null,
    });
  }
}
