import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DiffWordsComponent extends Component {
  @tracked oldSections = [];
  @tracked newSections = [];
  @tracked defaultLine = null;
  @tracked diff = null;

  constructor(owner, args) {
    super(owner, args);
    // Dynamic import
    import('diff').then((module) => (this.diff = module));
  }

  @action
  outputText() {
    const { oldText, newText } = this.args;
    let sections = this.diff.diffWords(oldText, newText);

    if (oldText === newText || sections.length < 2) {
      this.newSections = [];
      this.oldSections = [];
      this.defaultLine = oldText;
      return;
    }

    sections.forEach((s) => (s.value = s.value.trim()));

    this.oldSections = sections.filter(
      (s) => (!s.added && !s.removed) || s.removed
    );
    this.newSections = sections.filter(
      (s) => (!s.added && !s.removed) || s.added
    );
  }
}
