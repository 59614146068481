import Component from '@glimmer/component';

export default class NwDeepResearchBalanceBarComponent extends Component {
  get width() {
    const { current, total } = this.args;
    console.log('currentUsage', current);

    // If there is no usage, the bar should be full (100%)
    if (current === 0) {
      return 100;
    }

    // Ensure we do not divide by zero
    if (total === 0) {
      return 0;
    }

    // Calculate the remaining balance and width percentage
    const remainingBalance = total - current;
    return (remainingBalance / total) * 100;
  }
}
