import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export class DimensionSubgroup {
  @tracked dimensions = [];
  @tracked open = false;
  label = '';

  constructor({ dimensions, label, keyword }) {
    this.dimensions = dimensions;
    this.label = label;
    this.keyword = keyword;
  }

  @action
  toggle() {
    this.open = !this.open;
  }
}
