import { isSameDay, parseISO, formatISO } from 'date-fns';

// Ensure there are start and end dates matching the selected date range
const demarcateData = (data, { start, end }, options = { fillValue: null }) => {
  if (!datePresent(data, start)) {
    data.unshift([
      formatISO(start, { representation: 'date' }),
      options.fillValue,
    ]);
  }

  if (!datePresent(data, end)) {
    data.push([formatISO(end, { representation: 'date' }), options.fillValue]);
  }

  return data;
};

const datePresent = (data, date) =>
  data.find(([dateString]) => isSameDay(parseISO(dateString), date));

export default demarcateData;
