import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import refreshRoute from '../../../utils/refresh-route';
import { task } from 'ember-concurrency';
import delay from '../../../utils/delay';

/**
 * This component wraps a keyword suggestion table
 * @param url {Url} current URL
 */

export default class SettingsNwKeywordSuggestionsProviderComponent extends Component {
  @service fetch;
  @service store;
  @service discovery;
  @service notifications;
  @tracked tableData = [];
  @tracked showIgnoredKeywords = false;
  @tracked selectedKeywordSuggestions = [];
  @tracked direction = 'asc';
  @tracked sort = 'created_at';
  tableDataCache = [];

  get columns() {
    const columns = {
      default: {
        name: 'keyword-suggestion-keyword',
        displayName: 'Keyword',
        sortProperty: 'query',
      },
      selected: [
        {
          name: 'keyword-suggestion-position',
          displayName: 'Estimated Rank',
          sortProperty: 'position',
        },
        {
          name: 'keyword-suggestion-source',
          displayName: 'Source',
          sortProperty: 'source',
        },
        {
          name: 'keyword-suggestion-location',
          displayName: 'Location',
          sortProperty: 'location',
        },
        {
          name: 'keyword-suggestion-search-volume',
          displayName: 'Search Volume',
          sortProperty: 'search_volume',
        },
        {
          name: 'keyword-suggestion-discovered',
          displayName: 'Discovered',
          sortProperty: 'discovered',
        },
        {
          name: 'keyword-suggestion-actions',
        },
      ],
      drop: () => null,
    };
    if (this.showIgnoredKeywords) {
      columns.selected.splice(5, 0, {
        name: 'keyword-suggestion-status',
        displayName: 'Status',
        sortProperty: 'status',
      });
    }
    return columns;
  }

  get ignoredKeywordsCount() {
    return this.tableDataCache.filter((keyword) => keyword.ignored)?.length;
  }

  get allRowsSelected() {
    return this.tableData.length === this.selectedKeywordSuggestions.length;
  }

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  @action
  onSelectAllToggleChange(checked) {
    if (checked) {
      this.selectedKeywordSuggestions = [...this.tableData];
    } else {
      this.selectedKeywordSuggestions = [];
    }
  }

  @action
  onInsert() {
    // Hack that forces re-fetching of kw suggestions when clicking on a notification
    // for newly discovered keyword suggestions while on the kw suggestion table.
    window.addEventListener('goto-keyword-suggestions', async () => {
      await delay(800);
      this.loadTableData.perform();
    });
    this.loadTableData.perform();
  }

  @action
  toggleIgnoredKeywords() {
    this.showIgnoredKeywords = !this.showIgnoredKeywords;
    this.setTableData(this.tableDataCache);
  }

  @action
  onSort(sort, direction) {
    this.sort = sort;
    this.direction = direction;
    this.loadTableData.perform(sort, direction);
  }

  @task({ restartable: true })
  *loadTableData(sort = null, direction = null) {
    this.tableDataCache = yield this.store.query('keyword-suggestion', {
      url_id: this.args.url.id,
      sort: sort,
      direction: direction,
      limit: 50,
      page: 1,
      with_tracked: false,
      with_ignored: true,
    });
    this.setTableData(this.tableDataCache);
  }

  setTableData(tableData) {
    this.showIgnoredKeywords
      ? (this.tableData = tableData.toArray())
      : (this.tableData = tableData.filter(
          (keywordSuggestion) => !keywordSuggestion.ignored
        ));
  }

  _ignoreKeywordSuggestions(keywordSuggestions) {
    const ids = keywordSuggestions.mapBy('id');
    const url = this.args.url;
    return this.fetch
      .post('/keyword_suggestions/ignore', {
        data: {
          url_id: url.id,
          ids: ids,
          ignore: true,
        },
      })
      .catch(() => {
        this.notifications.error(
          'There was an error while ignoring keyword suggestions.',
          { autoClear: true, clearDuration: 5000 }
        );
      });
  }

  @action
  ignoreKeywordSuggestion(keywordSuggestion, ignore = true) {
    const promise = this._ignoreKeywordSuggestions([keywordSuggestion], ignore);
    promise.then(async () => {
      const url = this.args.url;
      await url.loadKeywordSuggestionCount();
      this.notifications.success('Keyword suggestion successfully ignored.', {
        autoClear: true,
        clearDuration: 5000,
      });
    });
    refreshRoute(this, 'dashboard.url.keywords.add.discover');
    this.tableData = this.tableData.without(keywordSuggestion);
  }

  @action
  toggleKeywordSelected(keyword) {
    if (this.selectedKeywordSuggestions.includes(keyword)) {
      this.selectedKeywordSuggestions.removeObject(keyword);
    } else {
      this.selectedKeywordSuggestions.pushObject(keyword);
    }
  }

  @action
  ignoreSelectedKeywords(keywordSuggestion) {
    const promise = this._ignoreKeywordSuggestions(
      this.selectedKeywordSuggestions,
      keywordSuggestion
    );
    promise.then(() => {
      this.notifications.success('Keyword suggestions successfully ignored.', {
        autoClear: true,
        clearDuration: 5000,
      });
      refreshRoute(this, 'dashboard.url.keywords.add.discover');
    });
  }

  @action
  addSelectedKeywords() {
    this.discovery.clearEmptyKeywords();
    this.selectedKeywordSuggestions.forEach((keywordSuggestion) => {
      this.discovery.keywords = [
        ...this.discovery.keywords,
        this.createKeyword(keywordSuggestion),
      ];
    });

    this.notifications.success('Keywords copied to Add Keywords tab.', {
      autoClear: true,
      clearDuration: 5000,
    });
  }

  @action
  addKeywordSuggestion(keywordSuggestion) {
    this.discovery.clearEmptyKeywords();
    this.discovery.keywords = [
      ...this.discovery.keywords,
      this.createKeyword(keywordSuggestion),
    ];
    this.notifications.success('Keyword copied to Add Keywords tab.', {
      autoClear: true,
      clearDuration: 5000,
    });
  }

  createKeyword(keywordSuggestion) {
    const keyword = this.store.createRecord('keyword');
    keyword.url = this.args.url;
    keyword.tags = [];
    keyword.mobile = false;
    keyword.engine = 'google';
    keyword.google_hl = this.args.url.language_code;
    keyword.google_gl = this.args.url.country_code;
    keyword.query = keywordSuggestion.query;
    return keyword;
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('goto-keyword-suggestions', () =>
      this.loadTableData.perform()
    );
  }
}
