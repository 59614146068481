import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { validator, buildValidations } from 'ember-cp-validations';
import isTesting from 'nightwatch-web/utils/is-testing';
import {
  domainsRegex,
  specialCharactersRegex,
} from '../../../../utils/regexes';

const Validations = buildValidations({
  'model.backlinksSettings.minUrlRating': validator('number', {
    allowString: false,
    integer: true,
    gt: 0,
    lt: 100,
  }),
});

export default class DashboardUrlSettingsBacklinksController extends Controller.extend(
  Validations
) {
  @service router;
  @service notifications;

  maxIgnoreDomains = 120;
  queryParams = ['ignored_domains'];
  ignored_domains = [];

  get url() {
    return this.model;
  }

  get ignoreDomainsOverLimit() {
    return (
      this.get('url.backlinksSettings.ignored_domains.length') >
      this.maxIgnoreDomains
    );
  }

  get ignoreDomainsError() {
    return this.ignoreDomainsOverLimit
      ? `Limit is ${this.maxIgnoreDomains} domains.`
      : '';
  }

  get contentForIgnoreDomains() {
    return (this.get('url.backlinksSettings.ignored_domains') || []).join('\n');
  }

  set contentForIgnoreDomains(value) {
    const domains = value
      .toLowerCase()
      .replace(' ', '')
      .split('\n')
      .uniq()
      .compact();
    this.set('url.backlinksSettings.ignored_domains', domains);
  }

  invalidDomains() {
    // Domains must not have spaces, special characters or begin with http/s
    const domains = this.get('url.backlinksSettings.ignored_domains');
    return domains.filter(
      (d) =>
        d.match(/' '|"|http/) ||
        !d.match(domainsRegex) ||
        d.match(specialCharactersRegex)
    );
  }

  @action
  saveUrl(url) {
    const invalidDomains = this.invalidDomains();
    if (invalidDomains.length > 0) {
      this.notifications.error(
        `Please check ${invalidDomains.join(
          ', '
        )} for errors, must supply a list of plain domains separated by new lines.`,
        { autoClear: true, clearDuration: 10000 }
      );
      return;
    }
    if (this.ignoreDomainsError) {
      this.notifications.error(
        'Please delete some of the ignore domains to fit the limit.',
        { autoClear: true, clearDuration: 10000 }
      );
      return;
    }
    const promise = url.save();
    const didAddIgnoredDomains = this.get('ignored_domains.length') > 0;
    promise.then(
      () => {
        if (didAddIgnoredDomains) {
          this.notifications.success(
            'Settings saved sucessfully. Domains were added to the ignore list. If you want to remove existing ones, delete them manually.',
            {
              autoClear: true,
              clearDuration: isTesting ? 0 : 15000,
            }
          );
          this.router.transitionTo('dashboard.url.backlinks.index', url);
        } else {
          this.notifications.success('Settings saved sucessfully.', {
            autoClear: true,
          });
        }
      },
      () => {
        this.notifications.error('Error while saving settings.', {
          autoClear: true,
          clearDuration: 10000,
        });
      }
    );
  }

  @action
  toggleMinUrlRating() {
    if (this.get('url.backlinksSettings.min_url_rating') > 0) {
      this.set('url.backlinksSettings.min_url_rating', null);
    } else {
      this.set('url.backlinksSettings.min_url_rating', 1);
    }
  }

  @action
  setMinUrlRating(amount) {
    this.set('url.backlinksSettings.min_url_rating', parseInt(amount));
  }

  @action
  toggleBacklinksFetchingEnabled() {
    this.toggleProperty('url.backlinksFetchingEnabled');
  }
}
