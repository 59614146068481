import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import chevronIconHtml from '../../utils/chevron-icon-html';

export default class BacklinksNotificationsService extends Service {
  @service notifications;
  @service router;
  @service session;
  @service siteData;

  // Store all notifications so that they can be removed later
  disabledNotification;
  limitNotification;

  notifyFetchingDisabled(url) {
    if (url.backlinksFetchingEnabled) return;
    if (this.session.user?.isLimited) return;
    if (this.disabledNotification) return;

    this.disabledNotification = this.notifications.warning(
      htmlSafe(
        `You disabled automatic new backlinks discovery for this URL. <b class="blue-alt">Update settings ${chevronIconHtml}</b>`
      ),
      {
        onClick: () => {
          this.router.transitionTo('dashboard.url.settings.backlinks', url.id);
        },
      }
    );
  }

  notifyLimitReached(user) {
    if (this.siteData.hideNightwatchBranding) return;

    const { backlinksCount, backlinksLimit } = user;
    if (backlinksCount < backlinksLimit) return;

    if (this.limitNotification) return;

    this.limitNotification = this.notifications.warning(
      htmlSafe(
        `You reached your plan’s backlink limit (${backlinksLimit}). Upgrade your plan to continue tracking more backlinks. <b class="blue-alt">Upgrade Plan ${chevronIconHtml}</b>`
      ),
      {
        onClick: () => {
          this.router.transitionTo('plans', {
            queryParams: { event: 'Backlinks Upgrade CTA' },
          });
        },
      }
    );
  }

  clearLimitNotification() {
    this.notifications.remove(this.limitNotification);
  }

  clearNotifications() {
    this.notifications.remove(
      this.disabledNotification,
      this.limitNotification
    );
  }
}
