import Model, { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';

export default class KeywordSuggestion extends Model {
  @attr('string') query;
  @attr('string') location;
  @attr('number') search_volume;
  @attr('number') position;
  @attr('boolean') ignored;
  @attr('string') source;
  @attr('boolean') tracked;
  @attr('date') created_at;
  @attr('date') search_volume_updated_at;

  get searchVolumeProcessing() {
    return (
      isBlank(this.search_volume) && isBlank(this.search_volume_updated_at)
    );
  }

  get tags() {
    return [];
  }
}
