import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SettingsSubusersNewRoute extends Route {
  @service store;

  model() {
    return hash({
      subuser: this.store.createRecord('subuser', {
        access_type: 'admin',
        group_ids: [],
      }),
      urlGroups: this.store.query('url-group', {
        static: true,
        include_default: false,
      }),
    });
  }

  @action
  willTransition() {
    if (this.controller.model.subuser.isNew) {
      this.controller.model.subuser.unloadRecord();
    }
  }
}
