import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import isTesting from 'nightwatch-web/utils/is-testing';

export default class CompetitorDiscoveryProviderComponent extends Component {
  @service store;
  @service fetch;
  @service session;
  @service metrics;
  @service discovery;
  @service saveCompetitors;
  @service notifications;

  columns = {
    default: {
      name: 'competitor-url',
      displayName: 'Competitor URL',
    },
    selected: [
      {
        name: 'competitor-google-place',
        displayName: 'Google Place',
        tooltip:
          'The Google Business Profile to track in Local Pack and Google Places (optional)',
      },
      {
        name: 'competitor-google-carousel',
        displayName: 'Google Carousel',
        tooltip: 'Google Carousel title to track (optional)',
      },
      {
        name: 'competitor-custom-name',
        displayName: 'Custom Name',
        tooltip:
          'Competitor name you want to see in created reports (optional)',
      },
      {
        name: 'competitor-track-subdomains',
        displayName: 'Track Subdomains',
        tooltip:
          'Tracks competitor subdomains (e.g. blog.example.com) (optional)',
      },
      {
        name: 'keyword-actions',
        displayName: '',
      },
    ],
    drop: () => null,
  };

  competitorProperties = {
    customName: null,
    displayName: null,
    url: this.args.url,
    matchSubdomains: true,
    competitor_url: null,
    places_info: null,
    places_match: null,
    places_image_title: null,
  };

  get competitors() {
    return this.discovery.competitors;
  }

  get hasCompetitorErrors() {
    return this.discovery.competitors.some(
      (competitor) => competitor.errors.length > 0
    );
  }

  get isYouTubeUrl() {
    return this.args.url?.isYoutube;
  }

  @action
  onInsert() {
    if (this.isYouTubeUrl) {
      this.addEmptyCompetitors(1);
      return;
    }
    if (!this.args.url || this.discovery.competitors.length) return;
    if (this.args.isSettingsMode) {
      const competitors = this.args.url?.competitors?.toArray();
      this.discovery.competitors.pushObjects(competitors);
      return;
    }

    this.discoverTask.perform();
  }

  @task({ drop: true })
  *discoverTask() {
    const url = this.args.url;
    const competitorResults = [];

    try {
      const competitors = yield this.discovery.fetchCompetitorSuggestions(url);
      if (!Object.keys(competitors).length) {
        this.warnNoCompetitors();
      }

      Object.keys(competitors)?.forEach((competitorItem) => {
        const competitor = this.store.createRecord('competitor');
        // Store initial keyword properties to reuse when creating new keywords.
        this.competitorProperties.url = url;
        if (this.args.isSettingsMode) competitor.discovered = true;
        for (let k in this.competitorProperties)
          competitor[k] = this.competitorProperties[k];

        competitor.competitor_url = competitorItem;
        competitorResults.push(competitor);
      });
      if (this.args.isSettingsMode) {
        this.discovery.competitors.pushObjects(competitorResults);
      } else {
        this.discovery.competitors = competitorResults;
      }
    } catch {
      this.errorSearchingCompetitors();
    }
  }

  @action
  addCompetitor() {
    const competitor = this.store.createRecord('competitor');
    competitor.url = this.args.url;
    // Copy initially discovered competitor properties into new competitor.
    for (let k in this.competitorProperties)
      competitor[k] = this.competitorProperties[k];
    this.discovery.competitors = [...this.discovery.competitors, competitor];
  }

  @action
  duplicateCompetitor(competitorToCopy) {
    const newCompetitor = this.store.createRecord('competitor');
    for (let k in this.competitorProperties) {
      newCompetitor[k] = competitorToCopy[k];
    }
    this.discovery.competitors = [...this.discovery.competitors, newCompetitor];
  }

  @action
  async removeCompetitor(competitor) {
    if (this.args.isSettingsMode && (!competitor.id || competitor.isNew)) {
      competitor.unloadRecord();
      this.discovery.competitors = this.competitors.without(competitor);
    } else if (
      isTesting ||
      confirm(
        'Are you sure you want to delete this competitor? All of its associated data wil be lost.'
      )
    ) {
      await competitor.destroyRecord();
      this.discovery.competitors = this.competitors.without(competitor);
    }
  }

  @action
  addEmptyCompetitors(amount) {
    [...Array(amount)].forEach(() => this.addCompetitor());
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.discovery.competitors = [];
  }

  warnNoCompetitors() {
    this.notifications.warning(
      "Couldn't find any competitors - the website is probably new.",
      { autoClear: true, clearDuration: 4000 }
    );
  }

  errorSearchingCompetitors() {
    this.notifications.error('Fetching Competitors', {
      type: 'warning',
      message: 'Auto discovery problems. Please contact support.',
    });
  }
}
