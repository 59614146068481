import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';

export default class OnFayeMessageModifier extends Modifier {
  @service faye;
  eventName = null;
  callback = null;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, () => {
      this.faye.off(this.eventName, this.callback);
    });
  }

  modify(element, [eventName, callback]) {
    this.eventName = eventName;
    this.callback = callback;
    this.faye.on(eventName, callback);
  }
}
