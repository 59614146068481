import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';

export default Component.extend({
  tagName: 'nav',
  classNames: ['nw-pagination'],
  classNameBindings: ['paginationShown::nw-pagination--disabled'],
  userSettings: service(),
  limits: A([50, 100, 250, 500]),
  defaultLimit: 50,
  setLimit: action(function (limit) {
    this.set('limit', limit);
    this.persistLimit(limit);
  }),
  persistLimit(limit) {
    const searchKeywordUrlId = this.get('url.id');
    const dynamicViewId = this.get('dynamicView.id');
    this.userSettings.saveSetting({
      searchKeywordUrlId,
      dynamicViewId,
      settingsData: {
        keywords: {
          limit: limit,
        },
      },
    });
  },
  pagesList: computed('currentPage', 'keywords.meta.[]', function () {
    const pagesCount = Number.parseInt(this.get('keywords.meta.pages'), 10);
    let pages =
      pagesCount && Number.parseInt(pagesCount, 10) > 0
        ? [...Array(pagesCount).keys()].map((p) => ++p)
        : [];

    if (pages.length > 10) {
      // show first, four pages before current, four pages after current and last
      const currentPage = this.currentPage;

      const left = [...Array(4).keys()]
        .map((n) => currentPage - (4 - n))
        .filter((p) => p > 1);
      const right = [...Array(4).keys()]
        .map((n) => currentPage + (1 + n))
        .filter((p) => p < pagesCount);

      pages = [1]
        .concat(left)
        .concat([currentPage])
        .concat(right)
        .concat([pagesCount])
        .uniq();

      const pagesWithTruncation = [];
      for (let index = 0; index < pages.length; index++) {
        const page = pages[index];
        pagesWithTruncation.push(page);
        if (pages[index + 1] && pages[index + 1] - page !== 1) {
          pagesWithTruncation.push(null); //ellipsis in template
        }
      }

      pages = pagesWithTruncation;
    }

    return pages;
  }),

  paginationShown: computed('pagesList', 'limit', function () {
    return this.pagesList.length > 1 || this.limit > this.defaultLimit;
  }),

  previousPage: computed('pagesList.[]', 'currentPage', function () {
    const previous = Number.parseInt(this.currentPage, 10) - 1;
    return this.pagesList.includes(previous) ? previous : null;
  }),

  nextPage: computed('pagesList.[]', 'currentPage', function () {
    const next = Number.parseInt(this.currentPage, 10) + 1;
    return this.pagesList.includes(next) ? next : null;
  }),
});
