import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditDisabledRoute extends Route {
  @service metrics;

  activate() {
    window.scrollTo(0, 0);
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Site Audit Not Enabled' });
    return true;
  }
}
