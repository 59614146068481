import Route from '@ember/routing/route';

export default class DashboardUrlBacklinksNewRoute extends Route {
  activate() {
    window.scrollTo(0, 0);
  }
  model() {
    return this.modelFor('dashboard.url');
  }
}
