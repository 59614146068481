import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * Report white label selector
 * @param {EmberModel} report
 * @param {EmberArray} logos - Options list
 * @param {EmberAction} onSelect - On select action
 */
export default class ReportWhitelabelComponent extends Component {
  @action
  selectLabel(labelId) {
    this.args.report.whitelabel_logo_id = labelId;
    this.args.onSelect();
  }
}
