import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinksRoute extends Route {
  @service router;
  @service session;

  beforeModel() {
    if (!this.session.user.backlinksVisible) {
      this.router.transitionTo('dashboard.url');
    }
  }

  model() {
    return this.modelFor('dashboard.url');
  }

  afterModel(model) {
    if (false) {
      this.router.transitionTo('dashboard.url.backlinks.enable', model);
    }
  }
}
