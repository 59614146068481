import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DashboardUrlChartRoute extends Route {
  @service metrics;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }

  model(params) {
    return this.store.findRecord('graph', params.graph_id);
  }

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Open Graph',
      name: this.controller.model.name,
    });
    return true;
  }
}
