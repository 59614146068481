import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';

export default class NwDeepResearchExportCandidatesComponent extends Component {
  @service store;
  @tracked urls = null;
  @tracked searchString = '';

  constructor(owner, args) {
    super(owner, args);
    this.getUrls.perform();
  }

  @task({ drop: false })
  *getUrls() {
    let urls = yield this.store.findAll('url');
    this.urls = urls;
  }

  // Filter URLs based on the search string
  @computed('searchString', 'urls')
  get filteredUrls() {
    if (!this.searchString) {
      return this.urls;
    }
    return this.urls.filter((url) =>
      url.url.toLowerCase().includes(this.searchString.toLowerCase())
    );
  }

  @action
  updateSearch(event) {
    this.searchString = event.target.value;
  }
}
