import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinksEnableRoute extends Route {
  @service router;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    if (this.session.user?.isLimited) {
      this.router.transitionTo('dashboard.url.backlinks');
    }
  }

  afterModel(model) {
    if (false) {
      this.router.transitionTo('dashboard.url.backlinks', model);
    }
  }

  deactivate() {
    this.controller.set('fetchingBacklinks', null);
  }
}
