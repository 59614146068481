import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class GoogleAnalyticsConnectorComponent extends Component {
  @service googleAnalyticsIntegration;
  @service session;
  @service siteData;

  get user() {
    return this.session.user;
  }

  get isWhiteLabel() {
    return this.siteData.isWhiteLabel;
  }

  get analyticsEnabled() {
    return this.session.user.googleAnalyticsEnabled;
  }

  reloadUser() {
    this.session.user.reload();
  }

  @action
  prepareGoogleClient() {
    if (this.isWhiteLabel) return;

    this.googleAnalyticsIntegration.prepareGoogleClient();
  }

  @action
  async onSwitch() {
    if (this.analyticsEnabled) {
      await this.googleAnalyticsIntegration.disableAnalytics();
    } else {
      await this.googleAnalyticsIntegration.connectGa();
    }
    this.reloadUser();
  }
}
