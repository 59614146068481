import Service, { inject as service } from '@ember/service';

export default class BacklinksSettingsService extends Service {
  @service userSettings;

  getSettings({ searchKeywordUrlId, backlinkViewId }) {
    let settings = {
      sort: null,
      direction: null,
      limit: null,
      columns: null,
    };

    try {
      const setting = this.userSettings.getSetting({
        searchKeywordUrlId,
        backlinkViewId,
      });

      if (setting) {
        const columns = setting.get('settingsData.backlinks.columns');
        const sort = setting.get('settingsData.backlinks.sortField');
        const direction = setting.get('settingsData.backlinks.sortDirection');
        const limit = setting.get('settingsData.backlinks.limit');

        if (columns) settings.columns = columns;
        if (sort) settings.sort = sort;
        if (direction) settings.direction = direction;
        if (limit) settings.limit = limit;
      }
      return settings;
    } catch (e) {
      return settings;
    }
  }
}
