import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TagsBatchActionsComponent extends Component {
  @service notifications;
  @service fetchTags;

  newTag = '';

  get tagsFromSelectedModels() {
    return this.args.selectedModels?.reduce((tags, model) => {
      return tags.concat(model.tags);
    }, []);
  }

  get tagsFromSelectedModelsWithFrequencies() {
    return this.tagsFromSelectedModels.reduce((tags, tag) => {
      const index = tags.findIndex((t) => t.name === tag);
      if (index === -1) {
        tags.push({ name: tag, count: 1 });
      } else {
        tags[index].count++;
      }
      return tags;
    }, []);
  }

  get hasSelectedModelsTags() {
    return this.tagsFromSelectedModels?.length > 0;
  }

  @action
  async addNewTag() {
    if (!this.newTag) return;
    await this.fetchTags.addTagToSelectedModels.perform(
      this.newTag,
      this.args.selectedModels,
      this.args.saveAfterAdd
    );
    this.fetchTags.fetchKeywordTags.perform(this.args.url, true);
    this.newTag = '';
  }
}
