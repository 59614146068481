import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

export default class PinnedItemSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    graph: { embedded: 'always' },
  };

  serializeIntoHash(hash, _typeClass, snapshot, options) {
    const payload = this.serialize(snapshot, options);

    if (snapshot.belongsTo('url')) {
      payload.search_keyword_url_id = snapshot.belongsTo('url').id;
    } else if (snapshot.belongsTo('urlGroup')) {
      payload.url_group_id = snapshot.belongsTo('urlGroup').id;
    } else if (snapshot.belongsTo('dynamicView')) {
      payload.dynamic_view_id = snapshot.belongsTo('dynamicView').id;
    }

    hash['pinned_item'] = payload;
    return hash;
  }
}
