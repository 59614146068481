import { action } from '@ember/object';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class DashboardReportsController extends Controller {
  @service router;

  @tracked reportModalShown = false;

  @action
  goToNewReport(item) {
    const { id, type, url_id } = item;
    if (type === 'dynamic_view') {
      if (url_id) {
        this.router.transitionTo('dashboard.url.reports.new', url_id, {
          queryParams: { viewId: id },
        });
      }
      return this.router.transitionTo('dashboard.dynamic-view.reports.new', id);
    }
    return this.router.transitionTo('dashboard.url.reports.new', id);
  }
}
