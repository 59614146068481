import Model, { attr, belongsTo } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class Subuser extends Model {
  @attr('string') email;
  @attr('string') access_type;
  @attr('boolean') adding_keywords;
  @attr('boolean') owner;
  @attr('number') keyword_limit;
  @attr() group_ids;
  @attr('boolean') keyword_limit_enabled;
  @attr('boolean') adding_urls;
  @attr('number') url_limit;
  @attr('boolean') url_limit_enabled;
  @attr('string') created; // Format: 4 months ago
  @attr('string') last_access;
  @attr('string', { readOnly: true }) last_login_ip;

  @belongsTo('user', { async: false }) user;

  get hasLoggedIn() {
    return isPresent(this.last_login_ip);
  }
}
