import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlDynamicViewsNewRoute extends Route {
  @service metrics;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    const url = this.modelFor('dashboard.url');
    return this.store.createRecord('dynamic-view', { url });
  }

  setupController(controller, dynamicView) {
    super.setupController(controller, dynamicView);
    controller.enteredRoute(dynamicView);
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Create View' });
    return true;
  }
}
