import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

/**
  @class ChangePasswordFormComponent

  For setting a new password

  @argument {String} resetToken - Required to change password
 */
export default class ChangePasswordFormComponent extends Component {
  @service router;
  @service fetch;
  @service notifications;

  @tracked password;
  @tracked passwordConfirmation;

  @tracked passwordConfirmationError;
  @tracked formSubmitted = false;

  @tracked showPassword = false;
  @tracked showPasswordConfirmation = false;

  @task
  *save() {
    this.formSubmitted = true;
    if (this.password !== this.passwordConfirmation) {
      this.passwordConfirmationError = 'Passwords must match.';
      return;
    }

    if (this.passwordError) {
      return;
    }

    try {
      yield this.fetch.post('/password_resets/reset_password', {
        data: {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          reset_token: this.args.resetToken,
        },
      });
    } catch (response) {
      this.notifications.error(
        response?.payload?.error ||
          'Something broke. Please contact us if error persists.'
      );
      return;
    }
    this.notifications.success(
      'Password reset successful. You can now login with your new password.'
    );
    this.router.transitionTo('login');
  }

  get passwordError() {
    if (!this.formSubmitted) {
      return false;
    }
    if (!this.password) {
      return 'Password is required';
    }

    const errorMessages = [];
    const validators = [
      { regex: /.{8,}/, message: 'be at least 8 characters long' },
      { regex: /[A-Z]/, message: 'include at least one uppercase letter' },
      { regex: /\d/, message: 'include at least one number' },
    ];

    validators.forEach(({ regex, message }) => {
      if (!regex.test(this.password)) {
        errorMessages.push(message);
      }
    });

    if (errorMessages.length > 0) {
      return 'Password must ' + errorMessages.join(', ');
    }

    return false;
  }
}
