import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

export default class NwKeywordTableRowKeywordComponent extends Component {
  @service fetch;
  @service notifications;

  @action
  async changeUrlPriority(keyword, url) {
    return this.fetch
      .post(`/urls/${keyword.url.id}/keywords/add_url_priority`, {
        data: { keyword_id: keyword.id, url: url },
      })
      .then(() => {
        // Handle the success scenario
        this.notifications.success('URL priority updated successfully!', {
          autoClear: true,
          clearDuration: 10000,
        });

        window.location.reload();
      })
      .catch((error) => {
        // Handle the error scenario
        this.notifications.error(
          'Error while updating URL priority. Please try again.',
          { autoClear: true, clearDuration: 10000 }
        );
      });
  }
}
