import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class UpdatePaymentController extends Controller {
  @service router;
  @service session;

  @action
  afterPaymentSuccess() {
    this.notifications.success(
      'You successfully updated your payment method. The new payment method will be applied in the next billing cycle for your account.'
    );
    this.router.transitionTo('plans');
  }
}
