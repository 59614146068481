import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

/**
  @argument dynamicView
  @argument filtersShown
  @argument onFilterChange
*/
export default class NwKeywordsTableFiltersComponent extends Component {
  @service filterGroupHandling;

  limit = 10;

  get visibleFilterGroups() {
    return this.args.dynamicView.visibleFilterGroups;
  }

  get visibleFilters() {
    return this.visibleFilterGroups.flatMap((fg) => fg.visibleFilters);
  }

  get visibleFiltersCount() {
    return this.visibleFilters.length;
  }

  get isLimitReached() {
    const { visibleFiltersCount, limit } = this;
    return isPresent(limit) && visibleFiltersCount >= limit;
  }

  get isAddingDisabled() {
    return this.isLimitReached;
  }

  get anyFilledFilters() {
    return this.visibleFilters.any((f) => f.hasValues);
  }

  get isSaveDropdownDisabled() {
    return this.isLimitReached || !this.anyFilledFilters;
  }

  get isSaveDisabled() {
    return this.isSaveDropdownDisabled || this.nameIsMissing;
  }

  get limitWarningMessage() {
    return this.isLimitReached
      ? `You can have a maximum of ${this.limit} filters.`
      : `You can only have ${this.limit} filters. Changes to views with more than ${this.limit} filters can not be saved.`;
  }

  get nameIsEntered() {
    return (
      this.args.dynamicView.name && this.args.dynamicView.name.trim().length > 0
    );
  }

  get nameIsMissing() {
    return !this.nameIsEntered;
  }

  @action
  nullifyFilter(filter) {
    this.filterGroupHandling.nullifyFilter(filter);
    this.args.onFilterChange();
  }

  @action
  removeFilter(filter) {
    this.filterGroupHandling.removeFilter(filter);
    this.args.onFilterChange();
  }

  @action
  prepareNewFilterGroup(dynamicView) {
    if (this.isAddingDisabled) return;
    this.filterGroupHandling.prepareNewFilterGroup(dynamicView);
  }

  @action
  prepareNewFilter(filterGroup) {
    if (this.isAddingDisabled) return;
    this.filterGroupHandling.prepareNewFilter(filterGroup);
  }

  @action
  onSaveViewFilters(dynamicView) {
    if (this.isSaveDisabled) return;
    this.filterGroupHandling.saveViewFilters.perform(dynamicView);
  }
}
