import { formatISO, getUnixTime } from 'date-fns';
import ENV from 'nightwatch-web/config/environment';

/**
  @function generateUserMetrics
  A standardized set of attributes for providing to external tracking services
 */
const generateUserMetrics = (user, siteDataService) => {
  const questionnaire = user.user_info?.signup_questionnaire;
  return {
    distinctId: user.registration_email,

    userId: user.id,

    name: user.name,
    company: user.is_company ? user.name : undefined,
    accountId: user.accountId,

    adminUrl: `https://admin.nightwatch.io/admin/users?utf8=%E2%9C%93&q%5Bemail_contains%5D=${encodeURIComponent(
      user.email
    )}`,

    email: user.email,
    registrationEmail: user.registration_email,

    planId: user.plan?.settings?.id,
    planName: user.plan?.settings?.name,
    planPeriodName: user.plan?.settings?.period_name,
    planEndDate: user.active_until,
    planStatus: user.status,
    planGroupId: user.planGroupId,

    backlinksUsed: user.backlinksUsed,
    keywordsUsed: user.keywordsUsed,
    urlsUsed: user.urlsUsed,

    backlinksRemaining: user.backlinksRemaining,
    keywordsRemaining: user.keywords_remaining,
    urlsRemaining: user.urls_remaining,

    backlinksLimit: user.backlinksLimit,
    keywordsLimit: user.keyword_limit,
    urlsLimit: user.url_limit,

    analyticsConnected: user.googleAnalyticsEnabled,
    timezone: user.timezone,

    createdAt: user.createdAt,
    createdAtUnix: user.createdAt ? getUnixTime(user.createdAt) : null,
    createdAtFormatted: user.createdAt ? formatISO(user.createdAt) : null,
    nightwatchWebCodeVersion: ENV.COMMIT_REF,

    questionnaireCompany: questionnaire?.findBy(
      'key',
      'signup_company_description'
    )?.answer,
    questionnaireEmployees: questionnaire?.findBy(
      'key',
      'signup_employee_count'
    )?.answer,
    questionnaireReferral: questionnaire?.findBy('key', 'signup_referral')
      ?.answer,

    isLimited: user.isLimited,
    isAdmin: user.isAdmin,
    isOwner: user.isOwner,

    siteTitle: siteDataService.siteTitle,
    isWhiteLabel: siteDataService.isWhiteLabel,
    hideNightwatchBranding: siteDataService.hideNightwatchBranding,
  };
};

export default generateUserMetrics;
