import { inject as service } from '@ember/service';
import Model, { belongsTo, attr } from '@ember-data/model';
import { parseISO, format } from 'date-fns';
import { tracked } from '@glimmer/tracking';

const DATE_VALUE_FORMAT = 'MM/dd/yyyy';

export default class Filter extends Model {
  @service filterData;

  @attr('string') field;
  @attr('string') condition;
  @attr('string') value;

  @belongsTo('filter-group', { async: false }) filterGroup;

  @tracked valueError;
  @tracked conditionError;
  @tracked fieldError;

  get dateValue() {
    return this.value ? parseISO(this.value) : null;
  }
  set dateValue(value) {
    this.value = format(value, DATE_VALUE_FORMAT);
  }

  get booleanValue() {
    return String(this.value) === 'true';
  }

  set booleanValue(value) {
    this.value = String(value);
  }

  get hasValues() {
    return this.filterData.filterHasValues(this);
  }

  get isDirty() {
    return this.filterData.filterIsDirty(this);
  }

  get isBlank() {
    return this.filterData.filterIsBlank(this);
  }

  get filterInfo() {
    return this.filterData.getFilterInfoByField(this.field);
  }

  get isBooleanField() {
    return this.filterData.isBooleanField(this.field);
  }
}
