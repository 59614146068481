import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NwDeepResearchRecommendationsListComponent extends Component {
  @service deepResearch;
  columns = {
    selected: [
      {
        name: 'target_page',
        displayName: 'Target page',
      },
      {
        name: 'target_keywords',
        displayName: 'Suggested keywords',
      },
      {
        name: 'have_existing_page',
        displayName: 'Existing page',
      },
      {
        name: 'recommendation-actions',
        displayName: 'Actions',
      },
    ],
    drop: () => null,
  };

  get mimicProvider() {
    return {
      columns: this.columns,
      onSort: () => null,
    };
  }

  @action
  async prepareDownloadItem(item, format) {
    const columns = ['Target Page', 'Suggested Keywords', 'Existing Page'];
    let rowsGenerator = (entry) => [
      entry.target_page,
      entry.target_keywords.join(', '),
      entry.have_existing_page ? 'Yes' : 'No',
    ];
    if (format === 'csv') {
      rowsGenerator = (entry) => [
        entry.target_page,
        entry.target_keywords.join(' | '),
        entry.have_existing_page ? 'true' : 'false',
      ];
    }
    const title =
      'Research History Report - Recommendations - ' + this.args.currentUrl;
    const subtitle = 'Research was done on ' + this.args.currentReportDate;
    const fileNameBase =
      this.args.currentUrl +
      '-recommendations-research-' +
      this.args.currentReportDate;

    await this.deepResearch.exportData(
      item,
      format,
      columns,
      rowsGenerator,
      title,
      subtitle,
      fileNameBase
    );
  }
}
