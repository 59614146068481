import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordsAddRoute extends Route {
  @service router;
  @service discovery;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel(transition) {
    if (transition.targetName === 'dashboard.url.keywords.add.index') {
      this.router.transitionTo('dashboard.url.keywords.add.manual');
    }
  }

  deactivate() {
    this.discovery.keywords = [];
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('url', this.modelFor('dashboard.url'));
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.discovery.keywords = [];
    }
  }
}
