import { helper } from '@ember/component/helper';

// New York,New York,United States to New York, New York, United States
export const prettifyCommas = (params) =>
  (params[0] || '')
    .split(',')
    .map(Function.prototype.call, String.prototype.trim)
    .join(', ');

export default helper(prettifyCommas);
