// app/helpers/highlight.js
import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export default helper(function highlight([text, search]) {
  if (!search) {
    return htmlSafe(text);
  }
  const regex = new RegExp(`(${search})`, 'gi');
  const highlighted = text.replace(regex, '<mark>$1</mark>');
  return htmlSafe(highlighted);
});
