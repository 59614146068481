import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { WEEKLY } from 'nightwatch-web/constants/time-periods';
import { inject as service } from '@ember/service';

export default class DashboardDynamicViewReportsNewRoute extends Route {
  @service metrics;
  @service store;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    const dynamicView = this.modelFor('dashboard.dynamic-view');
    const report = this.store.createRecord('report', {
      dynamicView,
      scheduled: false,
      schedule_interval: WEEKLY,
      day_in_month: 1,
      local_report_day: 0,
      local_report_hour: 12,
      recipients: [''],
      report_format: 'pdf',
      date_range_type: 'week',
      name: `Report for ${dynamicView.name}`,
    });
    report.setEmailParams();

    if (
      this.session.user.agency &&
      this.session.user.whitelabelDomains.whitelabelLogoId
    ) {
      const whitelabel_logo_id =
        this.session.user.whitelabelDomains.whitelabelLogoId;
      report.set('whitelabel_logo_id', whitelabel_logo_id);
    }

    return {
      dynamicView,
      report,
      whitelabelLogos: this.store.findAll('whitelabel-logo'),
    };
  }

  afterModel(model) {
    model.report.set('dynamicView', model.dynamicView);
  }

  renderTemplate() {
    this.render({
      into: 'dashboard',
    });
  }

  @action
  didTransition() {
    this.metrics.trackEvent({ event: 'Create Report' });
    return true;
  }
}
