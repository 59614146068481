import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NwKBarComponent extends Component {
  @service kbar;

  @action
  onInsert() {
    this.kbar.loadUrls.perform();
    // Listen for the Up/Down/Escape/Enter keys
    window.addEventListener('keydown', (e) => {
      // Load the URLs list, doing it here, so it always loads new URLs added.
      // Show and hide the KBar here
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyK') {
        this.kbar.kbarVisible = !this.kbar.kbarVisible;
      }
      if (!this.kbar.kbarVisible) return;
      switch (e.code) {
        case 'Enter':
          this.onClickItem();
          return;

        case 'ArrowDown':
          if (
            this.kbar.selectedIndex >=
            this.kbar.filteredNavigation.length - 1
          )
            return;
          this.kbar.selectedIndex += 1;
          this.scrollIntoView();
          return;

        case 'ArrowUp':
          if (this.kbar.selectedIndex === 0) return;
          this.kbar.selectedIndex -= 1;
          this.scrollIntoView();
          return;

        case 'Escape':
          this.kbar.kbarVisible = false;
          this.resetKBarSearch();
          return;

        default:
          return;
      }
    });
  }

  @action
  onClickItem(index) {
    const idx = index ? index : this.kbar.selectedIndex;
    this.kbar.filteredNavigation[idx]?.action();
    this.resetKBarSearch();
  }

  scrollIntoView() {
    document
      .querySelector('.nw-kbar__list-item--selected')
      .scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  resetKBarSearch() {
    this.kbar.selectedIndex = 0;
    this.kbar.kbarVisible = false;
    this.kbar.searchTerm = null;
  }
}
