import Component from '@glimmer/component';

export default class ReportColumnSorterComponent extends Component {
  get sort() {
    return this.args.keywordSortColumn;
  }

  get isCurrentSort() {
    return this.sort === this.args.column?.name;
  }

  get sortDirection() {
    return this.args.keywordSortDirection;
  }

  get isAsc() {
    if (!this.isCurrentSort) return false;
    return this.sortDirection === 'asc';
  }

  get isDesc() {
    if (!this.isCurrentSort) return false;
    return this.sortDirection === 'desc';
  }
}
