import Service from '@ember/service';
import loadExternalScripts from 'nightwatch-web/utils/load-external-scripts';

export default class ExternalScriptLoaderService extends Service {
  async loadBillingScripts() {
    const scripts = [
      'https://js.braintreegateway.com/web/3.85.1/js/client.min.js',
      'https://js.braintreegateway.com/web/3.85.1/js/hosted-fields.min.js',
      'https://js.braintreegateway.com/web/3.85.1/js/paypal-checkout.min.js',
      'https://js.braintreegateway.com/web/3.85.1/js/three-d-secure.min.js',
    ];
    await loadExternalScripts(scripts, 15);
  }
}
