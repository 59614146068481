import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class SettingsPrivacyController extends Controller {
  @service session;

  @action
  toggleConsent(consentType) {
    const consents = this.session?.user?.consents;

    consents.includes(consentType)
      ? consents.removeObject(consentType)
      : consents.pushObject(consentType);
  }

  @task
  *save() {
    try {
      yield this.session.user.save();
      this.notifications.success('Settings successfully updated.', {
        autoClear: true,
      });
    } catch (error) {
      this.notifications.error('Settings update failed.');
      throw error;
    }
  }
}
