import { helper } from '@ember/component/helper';
import { isDate, isValid, format } from 'date-fns';

export function formatDate([date, formatStr, fallbackDate]) {
  let dateObject;

  if (isDate(date) && isValid(date)) {
    dateObject = date;
  } else if (isDate(fallbackDate) && isValid(date)) {
    dateObject = fallbackDate;
  }

  return dateObject ? format(new Date(dateObject), formatStr) : '';
}

export default helper(formatDate);
