import Component from '@ember/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class AnnouncementNotificationComponent extends Component {
  @service store;
  @service metrics;
  @service session;

  @action
  onInsert() {
    const isWhitelabeledUser = this.get('siteData.isWhiteLabel');
    if (isWhitelabeledUser) {
      return;
    }

    const promise = this.store.findAll('announcement');
    promise.then((announcements) => {
      const announcement = announcements.get('firstObject');
      if (isBlank(announcement)) return;

      this.setProperties({
        title: announcement.get('title') || 'Update',
        message: announcement.get('message'),
        announcementNotificationShown: true,
      });
    });
  }

  get safeMessage() {
    return htmlSafe(this.message);
  }

  @action
  toggleAnnouncement() {
    const isShown = this.announcementNotificationShown;
    this.set('announcementNotificationShown', !isShown);

    const { user } = this.session;
    if (!user || !isShown) return;

    user.announcement_seen_at = new Date();
    user.save();

    this.metrics.trackEvent({
      event: 'Seen announcement',
      title: this.title,
    });
  }
}
