import { helper } from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export function subscriptionAmounts([amounts]) {
  // Options:
  // When there is VAT present, display: ($120 + 22% VAT)
  // When there is coupon present, display ($120 - 10% discount)
  // When there are VAT and coupon present, display: ($120 + 22% VAT - 10% discount)
  // When there are no VAT and coupon, return empty string.

  let msg = '';
  if (isPresent(amounts.vat_rate) && amounts.vat_rate > 0) {
    msg = `$${amounts.amount_original} + ${amounts.vat_rate}% VAT`;

    if (isPresent(amounts.coupon_percent) && amounts.coupon_percent > 0) {
      msg += ` - ${amounts.coupon_percent}% discount`;
    }
  } else if (isPresent(amounts.coupon_percent) && amounts.coupon_percent > 0) {
    msg += `$${amounts.amount_original} - ${amounts.coupon_percent}% discount`;
  }

  return isPresent(msg) ? `(${msg})` : msg;
}

export default helper(subscriptionAmounts);
