import { all, task } from 'ember-concurrency';
import KEYWORD_DISTRIBUTION_PROPS from '../../../constants/chart/keyword-distribution-props';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';
import { DimensionSubgroup } from './dimension-subgroup';

class DynamicViewDimension extends Dimension {
  requestWith = ['dynamicView'];

  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.find((d) => d.dynamic_view_id === parseInt(this.dynamicView.id, 10))
        ?.dynamic_view_series?.findBy('name', this.name)?.series ?? []
    );
  }
}

class DynamicViewCompetitorDimension extends Dimension {
  requestWith = ['dynamicView'];

  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.find((d) => d.dynamic_view_id === parseInt(this.dynamicView.id, 10))
        ?.competitor_series?.find(
          (d) =>
            d.competitor_id === parseInt(this.competitor.id) &&
            d.name === this.name
        )?.series ?? []
    );
  }

  get series() {
    return this.graph.series.find(
      (s) =>
        s.name === this.name &&
        s.url === this.url &&
        s.competitor === this.competitor &&
        s.dynamicView === this.dynamicView
    );
  }
}

export class DynamicViewDimensions extends DimensionGroup {
  label = 'Views';

  @task
  *partialLoadTask(seriesModels) {
    const dynamicViewIds = seriesModels
      .map((s) => s.belongsTo('dynamicView').id())
      .uniq()
      .compact();

    const [, ...dynamicViews] = yield all([
      this.url.loadCompetitors.perform(),
      ...dynamicViewIds.map((id) =>
        this.maybeFindModel.perform('dynamic-view', id)
      ),
    ]);

    this.createSubgroups(dynamicViews.compact());
  }

  @task({ drop: true })
  *loadTask() {
    yield all([
      this.url.loadDynamicViews.perform(),
      this.url.loadCompetitors.perform(),
    ]);

    const dynamicViews = this.url.dynamicViews.rejectBy('isNew');
    this.createSubgroups(dynamicViews);
  }

  createSubgroups(dynamicViews) {
    const { competitors } = this.url;

    this.subgroups = dynamicViews.sortBy('name').map((dynamicView) => {
      const props = {
        ...this.dimensionProps,
        dynamicView,
        subTitle: dynamicView.name,
      };
      return new DimensionSubgroup({
        label: dynamicView.name,
        dimensions: [
          new DynamicViewDimension({
            ...props,
            name: 'average_position',
            scaleId: 'nwPosition',
            title: 'Average Position',
            label: `Average Position | ${this.url.name} | ${dynamicView.name}`,
            competitorDimensions: competitors.map(
              (competitor) =>
                new DynamicViewCompetitorDimension({
                  ...props,
                  competitor,
                  name: 'average_position',
                  scaleId: 'nwPosition',
                  title: 'Average Position',
                  subTitle: `${dynamicView.name} (${competitor.competitor_url})`,
                  label: `Average Position | ${competitor.competitor_url} | ${dynamicView.name}`,
                })
            ),
          }),
          new DynamicViewDimension({
            ...props,
            name: 'search_visibility_index',
            title: 'Search Visibility Index',
            label: `Search Visibility Index | ${this.url.name} | ${dynamicView.name}`,
            competitorDimensions: competitors.map(
              (competitor) =>
                new DynamicViewCompetitorDimension({
                  ...props,
                  competitor,
                  name: 'search_visibility_index',
                  title: 'Search Visibility Index',
                  subTitle: `${dynamicView.name} (${competitor.competitor_url})`,
                  label: `Search Visibility Index | ${competitor.competitor_url} | ${dynamicView.name}`,
                })
            ),
          }),
          new DynamicViewDimension({
            ...props,
            name: 'click_potential',
            title: 'Click Potential',
            label: `Click Potential | ${this.url.name} | ${dynamicView.name}`,
            competitorDimensions: competitors.map(
              (competitor) =>
                new DynamicViewCompetitorDimension({
                  ...props,
                  competitor,
                  name: 'click_potential',
                  title: 'Click Potential',
                  subTitle: `${dynamicView.name} (${competitor.competitor_url})`,
                  label: `Click Potential | ${competitor.competitor_url} | ${dynamicView.name}`,
                })
            ),
          }),
          new DynamicViewDimension({
            ...props,
            name: 'total_keywords',
            title: 'Total Keywords',
            label: `Total Keywords | ${this.url.name} | ${dynamicView.name}`,
          }),
          new DynamicViewDimension({
            ...props,
            ...KEYWORD_DISTRIBUTION_PROPS,
            label: `Keyword Distribution | ${this.url.name} | ${dynamicView.name}`,
          }),
        ],
      });
    });

    const dimensions = this.subgroups.flatMap((s) => s.dimensions);
    const competitorDimensions = dimensions
      .flatMap((d) => d.competitorDimensions)
      .compact();

    this.dimensions = dimensions.concat(competitorDimensions);
  }
}
