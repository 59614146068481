import Controller from '@ember/controller';
import { action } from '@ember/object';
import refreshRoute from 'nightwatch-web/utils/refresh-route';
import { inject as service } from '@ember/service';

export default class DashboardDynamicViewReportsNewController extends Controller {
  @service router;

  @action
  onReportSave(report) {
    const reports = report.get('dynamicView.reports').toArray();
    reports.pushObject(report);
    report.set('dynamicView.reports', reports);
    const dynamicView = report.get('dynamicView');
    dynamicView.reload(); // Refresh report_count
    refreshRoute(this, 'dashboard.dynamic-view.reports');
    this.router.transitionTo('dashboard.dynamic-view.reports');
  }
}
