import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { setOwner, getOwner } from '@ember/application';
import { groupMethods } from '../grouping';

export class DimensionGroup {
  @service store;

  @tracked active = false;
  @tracked subgroups = [];
  @tracked dimensions = [];

  label = '';
  graph;
  url;

  searchEnabled = false;
  @tracked searchQuery = '';

  constructor({
    provider,
    owner,
    graph,
    url,
    urlGroup,
    dynamicView,
    canAddDimension,
    onGraphUpdate,
    onSeriesAdded,
    active,
    searchEnabled,
    onSeriesLoaded,
  }) {
    setOwner(this, owner);
    this.provider = provider;
    this.graph = graph;
    this.url = url;
    this.urlGroup = urlGroup;
    this.dynamicView = dynamicView;
    this.canAddDimension = canAddDimension;
    this.onGraphUpdate = onGraphUpdate;
    this.onSeriesAdded = onSeriesAdded;
    this.active = active ?? false;
    this.searchEnabled = searchEnabled ?? false;
    this.onSeriesLoaded = onSeriesLoaded;
  }

  get dimensionProps() {
    const {
      provider,
      graph,
      url,
      canAddDimension,
      onGraphUpdate,
      onSeriesAdded,
    } = this;
    return {
      provider,
      owner: getOwner(this),
      graph,
      url,
      canAddDimension,
      onGraphUpdate,
      onSeriesAdded,
      groupMethod: groupMethods.average,
      type: 'nwLine',
      scaleId: 'nwValue',
    };
  }

  get searchedSubgroups() {
    if (this.searchQuery === '') return this.subgroups;

    return this.subgroups.filter((subgroup) =>
      subgroup.label.toLowerCase().includes(this.searchQuery)
    );
  }

  get isLoading() {
    return this.loadTask?.isRunning ?? false;
  }

  get isLoaded() {
    return Boolean(this.loadTask?.lastSuccessful);
  }

  @task({ restartable: true })
  *search(query) {
    yield timeout(250);
    this.searchQuery = (query ?? '').toLowerCase();
  }

  @task
  *maybeFindModel(modelName, id) {
    const model = this.store.peekRecord(modelName, id);
    if (model) return model;

    try {
      return yield this.store.findRecord(modelName, id);
    } catch (e) {
      if (e.errors?.[0]?.status === '404') return; // Ignore deleted models
      throw e;
    }
  }

  @action
  load() {
    if (this.isLoaded) return;

    this.loadTask?.perform?.();
  }
}
