import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class WhenInViewportComponent extends Component {
  @tracked enteredViewport = false;

  @action
  onEnterViewport() {
    if (this.enteredViewport) return;

    this.enteredViewport = true;
  }
}
