import { nextColor, TRANSPARENT_COLOR } from '../colors';
import { groupData } from '../grouping';
import fillGaps from '../fill-gaps';
import clampData from '../clamp-data';
import demarcateData from '../demarcate-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import humanize from 'nightwatch-web/utils/humanize';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';

export default class Dimension {
  constructor({
    provider,
    owner,
    graph,
    url,
    competitor,
    keyword,
    name,
    dynamicView,
    urlGroup,
    backlinkView,
    groupMethod,
    canAddDimension,
    onGraphUpdate,
    onSeriesAdded,
    onSeriesLoaded,
    scaleId,
    type,
    label,
    icon,
    title,
    subTitle,
    source,
    competitorDimensions,
    previewKeyword,
    mobile,
    googleGl,
    fillGapsValue,
  }) {
    this.provider = provider;
    setOwner(this, owner);
    this.graph = graph;
    this.url = url;
    this.competitor = competitor;
    this.keyword = keyword;
    this.name = name;
    this.dynamicView = dynamicView;
    this.urlGroup = urlGroup;
    this.backlinkView = backlinkView;
    this.groupMethod = groupMethod;
    this.canAddDimension = canAddDimension;
    this.onGraphUpdate = onGraphUpdate;
    this.onSeriesAdded = onSeriesAdded;
    this.onSeriesLoaded = onSeriesLoaded;
    this.scaleId = scaleId;
    this.type = type;
    this.label = label;
    this.title = title;
    this.subTitle = subTitle;
    this.icon = icon;
    this._color = icon ? null : this.series?.color ?? nextColor();
    this.source = source;
    this.competitorDimensions = competitorDimensions;
    this.previewKeyword = previewKeyword;
    this.mobile = mobile;
    this.googleGl = googleGl;
    this.fillGapsValue = fillGapsValue ?? undefined;
  }
  @service graphColorManager;
  @service store;

  endpoint = 'series';

  fillGapsValue = null;

  get active() {
    return this.series?.active;
  }
  set active(value) {
    this.series.active = value;
    this.onGraphUpdate?.();
  }

  get selected() {
    return Boolean(this.series);
  }
  set selected(value) {
    value ? this.addToGraph() : this.removeFromGraph();
  }

  @tracked _color;
  get color() {
    return this._color;
  }
  set color(value) {
    this._color = value;
    if (this.series) {
      this.series.color = value;
      this.onGraphUpdate?.();
    }
  }

  get series() {
    return this.graph.series.find(
      (s) =>
        s.name === this.name &&
        this.hasSeriesRelationship(s, 'url') &&
        this.hasSeriesRelationship(s, 'competitor') &&
        this.hasSeriesRelationship(s, 'dynamicView') &&
        this.hasSeriesRelationship(s, 'keyword') &&
        this.hasSeriesRelationship(s, 'urlGroup') &&
        this.hasSeriesRelationship(s, 'backlinkView')
    );
  }

  // Safe comparison using belongsTo to avoid ember-data throwing errors
  // When a related model has not yet been loaded
  hasSeriesRelationship(series, relationshipName) {
    return (
      series.belongsTo(relationshipName).id() ===
      (this[relationshipName]?.id ?? null)
    );
  }

  get providedData() {
    if (this.endpoint === 'series') return this.provider?.seriesRequester.data;
    if (this.endpoint === 'trafficStats')
      return this.provider.trafficRequester?.data;
    return null;
  }

  get seriesData() {
    if (Array.isArray(this.providedData) && !this.providedData.length)
      return [];

    let data = this.findData;
    data = demarcateData(data, this.provider.interval, {
      fillValue: this.fillGapsValue,
    });
    data = clampData(data, this.provider.interval);
    data = fillGaps(data, { fillValue: this.fillGapsValue });
    data = groupData(data, this.graph.grouping, this.groupMethod);

    const value = data?.firstObject?.lastObject;
    if (value !== null && typeof value === 'object')
      return this.objectSeriesData(data);

    data = data.map(([date, value]) => ({ x: date, y: value }));

    next(() => this.onSeriesLoaded?.(this, data));

    const { type, label, scaleId, color, previewKeyword } = this;
    return {
      type,
      label,
      data,
      scaleId,
      backgroundColor: color ?? TRANSPARENT_COLOR,
      borderColor: color ?? TRANSPARENT_COLOR,
      previewKeyword,
    };
  }

  get missingData() {
    return isEmpty(this.findData);
  }

  objectSeriesData(data) {
    const [, value] = data.firstObject;
    const { type, label, scaleId } = this;
    return Object.keys(value).map((seriesName) => ({
      type,
      label: `${label} ${humanize(seriesName)}`,
      data: data.map(([date, value]) => ({ x: date, y: value?.[seriesName] })),
      scaleId,
      backgroundColor: this.graphColorManager.colorForSeries(seriesName),
      borderColor: this.graphColorManager.colorForSeries(seriesName),
    }));
  }

  get firstValue() {
    return this.seriesData?.data?.firstObject?.y;
  }

  get lastValue() {
    return this.seriesData?.data?.lastObject?.y;
  }

  get change() {
    let change = this.lastValue - this.firstValue;

    if (this.scaleId === 'nwPosition') {
      change = -change;
    }

    return Math.round(change * 10) / 10;
  }

  addToGraph() {
    if (!this.canAddDimension()) return;
    if (this.selected) return;

    const {
      name,
      color,
      url,
      competitor,
      dynamicView,
      keyword,
      urlGroup,
      backlinkView,
    } = this;

    this.store.createRecord('graph/series', {
      name,
      color,

      graph: this.graph,
      url,

      competitor,
      dynamicView,
      keyword,
      urlGroup,
      backlinkView,

      active: true,
    });

    this.onSeriesAdded();
    this.onGraphUpdate?.();
  }

  @action
  removeFromGraph() {
    if (!this.selected) return;

    const { series } = this;
    series.unloadRecord();

    this.onGraphUpdate?.();
  }
}
