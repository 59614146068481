import ApplicationAdapter from '../application';

export default class SiteAuditPageAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    const meta = {
      total: headers['X-Total-Count'] || headers['x-total-count'],
      pages: headers['X-Page-Count'] || headers['x-page-count'],
    };

    if (payload) {
      if (payload.constructor === Array) {
        payload = { 'site-audit/pages': payload };
      } else {
        payload = { 'site-audit/page': payload };
      }
      payload.meta = meta;
    }

    return super.handleResponse(status, headers, payload, requestData);
  }
}
