import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import ENV from 'nightwatch-web/config/environment';
import StripeSignupForm from 'nightwatch-web/components/stripe-signup-form';
import SignupForm from 'nightwatch-web/components/signup-form';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { emailRegex } from '../utils/regexes';
export default class SignupController extends Controller {
  @service siteData;
  @service signup;
  @service notifications;
  @tracked email;
  @tracked emailError = false;
  @tracked isLoading = false;
  queryParams = [
    'signup_token', // used for both Subuser *and* WhiteLabel signup
    'plan_id', // user selected plan id
    'personal', // used to bypass the biz email check
  ];

  get signupToken() {
    return this.signup_token;
  }

  get planId() {
    return this.plan_id;
  }

  get stripeSignup() {
    return !!ENV.features.STRIPE_SIGNUP;
  }

  @action
  submitEmailAction() {
    if (this.isEmailValid) {
      this.isLoading = true;
      this.signup.submitEmail.perform(this.email, this.planId).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.emailError = true;
    }
  }

  get paymentGateway() {
    return this.stripeSignup ? 'stripe' : null;
  }

  get signupComponent() {
    return this.stripeSignup ? StripeSignupForm : SignupForm;
  }

  get isDirectWhitelabelSignup() {
    return this.siteData.isWhiteLabel && !this.signupToken;
  }

  get hideNightwatchBranding() {
    return this.siteData.isWhiteLabel;
  }

  get isEmailValid() {
    return this.email && emailRegex.test(this.email);
  }

  get emailFieldHasErrors() {
    return this.emailError;
  }

  @action
  resetError() {
    this.emailError = false;
  }
}
