import Model, { attr } from '@ember-data/model';
import { format } from 'date-fns';

export default class UserNotification extends Model {
  @attr('number') search_keyword_url_id;
  @attr('number') search_keyword_id;
  @attr('string') message;
  @attr('string') notificationType;
  @attr('string') resourceName;
  @attr('string') title;
  @attr('string') body;
  @attr('boolean') expired;
  @attr('string') status;
  @attr('date') createdAt;

  get statusNew() {
    return this.status === 'new';
  }
  get isIndexedPagesChange() {
    return this.notificationType === 'index_pages_change';
  }
  get isNewKeywordSuggestions() {
    return this.notificationType === 'new_keyword_suggestions';
  }
  get isSessionsSpike() {
    return this.notificationType === 'sessions_spike';
  }
  get isNewBacklinks() {
    return this.notificationType === 'new_backlinks';
  }

  get createdAtDateString() {
    return format(this.createdAt, 'yyyy-MM-dd');
  }

  get isIncrease() {
    let increased = false;
    if (this.isIndexedPagesChange) {
      increased = this.body.indexOf('increased from') !== -1;
    } else if (this.isSessionsSpike || this.isNewBacklinks) {
      increased = true;
    }
    return increased;
  }

  get isDecrease() {
    let decreased = false;
    if (this.isIndexedPagesChange) {
      decreased = this.body.indexOf('decreased from') !== -1;
    }
    return decreased;
  }
}
