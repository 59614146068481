import Service, { inject as service } from '@ember/service';
import { DAILY, WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';
import DOCS from 'nightwatch-web/constants/docs';
import { differenceInDays } from 'date-fns';
import { htmlSafe } from '@ember/template';
import chevronIconHtml from '../../utils/chevron-icon-html';
import ENV from '../../config/environment';
import { competitorColumns as availableCompetitorColumns } from '../../utils/tables/keyword-table-columns';
import {
  DEFAULT_GROUPING_TYPE,
  GROUPING_TYPES,
} from '../../constants/chart/grouping';

export default class ReportUtilsService extends Service {
  @service notifications;
  @service siteData;

  defaultColumns = [
    'keyword-position',
    'keyword-mini-graph',
    'keyword-last-position-change',
    'keyword-dwm-change',
  ];
  templates = [
    {
      name: 'basic_report',
    },
    {
      name: 'progress_report',
    },
    {
      name: 'competitor_report',
    },
  ];
  allBlocks = [
    {
      name: 'keyword_overview',
    },
    {
      name: 'keyword_list',
    },
    {
      name: 'traffic_overview',
    },
    {
      name: 'backlink_overview',
    },
    {
      name: 'graph',
    },
    {
      name: 'site_audit_overview',
    },
    {
      name: 'site_audit_pages',
    },
    {
      name: 'compare_periods',
    },
  ];
  availableGroupings = GROUPING_TYPES;
  defaultGrouping = DEFAULT_GROUPING_TYPE;
  blockIcons = {
    keyword_overview: 'icon-file-text',
    keyword_list: 'icon-list',
    traffic_overview: 'icon-rankings',
    backlink_overview: 'icon-backlink',
    graph: 'icon-analytics-traffic',
    site_audit_overview: 'svg-notes',
    site_audit_pages: 'icon-file-text',
    compare_periods: 'icon-compare',
  };
  prepareLogos(logos) {
    const result = logos.map((logo) => ({
      id: Number(logo.get('id')),
      imgSrc: `${ENV.serverURL}${logo.get('logo')}`,
    }));
    if (!this.siteData.hideNightwatchBranding) {
      result.push({
        id: -1,
        imgSrc: '/assets/images/nightwatch-report-logo.png',
      });
    }
    return result;
  }
  getReportInterval(report) {
    const start = new Date(report.get('start_time'));
    const end = new Date(report.get('end_time'));
    const daysDiff = Math.abs(differenceInDays(start, end));
    if (daysDiff == 1) return DAILY;
    if (daysDiff == 7) return WEEKLY;
    if (daysDiff == 30) return MONTHLY;
    return null;
  }
  extractAvailableCompetitorColumns(competitors) {
    const competitorColumns = [];

    competitors.forEach((competitor) => {
      const competitorChildColumns = [];

      // No support for these in the backend currently
      const unsupportedColumns = [
        'keyword-position-places-image',
        'keyword-position-local-pack',
        'keyword-dwm-change',
      ];

      availableCompetitorColumns
        .filter((c) => unsupportedColumns.indexOf(c.name) === -1)
        .forEach((competitorColumn) => {
          competitorChildColumns.push({
            id: `${competitorColumn.name}-competitor-${competitor.id}`,
            text: competitorColumn.displayName,
          });
        });

      competitorColumns.push({
        groupName: `Competitor: ${competitor.get('displayName')}`,
        options: competitorChildColumns,
      });
    });

    return competitorColumns;
  }
  toggleKeywordLimitNotification(count, limit) {
    const className = 'report-too-many-keywords-error-notification';
    const notificationShown = document.querySelectorAll(`.${className}`).length;
    if (count && count >= limit) {
      if (notificationShown) return;

      const msg = htmlSafe(
        `The total limit for reports is ${limit} keywords. We suggest creating segmented reports using <b>keyword views ${
          this.siteData.hideNightwatchBranding ? '' : chevronIconHtml
        }</b>`
      );
      this.notifications.error(msg, {
        cssClasses: `${className}`,
        onClick: () => {
          if (this.siteData.hideNightwatchBranding) return;

          window.open(DOCS.keywordViewsAndLists, '_blank');
          this.notifications.clearAll();
        },
      });
    } else {
      Array.from(document.querySelectorAll(`.${className}`)).forEach((e) =>
        e.remove()
      );
    }
  }
  toggleHighKeywordCountWarningNotification(keywordCount) {
    const className = 'report-too-many-keywords-warning-notification';
    const keywordCountNotificationThreshold = 5000;
    const notificationShown = document.querySelectorAll(`.${className}`).length;
    if (keywordCount >= keywordCountNotificationThreshold) {
      if (notificationShown) return;

      const msg = htmlSafe(
        `Reports with more than ${keywordCountNotificationThreshold} keywords will take longer to create. We suggest creating segmented reports using <b class="blue-alt">keyword views ${
          this.siteData.hideNightwatchBranding ? '' : chevronIconHtml
        }</b>`
      );
      this.notifications.warning(msg, {
        cssClasses: `${className}`,
        onClick: () => {
          if (this.siteData.hideNightwatchBranding) return;

          window.open(DOCS.keywordViewsAndLists, '_blank');
          this.notifications.clearAll();
        },
      });
    } else {
      Array.from(document.querySelectorAll(`.${className}`)).forEach((e) =>
        e.remove()
      );
    }
  }
}
