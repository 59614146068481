import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import localStorage from 'ember-local-storage-decorator';
import isTesting from '../utils/is-testing';
import { DEFAULT_GROUPING_TYPE } from '../constants/chart/grouping';

/**
  @class NwKeywordsOverviewComponent

  @argument url

  @argument startDate
  @argument endDate
  @argument keywordStats
  @argument statsLoaded
  @argument isLoading
  @argument placeholder

  @argument notes
 */
export default class NwKeywordsOverviewComponent extends Component {
  @service('chart/nw-graph-series') nwGraphSeries;
  @service('overview/view-overview-state') viewOverviewState;
  @service siteData;

  @tracked grouping = DEFAULT_GROUPING_TYPE;

  @localStorage('nw:keywords-overview:show-graph') showGraph = true;
  @localStorage('nw:keywords-overview:selected-metric') selectedMetric =
    'average-position-box';

  constructor(owner, args) {
    super(owner, args);
    if (isTesting) this.showGraph = false;
  }

  get searchVisibilityCategory() {
    return this.viewOverviewState.searchVisibilityCategory;
  }

  get keywordStats() {
    return this.args.keywordStats;
  }

  get interval() {
    return { start: this.args.startDate, end: this.args.endDate };
  }

  get rankChangeMarks() {
    let down, firstMark, percentFactor, secondMark, stagnant, up;

    up = this.keywordStats?.keywords_went_up;
    down = this.keywordStats?.keywords_went_down;
    stagnant = this.keywordStats?.keywords_unchanged;
    percentFactor = 100 / (up + down + stagnant);
    firstMark = percentFactor * up;
    secondMark = percentFactor * down;

    return {
      firstMark: firstMark,
      secondMark: secondMark,
    };
  }

  get averagePositionBarWidth() {
    const average_position = this.keywordStats?.average_position;
    return average_position === 0 ? 0 : 100 - average_position;
  }

  get needToBreakUpDownRow() {
    let concat;
    concat =
      String(this.keywordStats?.keywords_went_up) +
      String(this.keywordStats?.keywords_went_down) +
      String(this.keywordStats?.keywords_unchanged);

    return concat.length >= 9;
  }

  get selectedGraph() {
    switch (this.selectedMetric) {
      case 'average-position-box':
        return {
          series: this.averagePositionSeries,
        };
      case 'search-visibility-box':
        return {
          series: this.searchVisibilitySeries,
          label: this.searchVisibilitySeries.firstObject.label,
        };
      case 'keyword-distribution-box':
        return {
          series: this.keywordDistributionSeries,
          label: 'Keyword Distribution',
        };
      case 'rank-box':
        return {
          series: this.keywordUpDownSeries,
          label: 'Keyword Up / Down',
        };
      case 'traffic-value':
        return {
          series: this.trafficValueSeries,
          label: 'Traffic Value',
        };
      default:
        throw `selectedGraph: unknown metric: ${this.selectedMetric}`;
    }
  }

  get singleKeywordDistributionSeries() {
    if (!this.keywordStats) return [];
    return this.nwGraphSeries.singleDistributionSeries(this.keywordStats);
  }

  get averagePositionSeries() {
    const data = this.keywordStats?.average_positions ?? [];
    return this.nwGraphSeries.averagePositionSeries(
      data,
      this.grouping,
      this.interval
    );
  }

  get searchVisibilitySeries() {
    const category = this.searchVisibilityCategory;
    const data = this.keywordStats?.[category] ?? [];
    return this.nwGraphSeries.searchVisibilitySeries(
      category,
      data,
      this.grouping,
      this.interval
    );
  }

  get keywordDistributionSeries() {
    let data = this.keywordStats?.keyword_distribution_series ?? [];
    return this.nwGraphSeries.distributionSeries(
      data,
      this.grouping,
      this.interval
    );
  }

  get keywordUpDownSeries() {
    let data = this.keywordStats?.keyword_up_down_series ?? [];
    return this.nwGraphSeries.upDownSeries(data, this.grouping, this.interval);
  }

  get trafficValueSeries() {
    let data = this.keywordStats?.traffic_value_series ?? [];
    return this.nwGraphSeries.trafficValueSeries(
      data,
      this.grouping,
      this.interval
    );
  }

  @action
  selectMetric(metricName) {
    this.selectedMetric = metricName;
    this.showGraph = true;
  }

  @action
  setSearchVisibilityCategory(category) {
    this.viewOverviewState.setSearchVisibilityCategory(category);
  }

  @action
  toggleExtendedOverview() {
    this.viewOverviewState.toggleShowExtendedOverview();
  }
}
