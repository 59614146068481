import ApplicationSerializer from './application';

// Change values can be a number or string which is quite weird
// Normalize values into numbers that are expected by the dwm-change-value helper
const normalizeChange = (value) => {
  if (typeof value !== 'string') return value;
  if (value === 'appeared') return 10_000;
  if (value === 'disappeared') return -10_000;
};

const transformAttributes = (attrs) => {
  const tags = attrs.tags?.some((e) => !!e.name)
    ? attrs.tags.mapBy('name') || []
    : attrs.tags || [];

  if (
    tags.find((t) => t === 'android-processing') &&
    attrs.result_url?.includes('&')
  ) {
    attrs.result_url = attrs.result_url.split('&')[0] || attrs.result_url;
    attrs.uri = attrs.uri.split('&')[0] || attrs.uri;
  }

  return {
    ...attrs,
    url_id: attrs.search_keyword_url_id,
    tags,
    url_value: attrs.url,
    url: null,
    last_day_change: normalizeChange(attrs.last_day_change),
    last_week_change: normalizeChange(attrs.last_week_change),
    last_month_change: normalizeChange(attrs.last_month_change),
    // For cases where API returns 'google_mobile' we should treat this as 'google'
    engine: attrs.engine === 'google_mobile' ? 'google' : attrs.engine,
  };
};

export default class KeywordSerializer extends ApplicationSerializer {
  normalizeArrayResponse(store, primaryType, payload) {
    payload.keywords = payload.keywords.map((k) => transformAttributes(k));
    return super.normalizeArrayResponse(store, primaryType, payload);
  }

  normalizeSingleResponse(store, primaryType, payload, recordId) {
    payload.keyword = transformAttributes(payload.keyword);
    return super.normalizeSingleResponse(store, primaryType, payload, recordId);
  }
}
