import Controller from '@ember/controller';
import { action } from '@ember/object';
import refreshRoute from 'nightwatch-web/utils/refresh-route';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class SettingsWhitelabelController extends Controller {
  @tracked domainError = null;
  @tracked nameError = null;
  @tracked logoError = null;

  validateWhitelabelDomain() {
    this.clearErrors();
    let valid = true;
    if (this.model.whitelabelDomain.enabled) {
      if (!this.model.whitelabelDomain.domain) {
        this.domainError = 'Domain missing';
        valid = false;
      }
      if (!this.model.whitelabelDomain.title) {
        this.nameError = 'Name missing';
        valid = false;
      }
      if (!this.model.whitelabelDomain.whitelabelLogoId) {
        valid = false;
        this.logoError = 'You have to set a default logo';
      }
    }
    return valid;
  }

  clearErrors() {
    this.domainError = null;
    this.nameError = null;
    this.logoError = null;
  }

  get shouldSaveWhitelabelDomain() {
    if (this.model.whitelabelDomain.isNew)
      return this.model.whitelabelDomain.enabled;
    return true;
  }

  @action
  switchDefaultLogo(whitelabelLogo) {
    this.model.whitelabelDomain.whitelabelLogoId = whitelabelLogo.id;
    this.model.whitelabelDomain.save();
  }

  @action
  refreshRoute() {
    refreshRoute(this, 'settings.whitelabel');
  }

  @task
  *save() {
    if (!this.validateWhitelabelDomain()) return;
    try {
      if (this.shouldSaveWhitelabelDomain)
        yield this.model.whitelabelDomain.save();
    } catch (error) {
      this.notifications.error('Settings update failed.');
      throw error;
    }
    this.notifications.success('Settings sucessfully updated.', {
      autoClear: true,
    });
  }
}
