import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DashboardUrlReportsNewController extends Controller {
  @service router;

  queryParams = ['viewId'];
  @tracked viewId = null;

  @action
  onReportSave(report) {
    const reports = report.get('url.reports').toArray();
    reports.pushObject(report);
    report.set('url.reports', reports);
    const url = report.get('url');
    url.reload(); // Refresh report_count
    this.router.transitionTo('dashboard.url.reports');
  }
}
