import Transform from '@ember-data/serializer/transform';
import { parse, format, isValid, isDate } from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';

export default class DayDateTransform extends Transform {
  serialize(value) {
    if (!value || !isDate(value) || !isValid(value)) return null;

    return format(value, DATE_FORMAT);
  }

  deserialize(dateString) {
    if (!dateString) return null;

    return parse(dateString, DATE_FORMAT, new Date());
  }
}
