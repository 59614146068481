import Component from '@glimmer/component';

/**
  @argument scaleClass
  @argument value
  @argument maxValue
  @argument minValue
  @argument minValueLabel
  @argument maxValueLabel
  @argument showScale
 */
export default class ProgressBarComponent extends Component {
  get minValue() {
    return this.args.minValue ?? 0;
  }

  get maxValue() {
    return this.args.maxValue ?? 100;
  }

  get scaleCoef() {
    return 100 / (this.maxValue - this.minValue);
  }

  get barWidth() {
    return Math.round(this.scaleCoef * (this.args.value - this.minValue));
  }
}
