import { modifier } from 'ember-modifier';

const defaultSvgAttributes = {
  class: 'nw-evolution__svg',
  viewBox: '0 0 120 40',
  preserveAspectRatio: 'xMinYMid meet',
};

/**
 * Build an array of objects describing single point rendering properties.
 * @param  {Array}  [series=[]]   Series of plot points.
 * @param  {Number} [min=0]       Min plot point value.
 * @param  {Number} [max=100]     Max plot point value.
 * @param  {Number} [yMax=100]    Height of the area for plot points (local SVG coords).
 * @param  {Number} [xStep=20]    Horizontal distance between plot points (local SVG coords).
 * @param  {Number} [xAdjust=0]   Left offset for first plot point (local SVG coords).
 * @param  {Number} [yAdjust=0]   Top offset for plot points (local SVG coords).
 * @return {[Array]}
 */
const plotPoints = (
  series = [],
  min = 0,
  max = 100,
  yMax = 100,
  xStep = 20,
  xAdjust = 0,
  yAdjust = 0
) =>
  series
    .reduce(
      (acc, value, idx) => {
        // Extract x and y from previous iteration as prevX and prevY
        const { x: prevX, y: prevY, value: prevValue } = acc[idx];
        // Set x and y for current iteration
        const x = prevX + xStep;
        const y =
          value < 0 // Is unranked?
            ? yMax + yAdjust + 4 // Put point into no rank zone
            : min === max // Is graph flat?
            ? Math.round(yMax / 2) + yAdjust // Put point in the middle of canvas
            : Math.round(((value - min) * yMax) / (max - min)) + yAdjust;
        // Construct point object
        const point = { x, y, value, growth: 'same' };
        // In the first iteration there is no previous point so continue to the next iteration
        if (idx === 0) {
          acc.push(point);
          return acc;
        }
        // Determine the change between previous and current point
        if (value < 0 && prevValue > -1) {
          point.growth = 'down'; // Drop into red zone
        } else if (prevValue < 0 && value > -1) {
          point.growth = 'up'; // Rise out of red zone
        } else {
          // The usual ups and downs
          if (value < prevValue) point.growth = 'up';
          if (value > prevValue) point.growth = 'down';
        }
        // Construct final point object and go to the next iteration
        acc.push({ ...point, prevX, prevY });
        return acc;
      },
      // Start reduce with a blank that applies the left offset
      [{ x: xAdjust - xStep }]
    )
    // Remove the first blank we initiated reduce with
    .slice(1);

/**
 * Create SVG paths to connect points in the graph.
 * @param  {Array}    [series=[]]   An array of plot point objects
 * @return {[Array]}
 */
const plotSegments = (series = []) =>
  series
    .map(({ x, y, prevX, prevY, growth }) => ({
      growth,
      path: `M${prevX},${prevY} ${x},${y}`,
    }))
    .reverse(); // Reverse order for proper visual layering

/**
 * Helper function to construct an SVG tree
 * @param  {String}       tag         SVG element tag
 * @param  {Object}       [attrs={}]  Element attributes
 * @param  {Node|String}  children    Child node(s); SVG element(s) or text
 * @return {[Node]}                   SVG node
 */
const svg = (tag, attrs = {}, ...children) => {
  const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
  Object.keys(attrs).forEach((attr) => {
    el.setAttributeNS(null, attr, attrs[attr]);
  });
  children.forEach((c) => {
    if (!c.toString()) return; // Skip empty strings
    if (typeof c !== 'object') {
      c = document.createTextNode(c.toString());
    }
    el.appendChild(c);
  });
  return el;
};

// Construct mini graph SVG tree
const renderEvolutionGraph = (
  segments = [],
  min = 0,
  max = 100,
  redZone = true
) =>
  svg(
    'svg',
    defaultSvgAttributes,
    svg('text', { class: 'nw-evolution__tx', x: 20, y: 13 }, min),
    svg('text', { class: 'nw-evolution__tx', x: 20, y: 34 }, max),
    svg('rect', {
      class: 'nw-evolution__axis',
      width: 90,
      height: 1,
      x: 25,
      y: 9,
    }),
    svg('rect', {
      class: 'nw-evolution__axis',
      width: 90,
      height: 1,
      x: 25,
      y: 30,
    }),
    redZone
      ? svg('rect', {
          class: 'nw-evolution__red-zone',
          width: 90,
          height: 6,
          x: 25,
          y: 31,
        })
      : '',
    svg(
      'g',
      {},
      ...segments.map((s) =>
        svg('path', {
          class: `nw-evolution__plot nw-evolution__plot--${segments[0].growth}`,
          d: s.path,
        })
      )
    )
  );

// Construct mini graph SVG tree where all points have the same value
const renderFlatEvolutionGraph = (segments = [], val = 0, redZone = true) =>
  svg(
    'svg',
    defaultSvgAttributes,
    svg(
      'text',
      { class: 'nw-evolution__tx', x: 20, y: 24 },
      val < 0 ? '/' : val
    ),
    svg('rect', {
      class: 'nw-evolution__axis',
      width: 90,
      height: 1,
      x: 25,
      y: 20,
    }),
    redZone
      ? svg('rect', {
          class: 'nw-evolution__red-zone',
          width: 90,
          height: 6,
          x: 25,
          y: 31,
        })
      : '',
    svg(
      'g',
      {},
      ...segments.map((s) =>
        svg('path', {
          class: `nw-evolution__plot nw-evolution__plot--${segments[0].growth}`,
          d: s.path,
        })
      )
    )
  );

// Construct mini graph SVG tree with a single point
const renderPointEvolutionGraph = ({ x, y, value }) =>
  svg(
    'svg',
    defaultSvgAttributes,
    svg(
      'text',
      { class: 'nw-evolution__tx', x: 20, y: 24 },
      value < 0 ? '/' : value
    ),
    svg('rect', {
      class: 'nw-evolution__axis',
      width: 90,
      height: 1,
      x: 25,
      y: 20,
    }),
    value < 0
      ? svg('rect', {
          class: 'nw-evolution__red-zone',
          width: 90,
          height: 6,
          x: 25,
          y: 17,
        })
      : '',
    svg('circle', {
      class: `nw-evolution__dot nw-evolution__dot--${
        value < 0 ? 'same' : 'up'
      }`,
      cx: x,
      cy: y,
      r: 3.5,
    })
  );

/**
 * Contruct a mini graph from a series array and append it to provided Node
 * @param  {Node}   node                          DOM element to append the graph to
 * @param  {Array}  [series=[]]                   Array of graph points
 * @param  {Date}   [seriesLastDate=new Date()]   Date of the last point in the series
 */
export const evolutionGraphSvg = (
  node,
  series = [],
  seriesLastDate = new Date()
) => {
  // Exit if empty series
  if (!node || !series.length) return;
  // Replace nulls with numbers
  const numSeries = series.map((p) =>
    Number.isNaN(Number.parseInt(p, 10)) ? -1 : p
  );
  // Subset only positive integers from series
  const subSeries = numSeries.filter((p) => p > -1);
  // Calculate limits which will have to be rescaled to plot canvas coordinates
  const min = subSeries.length ? Math.min(...subSeries) : -1;
  const max = Math.max(...numSeries, min);
  const xStep = 10; // Distance between points on the graph
  const yMax = 20; // Plottable canvas height
  const xAdjust = (10 - series.length) * xStep + 25; // First point left offset
  const yAdjust = 10; // Graph points top offset
  // Turn series into an array of objects describing each point
  const points = plotPoints(numSeries, min, max, yMax, xStep, xAdjust, yAdjust);
  // If there's only 1 point, change its y coordinate to middle of canvas
  if (points.length === 1) {
    points[0].y = Math.round(yMax / 2) + yAdjust;
  }
  // Construct SVG graph
  const graph =
    points.length > 1
      ? min === max
        ? renderFlatEvolutionGraph(
            plotSegments(points.slice(1)),
            min,
            subSeries.length < numSeries.length
          )
        : renderEvolutionGraph(
            plotSegments(points.slice(1)),
            min,
            max,
            subSeries.length < numSeries.length
          )
      : renderPointEvolutionGraph(points[0]);
  // Skip tooltips for browsers without Element.dataset (*caugh* SeaMonkey *caugh*)
  if (graph.dataset) {
    // Add a class to be used as a hook for tooltips
    graph.classList.add('js--evolution');
    // Store series values on the SVG element for tooltips
    graph.dataset.points = JSON.stringify(points);
    graph.dataset.seriesLastDate = seriesLastDate.getTime().toString();
  }
  // Attach SVG graph to DOM
  node.appendChild(graph);
};

const evolutionGraph = (element, [series, serieslastDate]) => {
  if (!element) return;

  const removeChildren = () => {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  };

  removeChildren();
  evolutionGraphSvg(element, series, serieslastDate);

  return removeChildren;
};

export default modifier(evolutionGraph, { eager: false });
