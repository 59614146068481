import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { formatISO } from 'date-fns';

export default class UserNotificationsComponent extends Component {
  @service userNotifications;
  @service siteData;
  @service session;

  get noNewNotifications() {
    return !this.session.user.new_user_notifications_count;
  }

  get notificationsCountDisplay() {
    const count = this.session.user.new_user_notifications_count;
    if (count) {
      if (count > 10) {
        return '10+';
      } else {
        return count;
      }
    } else {
      return 0;
    }
  }

  @action
  open() {
    this.userNotifications.fetchAndSetInitially();

    // If admin, don't set last notifications read time
    if (this.session.isAdminViewingUser) return;

    const { user } = this.session;
    user.last_notifications_read_at = formatISO(new Date());
    user.new_user_notifications_count = 0;
    user.save();
  }

  @action
  onNotificationClick(notification) {
    this.userNotifications.goToNotification(notification);
  }

  @action
  onScroll(e) {
    // Automatically load more when scroll slightly above bottom detected
    const element = e.currentTarget;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 60) {
      this.loadMore();
    }
  }

  loadMore() {
    this.userNotifications.loadMore();
  }
}
