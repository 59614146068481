import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import flattenPowerSelectOptions from 'nightwatch-web/utils/flatten-power-select-options';

export default class CompareKeywords extends Component {
  @tracked selectedDropdownColumns = [];
  @tracked keywordSortColumn;
  @tracked keywordSortDirection;

  constructor() {
    super(...arguments);
  }

  @action
  handleChangeDate(position, date) {
    const [period, part] = position.split('.');
    const mutatedDate = this.mutateDate(date);

    this.args.blockObject[period][part] = mutatedDate;
    this.args.handleKeywordComparisonChangeDate();
  }

  mutateDate(date) {
    let dateObj = new Date(date);

    // Ensure the date is valid
    if (!isNaN(dateObj)) {
      const month = dateObj.getMonth() + 1; // getMonth() returns 0-11
      const day = dateObj.getDate(); // getDate() returns 1-31
      const year = dateObj.getFullYear(); // getFullYear() returns the year

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

      return formattedDate;
    } else {
      return null;
    }
  }
}
