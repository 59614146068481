import { action } from '@ember/object';
import Route from '@ember/routing/route';
import backlinkViewRouteMixin from 'nightwatch-web/mixins/backlink-view-route';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinkViewRoute extends Route.extend(
  backlinkViewRouteMixin
) {
  @service metrics;

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Open Backlink View',
      name: this.controller.get('backlinkView.name'),
    });
    return true;
  }
}
