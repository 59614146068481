import Route from '@ember/routing/route';
import { all } from 'rsvp';
import { inject as service } from '@ember/service';

export default class DashboardOverviewRoute extends Route {
  @service store;

  beforeModel() {
    /* Pinned items can be removed by the backend as part of a graphs cleaning
     * process, in order to get an updated listing of pinned items we need to
     * first unload the ones already in the store. Note that calling `findAll`
     * with `reload: true` option will not clear the store, its purpose is to
     * change when the promise the function returns is resolved. However we
     * still need the `reload` functionality, without it, after calling
     * `unloadAll`, `findAll` will just return an empty set.
     *
     * More info:
     * https://www.emberjs.com/api/ember-data/release/classes/DS.Store/methods/findAll?anchor=findAll
     */
    this.store.unloadAll('pinned-item');
  }

  async model() {
    const pinnedItems = await this.store.findAll('pinned-item', {
      reload: true,
    });

    await all(pinnedItems.map((p) => p.loadRelationships.perform()));

    return pinnedItems;
  }

  activate() {
    this.controllerFor('dashboard').unsetSelectedUrl();
  }
}
