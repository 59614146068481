import isTesting from 'nightwatch-web/utils/is-testing';
import getElement from './get-element';
import delay from '../delay';

const shakeElement = async (target) => {
  const el = getElement(target);
  if (!el) return;

  el.classList.add('nudge-shake');
  await delay(isTesting ? 0 : 400);
  el.classList.remove('nudge-shake');
};

export default shakeElement;
