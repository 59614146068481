import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { A } from '@ember/array';

export default class DashboardUrlKeywordsAddController extends Controller {
  @service discovery;
  @service metrics;

  addedKeywordSuggestions = A();

  get keywordSuggestionsCount() {
    return this.discovery.keywords.filter(
      (keyword) => keyword.isNew && keyword.query
    )?.length;
  }

  addKeywordSuggestion(keywordSuggestion) {
    const suggestions = this.addedKeywordSuggestions.toArray().concat();
    suggestions.pushObject(keywordSuggestion);
    this.set('addedKeywordSuggestions', suggestions.uniqBy('id'));
    this.metrics.trackEvent({ event: 'Add Keyword Suggestion' });
  }

  removeKeywordSuggestion(keywordSuggestion) {
    const suggestions = this.addedKeywordSuggestions.toArray().concat();
    suggestions.removeObject(keywordSuggestion);
    this.set('addedKeywordSuggestions', suggestions);
  }

  removeKeywordSuggestions(keywords) {
    const suggestions = this.addedKeywordSuggestions.toArray().concat();
    keywords.forEach((k) => {
      const suggestion = suggestions.findBy('query', k.query);
      if (suggestion) {
        suggestions.removeObject(suggestion);
      }
    });

    this.set('addedKeywordSuggestions', suggestions);
  }
}
