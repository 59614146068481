import RSVP from 'rsvp';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';

export default class NwGooglePlacesSearchComponent extends Component {
  @service fetch;
  @service notifications;

  @task({ restartable: true })
  *search(term) {
    yield timeout(200);
    const abortController = new AbortController();

    let response;
    try {
      response = yield this.fetch.request('/google_places/autocomplete', {
        data: { q: term },
        signal: abortController.signal,
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      abortController.abort();
    }

    if (response.status === 'OK') {
      const results = response?.predictions?.map((result) => ({
        place_id: result.place_id,
        place_description: result.description,
      }));
      if (isPresent(term)) {
        // Prepend it to the result so users can select an arbitrary input.
        return [{ place_id: null, place_description: term }, ...results];
      }
      return results;
    } else {
      return [{ place_id: null, place_description: term }];
    }
  }

  fetchPlaceDetails(result) {
    if (!result.place_id) {
      // For allowing custom input. Some users are tracking different places within the
      // same website.
      return RSVP.Promise.resolve({
        place_cid: null,
        place_title: result.place_description,
        place_description: result.place_description,
      });
    }
    return this.fetch
      .request(`/google_places/${result.place_id}`)
      .then((response) => ({
        place_cid: response.cid,
        place_title: response.title,
        place_description: result.place_description,
      }))
      .catch((error) => this.handleError(error));
  }

  handleError(error) {
    this.notifications.remove(this.get('notification'));
    const notification = this.notifications.error(
      'Something went wrong while fetching Google Place details. Reload the page and try again or contact support',
      { autoClear: true }
    );
    this.set('notification', notification);
    throw error;
  }

  toValue(result) {
    if (result.place_cid) {
      return `cid_${result.place_cid}`;
    } else if (result.place_title) {
      return result.place_title;
    } else {
      return null;
    }
  }

  @action
  onPlaceSelected(selected) {
    if (selected) {
      this.fetchPlaceDetails(selected).then((result) => {
        this.args.onChange(result);
        this.args.onValueChange(this.toValue(result));
      });
    } else {
      this.args.onChange(null);
      this.args.onValueChange(null);
    }
  }
}
