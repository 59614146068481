import { underscore, decamelize } from '@ember/string';

const underscorifyKeys = (object) => {
  return Object.keys(object).reduce((newObject, key) => {
    newObject[underscore(decamelize(key))] = object[key];
    return newObject;
  }, {});
};

export default underscorifyKeys;
