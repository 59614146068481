import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ImportComponent extends Component {
  @service session;

  get isConnected() {
    return this.session.user.googleAnalyticsEnabled;
  }
}
