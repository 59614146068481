import Service, { inject as service } from '@ember/service';
import { TRANSPARENT_COLOR } from '../utils/chart/colors';

export default class GraphColorManagerService extends Service {
  @service theme;

  get colorsMapping() {
    if (this.theme.isBright) {
      return {
        top_3: '#48AAED',
        top_10: '#1883CA',
        top_100: '#004979',
        no_rank: '#bbbbbb',
      };
    } else {
      return {
        top_3: '#48AAED',
        top_10: '#1883CA',
        top_100: '#004979',
        no_rank: '#444242',
      };
    }
  }

  colorForSeries(seriesName) {
    return this.colorsMapping[seriesName] ?? TRANSPARENT_COLOR;
  }
}
