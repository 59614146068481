import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinksDisabledController extends Controller {
  @service router;

  @action
  goToPlans() {
    this.router.transitionTo('plans', {
      queryParams: { event: 'Backlinks Upgrade CTA' },
    });
  }
}
