import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class FiltersSummaryComponent extends Component {
  @service filterData;
  conditionsDisplayMapper = {
    starts_with: 'starts with',
    not_starts_with: "doesn't start with",
    ends_with: 'ends with',
    not_ends_with: "doesn't end with",
    less: '<',
    less_or_equal: '<=',
    more: '>',
    more_or_equal: '>=',
    equals: 'is',
    not_equals: 'is not',
    present: 'is present',
    absent: 'is absent',
    contains: 'contains',
    not_contains: "doesn't contain",
  };

  get allFilters() {
    return this.args.filterGroups
      .filter((fg) => !fg.markedAsDeleted)
      .flatMap((fg) => fg.filters.toArray());
  }

  get hasActiveFilters() {
    return this.allFilters.mapBy('hasValues')[0];
  }
}
