import Component from '@glimmer/component';

export default class PageDetailsLinksComponent extends Component {
  get shownStats() {
    return this.args.totalInternalUrlsCount > 0;
  }

  get healthyPageCount() {
    return this.args.totalInternalUrlsCount - this.args.brokenPages?.length;
  }
}
