import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from '../config/environment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * DeepResearchService handles research-related data exports (CSV, PDF, JSON),
 * keyword classification, URL extraction, and other deep research tasks.
 */
export default class DeepResearchService extends Service {
  @service session;
  @service siteData;
  @service notifications;
  _reportLogoUrl = '/assets/images/nightwatch-report-logo.png';

  @tracked progress = 0;
  @tracked totalTasks = 0;
  @tracked totalKeywordsClassified = 0;
  @tracked totalKeywords = 0;

  @tracked keywords = null;
  @tracked recommendations = null;
  @tracked pages = null;

  /**
   * Centralized error notification logic.
   * @param {Object} error - The error object.
   * @param {string} defaultMessage - The default error message.
   * @param {Array<{ match: string, message: string }>} specialCases - Specific cases to handle.
   * @returns {string} The final error message.
   */
  notifyError(error, defaultMessage, specialCases = []) {
    let errorMessage = defaultMessage;

    // Check for specific cases
    if (error?.messages && Array.isArray(error.messages)) {
      const specialCase = specialCases.find((caseItem) =>
        error.messages.some((msg) => msg.includes(caseItem.match))
      );

      if (specialCase) {
        errorMessage = specialCase.message;
      }
    }

    // Notify user only once
    this.notifications.error(errorMessage, {
      autoClear: false,
      htmlContent: true,
    });

    return errorMessage; // Return the error message to avoid rethrowing or duplicate notifications.
  }

  /**
   * Helper to chunk an array into sub-arrays of the specified size.
   * @param {Array} array - The array to be chunked.
   * @param {number} size - The size of each chunk.
   * @returns {Array<Array>} The chunked arrays.
   */
  chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  @task({ drop: false })
  *classifyKeywords(keywordIdeas, websiteDomain) {
    const keywordChunks = this.chunkArray(keywordIdeas, 100);
    this.totalTasks = keywordChunks.length;
    this.totalKeywords = keywordIdeas.length;
    this.progress = 0;
    this.totalKeywordsClassified = 0;

    const classifiedKeywordsPromises = keywordChunks.map((chunk) =>
      this.classifyKeywordChunk(chunk, websiteDomain)
    );

    const results = yield Promise.allSettled(classifiedKeywordsPromises);
    const allClassifiedKeywords = results
      .filter((result) => result.status === 'fulfilled')
      .flatMap((result) => result.value);

    this.progress = 0;
    this.totalTasks = 0;

    return allClassifiedKeywords;
  }

  async classifyKeywordChunk(chunk, websiteDomain) {
    const keywordString = chunk.reduce(
      (acc, kwObj) => (acc ? `${acc}, ${kwObj.keyword}` : kwObj.keyword),
      ''
    );

    const response = await fetch(
      `${config.apiServiceGatewayURL}/keywords/classification`,
      {
        method: 'POST',
        headers: {
          Authorization: `${this.session.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ keywords: keywordString, websiteDomain }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      this.progress += 1;
      this.totalKeywordsClassified += chunk.length;
      return data.data;
    } else {
      throw new Error('Error during keyword classification');
    }
  }

  /**
   * Fetches URLs from the API.
   * @param {string} url - The base URL to extract subpages from.
   * @returns {Promise<Array<string>>} Extracted URLs.
   */
  @task({ drop: false })
  *getUrls(url) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/extract-url`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ url }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        const errorData = yield response.json();
        this.notifyError(errorData, 'Error fetching URLs', [
          {
            match: 'unfortunately the website is not reachable',
            message: 'Unable to fetch subpages, the URL is not reachable.',
          },
        ]);
      }
    } catch (e) {
      this.notifyError(e, 'An unexpected error occurred while fetching URLs');
    }
  }

  /**
   * Extracts keywords from the given URL.
   * @param {string} url - The URL to extract keywords from.
   * @returns {Promise<Array<Object>>} Extracted keywords.
   */
  @task({ drop: false })
  *extractKeywords(url) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/generate-keywords`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ url }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        const errorData = yield response.json();
        this.notifyError(errorData, 'Error fetching keywords');
      }
    } catch (e) {
      this.notifyError(
        e,
        'An unexpected error occurred while fetching keywords'
      );
    }
  }

  /**
   * Fetches CPC data for the given keywords.
   * @param {Array<string>} keywords - The keywords to fetch CPC data for.
   * @returns {Promise<Array<Object>>} Keywords with CPC information.
   */
  @task({ drop: false })
  *getKeywordsCpc(keywords) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/generate-keywords-cpc`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ keywords }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        throw new Error('Error fetching CPC data');
      }
    } catch (e) {
      this.notifyError(e, 'Error fetching CPC data');
    }
  }

  /**
   * Generates keyword ideas based on the input keywords.
   * @param {Array<string>} keywords - The input keywords to generate ideas from.
   * @param {Array<string>} geoTargets - The input geo targets to generate ideas from.
   * @param {String} url - The input keywords to generate ideas from.
   * @returns {Promise<Array<Object>>} Generated keyword ideas.
   */
  @task({ drop: false })
  *extractAdvancedKeywords(seedUrl, keywords, geoTargets) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/generate-ideas`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ geoTargets, keywords, seedUrl }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        const errorData = yield response.json();
        this.notifyError(errorData, 'Error fetching advanced keywords');
      }
    } catch (e) {
      this.notifyError(
        e,
        'An unexpected error occurred while fetching advanced keywords'
      );
    }
  }

  /**
   * Retrieves keyword recommendations for given URLs and keywords.
   * @param {string} urls - The URLs to analyze.
   * @param {string} keywords - The keywords to analyze.
   * @returns {Promise<Array<Object>>} Keyword recommendations.
   */
  @task({ drop: false })
  *getRecommendations(urls, keywords) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/analyze`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({ urls, keywords }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        const errorData = yield response.json();
        this.notifyError(errorData, 'Error fetching recommendations');
      }
    } catch (e) {
      this.notifyError(
        e,
        'An unexpected error occurred while fetching recommendations'
      );
    }
  }

  /**
   * Retrieves advanced keyword recommendations with target segmentation.
   * @param {Array<string>} urls - The URLs to analyze.
   * @param {Array<string>} keywords - The keywords to analyze.
   * @param {string} country - The target country for the analysis.
   * @param {string} language - The target language for the analysis.
   * @returns {Promise<Array<Object>>} Advanced keyword recommendations.
   */
  @task({ drop: false })
  *getAdvancedRecommendations(urls, keywords, country, language) {
    try {
      const response = yield fetch(
        `${config.apiServiceGatewayURL}/keywords/analyze-v2`,
        {
          method: 'POST',
          headers: {
            Authorization: `${this.session.token}`,
          },
          body: JSON.stringify({
            urls,
            targetSegment: { country, keywords, language },
          }),
        }
      );

      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        const errorData = yield response.json();
        this.notifyError(errorData, 'Error fetching advanced recommendations');
      }
    } catch (e) {
      this.notifyError(
        e,
        'An unexpected error occurred while fetching advanced recommendations'
      );
    }
  }

  async setPdfHead(doc, title, subtitle) {
    const pageWidth = doc.internal.pageSize.getWidth();

    try {
      // Fetch the image and convert to Base64
      const base64Img = await this.getBase64ImageFromUrl();

      // Define image dimensions
      const imgWidth = 64; // Desired width of the image in mm
      const imgHeight = 8; // Desired height of the image in mm

      // Calculate x position to center the image
      const imgX = (pageWidth - imgWidth) / 2; // This centers the image

      // Add the Base64 image to the PDF
      doc.addImage(base64Img, 'PNG', imgX, 10, imgWidth, imgHeight, '', 'NONE');

      // Add the title below the logo and center it
      doc.setFontSize(16);
      const titleWidth = doc.getTextWidth(title);
      doc.text(title, (pageWidth - titleWidth) / 2, 30); // Center the title

      // Add a subtitle or some spacing before the table and center it
      doc.setFontSize(12);
      const subtitleWidth = doc.getTextWidth(subtitle);
      doc.text(subtitle, (pageWidth - subtitleWidth) / 2, 40); // Center the subtitle
    } catch (e) {
      this.notifications.error('Error generating PDF.', {
        autoclear: true,
      });
      console.error('Error setting PDF head', e);
    }
  }

  async getBase64ImageFromUrl() {
    const response = await fetch(this._reportLogoUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Consolidates export logic for generating and downloading CSV, PDF, or JSON files.
   *
   * @param {Array<Object>} item - The array of data objects to be exported.
   * @param {string} format - The format in which to export the data ('csv', 'pdf', 'json').
   * @param {Array<string>} columns - The array of column headers for CSV and PDF exports.
   * @param {function(Object): Array<string>} rowsGenerator - A function that generates an array of row data from each object in `item`.
   * @param {string} title - The title to be displayed in the PDF export.
   * @param {string} subtitle - The subtitle to be displayed in the PDF export.
   * @param {string} fileNameBase - The base name of the file (without extension) for downloading.
   *
   * @returns {Promise<Object>} An object containing the file `data` (Blob) and the `fileName` (string).
   */
  async exportData(
    item,
    format,
    columns,
    rowsGenerator,
    title,
    subtitle,
    fileNameBase
  ) {
    let fileName;
    let data;

    if (Array.isArray(item)) {
      // This is a single-section export (either PDF, CSV, or JSON).
      if (format === 'csv') {
        // Convert to CSV
        data = new Blob([this.convertToCsv(columns, item, rowsGenerator)], {
          type: 'text/csv;charset=utf-8;',
        });
        fileName = `${fileNameBase}.csv`;
      } else if (format === 'pdf') {
        const doc = new jsPDF({
          unit: 'mm',
          format: 'a4',
        });

        // Add the logo, title, and subtitle to the PDF
        await this.setPdfHead(doc, title, subtitle);

        // Generate table rows using the passed rows generator
        const rows = item.map(rowsGenerator);

        // Add the table to the PDF using autoTable
        doc.autoTable({
          startY: 45,
          head: [columns],
          body: rows,
          theme: 'grid',
          styles: { fillColor: [255, 255, 255] },
          headStyles: {
            fillColor: [0, 0, 0],
            textColor: [255, 255, 255],
            minCellWidth: 12,
          },
          bodyStyles: { textColor: [0, 0, 0] },
        });

        // Generate the PDF blob
        data = doc.output('blob');
        fileName = `${fileNameBase}.pdf`;
      } else if (format === 'json') {
        // Fallback to JSON
        data = new Blob([JSON.stringify(item, null, 2)], {
          type: 'application/json',
        });
        fileName = `${fileNameBase}.json`;
      }

      // Download the file
      this.downloadHistoryItem(data, fileName);
      return;
    }

    // This is a multi-section export (e.g., classification, pages, recommendations, search_keywords).
    if (format === 'pdf') {
      const doc = new jsPDF({
        unit: 'mm',
        format: 'a4',
      });

      // Add PDF header (logo, title, subtitle)
      await this.setPdfHead(doc, title, subtitle);

      // Define table configurations for each section of the object
      const sections = [
        {
          name: 'Pages',
          data: item.pages,
          columns: ['Nº', 'URL Segment'],
          rowsGenerator: (entry) => [entry.index, entry.url],
        },
        {
          name: 'Recommendations',
          data: item.recommendations,
          columns: ['Target Page', 'Suggested Keywords', 'Existing Page'],
          rowsGenerator: (entry) => [
            entry.target_page,
            entry.target_keywords.join(', '),
            entry.have_existing_page ? 'Yes' : 'No',
          ],
        },
        {
          name: 'Search Keywords',
          data: item.search_keywords,
          columns: [
            'Keywords',
            'Average Monthly Searches',
            'lowCpc',
            'highCpc',
          ],
          rowsGenerator: (entry) => [
            entry.keyword,
            entry.avgMonthlySearches,
            entry.lowCpc,
            entry.highCpc,
          ],
        },
      ];

      let startY = 50; // Initial vertical position after the header

      // Loop through each section and add it to the PDF
      for (let section of sections) {
        if (section.data && section.data.length > 0) {
          // Add section title
          doc.setFontSize(14);
          doc.text(section.name, 14, startY);
          startY += 8; // Add some space before the table

          // Add the table using autoTable
          doc.autoTable({
            startY,
            head: [section.columns],
            body: section.data.map(section.rowsGenerator),
            theme: 'grid',
            styles: { fillColor: [255, 255, 255] },
            headStyles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              minCellWidth: 12,
            },
            bodyStyles: { textColor: [0, 0, 0] },
            margin: { top: 5 },
            didDrawPage: (data) => {
              startY = data.cursor.y + 10; // Update startY based on table's end position
            },
          });

          // Check if the new content fits on the current page; if not, add a page
          if (doc.internal.pageSize.getHeight() - startY < 20) {
            doc.addPage();
            startY = 20; // Reset Y position for the new page
          }
        }
      }

      // Generate the PDF blob
      data = doc.output('blob');
      fileName = `${fileNameBase}.pdf`;

      // Download the PDF
      this.downloadHistoryItem(data, fileName);
    }

    // JSON export for multi-section data
    if (format === 'json') {
      data = new Blob([JSON.stringify(item, null, 2)], {
        type: 'application/json',
      });
      fileName = `${fileNameBase}.json`;
      this.downloadHistoryItem(data, fileName);
    }
  }

  /**
   * Converts an array of objects into a CSV format string.
   *
   * @param {Array<string>} headers - The array of column headers for the CSV.
   * @param {Array<Object>} data - The array of data objects to be converted to CSV.
   * @param {function(Object): Array<string>} rowGenerator - A function that generates an array of row data from each object.
   *
   * @returns {string} The CSV formatted string.
   */
  // Helper function to convert data to CSV
  convertToCsv(headers, data, rowGenerator) {
    const csvRows = [];

    // Add headers as the first row
    csvRows.push(headers.join(','));

    // Generate rows using the rowGenerator function
    data.forEach((entry) => {
      const row = rowGenerator(entry);
      csvRows.push(row.join(',')); // Join row data by commas
    });

    return csvRows.join('\n');
  }

  /**
   * Downloads the selected item with format specified.
   * @param {Object} data - The selected item to download.
   * @param {String} fileName - The name of the file to download.
   */
  downloadHistoryItem(data, fileName) {
    // Create the download link
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   * Get credit balance for the user.
   */
  @task({ drop: false })
  *getBalance() {
    try {
      const response = yield fetch(
        `${config.apiBaseURL}service_usages/balance?access_token=${this.session.token}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${this.session.token}`,
          },
        }
      );
      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        throw new Error('Error fetching balance');
      }
    } catch (e) {
      throw e;
    }
  }
}
