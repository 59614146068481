import Component from '@glimmer/component';

export default class NwKeywordSavingProgressComponent extends Component {
  get savingKeywords() {
    return this.args.keywordTasks?.filter((task) => task.isRunning);
  }

  get savedKeywords() {
    return this.args.keywordTasks?.filter(
      (task) => task.isFinished && task.isSuccessful
    );
  }

  get barProgress() {
    return (this.savedKeywords?.length / this.args.keywordTasks?.length) * 100;
  }

  get savedKeywordsLength() {
    return this.savedKeywords?.length / 1;
  }
}
