import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, rawTimeout } from 'ember-concurrency';

export default class CopySerpComponent extends Component {
  @tracked hasCopied = false;

  @task({ drop: true })
  *copySuccess() {
    this.hasCopied = true;
    yield rawTimeout(900);
    this.hasCopied = false;
  }
}
