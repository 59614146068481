import { helper } from '@ember/component/helper';

export function formatAmount([number]) {
  const parsed = Number.parseFloat(number);

  if (isNaN(parsed)) {
    return '';
  }

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return parsed.toLocaleString('en-US', options);
}

export default helper(formatAmount);
