import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UrlFormComponent extends Component {
  @service metrics;
  @service session;
  @service siteData;

  @tracked urlInputError = false;
  @tracked saveErrors = false;
  @tracked deleteUrlModalShown = false;
  @tracked isSaving = false;
  @tracked saved = false;

  currentGroupId = this.args.url?.urlGroup?.id;
  oldGroup = null;

  get couldBeWrongYoutubeFormat() {
    const wrongYoutubeFormat = (url) =>
      url.indexOf('youtube') !== -1 &&
      url.indexOf('watch?v') === -1 &&
      url.indexOf('/channel/') === -1;

    return wrongYoutubeFormat(this.args.url.url.toLowerCase());
  }

  get currentGroupId() {
    return this.currentGroupId;
  }

  set currentGroupId(id) {
    this.currentGroupId = id;
  }

  countries(c) {
    return {
      id: c.firstObject,
      text: c.lastObject,
    };
  }

  get languages() {
    return this.siteData.languages.map((lang) => {
      return {
        id: lang?.firstObject,
        text: lang?.lastObject,
      };
    });
  }

  isValid() {
    if (!this.args.url?.url) {
      this.urlInputError = 'Enter URL';
      return false;
    }
    return true;
  }

  handleGroupChange() {
    if (this.currentGroupId === this.args.url?.urlGroup?.id) return;

    this.oldGroup = this.args.url.urlGroup;
    this.args.url.urlGroup = this.args.urlGroups?.findBy(
      'id',
      this.currentGroupId
    );
  }

  resetErrors() {
    this.saveErrors = null;
  }

  @action
  toggleUrlProperty(property) {
    this.args.url[property] = !this.args.url[property];
  }

  @action
  enableDetailedTracking() {
    this.args.url.places_keyword = null;
  }

  @action
  delete() {
    const deletedUrl = this.args.url.url;
    this.args.url.destroyRecord().then(() => {
      this.session.user.reload();
      this.args.urlGroup?.reload();
      this.metrics.trackEvent({
        event: 'Removed URLs',
        urls: deletedUrl,
      });
      this.args.onUrlDeleted();
    });
  }

  @action
  save() {
    this.resetErrors();
    if (!this.isValid()) return;
    this.args.url.urlGroup = this.args.urlGroup;
    this.isSaving = true;
    const savingStarted = new Date();
    this.handleGroupChange();
    this.args.url.save().then(
      (url) => {
        const savingEnded = new Date();
        const savingTime = savingEnded - savingStarted;
        // ensure saving animation for at least 1s
        const t = 1000;
        const animateFor = savingTime >= t ? 0 : t - savingTime;
        later(
          this,
          () => {
            this.isSaving = false;
            this.saved = true;
            this.args.onUrlUpdate(url, this.oldGroup);
          },
          animateFor
        );
      },
      (url) => {
        this.isSaving = false;
        this.saveErrors = `URL ${url.errors?.[0].detail}.`;
      }
    );
  }

  @action
  toggleDeleteUrlModal() {
    this.deleteUrlModalShown = !this.deleteUrlModalShown;
    return false;
  }
}
