import Component from '@glimmer/component';

export default class StatsBadgeComponent extends Component {
  get badgeClass() {
    return this.args.value > 0 ? this.args.badgeClass : 'default';
  }

  get isValueTooBig() {
    return this.args.value > 9;
  }

  get safeValue() {
    if (!this.args.value) {
      return 0;
    } else {
      return this.isValueTooBig ? '9+' : this.args.value;
    }
  }

  get needTooltip() {
    return Boolean(this.args.tooltip);
  }
}
