import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlBacklinkViewSettingsController extends Controller {
  @service router;

  get backlinkView() {
    return this.model;
  }

  @action
  save(backlinkView) {
    backlinkView
      .save()
      .then((backlinkView) => {
        this.notifications.success('Backlink View updated successfully.', {
          autoClear: true,
          clearDuration: 3500,
        });
        this.router.transitionTo(
          'dashboard.url.backlink-view',
          backlinkView.id
        );
      })
      .catch(() => {
        this.notifications.error('There was an error while updating.', {
          autoClear: true,
          clearDuration: 3500,
        });
      });
  }

  @action
  delete(backlinkView) {
    if (confirm('Are you sure you want to delete this backlink view?')) {
      const url = backlinkView.get('url');
      backlinkView
        .destroyRecord()
        .then(() => {
          url.get('backlinkViews').removeObject(backlinkView);
          this.notifications.success('Backlink View deleted successfully.', {
            autoClear: true,
            clearDuration: 3500,
          });
        })
        .catch(() => {
          this.notifications.error('There was an error while deleting.', {
            autoClear: true,
            clearDuration: 3500,
          });
        });
      this.router.transitionTo('dashboard.url.backlinks', url);
    }
  }
}
