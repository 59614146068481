import { setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import underscorifyKeys from '../utils/underscorify-keys';
import { inject as service } from '@ember/service';

export default class KeywordsResource {
  @service store;

  @tracked data = [];

  constructor({ owner, afterKeywordsLoaded }) {
    setOwner(this, owner);
    this.afterKeywordsLoaded = afterKeywordsLoaded;
  }

  get loading() {
    return this.load.isRunning;
  }

  get loaded() {
    return Boolean(this.load.lastSuccessful);
  }

  @task({ restartable: true })
  *load(params) {
    const abortController = new AbortController();
    try {
      this.data = yield this.store.query('keyword', underscorifyKeys(params), {
        adapterOptions: { signal: abortController.signal },
      });
    } finally {
      abortController.abort();
    }

    this.afterKeywordsLoaded?.(params);
    return this.data;
  }
}
