import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DashboardUrlReportController extends Controller {
  @service router;

  queryParams = ['viewId'];
  viewId = null;

  @action
  onReportSave(report) {
    this.router.transitionTo('dashboard.url.report', report);
  }
}
