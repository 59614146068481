export const domainsRegex = new RegExp(
  /([a-z0-9A-Z]\.)*[a-z0-9-]+\.([a-z0-9]{2,24})+(\.co\.([a-z0-9]{2,24})|\.([a-z0-9]{2,24}))*/g
);

export const specialCharactersRegex = new RegExp(/(?![.])[^A-z\s\d][\\\^]?/g);

export const startUrlRegex = new RegExp(
  '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?(xn--)?[\\p{L}\\p{N}+]+([-\\.]{1}[\\p{L}\\p{N}+]+)*\\.[\\p{L}\\p{N}+]{2,}(:[0-9]{1,5})?(\\/.*)?$',
  'u'
);

export const emailRegex = new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,12}$/);

export const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*\d).{8,}$/);
