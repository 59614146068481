import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isPresent, isBlank } from '@ember/utils';

export default class DashboardUrlSiteAuditViewRoute extends Route {
  @service router;
  @service store;
  @service userSettings;
  @service('persistence/site-audit-settings') siteAuditSettings;

  activate() {
    window.scrollTo(0, 0);
  }

  async beforeModel(transition) {
    const url = this.modelFor('dashboard.url');
    const siteAuditViewId = transition.to.parent.params.view_id;

    await this.userSettings.loadData({ siteAuditViewId });

    const { sort, direction, limit } = this.siteAuditSettings.getSettings({
      siteAuditViewId,
    });
    const queryParamsWithoutParentParams = Object.keys(
      transition.to.queryParams
    ).filter((p) => p != 'crawling_session_id');

    const shouldOverwriteQueryParams = isBlank(queryParamsWithoutParentParams); //&& isPresent(transition.params["dashboard.url.site-audit.view"]);

    if (shouldOverwriteQueryParams) {
      const newQueryParams = Object.assign({}, transition.to.queryParams);

      if (isBlank(transition.to.queryParams.sort) && isPresent(sort)) {
        newQueryParams.sort = sort;
        newQueryParams.direction = direction || 'desc';
      }
      if (isBlank(transition.to.queryParams.limit) && isPresent(limit)) {
        newQueryParams.limit = limit;
      }
      this.router.transitionTo(
        'dashboard.url.site-audit.view.show',
        url,
        siteAuditViewId,
        {
          queryParams: newQueryParams,
        }
      );
    }
  }

  model(params) {
    const url = this.modelFor('dashboard.url');
    return this.store
      .findRecord('site-audit/view', params.view_id)
      .then((view) => {
        view.set('url', url);
        view.loadCount();
        return view;
      });
  }

  get viewName() {
    return this.router.currentRoute?.attributes?.name;
  }
}
