import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
 * Report schedule form
 * @param {EmberModel} report
 * @param {String[=""]} recipientsError
 */

export default class ReportSchedule extends Component {
  @service reportData;

  @tracked recipientsError = '';
  @tracked editEmailModalShown = '';

  @action
  setRecipient() {
    this.args.report.recipients = Array.from(
      document.querySelectorAll('#recipient-email-input')
    )
      ?.map((input) => input.value.trim())
      ?.uniq();
  }

  @action
  addRecipient() {
    this.args.report.recipients = [...this.args.report.recipients, ''];
  }

  @action
  removeRecipient(recipient) {
    const recipients = this.args.report.recipients;
    recipients.removeObject(recipient);
    if (recipients.length === 0) {
      return recipients.pushObject('');
    }
  }

  @action
  toggleEmailModal() {
    this.editEmailModalShown = !this.editEmailModalShown;
  }
}
