import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import humanize from 'nightwatch-web/utils/humanize';
import titleize from 'nightwatch-web/utils/titleize';
import { underscore } from '@ember/string';

export default Component.extend({
  metrics: service(),
  classNames: ['nw-classic-table__switcher'],
  columnsForDropdown: computed(
    'availableColumns.[]',
    'selectedColumn',
    'selectedColumns.[]',
    function () {
      const availableColumns = this.availableColumns.filter(
        (c) => c === this.selectedColumn || this.selectedColumns.indexOf(c) < 0
      );
      return availableColumns.map((c) => {
        const displayName =
          this.dropdownDisplayNames[c] ||
          this.columnDisplayNames[c] ||
          titleize(humanize(underscore(c)));
        return { id: c, text: displayName };
      });
    }
  ),
  init() {
    this._super(...arguments);
    this.selectedColumnIndex = null;
    this.powerSelectApi = null;
    this.isOpen = false;
  },
  didReceiveAttrs() {
    this._super();
    this.set(
      'selectedColumnIndex',
      this.columnsForDropdown.findIndex((ac) => ac.id === this.selectedColumn)
    );
  },
  willDestroyElement() {
    this._super(...arguments);
    this.set('powerSelectApi', null);
  },
  openDropdown: action(function () {
    if (this.powerSelectApi) {
      if (this.isOpen) return;
      this.powerSelectApi.open();
    } else {
      // This will trigger open() on power select component
      // then the "onOpen" listener will call cacheApi()
      // which will cache the component's instance so
      // that we can manipulate it from here
      this.element
        .querySelector('.ember-power-select-trigger')
        .dispatchEvent(new MouseEvent('mousedown'));
    }
    this.element.classList.add('nw-classic-table__switcher--active');
    this.set('isOpen', true);
  }),
  closeDropdown: action(function () {
    if (!this.isOpen) return;
    this.element.classList.remove('nw-classic-table__switcher--active');
    this.set('isOpen', false);
  }),
  onColumnSwitch: action(function (column) {
    this.switchColumn(this.index, column.id);
    this.metrics.trackEvent({
      event: 'Switched Columns',
      table: this.metricsTableName,
      columns: this.selectedColumns.join(', '),
    });
    this.closeDropdown();
  }),
  cacheApi: action(function (component) {
    if (this.powerSelectApi) return;
    this.set('powerSelectApi', component.actions);
  }),
});
