import { helper } from '@ember/component/helper';

export const truncateEllipsis = (params) => {
  const val = params[0];
  const n = params[1];
  if (val == null) return;
  return val.length > n ? val.substring(0, n - 1) + '…' : val;
};

export default helper(truncateEllipsis);
