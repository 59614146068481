import delay from '../delay';
import getElement from './get-element';

const fadeOut = async (target, duration = 500) => {
  const el = getElement(target);
  if (!el) return;

  el.style.opacity = 1;
  el.style.transition = `opacity ${duration}ms ease-out`;
  el.style.opacity = 0;
  await delay(duration);
  el.style.display = 'none';
};

const fadeIn = async (target, duration = 500) => {
  const el = getElement(target);
  if (!el) return;

  el.style.transition = `opacity ${duration}ms ease-in`;
  el.style.display = 'block';
  el.style.opacity = 0;
  await delay(duration);
  el.style.opacity = 1;
};

export { fadeOut, fadeIn };
