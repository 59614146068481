import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
  @argument {Array} options - all selectable columns including groups
  @argument {Object} selectedColumn - current column
  @argument {Number} columnIndex - current column index
  @argument {Function} onColumnSwitch - on column change callback
 */

export default class NwTableColumnSwitcherComponent extends Component {
  get selected() {
    return this.flatOptions?.findBy('name', this.args.selectedColumn?.name);
  }

  get flatOptions() {
    return this.args.options?.reduce(
      (acc, cur) => (cur.options ? acc.concat(cur.options) : acc.concat(cur)),
      []
    );
  }

  @action
  onChange({ name }) {
    this.args.onColumnSwitch(name, this.args.columnIndex);
  }
}
