import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlKeywordRoute extends Route {
  @service router;

  beforeModel(transition) {
    const keywordId = transition.to.params.keyword_id;
    this.router.transitionTo('dashboard.url.chart.new', {
      queryParams: { serpPreviewKeywordId: keywordId },
    });
  }
}
