import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class NwOnboardingKeywordRowComponent extends Component {
  @tracked cachedPreciseLocation;
  @tracked enablePreciseLocation;

  get isInvalidKeyword() {
    return (
      this.args.keywordValidations?.invalid_characters?.some((substring) =>
        this.args.keyword.query?.includes(substring)
      ) || this.args.keyword.query?.split(/[ ,.]/).length > 10
    );
  }

  @action
  onInsert() {
    if (this.args.keyword.preciseLocation) {
      this.enablePreciseLocation = true;
    }
  }

  @action
  onUpdate() {
    // When the keyword.precise_location gets updated, disable the location selector based on precise_location presence.
    this.enablePreciseLocation = !!this.args.keyword.preciseLocation;
  }

  @action
  togglePreciseLocation() {
    if (!this.enablePreciseLocation) this.args.keyword.preciseLocation = null;
  }
}
