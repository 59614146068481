import { task, all } from 'ember-concurrency';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';
import { DimensionSubgroup } from './dimension-subgroup';

class BacklinkViewDimension extends Dimension {
  requestWith = ['backlinkView'];

  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.find((d) => d.backlink_view_id === parseInt(this.backlinkView.id, 10))
        ?.backlink_view_series?.findBy('name', this.name)?.series ?? []
    );
  }
}

export class BacklinkViewDimensions extends DimensionGroup {
  label = 'Backlinks';

  @task
  *partialLoadTask(seriesModels) {
    const backlinkViewIds = seriesModels
      .map((s) => s.belongsTo('backlinkView').id())
      .without(this.allBacklinksView.id) // This one is manually injected in createSubgroups
      .uniq()
      .compact();

    const backlinkViews = yield all(
      backlinkViewIds.map((id) =>
        this.maybeFindModel.perform('backlink-view', id)
      )
    );

    this.createSubgroups(backlinkViews.compact());
  }

  @task({ drop: true })
  *loadTask() {
    yield this.url.loadBacklinkViews.perform();

    this.createSubgroups(this.url.backlinkViews.sortBy('name').toArray());
  }

  createSubgroups(backlinkViews) {
    // Inject "All Backlinks" at the beginning of the array
    this.subgroups = [this.allBacklinksView, ...backlinkViews].map(
      (backlinkView) => {
        const props = {
          ...this.dimensionProps,
          backlinkView,
          subTitle: backlinkView.name,
        };
        return new DimensionSubgroup({
          label: backlinkView.name,
          dimensions: [
            new BacklinkViewDimension({
              ...props,
              name: 'backlinks_total',
              title: 'Total Backlinks',
              label: `Total Backlinks | ${this.url.name} | ${backlinkView.name}`,
            }),
            new BacklinkViewDimension({
              ...props,
              name: 'backlinks_referring_ips',
              title: 'Referring IPs',
              label: `Referring IPs | ${this.url.name} | ${backlinkView.name}`,
            }),
            new BacklinkViewDimension({
              ...props,
              name: 'backlinks_referring_domains',
              title: 'Referring Domains',
              label: `Referring Domains | ${this.url.name} | ${backlinkView.name}`,
            }),
            new BacklinkViewDimension({
              ...props,
              name: 'backlinks_referring_subnets',
              title: 'Referring Subnets',
              label: `Referring Subnets | ${this.url.name} | ${backlinkView.name}`,
            }),
          ],
        });
      }
    );

    this.dimensions = this.subgroups.flatMap((s) => s.dimensions);
  }
  // Weird synthetic model to represent 'All Backlinks'
  get allBacklinksView() {
    return (
      this.store.peekRecord('backlink-view', '-1') ||
      this.store.push({
        data: {
          id: '-1',
          type: 'backlink-view',
          attributes: {
            name: 'All Backlinks',
          },
        },
      })
    );
  }
}
