import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlDynamicViewController extends Controller {
  @service('persistence/keyword-list-sorting') keywordListSorting;

  queryParams = ['page', 'direction', 'sort'];

  @tracked dynamicView;
  @tracked url;

  @tracked page = 1;
  @tracked sort = 'created_at';
  @tracked direction = 'desc';

  enteredRoute(dynamicView, url) {
    this.keywordListSorting.applySorting(this, { viewId: dynamicView.id });
    this.dynamicView = dynamicView;
    this.url = url;
  }

  @action
  updateParams({ page, sort, direction }) {
    this.page = page ?? this.page;
    this.sort = sort ?? this.sort;
    this.direction = direction ?? this.direction;
  }
}
