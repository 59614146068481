import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NwSiteAuditPagePageDetails extends Component {
  @service store;
  @service fetch;
  @service notifications;

  @tracked pageImages;
  @tracked brokenPages;
  @tracked brokenReferrers;
  @tracked auditChecks;

  @action
  onInsert() {
    this.fetchPageDetails.perform();
    this.fetchAuditChecks.perform();
  }

  @task({ drop: true })
  *fetchPageDetails() {
    try {
      const response = yield this.fetch.request('/site_audit/page_details', {
        data: {
          search_keyword_url_id: this.args.url.id,
          crawling_session_id: this.args.crawlingSession.id,
          page_id: this.args.page.id,
        },
      });
      this.pageImages = response.page_images;
      this.brokenPages = response.broken_pages;
      this.brokenReferrers = response.pages_linked_to_broken_page;
    } catch (err) {
      this.notifications.error(
        'Something went wrong while fetching page details. Reload the page and try again or contact support',
        { autoClear: true }
      );
    }
  }

  @task({ drop: true })
  *fetchAuditChecks() {
    const auditCheckIds = (this.args.page.auditCheckStats.problematic_ids || [])
      .concat(this.args.page.auditCheckStats.warning_ids)
      .compact();
    if (!isEmpty(auditCheckIds)) {
      const auditChecks = yield this.store.query('site-audit/audit-check', {
        ids: auditCheckIds,
        search_keyword_url_id: this.args.url.id,
      });
      this.auditChecks = auditChecks;
    }
  }
}
