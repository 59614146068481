import { helper } from '@ember/component/helper';
import { DAILY, WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';

// TODO: move these utils to a helper and use it in other parts of app too
const dayOfWeekAsString = (dayIndex) =>
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][dayIndex];

const hourDisplay = (hours) => {
  const suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours === '00' ? 12 : hours;
  return `${hours}:00 ${suffix}`;
};

const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) return i + 'st';
  if (j === 2 && k !== 12) return i + 'nd';
  if (j === 3 && k !== 13) return i + 'rd';
  return i + 'th';
};

export const scheduleTimeInWords = (params) => {
  const report = params[0];
  if (report.get('schedule_interval') === WEEKLY) {
    return `Weekly on ${dayOfWeekAsString(
      report.get('local_report_day')
    )} at ${hourDisplay(report.get('local_report_hour'))}`;
  } else if (report.get('schedule_interval') === MONTHLY) {
    return `Monthly on ${ordinalSuffix(
      report.get('day_in_month')
    )} at ${hourDisplay(report.get('local_report_hour'))}`;
  } else if (report.get('schedule_interval') === DAILY) {
    return `Daily at ${hourDisplay(report.get('local_report_hour'))}`;
  }
};

export default helper(scheduleTimeInWords);
