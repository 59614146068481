import Route from '@ember/routing/route';
import { didCancel } from 'ember-concurrency';

export default class DashboardUrlNotesRoute extends Route {
  activate() {
    window.scrollTo(0, 0);
  }

  async model() {
    const url = this.modelFor('dashboard.url');

    return url.loadNotes
      .perform({ reload: true })
      .then(() => {
        return { url: url, notes: url.get('notes') };
      })
      .catch((e) => {
        if (!didCancel(e)) {
          throw e;
        }
      });
  }
}
