import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import { fadeOut } from '../../../../utils/dom/fade-element';

export default class DashboardUrlBacklinksEnableController extends Controller {
  @service router;
  @service fetch;
  @service notifications;
  @service session;

  checkForBacklinks(url) {
    const _check = (url) => {
      if (!this.fetchingBacklinks) {
        return;
      }

      url.reload().then(async (url) => {
        if (isPresent(url.get('backlinksStatus.last_ahrefs_fetch_done_at'))) {
          this.set('fetchingBacklinks', false);
          await fadeOut('#splash-overlay');
          this.router.transitionTo('dashboard.url.backlinks', url);
        } else {
          later(() => {
            _check(url);
          }, 1500);
        }
      });
    };
    _check(url);
  }

  @action
  enableBacklinks() {
    const url = this.model;
    const promise = this.fetch.post(`/urls/${url.id}/enable_backlinks`);
    this.set('isEnabling', true);
    promise.then(() => {
      // To get backlinks total
      url.reload().then(() => {
        this.router.transitionTo('dashboard.url.backlinks', url);
      });
    });
    promise.catch(() => {
      this.notifications.error(
        'Something went wrong. Please contact support.',
        { autoClear: true, clearDuration: 10000 }
      );
    });
    promise.finally(() => {
      this.set('isEnabling', false);
    });
  }
}
