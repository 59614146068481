import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

export const THEMES = {
  BRIGHT: 'bright',
  DARK: 'dark',
};

const detectThemePreference = () => {
  return window?.matchMedia('(prefers-color-scheme: dark)').matches
    ? THEMES.DARK
    : THEMES.BRIGHT;
};

export default class ThemeService extends Service {
  @service session;
  @service metrics;

  init() {
    super.init(...arguments);
    this.current = detectThemePreference();
  }

  @tracked _current;
  get current() {
    return this._current;
  }
  set current(value) {
    this._current = value ?? THEMES.DARK;
    this.update();
  }

  get isDark() {
    return this.current === THEMES.DARK;
  }

  get isBright() {
    return this.current === THEMES.BRIGHT;
  }

  afterLoadUser(user) {
    if (!user?.id) return;

    this.current = user?.settings?.theme;
  }

  @task({ restartable: true })
  *switch() {
    const theme = this.current === THEMES.DARK ? THEMES.BRIGHT : THEMES.DARK;

    this.current = theme;
    this.session.user.settings = {
      ...(this.session.user?.settings ?? {}),
      theme,
    };

    this.metrics.trackEvent({ event: 'Switched Theme', theme });

    yield timeout(1500);
    yield this.session.user.save();
  }

  update() {
    const previous = this.current === THEMES.DARK ? THEMES.BRIGHT : THEMES.DARK;
    const body = document.querySelector('body');
    body.classList.remove(previous);
    body.classList.add(this.current);
  }
}
