import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditViewSettingsRoute extends Route {
  @service router;
  @service session;

  activate() {
    window.scrollTo(0, 0);
  }

  beforeModel() {
    if (this.session.user.isLimited) {
      this.router.transitionTo('dashboard.site-audit.view');
    }
  }
}
