import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MaintenanceRoute extends Route {
  @service router;

  async beforeModel() {
    try {
      await this.siteData.load();
      // API is responding – transition back to the dashboard
      this.router.transitionTo('dashboard.overview');
    } catch {
      // API still down – Continue to maintenance page...
    }
  }
}
