import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NavBarComponent extends Component {
  @service session;
  @service siteData;
  @service kbar;

  @tracked gettingStartedModalShown = false;

  @action
  toggleGettingStartedModal() {
    this.gettingStartedModalShown = !this.gettingStartedModalShown;
  }

  @action
  logout() {
    this.session.logout();
  }
}
