import { setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import underscorifyKeys from '../utils/underscorify-keys';
import { inject as service } from '@ember/service';
import { format } from 'date-fns';

export default class KeywordStatsResource {
  @service fetch;
  @service router;
  @service dashboardState;

  @tracked data;

  constructor({ owner }) {
    setOwner(this, owner);
  }

  get loading() {
    return this.fetchTask.isRunning;
  }

  get loaded() {
    return Boolean(this.fetchTask.lastSuccessful) && !this.loading;
  }

  load(args) {
    this.data = {};
    this.fetchTask.perform(args);
  }

  @task({ restartable: true })
  *fetchTask(args) {
    const params = this.params(args);

    // In some cases (needs investigation) scope params are missing and
    // then the API does calculation over whole account - very slow!
    if (!params.dynamicViewId && !params.urlId && !params.groupId) return;

    const abortController = new AbortController();
    try {
      this.data = yield this.fetch.request('/keyword_stats', {
        data: underscorifyKeys(params),
        signal: abortController.signal,
      });
    } catch (e) {
      if (e.status === 401)
        return this.router.transitionTo('dashboard.overview');

      throw e;
    } finally {
      abortController.abort();
    }
  }

  params({ startDate, endDate, dynamicView, url, filterGroups }) {
    return {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      extraStats: true,
      urlId: url?.id,
      dynamicViewId: dynamicView?.id,
      groupId: url?.id ? undefined : this.dashboardState?.currentUrlGroup?.id,
      filterGroups,
    };
  }
}
