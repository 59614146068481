import { helper } from '@ember/component/helper';

export function ensureProtocol([url]) {
  if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
    return `http://${url}`;
  }
  return url;
}

export default helper(ensureProtocol);
