import Service from '@ember/service';

function getLocalStorageKey(key) {
  return `nw:onboarding-${key}`;
}

export default class OnboardingLocalStorageService extends Service {
  _storageData = {
    url: undefined,
    urlId: undefined,
    groupId: undefined,
    targetLanguageCode: undefined,
    targetCountryCode: undefined,
    keywordPreciseLocation: undefined,
    targetEngine: undefined,
    targetDevice: undefined,
  };

  target = undefined;

  init() {
    super.init(...arguments);

    Object.keys(this._storageData).forEach((key) => {
      if (key === 'keywordPreciseLocation') {
        try {
          this._storageData[key] = JSON.parse(
            localStorage.getItem(getLocalStorageKey(key))
          );
        } catch {
          // Silent error
        }
      } else {
        this._storageData[key] = localStorage.getItem(getLocalStorageKey(key));
      }
    });
  }

  _saveStorageData(key, newData) {
    const localStorageKey = getLocalStorageKey(key);

    if (!newData) {
      localStorage.removeItem(localStorageKey);
      this._storageData[key] = undefined;
      return;
    }

    this._storageData[key] = newData;
    localStorage.setItem(localStorageKey, newData);
  }

  setTargetDevice(data) {
    this._saveStorageData('targetDevice', data);
  }

  setTargetEngine(data) {
    this._saveStorageData('targetEngine', data);
  }

  setTargetKeywordPreciseLocation(data) {
    this._saveStorageData('keywordPreciseLocation', JSON.stringify(data));
  }

  setTargetCountryCode(data) {
    this._saveStorageData('targetCountryCode', data);
  }

  setTargetLanguageCode(data) {
    this._saveStorageData('targetLanguageCode', data);
  }

  setUrlGroupId(data) {
    this._saveStorageData('groupId', data);
  }

  hasUrlChanged({ urlId, groupId, url }) {
    return (
      !!this._storageData.url &&
      !!this._storageData.urlId &&
      !!this._storageData.groupId &&
      (this._storageData.urlId !== urlId ||
        this._storageData.url !== url ||
        this._storageData.groupId !== groupId)
    );
  }

  _resetTargetCache() {
    this._storageData.targetLanguageCode = undefined;
    this._storageData.targetCountryCode = undefined;
    this._storageData.keywordPreciseLocation = undefined;
    this._storageData.targetEngine = undefined;
    this._storageData.targetDevice = undefined;
  }

  setUrl({ urlId, groupId, url }) {
    if (this.hasUrlChanged({ url, urlId, groupId })) {
      this._resetTargetCache();
    }

    this._saveStorageData('urlId', urlId);
    this._saveStorageData('url', url);
    this.setUrlGroupId(groupId);
  }

  get urlLanguageCode() {
    return this._storageData.targetLanguageCode;
  }

  get urlDevice() {
    return this._storageData.targetDevice;
  }

  get urlEngine() {
    return this._storageData.targetEngine;
  }

  get urlCountryCode() {
    return this._storageData.targetCountryCode;
  }

  get keywordPreciseLocation() {
    return this._storageData.keywordPreciseLocation;
  }

  get url() {
    return this._storageData.url;
  }

  get urlId() {
    return this._storageData.urlId;
  }
}
