import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { format, parseISO, sub, startOfDay } from 'date-fns';
import { relativeDateRanges } from 'nightwatch-web/constants/date-ranges';
import isTesting from 'nightwatch-web/utils/is-testing';
import { rawTimeout, task } from 'ember-concurrency';

export const testRange = {
  start: parseISO('2021-07-14'),
  end: parseISO('2021-07-28'),
};

export default class NwDateRangePickerComponent extends Component {
  @tracked isCalendarShown;
  @tracked selectedRange = {};
  // When rendering in test runs – initially show fixed dates
  // This is to avoid daily diffs in visual regression testing
  @tracked displayTestRange = isTesting;

  firstDatePicker = null;
  secondDatePicker = null;

  @action
  onUpdate() {
    const { startDate, endDate, dateRangeType } = this.args;
    this.selectedRange = {
      type: dateRangeType,
      start: startDate,
      end: endDate,
    };
    this.center = startDate;
    this.isCalendarShown = !dateRangeType;
    this.updateDatePickerValues();
  }

  @action
  didInsertDateInputs(element) {
    this.firstDatePicker = element.querySelector('#nw-date-range-picker-0');
    this.secondDatePicker = element.querySelector('#nw-date-range-picker-1');
    this.updateDatePickerValues();
  }

  updateDatePickerValues() {
    if (!this.datePickersPresent) return;
    const maxDate = format(new Date(this.maxDate), 'yyyy-MM-dd');
    this.firstDatePicker.value = this.startDate;
    this.firstDatePicker.max = format(new Date(maxDate), 'yyyy-MM-dd');
    this.firstDatePicker.min = format(
      sub(new Date(maxDate), { years: 8 }),
      'yyyy-MM-dd'
    );
    this.secondDatePicker.value = this.endDate;
    this.secondDatePicker.max = maxDate;
    this.secondDatePicker.min = this.startDate;
  }

  @task({ restartable: true })
  *setDate(typing = false) {
    yield rawTimeout(typing ? 1000 : 500); //
    if (!this.datePickersPresent || this.invalidDates) return;
    this.selectedRange.start = startOfDay(
      parseISO(this.firstDatePicker?.value)
    );
    this.selectedRange.end = startOfDay(parseISO(this.secondDatePicker?.value));
    const { start, end } = this.selectedRange;
    this.args.setDateRange({ start, end });
  }

  get startDate() {
    return format(new Date(this.args.startDate), 'yyyy-MM-dd');
  }

  get endDate() {
    return format(new Date(this.args.endDate), 'yyyy-MM-dd');
  }

  get datePickersPresent() {
    return this.firstDatePicker && this.secondDatePicker;
  }

  get invalidDates() {
    return (
      !this.firstDatePicker?.value ||
      !this.secondDatePicker?.value ||
      new Date(this.firstDatePicker?.value) >
        new Date(this.secondDatePicker?.value) ||
      new Date(this.firstDatePicker?.value) <
        sub(new Date(this.maxDate), { years: 8 })
    );
  }

  get selectionDisplay() {
    const { start, end } = this.displayTestRange
      ? testRange
      : this.selectedRange;
    const formattedDate = (date) =>
      date ? format(date, 'MMM d, y') : 'Select date';
    return `${formattedDate(start)} - ${formattedDate(end)}`;
  }

  get maxDate() {
    return this.args.maxDate || new Date();
  }

  get horizontalPosition() {
    return this.args.horizontalPosition || 'right';
  }

  get rangeOptions() {
    const ranges = this.args.ranges;
    const rangeOptions = [];
    relativeDateRanges().forEach((range) => {
      if (!ranges || ranges.includes(range.type)) {
        rangeOptions.push({
          ...range,
          isActive:
            this.selectedRange?.type === range.type && !this.isCalendarShown,
        });
      }
    });
    return rangeOptions;
  }

  @action
  onRangeOptionSelect(closeDropdown, { type, start, end }) {
    this.selectedRange = { type, start, end };
    this.displayTestRange = false;
    this.center = start;
    this.isCalendarShown = false;
    this.args.setDateRange({ type, start, end });
    closeDropdown();
  }
}
