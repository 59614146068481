import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class NeatSection extends Component {
  @tracked isOpen = this.args.initiallyOpen || false;

  @action
  initiallyOpenChanged() {
    const { initiallyOpen } = this.args;
    if (!initiallyOpen) return;

    this.isOpen = initiallyOpen;
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }
}
