import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ReportsProviderComponent extends Component {
  @service store;
  @service metrics;
  @service notifications;

  @tracked reports;
  @tracked sort = 'name';
  @tracked direction = 'asc';

  columns = {
    default: {
      name: 'report',
      displayName: 'Report',
      sortProperty: 'name',
    },
    selected: [
      {
        name: 'domain',
        displayName: 'Domain',
        sortProperty: 'name',
      },
      {
        name: 'format',
        displayName: 'Format',
        sortProperty: 'name',
      },
      {
        name: 'last-sent-at',
        displayName: 'Last sent at',
        sortProperty: 'created_at',
      },
      {
        name: 'schedule',
        displayName: 'Schedule',
        sortProperty: 'schedule_interval',
      },
      {
        name: 'actions',
        displayName: 'Actions',
      },
    ],
    drop: () => null,
  };

  @task({ drop: true })
  *loadReports() {
    this.reports = null;
    // Get all reports across all URLs and dynamic views for dashboard view.
    if (!this.args.dynamicView && !this.args.url) {
      this.reports = yield this.store.findAll('report');
      return;
    }
    const queryOptions = this.args.dynamicView
      ? {
          dynamic_view_id: this.args.dynamicView.id,
        }
      : {
          url_id: this.args.url.id,
        };
    this.reports = yield this.store.query('report', queryOptions);
  }

  @task({ drop: true })
  *sendReport(report) {
    if (
      !confirm(
        `Are you sure you want to send this report to ${
          report.recipients?.length
        } recipient${report.recipients?.length > 1 ? 's' : ''} now?`
      )
    ) {
      return;
    }
    try {
      yield report.sendReport.perform();
      this.notifications.success('Report sent.', {
        autoClear: true,
      });
      report.reload();
    } catch (e) {
      this.notifications.error(
        'An error occurred while sending the report. Please contact support'
      );
    }
  }

  @action
  async deleteReport(report) {
    if (!confirm(`Are you sure you want to delete this report?`)) {
      return;
    }
    const reportToDelete = this.store.peekRecord('report', report.id);
    if (reportToDelete) report.destroyRecord();
    this.args.dynamicView
      ? this.args.dynamicView?.reload?.()
      : this.args.url?.reload?.();
  }

  @action
  onSort(sort, direction) {
    if (!sort) return;

    this.sort = sort;
    this.direction = direction;

    this.metrics.trackEvent({ event: 'Sorted Reports' });
  }

  get oppositeDirection() {
    return this.direction === 'asc' ? 'desc' : 'asc';
  }

  get sortedReports() {
    let reports = this.reports?.filterBy('isNew', false).sortBy(this.sort);
    if (this.sort === 'schedule_interval') {
      reports = reports.sortBy('scheduled');
    }
    if (this.direction === 'desc') {
      reports = reports.reverse();
    }
    return reports;
  }
}
