import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
  @argument pinnedItem
  @argument dateFrom
  @argument dateTo
  @argument goToPinnedItem
  @argument onUnpinItem
  @argument grouping
 */
export default class PinnedItemComponent extends Component {
  @service siteData;
  @service screenGrabber;

  @tracked sharingPage = null;
  @tracked sharingPopupVisible = false;

  element = null;

  @action
  storeElement(element) {
    this.element = element;
  }

  get sharingAllowed() {
    return !this.siteData.isWhiteLabel;
  }

  @task
  *makeScreenshot() {
    const { pinnedItem, dateFrom, dateTo } = this.args;
    this.sharingPopupVisible = true;
    const title = pinnedItem.isUrl
      ? pinnedItem.url?.friendlyUrl
      : pinnedItem.name;
    try {
      this.sharingPage = yield this.screenGrabber.grabAndUpload.perform(
        this.element,
        dateFrom,
        dateTo,
        title
      );
    } catch (_) {
      this.sharingPopupVisible = false;
    }
  }

  @action
  toggleSharingPopup() {
    this.sharingPage = null;
    this.sharingPopupVisible = !this.sharingPopupVisible;
  }

  @task({ restartable: true })
  *save() {
    yield timeout(300);
    yield this.args.pinnedItem.save();
  }
}
