import { helper } from '@ember/component/helper';
import { format, differenceInDays, formatDistanceStrict } from 'date-fns';

export function relativeDateLimited([dateValue, periodDays]) {
  const daysDiff = Math.abs(
    differenceInDays(new Date(dateValue || new Date()), new Date())
  );

  !dateValue ? (dateValue = new Date()) : (dateValue = new Date(dateValue));

  if (daysDiff <= periodDays) {
    return formatDistanceStrict(dateValue, new Date(), {
      addSuffix: true,
    });
  } else {
    return format(dateValue, 'MMM dd, yyyy');
  }
}

export default helper(relativeDateLimited);
