import { helper } from '@ember/component/helper';

export const clipboardSupported = async () => {
  if (
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === 'function'
  ) {
    // The browser supports clipboard writeText function
    return true;
  } else {
    // The browser does not support clipboard writeText function
    return false;
  }
};

export default helper(clipboardSupported);
