import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class FetchPreciseLocationsService extends Service {
  @service fetchPreciseLocation;

  DIVIDER = ':';
  loactions = {};

  async initialize(keywordsData) {
    const uniqueKeywordsData = this._getUniqueLocations(keywordsData);
    this.locations = await Promise.allSettled(
      Array.from(uniqueKeywordsData).map(async ({ preciseLocation, loc }) => {
        const data = {
          search: preciseLocation,
          gl: loc,
        };
        const results = await this.fetchPreciseLocation.fetchLocation(data);

        return { data: results, preciseLocation, loc };
      })
    ).then((results) =>
      results
        .filter(({ status }) => status === 'fulfilled')
        .reduce((prev, nextResult) => {
          const { value } = nextResult;
          if (!value.data || value.data.length === 0) {
            return prev;
          }

          return {
            ...prev,
            [this._getKey(value.loc, value.preciseLocation)]: value.data[0],
          };
        }, {})
    );
  }

  _getKey(loc, preciseLocation) {
    return `${loc}${this.DIVIDER}${preciseLocation}`;
  }

  _getUniqueLocations(keywordsData) {
    const uniqueLocationsKeys = new Set();

    keywordsData.forEach(({ loc, preciseLocation }) => {
      if (loc && preciseLocation) {
        uniqueLocationsKeys.add(this._getKey(loc, preciseLocation));
      }
    });

    return Array.from(uniqueLocationsKeys).map((key) => {
      const [loc, preciseLocation] = key.split(this.DIVIDER);

      return { loc, preciseLocation };
    });
  }

  getPreciseLocation(loc, preciseLocation) {
    if (!loc || !preciseLocation) {
      return undefined;
    }

    return this.locations[this._getKey(loc, preciseLocation)];
  }
}
