import KEYWORD_DISTRIBUTION_PROPS from '../../../constants/chart/keyword-distribution-props';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';

class GroupDimension extends Dimension {
  requestWith = ['urlGroup'];

  get findData() {
    return (
      this.providedData
        ?.find(
          (d) =>
            d.url_group_id === parseInt(this.urlGroup.id, 10) &&
            d.url_group_series
        )
        ?.url_group_series?.findBy('name', this.name)?.series ?? []
    );
  }

  get series() {
    return this.graph.series.find(
      (s) => s.name === this.name && s.urlGroup === this.urlGroup
    );
  }
}

export class GroupDimensions extends DimensionGroup {
  label = 'Group';

  constructor() {
    super(...arguments);
    const { urlGroup } = this;
    const props = {
      ...this.dimensionProps,
      urlGroup,
      subTitle: urlGroup.name,
    };
    this.dimensions = [
      new GroupDimension({
        ...props,
        name: 'average_position',
        scaleId: 'nwPosition',
        title: 'Average Position',
        label: 'Average Position',
      }),
      new GroupDimension({
        ...props,
        name: 'search_visibility_index',
        title: 'Search Visibility',
        label: 'Search Visibility',
      }),
      new GroupDimension({
        ...props,
        name: 'click_potential',
        title: 'Click Potential',
        label: 'Click Potential',
      }),
      new GroupDimension({
        ...props,
        name: 'total_keywords',
        title: 'Total Keywords',
        label: 'Total Keywords',
      }),
      new GroupDimension({
        ...props,
        ...KEYWORD_DISTRIBUTION_PROPS,
      }),
    ];
  }
}
