import Model, { belongsTo, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { capitalize } from '@ember/string';
import { task, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class PinnedItem extends Model {
  @service store;
  @attr('string') itemType;

  @belongsTo('graph', { async: false }) graph;

  @belongsTo('url', { async: false }) url;
  @belongsTo('dynamic-view', { async: false }) dynamicView;
  @belongsTo('url-group', { async: false }) urlGroup;

  get resourceId() {
    return this.urlGroup?.id ?? this.dynamicView?.id ?? this.url?.id;
  }

  get isGlobalDynamicView() {
    return this.dynamicView?.id && !this.url?.id;
  }

  get globalDynamicView() {
    if (this.isGlobalDynamicView) return this.dynamicView;
    return null;
  }

  get isLoaded() {
    return isPresent(this.name);
  }

  get name() {
    switch (this.itemType) {
      case 'url':
        return this.url?.name;
      case 'view':
        return this.dynamicView?.name;
      case 'group':
        return this.urlGroup?.name;
      default:
        return null;
    }
  }

  get isUrl() {
    return this.itemType === 'url';
  }

  get isView() {
    return this.itemType === 'view';
  }

  get isUrlView() {
    return this.isView && this.url;
  }

  get isGroup() {
    return this.itemType === 'group';
  }

  get description() {
    if (this.itemType) {
      let itemType = capitalize(this.itemType);
      if (itemType === 'Url') {
        itemType = 'URL';
      }
      if (this.itemType) {
        return `${itemType}: ${this.name}`;
      }
    }
    return null;
  }

  get isBlank() {
    return (
      [this.url?.id, this.urlGroup?.id, this.dynamicView?.id].compact()
        .length === 0
    );
  }

  @task
  *loadRelationships() {
    yield all(
      [
        this.belongsTo('url'),
        this.belongsTo('dynamicView'),
        this.belongsTo('urlGroup'),
      ].map((ref) => {
        if (ref.id() && !ref.value()) {
          return this.store.findRecord(ref.type, ref.id());
        }
      })
    );
  }

  toJSON() {
    const json = {};
    this.eachRelationship((relationship) => {
      json[relationship] = this[relationship]?.id;
    });
    this.eachAttribute((attribute) => {
      json[attribute] = this[attribute];
    });
    return json;
  }
}
