// app/helpers/format-date.js
import { helper } from '@ember/component/helper';

export default helper(function dateFormat([dateString]) {
  let date = new Date(dateString);

  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year = date.getFullYear();

  return `${day}.${month}.${year}`;
});
