import Component from '@glimmer/component';

export default class PageDepthChartContainer extends Component {
  get chartData() {
    return [
      {
        label: 'Level 1',
        backgroundColor: '#48AAED',
        data: this.args.stats?.level_1_pages_count,
        change: this.args.stats?.level_1_pages_count_change,
      },
      {
        label: 'Level 2',
        backgroundColor: '#1883CA',
        data: this.args.stats?.level_2_pages_count,
        change: this.args.stats?.level_2_pages_count_change,
      },
      {
        label: 'Level 3',
        backgroundColor: '#004979',
        data: this.args.stats?.level_3_pages_count,
        change: this.args.stats?.level_3_pages_count_change,
      },
      {
        label: 'Level 4',
        backgroundColor: '#404040',
        data: this.args.stats?.level_4_pages_count,
        change: this.args.stats?.level_4_pages_count_change,
      },
    ];
  }
}
