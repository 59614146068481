import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

export default class TruncateWithTooltip extends Component {
  @tracked isTruncated = false;

  /**
    @param {HTMLElement} element
    @param {Array} _args - Unused args that are watched by the modifier
    @param {Function(Boolean)} onCheck - Callback - returns boolean
  */
  truncationCheck = modifier((element, _args, { onCheck }) => {
    // If scrollWidth > offsetWidth then the content does not fit without truncation
    const isTruncated = element.scrollWidth > element.offsetWidth;
    onCheck?.(isTruncated);
  });

  @action
  onTruncationCheck(isTruncated) {
    this.isTruncated = isTruncated;
  }
}
