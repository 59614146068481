import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { underscore } from '@ember/string';
import { ActiveModelSerializer } from 'active-model-adapter';

export default class FilterGroupSerializer extends ActiveModelSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    filters: { serialize: 'records', deserialize: 'records' },
  };

  normalizeArrayResponse(store, primaryType, payload) {
    return super.normalizeArrayResponse(store, primaryType, {
      filter_groups: payload,
    });
  }

  normalizeSingleResponse(store, primaryType, payload, recordId) {
    return super.normalizeSingleResponse(
      store,
      primaryType,
      { filter_group: payload },
      recordId
    );
  }

  serializeIntoHash(data, type, record, options) {
    const root = underscore(type.modelName);
    return (data[root] = this.serialize(record, options));
  }
}
