export function initialize(appInstance) {
  const metricsService = appInstance.lookup('service:metrics');
  if (navigator.doNotTrack === '1') {
    metricsService.enabled = false;
  }
}

export default {
  initialize,
};
