import Service, { inject as service } from '@ember/service';

export default class CouponValidatorService extends Service {
  @service fetch;

  validateCoupon(couponName, planId) {
    const data = { coupon_name: couponName, plan_id: planId };
    return this.fetch.post('/coupons/validate', { data });
  }
}
