import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import localStorage from 'ember-local-storage-decorator';
import { action } from '@ember/object';
import { DEFAULT_GROUPING_TYPE } from '../constants/chart/grouping';

export const channelDistributionColors = {
  direct: '#48AAED',
  organic: '#1883CA',
  social: '#004979',
  referral: '#0857b1',
  ads: '#11487a',
  other: '#000000',
};

export const newReturningColors = {
  new: '#058DC7',
  returning: '#50B432',
};

export default class NwTrafficOverview extends Component {
  @service graphColorManager;
  @service siteData;
  @service('chart/nw-graph-series') nwGraphSeries;

  @tracked grouping = DEFAULT_GROUPING_TYPE;

  @localStorage('nw:traffic-overview:show-graph') showGraph = true;
  @localStorage('nw:traffic-overview:selected-metric') selectedMetric =
    'sessions';
  @localStorage('nw:traffic-overview:audience-category') audienceCategory =
    'pageviews';

  channelDistributionColors = channelDistributionColors;
  newReturningColors = newReturningColors;

  get stats() {
    return this.args.stats;
  }

  get interval() {
    return { start: this.args.startDate, end: this.args.endDate };
  }

  get selectedGraph() {
    switch (this.selectedMetric) {
      case 'sessions':
        return {
          series: this.sessionsSeries,
        };
      case 'audience':
        return {
          series: this.audienceSeries,
        };
      case 'acquisition':
        return {
          series: this.acqusitionSeries,
          label: 'Acquisition',
        };
      case 'new-returning':
        return {
          series: this.newReturningSeries,
          label: 'New / Returning',
        };
      default:
        throw `selectedGraph: unknown metric: ${this.selectedMetric}`;
    }
  }

  get sessionsSeries() {
    const data = this.stats?.sessions_series || [];
    let series = this.nwGraphSeries.averagePositionSeries(
      data,
      this.grouping,
      this.interval
    );
    series[0].label = 'Total Sessions';
    return series;
  }

  get audienceSeries() {
    const data = this.stats?.[`${this.audienceCategory}_series`] ?? [];
    const series = this.nwGraphSeries.searchVisibilitySeries(
      this.audienceCategory,
      data,
      this.grouping,
      this.interval
    );
    return series;
  }

  get singleAcquistionSeries() {
    return this.nwGraphSeries.trafficAcquisitionSeries(this.stats);
  }

  get acqusitionSeries() {
    return this.nwGraphSeries.acqusitionSeries(
      this.stats,
      this.grouping,
      this.interval
    );
  }

  get newReturningSeries() {
    return this.nwGraphSeries.newReturningSeries(
      this.stats,
      this.grouping,
      this.interval
    );
  }

  get newUsersPercent() {
    const newUsers = this.stats?.new_users;
    const returningUsers = this.stats?.returning_users;
    const sum = newUsers + returningUsers;
    return (100 * newUsers) / sum;
  }

  get returningUsersPercent() {
    return 100 - this.newUsersPercent;
  }

  get statsLoaded() {
    return Boolean(this.stats?.sessions_series);
  }

  @action
  selectMetric(metricName) {
    this.selectedMetric = metricName;
    this.showGraph = true;
  }

  @action
  setGrouping(grouping) {
    this.grouping = grouping;
  }
}
