import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

let ITEMS_PER_PAGE = 20;

export default class NwDeepResearchSegmentsListComponent extends Component {
  @service deepResearch;
  @tracked searchString = '';
  @tracked currentPage = 1;
  columns = {
    selected: [
      {
        name: 'index',
        displayName: 'Nº',
      },
      {
        name: 'url',
        displayName: 'Researched subpages',
      },
    ],
    drop: () => null,
  };

  get mimicProvider() {
    return {
      columns: this.columns,
      sort: (...args) => null,
      onSort: (...args) => null,
    };
  }
  get totalSegments() {
    return this.args.segments.length;
  }

  @computed('searchString', 'args.segments')
  get filteredSegmentIndices() {
    if (!this.searchString) {
      return this.args.segments;
    }

    const searchStringLower = this.searchString.toLowerCase();
    return this.args.segments.reduce((indices, segment) => {
      if (segment.url.toLowerCase().includes(searchStringLower)) {
        indices.push(segment);
      }
      return indices;
    }, []);
  }

  get totalPages() {
    return Math.ceil(this.filteredSegmentIndices.length / ITEMS_PER_PAGE);
  }

  get paginatedSegments() {
    const start = (this.currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return this.filteredSegmentIndices.slice(start, end);
  }

  get isFirstPage() {
    return this.currentPage === 1;
  }

  get isLastPage() {
    return this.currentPage === this.totalPages;
  }

  @action
  updateSearch(event) {
    this.searchString = event.target.value;
    this.currentPage = 1; // Reset to first page on search
  }

  @action
  nextPage() {
    if (!this.isLastPage) {
      this.currentPage++;
    }
  }

  @action
  previousPage() {
    if (!this.isFirstPage) {
      this.currentPage--;
    }
  }

  @action
  async prepareDownloadItem(item, format) {
    const columns = ['Nº', 'URL Segment'];
    let rowsGenerator = (entry) => [entry.index, entry.url];

    if (format === 'csv') {
      columns.shift();
      rowsGenerator = (entry) => [entry.url];
    }
    const title = 'Research History Report - Segments - ' + this.args.currentUrl;
    const subtitle = 'Research was done on ' + this.args.currentReportDate;
    const fileNameBase =
      this.args.currentUrl +
      '-segmentss-research-' +
      this.args.currentReportDate;

    await this.deepResearch.exportData(
      item,
      format,
      columns,
      rowsGenerator,
      title,
      subtitle,
      fileNameBase
    );
  }
}
