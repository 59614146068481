import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

function normalize(str) {
  if (str && 'string' === typeof str) {
    return str.toLowerCase().trim();
  }

  return str;
}

export default class ConfirmResourceDeletionComponent extends Component {
  @tracked providedInput = null;
  @tracked isDeleting = false;

  get canBeDeleted() {
    return normalize(this.providedInput) === normalize(this.args.expectedInput);
  }

  @action
  delete() {
    const onConfirmDelete = this.args.onConfirmDelete;
    this.isDeleting = true;

    if (this.canBeDeleted) {
      onConfirmDelete(this.args.resource);
    }
  }
}
