import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class OverviewComponent extends Component {
  // @tracked statsLoading = false;

  get okPagesPercent() {
    const okPagesCount = this.args.stats.ok_pages_count;
    const redirectPagesCount = this.args.stats.redirection_pages_count;
    const errorPagesCount = this.args.stats.error_pages_count;
    const sum = okPagesCount + redirectPagesCount + errorPagesCount;
    return (100 * okPagesCount) / sum;
  }

  get redirectPagesPercent() {
    const okPagesCount = this.args.stats.ok_pages_count;
    const redirectPagesCount = this.args.stats.redirection_pages_count;
    const errorPagesCount = this.args.stats.error_pages_count;
    const sum = okPagesCount + redirectPagesCount + errorPagesCount;
    return (100 * redirectPagesCount) / sum;
  }

  get errorPagesPercent() {
    return 100 - this.okPagesPercent - this.redirectPagesPercent;
  }
}
