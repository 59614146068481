import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class DashboardUrlSiteAuditViewSettingsController extends Controller {
  @service router;
  @service notifications;

  @action
  delete(view) {
    if (!confirm('Are you sure you want to delete this view?')) {
      return;
    }
    view.destroyRecord().then(
      () => {
        this.notifications.success('Site audit view deleted successfully.', {
          autoClear: true,
        });
        this.router.transitionTo('dashboard.url.site-audit');
      },
      () => {
        this.notifications.error(
          'There has been an error while deleting site audit view.',
          { autoClear: true }
        );
      }
    );
  }

  @action
  save(view) {
    view.save().then(
      () => {
        this.notifications.success('Site audit view saved successfully.', {
          autoClear: true,
        });
        this.router.transitionTo('dashboard.url.site-audit.view', view);
      },
      () => {
        this.notifications.error(
          'There has been an error while saving site audit view.',
          { autoClear: true }
        );
      }
    );
  }
}
