import Service, { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import { getOwner } from '@ember/application';

export default class ErrorReporterService extends Service {
  @service session;

  get userConsent() {
    const consents = this.session?.user?.consents;
    return consents ? consents.includes('error_tracking') : true;
  }

  get enabled() {
    return true;
  }

  get person() {
    const user = this.session?.user;
    return (
      user && {
        id: user.id,
        email: user.email,
        username: user.username,
      }
    );
  }

  set person(value) {
    if (this.userConsent && value) {
      Sentry.setUser(value);
    }
  }

  get config() {
    return getOwner(this).resolveRegistration('config:environment')
      .sentryConfig;
  }

  // Notifications
  captureException(error, context = {}) {
    if (!this.userConsent || !this.enabled) return;

    Sentry.withScope((scope) => {
      if (context.tags) {
        scope.setTags(context.tags);
      }
      if (context.extra) {
        scope.setExtras(context.extra);
      }
      if (context.fingerprint) {
        scope.setFingerprint(context.fingerprint);
      }
      Sentry.captureException(error);
    });
  }

  captureMessage(message, level = 'info', context = {}) {
    if (!this.userConsent || !this.enabled) return;

    Sentry.withScope((scope) => {
      if (context.tags) {
        scope.setTags(context.tags);
      }
      if (context.extra) {
        scope.setExtras(context.extra);
      }
      if (context.fingerprint) {
        scope.setFingerprint(context.fingerprint);
      }
      Sentry.captureMessage(message, level);
    });
  }

  critical(...args) {
    return this.captureMessage(args[0], 'fatal', args[1] || {});
  }

  error(...args) {
    return this.captureException(args[0], args[1] || {});
  }

  warning(...args) {
    return this.captureMessage(args[0], 'warning', args[1] || {});
  }

  info(...args) {
    return this.captureMessage(args[0], 'info', args[1] || {});
  }

  debug(...args) {
    return this.captureMessage(args[0], 'debug', args[1] || {});
  }
}
