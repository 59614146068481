import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
export default class ConfirmEmailController extends Controller {
  @service siteData;
  @service signup;
  @service router;

  queryParams = ['email', 'code'];
  @tracked email = null;
  @tracked token;

  @tracked timeLeft = 0; // Time left for the countdown
  @tracked resendDisabled = false; // Whether the resend button is disabled
  @tracked isLoading = false;
  timeUntilResendAvailable = 70;

  init() {
    super.init();
    this.startCountdown();
  }

  get formattedTimeLeft() {
    let minutes = Math.floor(this.timeLeft / 60);
    let seconds = this.timeLeft % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`; // Formats the time as "m:ss"
  }

  @action
  submitVerificationCode() {
    this.isLoading = true;
    this.signup.submitVerificationCode.perform(this.token).finally(() => {
      this.isLoading = false;
    });
  }

  @action
  resendVerificationEmail() {
    if (!this.resendDisabled) {
      this.signup.resendVerificationEmail.perform(this.email);
      this.startCountdown(); // Start the countdown
    }
  }

  startCountdown() {
    this.resendDisabled = true;
    this.timeLeft = this.timeUntilResendAvailable; // 60 seconds countdown
    let countdown = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.resendDisabled = false;
        clearInterval(countdown);
      }
    }, 1000);
  }

  @action
  navigateToSignup() {
    this.router.transitionTo('signup');
  }

  /**
   * Check if the code exists
   * @returns {boolean}
   */
  get isTokenValid() {
    return !!this.token;
  }

  get userEmail() {
    return this.email;
  }
}
