import Component from '@glimmer/component';

export default class PageDepthChartContainer extends Component {
  get chartData() {
    return [
      {
        label: 'OK',
        backgroundColor: '#27b14e',
        data: this.args.stats?.ok_pages_count,
        change: this.args.stats?.ok_pages_count_change,
      },
      {
        label: 'REDIRECT',
        backgroundColor: '#ceca3f',
        data: this.args.stats?.redirection_pages_count,
        change: this.args.stats?.redirection_pages_count_change,
      },
      {
        label: 'ERROR',
        backgroundColor: '#d8232e',
        data: this.args.stats?.error_pages_count,
        change: this.args.stats?.error_pages_count_change,
      },
    ];
  }
}
