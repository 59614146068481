import Component from '@glimmer/component';

export default class PageDetailsAuditChecksComponent extends Component {
  get problematics() {
    return this.args.auditChecks?.filter(
      (auditCheck) => auditCheck.category === 'problematic'
    );
  }

  get warnings() {
    return this.args.auditChecks?.filter(
      (auditCheck) => auditCheck.category === 'warning'
    );
  }
}
