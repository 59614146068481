const getElement = (target) => {
  if (typeof target === 'string') {
    return document.querySelector(target);
  } else if (target?.nodeType === Node.ELEMENT_NODE) {
    return target;
  }
  throw `getElement - invalid target type ${typeof target}`;
};

export default getElement;
