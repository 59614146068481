import Service from '@ember/service';
import { startOfDay, endOfDay, parse, format } from 'date-fns';

export default class NoteUtilsService extends Service {
  notesInRange(notes, dateFrom, dateTo) {
    return notes.filter((note) => {
      const betweenDate = note.date;
      return (
        betweenDate > startOfDay(parse(dateFrom, 'yyyy-MM-dd', new Date())) &&
        betweenDate < endOfDay(parse(dateTo, 'yyyy-MM-dd', new Date()))
      );
    });
  }

  transformForGraph(notes, dateFrom, dateTo) {
    if (!notes) return;
    return this.notesInRange(notes, dateFrom, dateTo)
      .map((note) => {
        return {
          /*
            Imports UTC 00:00 note.date into user timezone.
            Otherwise the x value doesn't line up with graph data.
          */
          x: Date.parse(format(note.date, 'yyyy-MM-dd')),
          y: 0,
          userNote: note.text,
          zIndex: 3,
        };
      })
      .sort((a, b) => a?.x - b?.x);
  }
}
