import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';

export default class GroupSectionComponent extends Component {
  get items() {
    const { urls, dynamicViews } = this.args.urlGroup;
    return urls
      .toArray()
      .concat(dynamicViews.toArray())
      .filterBy('isNew', false)
      .sortBy('name');
  }

  get filteredItems() {
    const { searchQuery } = this.args;
    if (isBlank(searchQuery)) return this.items;

    const query = searchQuery.toLowerCase();
    const matches = (value) => {
      return value?.toLowerCase().includes(query);
    };
    return this.items.filter((model) => {
      return (
        matches(model.get('name')) ||
        matches(model.get('url')) ||
        matches(model.get('url_info.title'))
      );
    });
  }
}
