import Service, { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import ENV from 'nightwatch-web/config/environment';
import DOCS from 'nightwatch-web/constants/docs';
import { htmlSafe } from '@ember/template';
import fetch from 'fetch';
import chevronIconHtml from '../utils/chevron-icon-html';

export default class DashboardNotificationsService extends Service {
  @service session;
  @service notifications;
  @service router;
  @service siteData;

  // Store all notifications so that they can be removed later
  dashboardNotice;

  // User Notifications
  paymentNotification;
  trialNotification;
  billingDateNotification;

  // Url Notifications
  gaErrorNotification;

  // Keyword Notifications
  noKeywordsNotification;
  stillProcessingNotification;
  notRankingNotification;

  showSubscriptionNotifications(user) {
    if (!user) return;
    if (this.siteData.hideNightwatchBranding) return;
    this.notifyTrialEnd(user);
    this.notifyPaymentProblem(user);
    this.notifyMissingBillingInfo(user);
  }

  async showNotice() {
    try {
      const request = await fetch(
        `${ENV.serverURL}html_snippets/dashboard_notice_nw`,
        {
          contentType: 'text/html',
        }
      );
      const response = await request.json();
      if (response?.content) {
        this.notifications.remove(this.dashboardNotice);
        this.dashboardNotice = this.showInfoMsg(htmlSafe(response.content));
      }
    } catch {
      console.info('No new dashboard notifications.');
    }
  }

  notifyTrialEnd(user) {
    if (user.stripeCustomer) return; // Stripe customers are automatically billed at trial end
    if (!user.isTrial) return;
    let msg;
    const daysToEnd = user.daysToInactivity;
    const plansText = `Make sure to subscribe to one of <b class="blue-alt">our plans</b>.`;

    if (user.isInactive) {
      msg = htmlSafe(`Your trial period ended. ${plansText}`);
    } else {
      if (daysToEnd <= 4) {
        if (daysToEnd > 1) {
          msg = htmlSafe(
            `Your trial period is about to end in ${daysToEnd} days. ${plansText}`
          );
        } else if (daysToEnd === 1) {
          msg = htmlSafe(
            `Your trial period is about to end tomorrow. ${plansText}`
          );
        } else {
          msg = htmlSafe(`Your trial period ends today. ${plansText}`);
        }
      }
    }

    if (msg) {
      this.notifications.remove(this.trialNotification);
      const onClick = () => {
        this.router.transitionTo('plans');
        this.notifications.clearAll();
      };
      this.trialNotification = this.showWarningMsg(
        msg,
        onClick,
        'subscription-notification notification-trial-end'
      );
    }
  }

  notifyPaymentProblem(user) {
    if (!user.hasPaymentNotification) return;

    const msg = htmlSafe(
      `We're unable to renew your subscription because of a payment problem. To keep your subscription, please <b>update your payment method ${chevronIconHtml}</b>`
    );

    this.notifications.remove(this.paymentNotification);
    this.paymentNotification = this.notifications.error(msg, {
      cssClasses: 'subscription-notification',
      onClick: () => {
        this.router.transitionTo('update-payment');
        this.notifications.clearAll();
      },
    });
  }

  notifyMissingBillingInfo(user) {
    if (!user.missingBillingInfo) return;
    if (user.isLimited) return;

    const msg = htmlSafe(
      `To correctly process your VAT exemption, please <b class="blue-alt">fill out your company info ${chevronIconHtml}</b>`
    );

    this.notifications.remove(this.billingDateNotification);
    this.billingDateNotification = this.notifications.warning(msg, {
      cssClasses: 'subscription-notification',
      onClick: () => {
        this.router.transitionTo('settings.billing');
        this.notifications.clearAll();
      },
    });
  }

  showUrlNotifications(url) {
    if (isPresent(url.google_analytics_error)) {
      this.notifyGoogleAnalyticsError(url);
    } else {
      this.notifications.remove(this.gaErrorNotification);
    }
  }

  showKeywordNotifications(params) {
    this.notifyNoKeywords(params);
    const isStillProcessing = this.notifyStillProcessing(params);
    if (isStillProcessing) return;

    this.notifyNotRanking(params);
  }

  notifyNoKeywords({ keywords, user, url }) {
    if (!url) return;

    let msg = '';
    let onClick = null;
    if (user.canAddKeywords) {
      msg = htmlSafe(
        `You haven't added any keywords for tracking. Don't let the owl sleep. <b class="blue-alt">Add keywords ${chevronIconHtml}</b>`
      );
      const addKeywordsRoute = this.router.urlFor(
        'dashboard.url.keywords.add.discover',
        url
      );
      onClick = () => {
        this.router.transitionTo(addKeywordsRoute);
        this.notifications.clearAll();
      };
    } else {
      msg = `No keywords added yet. This account doesn't have the permission to add keywords. Please ask your account manager to add some keywords for tracking.`;
    }

    if (keywords.length === 0) {
      this.clearUrlKeywordsNotifications();
      this.noKeywordsNotification = this.showWarningMsg(msg, onClick);
      return true;
    }
    return false;
  }

  notifyStillProcessing({ keywords }) {
    const notProcessedCount = keywords.filter((k) =>
      isBlank(k.last_processed_at)
    ).length;
    if (notProcessedCount > 0 && notProcessedCount === keywords.length) {
      this.clearUrlKeywordsNotifications();
      this.stillProcessingNotification = this.showWarningMsg(
        'The data for your keywords is being processed - it can take a few minutes...'
      );
      return true;
    }
    return false;
  }

  notifyNotRanking({ keywords }) {
    const notRankingCount = keywords.filter(
      (k) => isPresent(k.last_processed_at) && isBlank(k.position)
    ).length;

    if (notRankingCount > 0 && notRankingCount === keywords.length) {
      const msg =
        this.siteData.hideNightwatchBranding || this.siteData.isWhiteLabel
          ? htmlSafe(
              `Your website is not ranking for any of the <b>${keywords.length}</b> tracked keywords.`
            )
          : htmlSafe(
              `Your website is not ranking for any of the <b>${keywords.length}</b> tracked keywords. <b class="blue-alt">Read why</b> ${chevronIconHtml}`
            );
      const onClick = () => {
        if (this.siteData.hideNightwatchBranding || this.siteData.isWhiteLabel)
          return;

        window.open(DOCS.websiteIsntRanking, '_blank');
        this.notifications.clearAll();
      };
      this.clearUrlKeywordsNotifications();
      this.notRankingNotification = this.showWarningMsg(msg, onClick);
      return true;
    }
    return false;
  }

  showWarningMsg(msg, onClick, cssClasses = '') {
    return this.notifications.warning(msg, {
      cssClasses,
      onClick,
    });
  }

  showInfoMsg(msg) {
    return this.notifications.info(msg);
  }

  notifyGoogleAnalyticsError(url) {
    if (this.session.user?.isLimited) return;

    const msg = htmlSafe(
      `There is an authorization problem with your Google Analytics integration. Please reconnect your Google Analytics property in <b class="blue-alt">settings</b>.`
    );

    this.gaErrorNotification = this.notifications.warning(msg, {
      onClick: () => {
        this.router.transitionTo('dashboard.url.settings', url.id);
        this.notifications.clearAll();
      },
    });
  }

  clearUrlKeywordsNotifications() {
    this.notifications.remove(
      this.gaErrorNotification,
      this.noKeywordsNotification,
      this.stillProcessingNotification,
      this.notRankingNotification
    );
  }
}
