import Route from '@ember/routing/route';

export default class DashboardUrlSiteAuditViewChecksShowRoute extends Route {
  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    return { url: this.modelFor('dashboard.url') };
  }
}
