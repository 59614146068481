import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ReportGroupingChooserComponent extends Component {
  @tracked selectedGrouping = '';

  @action
  onInsert() {
    this.selectedGrouping = this.args.selected;
  }
}
