import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class UrlDynamicViewSettingsController extends Controller {
  @service router;

  @action
  afterDelete(url) {
    this.router.transitionTo('dashboard.url.keywords', url);
  }
}
