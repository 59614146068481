import Component from '@glimmer/component';
import siteData from 'nightwatch-web/constants/site-data';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
// import { action } from '@ember/object';

export default class BillingInfoFormComponent extends Component {
  @service session;
  @service notifications;
  @service siteData;

  @tracked userError = null;

  get user() {
    return this.session.user;
  }

  get countries() {
    return siteData.countries.map(([id, name]) => ({ id, name }));
  }

  // get billingCountryCode() {
  //   return this.countries.findBy('name', this.user.invoice_country)?.id;
  // }
  //
  // @action
  // setBillingCountry(countryId) {
  //   if (countryId === 'uk') {
  //     this.user.invoice_country = 'United Kingdom';
  //     return;
  //   }
  //   const billingCountry = this.countries.findBy('id', countryId)?.name;
  //   if (!billingCountry)
  //     throw new Error('Could not find billing country in country list.');
  //   this.user.invoice_country = this.countries.findBy('id', countryId)?.name;
  // }

  @task
  *save() {
    this.userError = null;
    if (!this.session.user.is_company) {
      this.session.user.invoice_vat_number = null;
    }
    try {
      yield this.session.user.save();
    } catch (response) {
      this.userError = `${response?.errors[0]?.detail}`;
    }
    this.notifications.success('Settings sucessfully updated.', {
      autoClear: true,
    });
    if (this.args.afterSave) this.args.afterSave();
  }
}
