import Service, { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';

export default class GeneralSearchService extends Service {
  @service fetch;

  search(query) {
    debounce(this, '_search', query, 250);
  }

  _search(query) {
    this.fetch
      .request('/search', { data: { search: query } })
      .then((results) => {
        this.set('results', results);
      });
  }

  get searchGroupIds() {
    if (!this.results) return [];
    const groupIds = this.get('results.url_groups');
    return groupIds.concat(this.searchGroupIdsWithNestedResult);
  }

  get searchGroupIdsWithNestedResult() {
    if (!this.results) return [];
    const groupIdsFromUrls = this.get('results.urls').mapBy('group_id');
    const groupIdsFromDynamicViews = this.get('results.dynamic_views').mapBy(
      'group_id'
    );
    return groupIdsFromUrls.concat(groupIdsFromDynamicViews);
  }
}
