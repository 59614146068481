import Model, { attr } from '@ember-data/model';
import { format } from 'date-fns';

export default class CrawlingSession extends Model {
  @attr('date') startedAt;
  @attr('date') createdAt;
  @attr('number') searchKeywordUrlId;
  @attr('date') endedAt;
  @attr('number') internalUrlsCount;
  @attr('string') status;
  @attr('string') endReason;
  @attr() ips;
  @attr() dns_servers;
  @attr() aliases;
  @attr('boolean') sitemap;
  @attr('boolean') robots;
  @attr('boolean') sslValid;
  @attr('date') sslValidUntil;
  @attr('number') pagesCount;
  @attr('number') version;
  @attr('number') nextCrawlInterval;

  get isDone() {
    return this.status === 'done';
  }
  get isPending() {
    return this.status === 'pending';
  }
  get isProcessing() {
    return this.status === 'processing';
  }
  get isPendingOrProcessing() {
    return this.isPending || this.isProcessing;
  }

  get forcefullyTerminated() {
    return this.endReason && this.endReason !== 'no_links';
  }

  get startedAtDateString() {
    return this.startedAt
      ? format(new Date(this.startedAt), 'MM-dd-yyyy')
      : format(new Date(), 'MM-dd-yyyy');
  }
}
