import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class GettingStartedComponent extends Component {
  @service store;

  @tracked videos = [];
  @tracked currentVideo;

  @task({ drop: true })
  *loadVideos() {
    this.videos = yield this.store.findAll('help-link');
    this.currentVideo = this.videos.firstObject;
  }
}
