import Service, { inject as service } from '@ember/service';
import groupBy from 'nightwatch-web/utils/group-by';

export default class UserNotificationsService extends Service {
  @service store;
  @service router;

  @groupBy('notifications', 'createdAtDateString')
  notificationsByDate;

  get notificationsEmpty() {
    return this.notificationsLoaded && this.get('notifications.length') === 0;
  }

  get allNotificationsLoaded() {
    return (
      parseInt(this.notificationsTotal) === this.get('notifications.length')
    );
  }

  init() {
    super.init(...arguments);
    this.set('notifications', []);
    this.set('page', 1);
    this.set('limit', 10);
    this.set('notificationsTotal', 0);
    this.set('notificationsLoaded', false);
  }

  loadMore() {
    this.set('page', parseInt(this.page) + 1);
    const params = { page: this.page };
    this.fetchNotifications(params).then((notifications) => {
      const existingNotifications = this.notifications.toArray();
      const newNotifications = existingNotifications.concat(
        notifications.toArray()
      );
      this.set('notifications', newNotifications);
    });
  }

  fetchNotifications(params = {}) {
    const defaultParams = {
      page: 1,
      limit: this.limit,
    };

    params = Object.assign(defaultParams, params);
    return this.store
      .query('user-notification', params)
      .then((notifications) => {
        this.set('notificationsLoaded', true);
        return notifications;
      });
  }

  fetchAndSetInitially(params) {
    return this.fetchNotifications(params).then((notifications) => {
      this.set('page', 1);
      this.set('notificationsTotal', notifications.meta.total);
      this.set('notifications', notifications);
    });
  }

  goToNotification(notification) {
    const urlId = notification.get('search_keyword_url_id');
    const notificationType = notification.get('notificationType');

    if (urlId) {
      switch (notificationType) {
        case 'index_pages_change':
          this.router.transitionTo('dashboard.url', urlId);
          break;
        case 'new_keyword_suggestions':
          this.router.transitionTo(
            'dashboard.url.keywords.add.discover',
            urlId
          );
          window.dispatchEvent(new Event('goto-keyword-suggestions'));
          break;
        case 'new_backlinks':
          this.router.transitionTo('dashboard.url.backlinks', urlId);
          break;
        default:
          this.router.transitionTo('dashboard.url', urlId);
      }
    }
  }
}
