import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { THEMES } from '../services/theme';

export default class ConfirmEmailRoute extends Route {
  @service session;
  @service metrics;
  @service phoneInput;
  @service theme;
  @service router;
  @service signup;

  // @action
  // didTransition() {
  //   this.metrics.trackEvent({ event: 'Signup Form' });
  //   return true;
  // }

  model(params) {
    return {
      code: params.code,
      email: params.email,
    };
  }

  beforeModel() {
    if (this.session.isAuthenticated) {
      this.router.transitionTo('dashboard');
    } else {
      this.theme.current = THEMES.BRIGHT;
      return this.phoneInput.load();
    }
  }

  afterModel(model) {
    if (model.code) {
      // If a code is provided, perform the verification automatically
      this.signup.submitVerificationCode.perform(model.code);
    }
  }
}
