import Modifier from 'ember-modifier';
import { keyResponder, onKey } from 'ember-keyboard';

/**
  @class GlobalSearch

  Sets up an input field to behave like "Find In Page" search.

  Press Command+f or Control+f
    Will focus this element, select its content
    and prevent the "Find In Page" search from appearing.

  Press Escape
    Will blur (unfocus) this element if it is selected.
 */
@keyResponder
export default class GlobalSearch extends Modifier {
  @onKey('cmd+f')
  focus(event) {
    event.preventDefault();
    this.element.focus();
    this.element.select();
  }

  @onKey('Escape')
  blur(event) {
    if (event.srcElement !== this.element) return;

    this.element.blur();
  }
}
