import { task } from 'ember-concurrency';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';
import { DimensionSubgroup } from './dimension-subgroup';

export class CompetitorDimension extends Dimension {
  requestWith = ['competitor'];

  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.filter((d) => d.competitor_series)
        ?.find((d) =>
          d.competitor_series.find(
            (dd) =>
              dd.competitor_id === parseInt(this.competitor.id) &&
              dd.name === this.name
          )
        )
        ?.competitor_series?.findBy('name', this.name)?.series ?? []
    );
  }
}

export class CompetitorDimensions extends DimensionGroup {
  label = 'Competitors';

  @task
  *partialLoadTask() {
    // No possible optimisation as competitors are loaded in groups only
    yield this.loadTask.perform();
  }

  @task({ drop: true })
  *loadTask() {
    yield this.url.loadCompetitors.perform();

    this.subgroups = this.url.competitors.map((competitor) => {
      return new DimensionSubgroup({
        label: competitor.displayName,
        dimensions: [
          new CompetitorDimension({
            ...this.dimensionProps,
            competitor,
            name: 'average_position',
            scaleId: 'nwPosition',
            title: 'Average Position',
            subTitle: competitor.friendlyUrl,
            label: `Average Position | ${competitor.displayName}`,
          }),
          new CompetitorDimension({
            ...this.dimensionProps,
            competitor,
            name: 'search_visibility_index',
            title: 'Search Visibility',
            subTitle: competitor.friendlyUrl,
            label: `Search Visibility | ${competitor.displayName}`,
          }),
          new CompetitorDimension({
            ...this.dimensionProps,
            competitor,
            name: 'click_potential',
            title: 'Click Potential',
            subTitle: competitor.friendlyUrl,
            label: `Click Potential | ${competitor.displayName}`,
          }),
        ],
      });
    });

    this.dimensions = this.subgroups.flatMap((s) => s.dimensions);
  }
}
