import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { relativeDateRanges } from 'nightwatch-web/constants/date-ranges';
import { DEFAULT_DATE_RANGE_TYPE } from 'nightwatch-web/models/graph';
import { DEFAULT_GROUPING_TYPE } from '../../../../constants/chart/grouping';
import { isToday, startOfDay } from 'date-fns';

export default class DashboardUrlChartNewRoute extends Route {
  @service metrics;
  @service store;

  activate() {
    window.scrollTo(0, 0);
  }

  async model(params) {
    const { serpPreviewKeywordId } = params;
    const url = this.modelFor('dashboard.url');
    let serpPreviewKeyword;

    const dateRange = relativeDateRanges().findBy(
      'type',
      DEFAULT_DATE_RANGE_TYPE
    );

    if (serpPreviewKeywordId) {
      serpPreviewKeyword = await this.store.findRecord(
        'keyword',
        serpPreviewKeywordId
      );
      // If creation date is today, use today as the end date instead of yesterday.
      // Prevents the SERP from showing nothing because the default date end date is yesterday.
      if (isToday(new Date(serpPreviewKeyword.created_at))) {
        dateRange.end = startOfDay(new Date());
      }
    }

    const graph = this.store.createRecord('graph', {
      name: 'New Graph',
      url,
      relativeDateRange: dateRange.type,
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
      grouping: DEFAULT_GROUPING_TYPE,
    });

    const defaultSeries = [
      {
        name: serpPreviewKeywordId ? 'position' : 'average_position',
        color: '#0ea1ec',
      },
      {
        name: 'click_potential',
        color: '#e7d153',
      },
    ];

    if (url.google_analytics_profile_id) {
      defaultSeries.push({
        name: 'sessions',
        color: '#8ad633',
      });
    }

    defaultSeries.forEach((series) => {
      this.store.createRecord('graph/series', {
        graph,
        keyword: serpPreviewKeyword,
        url,
        color: series.color,
        active: true,
        name: series.name,
      });
    });

    return { graph, serpPreviewKeyword };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.url = this.modelFor('dashboard.url');
  }

  resetController(controller) {
    const graph = controller.model;
    if (!graph.isNew) return;

    graph.series.invoke('unloadRecord');
    graph.unloadRecord();
  }

  @action
  didTransition() {
    this.metrics.trackEvent({
      event: 'Open Graph',
      name: this.controller.model.name,
    });
    return true;
  }
}
