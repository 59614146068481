import Route from '@ember/routing/route';

export default class DashboardUrlSiteAuditViewsNewRoute extends Route {
  activate() {
    window.scrollTo(0, 0);
  }

  model() {
    const url = this.modelFor('dashboard.url');
    return {
      url,
    };
  }
}
