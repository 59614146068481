import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class BacklinkFormComponent extends Component {
  @service fetch;
  @service notifications;
  @tracked backlinksInputError = null;
  @tracked isAdding = false;
  @tracked createdBacklinks = null;
  @tracked invalidBacklinks = null;
  @tracked backlinksInput = null;
  tags = [];

  get backlinksInputArray() {
    return (this.backlinksInput || '').split('\n').filter((l) => isPresent(l));
  }

  @action
  saveLinks() {
    const links = this.backlinksInputArray;
    if (links.length === 0) {
      this.backlinksInputError = 'Enter some links.';
      return;
    } else {
      this.backlinksInputError = null;
    }
    this.isAdding = true;
    const url = this.args.url;
    this.fetch
      .post('/backlinks/bulk_create', {
        data: {
          url_id: url.id,
          link_urls: links,
          tags: this.tags,
        },
      })
      .then((data = {}) => {
        const createdBacklinks = data.created_backlinks;
        const invalidBacklinks = data.invalid_backlinks;

        if (!invalidBacklinks || invalidBacklinks.length === 0) {
          this.createdBacklinks = null;
          this.notifications.success(
            'Links added successfully. Wait a couple of minutes to see them processed.',
            { autoClear: true, clearDuration: 8000 }
          );
          this.args.onBacklinksCreated();
        } else {
          const adjustedInvalidBacklinks = invalidBacklinks.map((b) => {
            return {
              url_from: b.url_from,
              errors: b.errors.map((e) => e.replace('Url from', 'link')),
            };
          });
          this.createdBacklinks = createdBacklinks;
          this.invalidBacklinks = adjustedInvalidBacklinks;
        }
      })
      .finally(() => {
        this.isAdding = false;
      });
  }
}
