import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import extractTld from 'nightwatch-web/utils/extract-tld';
import { inject as service } from '@ember/service';
import shakeElement from '../../utils/dom/shake-element';
import { startUrlRegex } from '../../utils/regexes';

export default class OnboardingUrlComponent extends Component {
  @service fetch;
  @service siteData;
  @service router;
  @service store;
  @tracked multiple = false;
  @tracked urls = [];

  @tracked errorsForMultiple;
  @tracked urlsAdded;

  @tracked deleteGroupModalShown = false;
  @tracked groupModalShown = false;
  @tracked groupForModal = null;
  @tracked groupForModal = null;
  @tracked groupForDeleteModal = null;
  @tracked updatedGroups;

  constructor() {
    super(...arguments); // Always call `super` in the constructor
    if (this.args.url.url) {
      this.fillLocalizationFromDomain();
    }
  }

  @action
  validateUrl(event) {
    const isValid = event.target.value.match(startUrlRegex) !== null;
    if (!isValid && event.target.value.length > 0) {
      event.target.setAttribute('error', 'true');
    } else {
      event.target.setAttribute('error', 'false');
    }
  }
  get groups() {
    return this.updatedGroups ?? this.args.urlGroups.toArray();
  }

  get urlGroup() {
    return (
      this.groups?.findBy('id', this.args.groupId) ?? this.groups.firstObject
    );
  }

  @action
  fillLocalizationFromDomain() {
    const url = this.args.url.url;
    const tld = extractTld(url);
    if (!tld) {
      return;
    }
    if (['com', 'net', 'org', 'io'].includes(tld.toLowerCase())) {
      this.args.url.country_code = 'US';
      this.args.url.language_code = 'en';
      return;
    }
    if (['gb'].includes(tld.toLowerCase())) {
      this.args.url.country_code = 'UK';
      this.args.url.language_code = 'en';
      return;
    }

    const countryCode = this.siteData.countries.find(
      (countryData) => countryData[0] === tld.toLowerCase()
    );

    if (countryCode) {
      const countryLanguage = this.siteData.countryLanguages.find(
        (countryLanguageData) => {
          const code = countryLanguageData[0].toLowerCase();
          return code === countryCode[0];
        }
      );
      const lang = countryLanguage?.[1];
      this.args.url.country_code = countryCode[0] ?? '';
      this.args.url.language_code = lang ?? 'en';
    }
  }

  @action
  onClose() {
    this.router.transitionTo('dashboard.overview');
  }

  @action
  toggleGroupModal(group) {
    if (!this.groupModalShown) {
      this.groupForModal = group ?? this.store.createRecord('url-group');
    } else {
      if (this?.groupForModal?.hasDirtyAttributes)
        this.groupForModal.rollbackAttributes();
      this.groupForModal = null;
    }
    this.groupModalShown = !this.groupModalShown;
    return false;
  }

  @action
  saveGroup(group, ev) {
    return group
      .save()
      .then((group) => {
        this.groupModalShown = !this.groupModalShown;

        // hack to add an object to model array without reloading
        const urlGroupsArray = this.args.urlGroups.toArray();
        urlGroupsArray.addObjects(this.args.urlGroups);
        urlGroupsArray.addObject(group);
        // this.set('@urlGroups', urlGroupsArray);
        this.updatedGroups = urlGroupsArray;

        this.args.updateUrlGroups(urlGroupsArray);
        this.args.updateGroupId({ id: group.id });
      })
      .catch(() => {
        shakeElement(ev.submitter);
      });
  }

  @action
  toggleDeleteGroupModal(group) {
    if (!this.deleteGroupModalShown) {
      this.toggleGroupModal(null);
      this.groupForDeleteModal = group;
    } else {
      this.toggleGroupModal(this.groupForDeleteModal);
      this.groupForDeleteModal = null;
    }

    this.deleteGroupModalShown = !this.deleteGroupModalShown;
    return false;
  }
}
