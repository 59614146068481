export const initialize = (application) => {
  application.inject('controller', 'notifications', 'service:notifications');
  application.inject('route', 'notifications', 'service:notifications');
  application.inject('component', 'notifications', 'service:notifications');
};

export default {
  name: 'inject-notifications',
  initialize,
};
