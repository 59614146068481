import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class KBarService extends Service {
  @service router;
  @service store;
  @service session;
  @tracked kbarVisible = false;
  @tracked urlGroups = null;
  @tracked searchTerm = null;
  @tracked selectedIndex = 0;
  general = [
    {
      name: 'Go to Overview',
      icon: 'icon-left-arrow',
      action: () => this.router.transitionTo('dashboard.overview'),
      omitUrl: true,
    },
    {
      name: 'Add URL',
      icon: 'icon-plus-circle',
      action: () => this.router.transitionTo('onboarding'),
      omitUrl: true,
    },
    {
      name: 'Create View',
      icon: 'icon-plus-circle',
      action: () => this.router.transitionTo('dashboard.dynamic-views.new'),
      omitUrl: true,
    },
    {
      name: 'App Settings',
      icon: 'icon-settings',
      action: () => this.router.transitionTo('settings.profile'),
      omitUrl: true,
    },
  ];
  navigation = [
    {
      name: 'Add Keywords',
      icon: 'icon-plus-circle',
      action: () => this.navigate('dashboard.url.keywords.add.manual'),
    },
    {
      name: 'Dashboard',
      icon: 'icon-dashboard',
      action: () => this.navigate('dashboard.url.dashboard'),
    },
    {
      name: 'Rankings',
      icon: 'icon-rankings',
      action: () => this.navigate('dashboard.url.keywords'),
    },
    {
      name: 'Graphs',
      icon: 'icon-analytics-traffic',
      action: () => this.navigate('dashboard.url.chart.new'),
    },
    {
      name: 'Site Audit',
      icon: 'icon-siteaudit',
      action: () => this.navigate('dashboard.url.site-audit.all'),
    },
    {
      name: 'Reports',
      icon: 'icon-reports',
      action: () => this.navigate('dashboard.url.reports'),
    },
    {
      name: 'Notes',
      icon: 'icon-notes',
      action: () => this.navigate('dashboard.url.notes'),
    },
    {
      name: 'URL Settings',
      icon: 'icon-settings',
      action: () => this.navigate('dashboard.url.settings.configuration.index'),
    },
  ];

  get isGeneralPage() {
    return !this.router.currentRouteName.match('dashboard.url');
  }

  get urlList() {
    // Form the URL list like the list of genera/navigate objects above.
    const urls = this.urlGroups?.toArray()?.flatMap((group) => {
      const dynamicViews = group.dynamicViews?.toArray()?.map((dynView) => {
        dynView.isDynamicView = true;
        return dynView;
      });
      return [...group.urls.toArray(), ...dynamicViews];
    });
    return (
      urls?.map((url) => {
        return {
          name: url.isYoutube
            ? `Open ${url.domain} - ${url.name}`
            : `Open ${url.domain || url.name}`,
          isUrl: true,
          icon: url.isDynamicView ? 'icon-eye' : null,
          omitUrl: !!url.isDynamicView,
          isDynamicView: !!url.isDynamicView,
          faviconUrl: url.faviconUrl,
          action: () => {
            if (url.isDynamicView) {
              this.router.transitionTo('dashboard.dynamic-view.keywords', url);
            } else {
              this.router.transitionTo('dashboard.url.keywords.index', url);
            }
          },
        };
      }) ?? []
    );
  }

  get limitedNavigation() {
    const filterArr = [];
    if (!this.session.user.canAddUrls) {
      filterArr.push('Add URL');
    }
    if (!this.session.user.canAddKeywords) {
      filterArr.push('Add Keywords');
    }
    if (this.session.user.isLimited) {
      filterArr.push('URL Settings');
    }
    const limitedGeneral = this.general?.filter(
      (item) => !filterArr.includes(item.name)
    );
    const limitedNavigation = this.navigation?.filter(
      (item) => !filterArr.includes(item.name)
    );
    return this.isGeneralPage
      ? limitedGeneral
      : [...limitedGeneral, ...limitedNavigation];
  }

  get filteredNavigation() {
    let itemsArray;
    if (this.session.user.isLimited) {
      itemsArray = [...this.limitedNavigation, ...this.urlList];
    } else {
      itemsArray = this.isGeneralPage
        ? [...this.general, ...this.urlList]
        : [...this.general, ...this.navigation, ...this.urlList];
    }
    // If there is no URL ID present just show the URL list and general nav options without the URL specific nav options
    return itemsArray.filter((item) => {
      return this.searchTerm
        ? item.name
            .toLocaleLowerCase()
            .match(this.searchTerm.toLocaleLowerCase())
        : this.navigation;
    });
  }

  // Depending on the route, the attributes are different, sometimes a URL is included, sometimes not.
  get currentUrlId() {
    return (
      this.router.currentRoute.attributes?.id ??
      this.router.currentRoute.attributes?.url?.id ??
      this.router.currentRoute.parent.params.url_id
    );
  }

  get currentUrl() {
    return (
      this.router.currentRoute?.attributes?.url ||
      this.router.currentRoute?.attributes?.url?.url
    );
  }

  get faviconUrl() {
    return (
      this.router.currentRoute?.attributes?.faviconUrl ||
      this.router.currentRoute?.attributes?.url?.faviconUrl
    );
  }

  get domain() {
    return (
      this.router.currentRoute?.attributes?.domain ||
      this.router.currentRoute?.attributes?.url?.domain
    );
  }

  navigate(routeName) {
    this.router.transitionTo(routeName, this.currentUrlId);
  }

  @action
  onChangeSearch(term) {
    this.selectedIndex = 0;
    this.searchTerm = term;
  }

  @task({ restartable: true })
  *loadUrls() {
    this.urlGroups = yield this.store.query('url-group', {
      static: true,
      include_default: false,
    });
  }
}
