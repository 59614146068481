import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import posthog from 'posthog-js';
import ENV from 'nightwatch-web/config/environment';
import * as Sentry from '@sentry/ember';

Sentry.init({
  dsn: ENV.sentryDsn,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
Sentry.setUser(null);

export default class App extends Application {
  constructor() {
    super();

    const host = window.location.host;
    if (
      !host.includes('127.0.0.1') &&
      !host.includes('localhost') &&
      !host.includes('nightwatch.dev')
    ) {
      posthog.init(ENV.posthog, { api_host: 'https://app.posthog.com' });
    }
  }

  modulePrefix = config.modulePrefix;
  podModulePrefix = '';
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
