/**
 * Helper function to construct an SVG tree
 * @param  {String}       tag         SVG element tag
 * @param  {Object}       [attrs={}]  Element attributes
 * @param  {Node|String}  children    Child node(s); SVG element(s) or text
 * @return {[Node]}                   SVG node
 */
export const svg = (tag, attrs = {}, ...children) => {
  const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
  Object.keys(attrs).forEach((attr) => {
    el.setAttributeNS(null, attr, attrs[attr]);
  });
  children.forEach((c) => {
    if (!c.toString()) return; // Skip empty strings
    if (typeof c !== 'object') {
      c = document.createTextNode(c.toString());
    }
    el.appendChild(c);
  });
  return el;
};
