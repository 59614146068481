import Component from '@glimmer/component';

export default class PageDetailsImagesComponent extends Component {
  get shownStats() {
    return this.args.totalImgCount > 0;
  }

  get restImagesCount() {
    return this.args.totalImgCount - this.args.images?.length;
  }
}
