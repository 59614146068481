import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { DAILY, WEEKLY, MONTHLY } from 'nightwatch-web/constants/time-periods';
import { task } from 'ember-concurrency';

const REPORT_INTERVALS = [
  [0, 'Never'],
  [DAILY, 'Daily'],
  [WEEKLY, 'Weekly'],
  [MONTHLY, 'Monthly'],
];

export default class SettingsProfileController extends Controller {
  @service reportData;
  @service session;
  @service notifications;
  @service siteData;

  get user() {
    return this.session.user;
  }

  get reportIntervalsForDropdown() {
    return REPORT_INTERVALS.map(([id, text]) => ({ id, text }));
  }

  get timezonesForDropdown() {
    const timezones = this.siteData.timezones;
    const result = [];
    for (let timezoneKey in timezones) {
      result.push({
        id: timezoneKey,
        text: timezones[timezoneKey],
      });
    }
    return result;
  }

  @task
  *save() {
    try {
      yield this.session.user.save();
      this.notifications.success('Settings sucessfully updated.', {
        autoClear: true,
      });
    } catch (error) {
      this.notifications.error('Settings update failed.');
      throw error;
    }
  }
}
